import React from "react";

import staticFiles from "juice-base/static-files.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmClassCodeSendEmails = (props) => {
    let content = `
        We sent links to your students!
        When they finish signing up, you'll get a notification that they want to join your class on The Juice.
    `;

    if (props.isLoading) {
        content = <RequestLoader />;
    }

    if (props.error) {
        content = props.error;
    }

    return (
        <PopupConfirm>
            <PopupConfirmIcon
                src={staticFiles.iconEmailGrey}
                alt="Emails"
            />

            <PopupConfirmContent>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    uppercase
                    onClick={props.onClose}
                >
                    Close
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmClassCodeSendEmails.defaultProps = {
    content: "",
    isLoading: false,
    onClose: () => {},
};

export default PopupConfirmClassCodeSendEmails;
