import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorBoundarySmall from "juice-base/components/error-boundary-small/index.js";
import Tutorial from "juice-base/components/tutorial/index.js";

import actions from "juice-base/store/actions.js";
import api from "juice-app/api.js";


const TutorialContainer = (props) => {
    if (!props.name) {
        return null;
    }

    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        dimensions: state.device.dimensions,
        user: state.user,
        tutorialsBySlug: state.tutorials.tutorialsBySlug,
    }));

    const isMobile = store.dimensions.width <= 1024;

    useEffect(() => {
        if (store.tutorialsBySlug[props.name]) {
            return;
        }

        api.tutorials.getTutorial({
            session: store.user.session,
            slug: props.name,
        }).then((res) => {
            if (res.ok && res.tutorial) {
                dispatch(actions.tutorials.setTutorialBySlug({
                    slug: props.name,
                    tutorial: res.tutorial || {},
                }));
            }
        });
    }, []);

    const getSteps = (tut) => {
        const steps = [];

        tut.steps.forEach((step, i) => {
            let target = step.desktop_selector;

            if (isMobile) {
                target = step.mobile_selector;
            }

            if (target) {
                steps.push({
                    target,
                    content: step.message,
                    disableBeacon: i === 0,
                    placement: isMobile ? "bottom-end" : "auto",
                });
            }
        });

        return steps;
    };

    const onEnd = () => {
        dispatch(actions.user.setUserOption({
            key: props.name,
            value: true,
        }));

        if (!store.user.session) {
            return;
        }

        const tutorial = store.tutorialsBySlug[props.name] || {};

        if (tutorial.isAlwaysVisible) {
            return;
        }

        api.user.setOption({
            session: store.user.session,
            key: props.name,
            value: true,
        });
    };

    const tutorial = store.tutorialsBySlug[props.name];

    if (!store.user.isOptionsLoaded || !tutorial || !tutorial.steps) {
        return null;
    }

    const options = store.user.options || {};
    const isFinished = options[props.name];

    if (isFinished && !tutorial.isAlwaysVisible) {
        return null;
    }

    const steps = getSteps(tutorial);

    return (
        <ErrorBoundarySmall>
            <Tutorial
                key={props.name}
                steps={steps}
                onEnd={onEnd}
                isMobile={isMobile}
            />
        </ErrorBoundarySmall>
    );
};

TutorialContainer.defaultProps = {
    name: "",
};

export default TutorialContainer;
