// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".by9J0UFc5QiVSBDYPUKs0 {\n    width: 100%;\n    height: 100%;\n\n    display: grid;\n    justify-content: center;\n\n    padding: 20% 1rem 1rem 1rem;\n}\n\n._2e1SXzvdWNBmGGMlOBHfOb {\n    z-index: 201;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-loading/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;;IAEvB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".popupLoading {\n    width: 100%;\n    height: 100%;\n\n    display: grid;\n    justify-content: center;\n\n    padding: 20% 1rem 1rem 1rem;\n}\n\n.overlappedPopup {\n    z-index: 201;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupLoading": "by9J0UFc5QiVSBDYPUKs0",
	"overlappedPopup": "_2e1SXzvdWNBmGGMlOBHfOb"
};
export default ___CSS_LOADER_EXPORT___;
