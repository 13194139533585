// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u4FwsTQ1_4AzRt-kYGHKi {\n    background-color: #fff;\n}\n\n/* --- */\n\n.CBFpf5t1mxQN0YpaP8Qq5 {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n._1RiqkhGieI9cFvgsKVcoef {\n    padding: 0 2rem 2rem 2rem;\n}\n\n._2y2PxTNi55XGRT4NwIxKgT {\n    padding: 0 2rem 2rem 2rem;\n}\n\n._3INYoSAP1PrGHRtC1durz3 {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .CBFpf5t1mxQN0YpaP8Qq5,\n    ._1RiqkhGieI9cFvgsKVcoef,\n    ._2y2PxTNi55XGRT4NwIxKgT,\n    ._3INYoSAP1PrGHRtC1durz3 {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/extra-juice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;;;;QAII,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".extraJuice {\n    background-color: #fff;\n}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.headline {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.playerAudioToggleButton {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .headline,\n    .playerAudioToggleButton,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extraJuice": "u4FwsTQ1_4AzRt-kYGHKi",
	"category": "CBFpf5t1mxQN0YpaP8Qq5",
	"headline": "_1RiqkhGieI9cFvgsKVcoef",
	"playerAudioToggleButton": "_2y2PxTNi55XGRT4NwIxKgT",
	"content": "_3INYoSAP1PrGHRtC1durz3"
};
export default ___CSS_LOADER_EXPORT___;
