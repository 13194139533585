import React from "react";

import { Link } from "react-router-dom";

import RequestLoader from "../request-loader/index.js";
import SectionTeacher from "../section-teacher/index.js";
import ChartPie from "../chart-pie/index.js";
import Dropdown from "../dropdown/index.js";
import ButtonWithMessage from "../button-with-message/index.js";

// TODO: rename style names
import styles from "./styles.module.css";


const TeacherClassStats = (props) => {
    const statsDates = [
        { value: "today", label: "Today" },
        { value: "yesterday", label: "Yesterday" },
        { value: "this-week", label: "This Week" },
        { value: "last-week", label: "Last Week" },
        { value: "this-month", label: "This Month" },
    ];

    const renderScoreAverage = () => {
        const scoreClasses = [
            styles.statsValueSection,
            styles.statsScoreAverage,
        ];

        return (
            <div className={scoreClasses.join(" ")}>
                <div className={styles.statsSectionValue}>
                    <div className={styles.statTitle}>
                        Average Score
                    </div>
                    <div className={styles.statValue}>
                        {props.stats.average}
                    </div>
                </div>
                <div
                    className={styles.statsSectionControl}
                    onClick={props.onViewAllScores}
                    onKeyPress={props.onViewAllScores}
                    role="button"
                    tabIndex="-1"
                >
                    View all scores
                </div>
            </div>
        );
    };

    const renderScoreRange = () => {
        let value = `${props.stats.range.min}% to ${props.stats.range.max}%`;

        if (props.stats.range.min === ""
            || props.stats.range.max === ""
        ) {
            value = "--";
        }

        const scoreClasses = [
            styles.statsValueSection,
            styles.statsScoreRange,
        ];

        return (
            <div className={scoreClasses.join(" ")}>
                <div className={styles.statsSectionValue}>
                    <div className={styles.statTitle}>
                        Score Range
                    </div>
                    <div className={styles.statValue}>
                        {value}
                    </div>
                </div>
                <Link
                    to="/class"
                    className={styles.statsSectionControl}
                >
                    View all scores
                </Link>
            </div>
        );
    };

    const renderStats = () => {
        if (props.isLoading) {
            return (
                <div className={styles.statsLoader}>
                    <RequestLoader />
                </div>
            );
        }

        if (props.error) {
            return (
                <div className={styles.noStats}>
                    {props.error}
                </div>
            );
        }

        if (!props.stats || !props.chartPie) {
            let range = props.dropdownRange;

            if (props.dropdownRange === "this-week") {
                range = "this week";
            }

            if (props.dropdownRange === "last-week") {
                range = "last week";
            }

            if (props.dropdownRange === "this-month") {
                range = "this month";
            }

            return (
                <div className={styles.noStats}>
                    {`The Juice was off ${range}, and so there is no activity data to see.
                    Change the timeframe to see your class activity stats.`}
                </div>
            );
        }

        return (
            <div className={styles.statsSection}>
                <div className={styles.statsPieChart}>
                    <ChartPie
                        title="Completion Rate"
                        chartContentClasses={styles.chartContent}
                        data={[
                            {
                                label: "Complete",
                                value: props.chartPie.complete,
                            },
                            {
                                label: "In Progress",
                                value: props.chartPie.inProgress,
                            },
                            {
                                label: "Unopened",
                                value: props.chartPie.unopened,
                            },
                        ]}
                    />
                </div>

                {renderScoreAverage()}

                {renderScoreRange()}
            </div>
        );
    };

    return (
        <SectionTeacher
            className={styles.section}
            dataComment="daily-juice-stats"
        >
            <div className={styles.dailyJuiceStats}>
                <div className={styles.dailyJuiceStatsHeader}>
                    <div className={styles.headerName}>
                        <div className={styles.headerNameTitle}>
                            Class Activity Stats
                        </div>
                        <div>
                            <ButtonWithMessage
                                message="Student activity and progress in today's edition of the Daily Juice."
                                messageClassName={styles.messageBlock}
                                triangleClassName={styles.messageTriangle}
                                pageWidth={props.pageWidth}
                            >
                                <div className={styles.messageButton}>
                                    ?
                                </div>
                            </ButtonWithMessage>
                        </div>
                    </div>
                    <div>
                        <Dropdown
                            selectedValue={props.dropdownRange}
                            values={statsDates}
                            onChange={props.onRangeChange}
                            withControlBoxShadow={false}
                        />
                    </div>
                </div>

                {renderStats()}
            </div>
        </SectionTeacher>
    );
};

TeacherClassStats.defaultProps = {
    pageWidth: 0,
    stats: {},
    chartPie: {},
    isLoading: false,
    dropdownRange: null,
    error: null,
    onRangeChange: () => {},
    onViewAllScores: () => {},
};

export default TeacherClassStats;
