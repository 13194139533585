// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1CuIGR7TmlI051zDsOIBYj {\n    min-height: 20rem;\n}\n\n._2lU1PCY9w1uHEeWYU9iMxB { }\n\n.MQk53skBePge-xRBoVB4c { }\n", "",{"version":3,"sources":["webpack://./base/components/forms/text-editor/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA,2BAAkB;;AAElB,yBAAS","sourcesContent":[".formField {\n    min-height: 20rem;\n}\n\n.formFieldError { }\n\n.error { }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": "_1CuIGR7TmlI051zDsOIBYj",
	"formFieldError": "_2lU1PCY9w1uHEeWYU9iMxB",
	"error": "MQk53skBePge-xRBoVB4c"
};
export default ___CSS_LOADER_EXPORT___;
