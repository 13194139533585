import React from "react";

import SectionStudent from "../section-student/index.js";
import Text from "../text/index.js";

import classAnnouncementStyles from "../class-announcement/styles.module.css";


const SectionAnnouncement = (props) => {
    return (
        <SectionStudent
            title={props.title}
            dataComment="section-announcement"
            showSeeMore={false}
        >
            <Text className={classAnnouncementStyles.content}>
                {props.content}
            </Text>
        </SectionStudent>
    );
};

SectionAnnouncement.defaultProps = {
    title: "Class announcement",
    content: null,
};

export default SectionAnnouncement;
