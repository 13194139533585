import React from "react";

import Close from "../close/index.js";
import PopupScroller from "../popup-scroller/index.js";

import styles from "./styles.module.css";


const PopupQuizReview = (props) => {
    const quistions = [];

    props.quistions.forEach((quiz, index) => {
        const answers = [];
        const studentAnswers = [];

        let studentAnswersCorrect = true;

        for (let i = 0; i < quiz.answers.length; i += 1) {
            const answerClasses = [styles.answer];

            if (quiz.answers[i].is_correct_answer) {
                answerClasses.push(styles.correctAnswer);
            }

            if (quiz.answers[i].is_user_choice) {
                studentAnswers.push(i + 1);
            }

            if (quiz.answers[i].is_user_choice && !quiz.answers[i].is_correct_answer) {
                studentAnswersCorrect = false;
            }

            answers.push(
                <div className={answerClasses.join(" ")}>
                    {`${i + 1}.  ${quiz.answers[i].answer}`}
                </div>,
            );
        }

        let studentAnswerd = (
            <div className={[styles.studentAnswer, styles.incorrect].join(" ")}>
                incorrect
            </div>
        );

        if (studentAnswersCorrect) {
            studentAnswerd = (
                <div className={[styles.studentAnswer, styles.correct].join(" ")}>
                    correct
                </div>
            );
        }

        quistions.push(
            <div className={styles.quiz}>
                <div className={styles.question}>
                    <div className={styles.qustionSmall}>
                        {`question ${index + 1}`}
                    </div>
                    <div className={styles.questionTitle}>
                        {quiz.question}
                    </div>
                </div>
                <div className={styles.studentAnswersBlock}>
                    <div className={styles.studentAnswers}>
                        {`Your Answer: ${studentAnswers.join(", ")}`}
                    </div>
                    {studentAnswerd}
                </div>
                <div>
                    {answers}
                </div>
            </div>,
        );
    });

    return (
        <PopupScroller
            onClose={props.onClose}
            popupClasses={styles.popup}
            showClose={false}
        >
            <div className={styles.popupContainer}>
                <Close
                    onClose={props.onClose}
                    className={styles.close}
                />
                {quistions}
            </div>
        </PopupScroller>
    );
};

PopupQuizReview.defaultProps = {
    popupClasses: "",
    onClose: () => {},
    quistions: [],
};

export default PopupQuizReview;
