// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ubp33jl310oLWMGSbusaR {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.nABKTHQV4T2xTFYcaCroe {\n    display: grid;\n}\n\n._19IbB25xuJHTfTtzG8Gzsp {\n    margin: 0 auto 2rem auto;\n    text-align: center;\n}\n\n._3QrPllnlBHZb39aPCS-0J6 {\n    margin: 1rem 0 0 0;\n\n    font-weight: 600;\n    font-size: 2rem;\n}\n\n@media only screen and (min-width: 521px) {\n    .nABKTHQV4T2xTFYcaCroe {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    ._1ubp33jl310oLWMGSbusaR {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/user/password-forgot/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;;IAElB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI;QACI,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".passwordForgot {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    display: grid;\n}\n\n.contentHeader {\n    margin: 0 auto 2rem auto;\n    text-align: center;\n}\n\n.formName {\n    margin: 1rem 0 0 0;\n\n    font-weight: 600;\n    font-size: 2rem;\n}\n\n@media only screen and (min-width: 521px) {\n    .content {\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .passwordForgot {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordForgot": "_1ubp33jl310oLWMGSbusaR",
	"content": "nABKTHQV4T2xTFYcaCroe",
	"contentHeader": "_19IbB25xuJHTfTtzG8Gzsp",
	"formName": "_3QrPllnlBHZb39aPCS-0J6"
};
export default ___CSS_LOADER_EXPORT___;
