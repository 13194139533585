// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Rp4NRQ26TVWKVtYY3h60c {\n    width: 100%;\n\n    border-radius: 1rem;\n    background: #fff;\n    box-shadow: 0rem 0.3rem 1rem 0.5rem #d5d5d5;\n\n    overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./base/components/section-teacher/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX,mBAAmB;IACnB,gBAAgB;IAChB,2CAA2C;;IAE3C,gBAAgB;AACpB","sourcesContent":[".section {\n    width: 100%;\n\n    border-radius: 1rem;\n    background: #fff;\n    box-shadow: 0rem 0.3rem 1rem 0.5rem #d5d5d5;\n\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "_2Rp4NRQ26TVWKVtYY3h60c"
};
export default ___CSS_LOADER_EXPORT___;
