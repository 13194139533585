// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MlB7S43cN4CvSG87LfrOT {\n    font-family: 'Soleil-Regular';\n    line-height: 1.8rem;\n}\n\n.MlB7S43cN4CvSG87LfrOT p {\n    margin: 1.2rem 0;\n}\n\n.MlB7S43cN4CvSG87LfrOT a {\n    display: inline-block;\n\n    color: #00e;\n    text-decoration: none;\n    font-weight: 600;\n    border-bottom: 0.2rem solid #00e;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.MlB7S43cN4CvSG87LfrOT a:visited {\n    color: #551a8b;\n    border-color: #551a8b;\n}\n", "",{"version":3,"sources":["webpack://./base/components/class-announcement/styles.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;;IAErB,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,gCAAgC;;IAEhC,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".content {\n    font-family: 'Soleil-Regular';\n    line-height: 1.8rem;\n}\n\n.content p {\n    margin: 1.2rem 0;\n}\n\n.content a {\n    display: inline-block;\n\n    color: #00e;\n    text-decoration: none;\n    font-weight: 600;\n    border-bottom: 0.2rem solid #00e;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.content a:visited {\n    color: #551a8b;\n    border-color: #551a8b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "MlB7S43cN4CvSG87LfrOT"
};
export default ___CSS_LOADER_EXPORT___;
