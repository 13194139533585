// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._33QEXjyBb_BMk16WmhiaCR {\n    width: 100%;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    padding: 1.5rem 3rem;\n\n    border: none;\n\n    cursor: pointer;\n    outline: none;\n\n    color: var(--cerulean);\n    background-color: #fff;\n\n    text-align: center;\n    text-decoration: none;\n    font-family: \"Soleil-Regular\";\n    font-size: 1.5rem;\n    font-weight: 600;\n}\n\n._2I6oB6QHabmJV24jJ-5YfY {\n    text-transform: uppercase;\n}\n\n._33QEXjyBb_BMk16WmhiaCR:hover {\n    background-color: #eee;\n}\n\n._33QEXjyBb_BMk16WmhiaCR:disabled {\n    color: var(--grey);\n    cursor: not-allowed;\n}\n", "",{"version":3,"sources":["webpack://./base/components/button-flat/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,oBAAoB;;IAEpB,YAAY;;IAEZ,eAAe;IACf,aAAa;;IAEb,sBAAsB;IACtB,sBAAsB;;IAEtB,kBAAkB;IAClB,qBAAqB;IACrB,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".button {\n    width: 100%;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    padding: 1.5rem 3rem;\n\n    border: none;\n\n    cursor: pointer;\n    outline: none;\n\n    color: var(--cerulean);\n    background-color: #fff;\n\n    text-align: center;\n    text-decoration: none;\n    font-family: \"Soleil-Regular\";\n    font-size: 1.5rem;\n    font-weight: 600;\n}\n\n.uppercase {\n    text-transform: uppercase;\n}\n\n.button:hover {\n    background-color: #eee;\n}\n\n.button:disabled {\n    color: var(--grey);\n    cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_33QEXjyBb_BMk16WmhiaCR",
	"uppercase": "_2I6oB6QHabmJV24jJ-5YfY"
};
export default ___CSS_LOADER_EXPORT___;
