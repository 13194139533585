import React from "react";

import styles from "./styles.module.css";


const CheckboxCustom = (props) => {
    return (
        <div
            className={styles.customCheckbox}
            data-comment={props.dataComment}
        >
            <input
                id={props.name}
                name={props.name}
                type="checkbox"
                className={styles.customCheckboxInput}
                checked={props.checked}
                disabled={props.disabled}
                onChange={props.onChange}
            />
            <label
                htmlFor={props.name}
                className={styles.customCheckboxLabel}
            >
                {props.label}
            </label>
        </div>
    );
};

CheckboxCustom.defaultProps = {
    name: "",
    label: "",
    checked: false,
    disabled: false,
    dataComment: "",
    onChange: () => {},
};

export default CheckboxCustom;
