// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1VBqJW4BG1g6TPa-3Zp3j- {\n    height: var(--mobile-header-bottom-height);\n    padding: 0.5rem;\n\n    background: #ffffff;\n    border-top: 0.1rem solid #e3e3e3;\n\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: center;\n    grid-column-gap: 3.5rem;\n}\n\n._3Bl60O06FOac20osxD3DZZ {\n    display: flex;\n    justify-items: center;\n\n    color: #828282;\n    text-decoration: none;\n\n    outline: none;\n}\n\n._3Bl60O06FOac20osxD3DZZ img {\n    filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(95%) contrast(87%);\n}\n\n._2Do6mh3Dv9iNqnRqX3WEcM {\n    color: #000000;\n}\n\n._2Do6mh3Dv9iNqnRqX3WEcM img {\n    filter: initial;\n}\n\n._2zVDRm2qGz4tnNhtE1NCox {\n    font-size: 1rem;\n    font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./base/components/short-nav/styles.module.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,eAAe;;IAEf,mBAAmB;IACnB,gCAAgC;;IAEhC,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,qBAAqB;;IAErB,cAAc;IACd,qBAAqB;;IAErB,aAAa;AACjB;;AAEA;IACI,2FAA2F;AAC/F;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".shortNav {\n    height: var(--mobile-header-bottom-height);\n    padding: 0.5rem;\n\n    background: #ffffff;\n    border-top: 0.1rem solid #e3e3e3;\n\n    display: grid;\n    grid-auto-flow: column;\n    justify-content: center;\n    grid-column-gap: 3.5rem;\n}\n\n.section {\n    display: flex;\n    justify-items: center;\n\n    color: #828282;\n    text-decoration: none;\n\n    outline: none;\n}\n\n.section img {\n    filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(220deg) brightness(95%) contrast(87%);\n}\n\n.sectionSelected {\n    color: #000000;\n}\n\n.sectionSelected img {\n    filter: initial;\n}\n\n.sectionName {\n    font-size: 1rem;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shortNav": "_1VBqJW4BG1g6TPa-3Zp3j-",
	"section": "_3Bl60O06FOac20osxD3DZZ",
	"sectionSelected": "_2Do6mh3Dv9iNqnRqX3WEcM",
	"sectionName": "_2zVDRm2qGz4tnNhtE1NCox"
};
export default ___CSS_LOADER_EXPORT___;
