// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3e7JkWw66KVQGLeEnGtYJN {\n    width: 100%;\n    height: 100%;\n\n    overflow: hidden;\n}\n\n._2TkWVGw95sXJe_mAyhqUNx {\n    display: grid;\n    grid-auto-flow: column;\n\n    transition: all 1000ms ease-in-out 0s\n}\n\n._12X07mdLeaHxYOHQ9Hum9D {\n    width: 100vw;\n\n    /* NOTE: with additional nav shadow indent */\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem);\n}\n\n@media only screen and (min-width: 1025px) {\n    ._12X07mdLeaHxYOHQ9Hum9D {\n        height: calc(100vh - var(--main-header-height) - var(--daily-juice-nav-height-desktop));\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/swiper-popup-pages/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB;AACJ;;AAEA;IACI,YAAY;;IAEZ,4CAA4C;IAC5C,iGAAiG;AACrG;;AAEA;IACI;QACI,uFAAuF;IAC3F;AACJ","sourcesContent":[".swipper {\n    width: 100%;\n    height: 100%;\n\n    overflow: hidden;\n}\n\n.pages {\n    display: grid;\n    grid-auto-flow: column;\n\n    transition: all 1000ms ease-in-out 0s\n}\n\n.page {\n    width: 100vw;\n\n    /* NOTE: with additional nav shadow indent */\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem);\n}\n\n@media only screen and (min-width: 1025px) {\n    .page {\n        height: calc(100vh - var(--main-header-height) - var(--daily-juice-nav-height-desktop));\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swipper": "_3e7JkWw66KVQGLeEnGtYJN",
	"pages": "_2TkWVGw95sXJe_mAyhqUNx",
	"page": "_12X07mdLeaHxYOHQ9Hum9D"
};
export default ___CSS_LOADER_EXPORT___;
