// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1KZ9d1VVQmUTjRsKgCedCV {}\n\n._1j3WuvNaSOGkt78qDpvizK {\n    display: grid;\n    text-align: center;\n    word-break: break-all;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-new-password/styles.module.css"],"names":[],"mappings":"AAAA,0BAAU;;AAEV;IACI,aAAa;IACb,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".content {}\n\n.message {\n    display: grid;\n    text-align: center;\n    word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_1KZ9d1VVQmUTjRsKgCedCV",
	"message": "_1j3WuvNaSOGkt78qDpvizK"
};
export default ___CSS_LOADER_EXPORT___;
