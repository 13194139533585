import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import staticFiles from "juice-base/static-files.js";

import DailyJuices from "juice-base/project/daily-juices.js";

import { withAuth } from "juice-base/components/auth/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import Tabs from "juice-base/components/tabs/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import SectionAnnouncement from "juice-base/components/section-announcement/index.js";
import TeacherJuiceContent from "juice-base/components/teacher-juice-content/index.js";
import DatePickerSingle from "juice-base/components/date-picker-single/index.js";
import ClassroomShareButton from "juice-base/components/google-classroom-share-button/index.js";

import PopupImage from "juice-base/components/popup-image/index.js";
import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import {
    tryFormatDate,
    getDateFromDate,
    formatMonthDayDate,
} from "juice-base/lib/date.js";

import * as events from "juice-base/events.js";
import actions from "juice-base/store/actions.js";
import device from "juice-base/lib/device.js";
import storage from "juice-base/lib/storage/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const TeacherDailyJuicePage = () => {
    const [wordPopupState, setWordPopup] = useState({
        isLoading: false,
        word: "",
        errors: {},
        isOpen: false,
    });

    const [imagePopupState, setImagePopup] = useState({
        isOpen: false,
        img: {
            url: null,
            title: null,
        },
    });

    const [teacherClasses, setTeacherClasses] = useState({
        isLoaded: false,
        classes: [],
        selectedClassId: -1,
    });

    // TODO: redux
    const [juiceState, setJuice] = useState({
        isLoaded: false,
        juiceId: -1,
        juiceTitle: "",
        juiceDate: "",
        stories: [],
        announcement: {},
        error: null,
    });

    const [availableJuices, setAvailableJuices] = useState({
        isLoaded: false,
        dates: [],
    });

    const [datePickerState, setDatePicker] = useState({
        isLoaded: false,
        date: null,
    });

    const store = useSelector((state) => ({
        session: state.user.session,
        wordsByName: state.vocabulary.wordsByName,
        dimensions: state.device.dimensions,
        teacher: state.teacher,
        t2sWords: state.text2speech.words,
    }));

    const dispatch = useDispatch();

    const am = useAudioManager({
        isMac: device.isMac,
    });

    useEffect(() => {
        if (store.session) {
            api.classes.getTeacherClasses({
                session: store.session,
            }).then((res) => {
                if (res.ok) {
                    const classes = res.classes || [];

                    let classId = storage.local.loadTeacherSelectedClass();

                    let classStillExists = false;

                    if (classId) {
                        for (let i = 0; i < classes.length; i += 1) {
                            if (classes[i].id === parseInt(classId, 10)) {
                                classStillExists = true;
                                break;
                            }
                        }
                    }

                    classId = parseInt(classId, 10);

                    if (!classStillExists && classes[0]) {
                        classId = classes[0].id || -1;
                        storage.local.saveTeacherSelectedClass(classId);
                    }

                    dispatch(actions.teacher.setTeacherSelectedClass({
                        selectedClassId: classId,
                    }));

                    setTeacherClasses({
                        isLoaded: true,
                        selectedClassId: classId,
                        classes,
                    });
                }
            });
        }
    }, [store.session]);

    useEffect(() => {
        am.setFiles({
            words: store.t2sWords,
        });
    }, [store.t2sWords]);

    const loadAvailableJuices = () => {
        setAvailableJuices({
            isLoaded: false,
            dates: [],
        });

        api.dailyJuices.getJuicesDates({
            session: store.session,
            classId: teacherClasses.selectedClassId,
        }).then((res) => {
            const dates = [];

            if (res.ok) {
                res.juices.forEach((juice) => {
                    dates.push(juice.date);
                });
            }

            // TODO: use redux
            setAvailableJuices({
                isLoaded: true,
                dates,
            });
        });
    };

    const loadJuiceByDate = (date) => {
        setJuice((prev) => ({
            ...prev,
            isLoaded: false,
            juiceId: -1,
            juiceTitle: "",
            juiceDate: "",
            stories: [],
            announcement: {},
            error: null,
        }));

        const juiceDate = tryFormatDate(date, getDateFromDate);

        api.dailyJuices.getTeacherJuiceByDate({
            session: store.session,
            classId: teacherClasses.selectedClassId,
            date: juiceDate,
        }).then((res) => {
            if (!res.ok) {
                let errorText = res.error || "";

                if (errorText.indexOf(juiceDate)) {
                    const dateStr = tryFormatDate(date, formatMonthDayDate);
                    errorText = `No published juices on ${dateStr}`;
                }

                setJuice((prev) => ({
                    ...prev,
                    isLoaded: true,
                    error: errorText,
                }));
                return;
            }

            let announcement = {};

            if (res.data.classCustomContent) {
                announcement = {
                    title: res.data.classCustomContent.announcementTitle || "Class announcement",
                    content: res.data.classCustomContent.announcementContent || "",
                };
            }

            events.teacherDailyJuiceOpen({
                session: store.session,
                juiceId: res.data.id,
            });

            setJuice((prev) => ({
                ...prev,
                isLoaded: true,
                juiceId: res.data.ID,
                juiceTitle: res.data.title,
                juiceDate: res.data.juiceDate,
                stories: res.data.stories.juice,
                announcement,
            }));
        });
    };

    useEffect(() => {
        if (teacherClasses.selectedClassId && teacherClasses.selectedClassId !== -1) {
            setDatePicker({
                isLoaded: false,
                date: null,
            });

            loadAvailableJuices();

            api.site.getSiteDate().then((res) => {
                const siteDate = res.ok ? res.date : new Date();

                setDatePicker({
                    isLoaded: true,
                    date: siteDate,
                });

                loadJuiceByDate(siteDate);
            });
        }
    }, [teacherClasses.selectedClassId]);

    const onWordClick = (word, extraJuiceId) => {
        if (extraJuiceId) {
            events.teacherDailyJuiceExtrajuiceVocabOpen({
                session: store.session,
                extraJuiceId,
                // TODO:
                wordId: -1,
            });
        }

        setWordPopup({
            word,
            isLoading: true,
            isOpen: true,
        });

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));
            } else {
                setWordPopup((prev) => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        [word]: "Cannot load word",
                    },
                }));
            }

            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: wordValue,
            }));
        }).catch(() => {
            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: "",
                errors: {
                    ...prev.errors,
                    [word]: "Cannot load word",
                },
            }));
        });
    };

    const onExtraJuiceClick = (values) => {
        if (values.isExtended && values.extraJuice && values.extraJuice.id) {
            events.teacherDailyJuiceExtraJuiceOpen({
                session: store.session,
                juiceId: values.juiceId,
                extraJuiceId: values.extraJuice.id,
            });
        }
    };

    const onTabChange = (id) => {
        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: id,
        }));
        storage.local.saveTeacherSelectedClass(id);

        setTeacherClasses((prevState) => ({
            ...prevState,
            selectedClassId: id,
        }));
    };

    const onDateChange = (value) => {
        const newDate = new Date(value);

        setDatePicker({
            date: newDate,
            isLoaded: true,
        });

        loadJuiceByDate(newDate);
    };

    const onWordLoad = (wordId, isDefinition) => {
        if (isDefinition) {
            events.teacherDailyJuiceDefinitionPlay({
                session: store.session,
                wordId,
            });
        } else {
            events.teacherDailyJuicePronouncePlay({
                session: store.session,
                wordId,
            });
        }
    };

    const isContentLoaded = () => {
        if (!availableJuices.isLoaded
            || !datePickerState.isLoaded
            || !juiceState.isLoaded
            || juiceState.error
            || !juiceState.stories
            || juiceState.stories.length === 0) {
            return false;
        }

        return true;
    };

    const renderTutorial = () => {
        if (!isContentLoaded()) {
            return null;
        }

        return (
            <Tutorial
                name="teacher-daily-juices"
            />
        );
    };

    const renderImagePopup = () => {
        if (!imagePopupState.isOpen) {
            return null;
        }

        return (
            <PopupImage
                image={imagePopupState.img}
                popupClasses={styles.fixedPopup}
                onClose={() => {
                    setImagePopup({
                        isOpen: false,
                        img: {
                            url: null,
                            title: null,
                        },
                    });
                }}
            />
        );
    };

    const renderWordPopup = () => {
        if (!wordPopupState.isOpen) {
            return null;
        }

        if (wordPopupState.isLoading) {
            return (
                <PopupLoading
                    popupClasses={styles.fixedPopup}
                />
            );
        }

        const wordLowerCase = wordPopupState.word.toLowerCase();

        let word = store.wordsByName[wordPopupState.word];

        if (!word && store.wordsByName[wordLowerCase]) {
            word = store.wordsByName[wordLowerCase];
        }

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    popupClasses={styles.fixedPopup}
                    onClose={() => {
                        setWordPopup({
                            isOpen: false,
                            isLoading: false,
                            word: "",
                            errors: {},
                        });
                    }}
                />
            );
        }

        const trackGroupName = "words";
        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                popupClasses={styles.fixedPopup}
                onLoad={(text, wordId, isDefinition) => {
                    onWordLoad(wordId, isDefinition);

                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));

                            am.playerPlayNext(trackGroupName, text);
                        }
                    });
                }}
                onPlay={(text, wordId, isDefinition) => {
                    onWordLoad(wordId, isDefinition);

                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={() => {
                    setWordPopup({
                        isOpen: false,
                        isLoading: false,
                        word: "",
                        errors: {},
                    });
                }}
                closeOnLayoutClick
            />
        );
    };

    const renderClassAnnouncement = () => {
        if (!juiceState.isLoaded) {
            return null;
        }

        return (
            <SectionAnnouncement
                title={juiceState.announcement.title}
                content={juiceState.announcement.content}
            />
        );
    };

    const renderTabs = () => {
        return (
            <div className={styles.classesTabs}>
                <Tabs
                    tabs={teacherClasses.classes}
                    onChange={(title, id) => {
                        onTabChange(id);
                    }}
                    selectedClassId={teacherClasses.selectedClassId}
                    dimensions={store.dimensions}
                />
            </div>
        );
    };

    const renderJuiceSelector = () => {
        if (!availableJuices.isLoaded
            || !datePickerState.isLoaded) {
            return (
                <RequestLoader />
            );
        }

        const customInputIcon = (
            <img
                className={styles.customInputIcon}
                src={staticFiles.arrowInCircle}
                alt="Change date"
            />
        );

        let shareButton = null;

        if (juiceState.isLoaded && juiceState.juiceId !== -1) {
            const juiceParam = DailyJuices.getJuiceDayDate(juiceState.juiceDate)
                || juiceState.juiceId;

            shareButton = (
                <div className={styles.shareButton}>
                    <ClassroomShareButton
                        title={`Daily Juice: ${juiceState.juiceTitle}`}
                        url={`/daily-juice/${juiceParam}`}
                        dataComment="classroom-share-button-daily-juice"
                    />
                </div>
            );
        }

        return (
            <div className={styles.dailyJuiceSelector}>
                <DatePickerSingle
                    date={datePickerState.date}
                    customInputIcon={customInputIcon}
                    availableDates={availableJuices.dates}
                    dataComment="juice-date-selector"
                    onChange={onDateChange}
                />
                {shareButton}
            </div>
        );
    };

    const renderContent = () => {
        if (!availableJuices.isLoaded
            || !datePickerState.isLoaded) {
            return null;
        }

        if (!juiceState.isLoaded) {
            return (
                <RequestLoader />
            );
        }

        if (juiceState.error) {
            return (
                <div className={styles.juiceError}>
                    {juiceState.error}
                </div>
            );
        }

        if (!juiceState.stories || juiceState.stories.length === 0) {
            return (
                <div className={styles.juiceError}>
                    Stories not found
                </div>
            );
        }

        const juiceContents = [];

        juiceState.stories.forEach((story) => {
            let featuredVideo = null;

            if (story.categoryName !== "Vocabulary" && story.featuredVideo && story.featuredVideo.url) {
                let posterUrl = "";
                if (story.featuredVideo.featuredImage && story.featuredVideo.featuredImage.url) {
                    posterUrl = story.featuredVideo.featuredImage.url;
                }

                const captionApiUrl = api.videos.getVideoCaptionURL({
                    id: story.featuredVideo.id,
                    session: store.session,
                });

                featuredVideo = {
                    src: story.featuredVideo.url,
                    captionSrc: captionApiUrl,
                    type: story.featuredVideo.mimeType,
                    poster: posterUrl,
                };
            }

            // TODO: move storyId, storyTitle, storyCategory, content -> in one object

            juiceContents.push(
                <TeacherJuiceContent
                    juiceId={juiceState.juiceId}
                    storyId={story.ID}
                    storyTitle={story.title}
                    storyCategory={story.categoryName}
                    content={story.content}
                    featuredImage={story.categoryName === "Vocabulary" ? null : story.featuredImage}
                    backgroundImage={story.categoryName === "Vocabulary" ? null : story.backgroundImage}
                    featuredVideo={featuredVideo}
                    isDefaultVideo={!device.isChrome}
                    quizzes={story.quizzes}
                    extraJuices={story.extraJuices || []}
                    onExtraJuiceClick={onExtraJuiceClick}
                    onFeaturedImageFullScreen={(img) => {
                        setImagePopup({
                            isOpen: true,
                            img: {
                                url: img.url,
                                title: img.title,
                            },
                        });
                    }}
                    onVideoPlayStart={() => {
                        events.teacherDailyJuiceVideoStart({
                            storyId: story.ID,
                            videoId: story.featuredVideo.id || -1,
                        });
                    }}
                    onVideoPause={() => {
                        events.teacherDailyJuiceVideoPause({
                            storyId: story.ID,
                            videoId: story.featuredVideo.id || -1,
                        });
                    }}
                    onWordClick={(word, extraJuiceId = null) => {
                        onWordClick(word, extraJuiceId);
                    }}
                />,
            );
        });

        return (
            <>
                {renderClassAnnouncement()}
                <div className={styles.stories}>
                    {juiceContents}
                </div>
            </>
        );
    };

    if (!teacherClasses.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderTutorial()}

            {renderImagePopup()}
            {renderWordPopup()}

            <div
                data-comment="teacher-daily-juice"
                className={styles.teacherDailyJuice}
            >
                <div className={styles.content}>
                    {renderTabs()}
                    {renderJuiceSelector()}
                    {renderContent()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["teacher"])(TeacherDailyJuicePage);
