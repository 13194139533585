// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._28UbX6ypzGXVQ5AO-Ixhkx {\n    text-align: center;\n    margin: 2rem 0;\n}\n\n/* ----- */\n\n._1leQeui_HNatQ2R3_9K9D_ {\n    display: grid;\n    grid-template-columns: 5rem auto;\n    justify-content: start;\n    align-items: center;\n\n    padding: 0.5rem 1.5rem;\n\n    background-color: #fff;\n    color: #000;\n}\n\n._238q6iq6zMzDBbBCuCnJFM {\n    background-color: #ffffff;\n}\n\n._1DHcDlpwBWKEJdg_9oSm3G {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n._13qZFbqMQXl1NMRsntBRZ7 {\n    display: block;\n    padding: 0.3rem 0 0.3rem 0;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-grade-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,gCAAgC;IAChC,sBAAsB;IACtB,mBAAmB;;IAEnB,sBAAsB;;IAEtB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,0BAA0B;;IAE1B,eAAe;IACf,aAAa;AACjB","sourcesContent":[".header {\n    text-align: center;\n    margin: 2rem 0;\n}\n\n/* ----- */\n\n.field {\n    display: grid;\n    grid-template-columns: 5rem auto;\n    justify-content: start;\n    align-items: center;\n\n    padding: 0.5rem 1.5rem;\n\n    background-color: #fff;\n    color: #000;\n}\n\n.fieldChecked {\n    background-color: #ffffff;\n}\n\n.fieldWrapper {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.fieldLabel {\n    display: block;\n    padding: 0.3rem 0 0.3rem 0;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_28UbX6ypzGXVQ5AO-Ixhkx",
	"field": "_1leQeui_HNatQ2R3_9K9D_",
	"fieldChecked": "_238q6iq6zMzDBbBCuCnJFM",
	"fieldWrapper": "_1DHcDlpwBWKEJdg_9oSm3G",
	"fieldLabel": "_13qZFbqMQXl1NMRsntBRZ7"
};
export default ___CSS_LOADER_EXPORT___;
