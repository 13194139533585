// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2CXhvKwAB1OQPjGNwTrtEI {\n    text-align: center;\n    margin: 2rem 0;\n}\n\n.F0Lfn6q7aEogn-mxcaK1r {\n    margin: 2rem;\n}\n\n._2DhVf_TNcA9ViyBkN1r1Kt {\n    color: crimson;\n}\n\n/* ----- */\n\n._3W4FIGYa2LqgxN6owZr9qP {\n    display: grid;\n    grid-template-columns: 5rem auto;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n\n    background-color: #fff;\n    color: #000;\n}\n\n._1H-_uj8YKHQBJ3xPz1bp9D {\n    background-color: #ffffff;\n}\n\n._34FXXwyLAYZj2q_fcVgqdw {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n._2V2BpBdO-Q4ggMr0mkX3dn {\n    display: block;\n    padding: 0.3rem 0 0.3rem 0;\n\n    cursor: pointer;\n    outline: none;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-email-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA,UAAU;;AAEV;IACI,aAAa;IACb,gCAAgC;IAChC,mBAAmB;;IAEnB,oBAAoB;;IAEpB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,0BAA0B;;IAE1B,eAAe;IACf,aAAa;AACjB","sourcesContent":[".header {\n    text-align: center;\n    margin: 2rem 0;\n}\n\n.popupContent {\n    margin: 2rem;\n}\n\n.errorText {\n    color: crimson;\n}\n\n/* ----- */\n\n.field {\n    display: grid;\n    grid-template-columns: 5rem auto;\n    align-items: center;\n\n    padding: 1rem 1.5rem;\n\n    background-color: #fff;\n    color: #000;\n}\n\n.fieldChecked {\n    background-color: #ffffff;\n}\n\n.fieldWrapper {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.fieldLabel {\n    display: block;\n    padding: 0.3rem 0 0.3rem 0;\n\n    cursor: pointer;\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_2CXhvKwAB1OQPjGNwTrtEI",
	"popupContent": "F0Lfn6q7aEogn-mxcaK1r",
	"errorText": "_2DhVf_TNcA9ViyBkN1r1Kt",
	"field": "_3W4FIGYa2LqgxN6owZr9qP",
	"fieldChecked": "_1H-_uj8YKHQBJ3xPz1bp9D",
	"fieldWrapper": "_34FXXwyLAYZj2q_fcVgqdw",
	"fieldLabel": "_2V2BpBdO-Q4ggMr0mkX3dn"
};
export default ___CSS_LOADER_EXPORT___;
