// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1x_rGakzV6aP1Fhn1Vhydv {\n    color: var(--cerulean);\n    text-decoration: none;\n    font-weight: 600;\n    border-bottom: 0.2rem solid var(--cerulean);\n\n    cursor: pointer;\n    outline: none;\n}\n\n._1x_rGakzV6aP1Fhn1Vhydv:visited {\n    color: #551a8b;\n    border-color: #551a8b;\n}\n", "",{"version":3,"sources":["webpack://./base/components/link/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,gBAAgB;IAChB,2CAA2C;;IAE3C,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".link {\n    color: var(--cerulean);\n    text-decoration: none;\n    font-weight: 600;\n    border-bottom: 0.2rem solid var(--cerulean);\n\n    cursor: pointer;\n    outline: none;\n}\n\n.link:visited {\n    color: #551a8b;\n    border-color: #551a8b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "_1x_rGakzV6aP1Fhn1Vhydv"
};
export default ___CSS_LOADER_EXPORT___;
