// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._253Xo73dxzPNwsc80zrdkg {\n    width: 100%;\n    height: 100%;\n\n    overflow: hidden;\n}\n\n._2A5krsYOLFBNs38963lxst {\n    display: grid;\n    grid-auto-flow: column;\n\n    transition: all 1000ms ease-in-out 0s\n}\n\n._3qlFDgdmcGuCdmyWXnG07q {\n    width: 100vw;\n\n    /* NOTE: with additional nav shadow indent */\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem);\n}\n\n._2iXq7okQJKoYuZkU7ix0qg {\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem - 7rem);\n}\n\n@media only screen and (min-width: 1025px) {\n    ._3qlFDgdmcGuCdmyWXnG07q {\n        height: calc(100vh - var(--main-header-height) - var(--daily-juice-nav-height-desktop));\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/swiper-daily-juice-stories/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB;AACJ;;AAEA;IACI,YAAY;;IAEZ,4CAA4C;IAC5C,iGAAiG;AACrG;;AAEA;IACI,wGAAwG;AAC5G;;AAEA;IACI;QACI,uFAAuF;IAC3F;AACJ","sourcesContent":[".swiper {\n    width: 100%;\n    height: 100%;\n\n    overflow: hidden;\n}\n\n.pages {\n    display: grid;\n    grid-auto-flow: column;\n\n    transition: all 1000ms ease-in-out 0s\n}\n\n.page {\n    width: 100vw;\n\n    /* NOTE: with additional nav shadow indent */\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem);\n}\n\n.pageForSafari {\n    height: calc(100vh - var(--daily-juice-nav-height) - var(--mobile-header-bottom-height) - 0.3rem - 7rem);\n}\n\n@media only screen and (min-width: 1025px) {\n    .page {\n        height: calc(100vh - var(--main-header-height) - var(--daily-juice-nav-height-desktop));\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": "_253Xo73dxzPNwsc80zrdkg",
	"pages": "_2A5krsYOLFBNs38963lxst",
	"page": "_3qlFDgdmcGuCdmyWXnG07q",
	"pageForSafari": "_2iXq7okQJKoYuZkU7ix0qg"
};
export default ___CSS_LOADER_EXPORT___;
