import React, { useEffect, useState } from "react";

import PopupCommon from "juice-base/components/popup-common/index.js";
import PopupConfirmStudentDuplicate from "juice-base/business/popup-confirm-student-duplicate/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


import LoaderSmall from "juice-base/components/loader-small/index.js";

import styles from "./styles.module.css";


const PopupAddStudentsProgress = (props) => {
    const [isVisibleFinsihedPopup, setVisibleFinishedPopup] = useState(false);

    useEffect(() => {
        if (props.data.length === props.totalLoaded) {
            setVisibleFinishedPopup(true);
        }
    }, [props.totalLoaded]);

    const renderList = () => {
        const list = props.data.map((student) => {
            let status = null;

            if (!student.status || student.status === "loading") {
                status = (
                    <LoaderSmall />
                );
            } else if (student.status === "success") {
                status = (
                    <div>Success!</div>
                );
            } else if (student.status === "failed") {
                status = (
                    <div>Failed!</div>
                );
            } else if (student.status === "skipped") {
                status = (
                    <div>Skipped!</div>
                );
            }

            return (
                <div className={styles.studentRow}>
                    <div>
                        {`${student.nameValue} ${student.lastNameValue}`}
                    </div>
                    <div>
                        {status}
                    </div>
                </div>
            );
        });

        return (
            <div className={styles.studentsList}>
                {list}
            </div>
        );
    };

    const renderFinishedPopup = () => {
        if (!isVisibleFinsihedPopup) {
            return null;
        }

        let addedStudentsCount = 0;

        for (let i = 0; i < props.data.length; i += 1) {
            if (props.data[i].status === "success") {
                addedStudentsCount += 1;
            }
        }

        return (
            <PopupConfirm>
                <PopupConfirmContent>
                    {`Nice! You just added ${addedStudentsCount} students. Head back to the class page to see your roster.`}
                </PopupConfirmContent>

                <PopupConfirmButtons>
                    <ButtonFlat
                        uppercase
                        onClick={() => {
                            setVisibleFinishedPopup(false);
                        }}
                    >
                        Ok
                    </ButtonFlat>
                </PopupConfirmButtons>
            </PopupConfirm>
        );
    };

    const renderConfilctPopup = () => {
        if (props.currentStudentErrorCode === -1) {
            return null;
        }

        return (
            <PopupConfirmStudentDuplicate
                user={props.data[props.currentLoadingStudentIndex]}
                errorCode={props.currentStudentErrorCode}
                onSkipDuplicate={props.onSkipDuplicate}
                onAddDuplicate={props.onAddDuplicate}
            />
        );
    };

    return (
        <>
            <PopupCommon
                onClose={props.onClose}
                showClose={false}
            >
                <div className={styles.popup}>
                    <div className={styles.content}>
                        {renderList()}
                    </div>
                    <div className={styles.progress}>
                        {`${props.totalLoaded} out of ${props.data.length}`}
                    </div>
                    <PopupConfirmButtons>
                        <ButtonFlat
                            onClick={props.onClose}
                            disabled={props.inProgress}
                        >
                            Close
                        </ButtonFlat>
                    </PopupConfirmButtons>
                </div>
            </PopupCommon>
            {renderConfilctPopup()}
            {renderFinishedPopup()}
        </>
    );
};

PopupAddStudentsProgress.defaultProps = {
    data: [],

    currentStudentErrorCode: -1,

    currentLoadingStudentIndex: null,

    inProgress: false,
    totalLoaded: 0,

    onAddDuplicate: () => {},
    onSkipDuplicate: () => {},

    onClose: () => {},
};

export default PopupAddStudentsProgress;
