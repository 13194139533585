import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";
import User from "juice-base/project/user.js";
import CssIcon from "juice-base/components/css-icon/index.js";

import styles from "./styles.module.css";


const Header = (props) => {
    const {
        name,
        user,
        pathname,
        sectionsByRoles,
        onAsideToggle,
    } = props;

    const renderSections = () => {
        if (!user.userId) {
            return null;
        }

        const sections = [];

        User.getNavSections(user, sectionsByRoles).forEach((sec) => {
            const sClasses = [styles.sectionLink];

            if (pathname.indexOf(sec.to) === 0) {
                sClasses.push(styles.sectionLinkSelected);
            }

            const el = (
                <Link
                    className={sClasses.join(" ")}
                    to={sec.to}
                    key={sec.name}
                    data-comment={`header-${sec.dataComment}`}
                >
                    {sec.name}
                </Link>
            );

            sections.push(el);
        });

        const homeClasses = [styles.sectionLink];

        if (pathname === "/") {
            homeClasses.push(styles.sectionLinkSelected);
        }

        const homeLink = (
            <Link
                to="/"
                className={homeClasses.join(" ")}
                data-comment="header-link-home"
            >
                Home
            </Link>
        );

        return (
            <>
                {homeLink}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        if (!user.userId) {
            return (
                <Link to="/user/sign-in" className={styles.signin}>
                    Sign In
                    <CssIcon
                        url={staticFiles.iconLogIn}
                        name="Sign In"
                        className={styles.signInIcon}
                    />
                </Link>
            );
        }

        return (
            <>
                <Link
                    to="/user/profile"
                    className={styles.username}
                >
                    {user.fullName}
                </Link>
                <CssIcon
                    url={staticFiles.iconLogOut}
                    name="Sign Out"
                    className={styles.signOut}
                    onClick={props.onSignOut}
                />
            </>
        );
    };

    return (
        <header className={styles.header}>
            <CssIcon
                url={staticFiles.iconMenu}
                name="Menu"
                className={styles.menu}
                onClick={onAsideToggle}
            />
            <div className={styles.logo}>
                <Link to="/">
                    <img
                        src={staticFiles.juiceHomeLogo}
                        alt={name}
                        title={name}
                        className={styles.logoImg}
                    />
                </Link>
            </div>
            <div className={styles.sections}>
                {renderSections()}
            </div>
            <div className={styles.userControl}>
                {renderUser()}
            </div>
        </header>
    );
};

Header.defaultProps = {
    name: "",
    user: {},
    pathname: "/",
    sectionsByRoles: {},
    onAsideToggle: () => {},
    onSignOut: () => {},
};

export default Header;
