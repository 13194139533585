import React, { useState } from "react";

import classNames from "../../lib/class-names.js";

import styles from "./styles.module.css";


const ButtonWithMessage = (props) => {
    const { pageWidth } = props;

    const [isOpenMessage, setOpenMessage] = useState(false);

    const messageClassName = classNames({
        [props.messageClassName]: true,
        [styles.messageBlock]: true,
        [styles.popover]: true,
        [styles.hiddenMessage]: !isOpenMessage,
    });

    const changeMessageState = () => {
        if (pageWidth && pageWidth < 1000) {
            setOpenMessage(!isOpenMessage);
        }

        // if (dimensions && dimensions.width && dimensions.width < 1000) {
        //     setOpenMessage(!isOpenMessage);
        // }
    };

    const openMessage = () => {
        if (pageWidth && pageWidth >= 1000) {
            setOpenMessage(true);
        }
        // if (dimensions && dimensions.width && dimensions.width >= 1000) {
        //     setOpenMessage(true);
        // }
    };

    const closeMessage = () => {
        if (pageWidth && pageWidth >= 1000) {
            setOpenMessage(false);
        }
        // if (dimensions && dimensions.width && dimensions.width >= 1000) {
        //     setOpenMessage(false);
        // }
    };

    return (
        <div className={styles.buttonWithMessage}>
            <div
                className={styles.control}
                onKeyPress={changeMessageState}
                onClick={changeMessageState}
                onFocus={openMessage}
                onMouseOver={openMessage}
                onMouseLeave={closeMessage}
                role="button"
                tabIndex="-1"
            >
                {props.children}
            </div>
            <div className={messageClassName}>
                <div className={styles.popoverContent}>
                    {props.message}
                </div>
                <div
                    className={[
                        props.triangleClassName,
                        styles.triangle,
                    ].join(" ")}
                />
            </div>
        </div>
    );
};

ButtonWithMessage.defaultMessage = {
    message: "",
    messageClassName: "",
    triangleClassName: "",
    pageWidth: 0,
};

export default ButtonWithMessage;
