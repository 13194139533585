import React from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupFullScreenMenu = (props) => {
    const tabClassName = classNames({
        [styles.menuTab]: true,
        [styles.menuTabSelected]: props.selected,
    });

    return (
        <div
            className={tabClassName}
            onClick={() => {
                props.onClick();
            }}
            onKeyPress={() => {
                props.onClick();
            }}
            tabIndex="-1"
            role="button"
        >
            {props.children}
        </div>
    );
};

PopupFullScreenMenu.defaultProps = {
    children: "",
    selected: false,
    onClick: () => {},
};

export default PopupFullScreenMenu;
