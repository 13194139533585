// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3cmbE5lKhIs69vmEBATIvi {\n    height: min-content;\n\n    padding: 2rem;\n\n    border-radius: 1rem;\n    background-color: #fff;\n    box-shadow: 0rem 0.3rem 1rem 0.5rem #d5d5d5;\n}\n\n.X6711ExPR8Ox9yO3NC9cL {\n    margin: 0 0 1rem 0;\n\n    font-family: 'Grifter-Bold';\n    font-weight: bold;\n    font-size: 3rem;\n    letter-spacing: 0.1rem;\n    text-transform: lowercase;\n\n    background-color: #ff8033;\n    background: -webkit-linear-gradient(280deg, #dc5698 10%, #ff8033 40%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n@media only screen and (max-width: 800px) {\n    .X6711ExPR8Ox9yO3NC9cL {\n        display: none;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/section/styles.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB,aAAa;;IAEb,mBAAmB;IACnB,sBAAsB;IACtB,2CAA2C;AAC/C;;AAEA;IACI,kBAAkB;;IAElB,2BAA2B;IAC3B,iBAAiB;IACjB,eAAe;IACf,sBAAsB;IACtB,yBAAyB;;IAEzB,yBAAyB;IACzB,qEAAqE;IACrE,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".section {\n    height: min-content;\n\n    padding: 2rem;\n\n    border-radius: 1rem;\n    background-color: #fff;\n    box-shadow: 0rem 0.3rem 1rem 0.5rem #d5d5d5;\n}\n\n.header {\n    margin: 0 0 1rem 0;\n\n    font-family: 'Grifter-Bold';\n    font-weight: bold;\n    font-size: 3rem;\n    letter-spacing: 0.1rem;\n    text-transform: lowercase;\n\n    background-color: #ff8033;\n    background: -webkit-linear-gradient(280deg, #dc5698 10%, #ff8033 40%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n@media only screen and (max-width: 800px) {\n    .header {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "_3cmbE5lKhIs69vmEBATIvi",
	"header": "X6711ExPR8Ox9yO3NC9cL"
};
export default ___CSS_LOADER_EXPORT___;
