import React from "react";

import classNames from "juice-base/lib/class-names.js";

import staticFiles from "juice-base/static-files.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import styles from "./styles.module.css";


const PopupConfirmGenerateClassCode = (props) => {
    let contentTitle = null;
    let contentBody = "Your Class Link is";

    let copyButtonText = "Copy Class Link to Clipboard";

    if (!props.fullClassCodeLink) {
        let hostname = "the site";

        if (window?.location?.hostname) {
            hostname = `${window.location.hostname}${props.signUpByCodePath}`;
        }

        contentTitle = (
            <div className={styles.contentTitle}>
                {`Tell your students to visit ${hostname} and enter the class code to join to your class!`}
            </div>
        );

        contentBody = "Your Class Code is";

        copyButtonText = "Copy Class Code to Clipboard";
    }

    let content = (
        <div className={styles.classCode}>
            {contentTitle}
            <div>
                {contentBody}
            </div>
            <div className={styles.classCodeLink}>
                {props.code}
            </div>
        </div>
    );

    if (props.isLoading) {
        content = <RequestLoader />;
    }

    const popupWindowClassName = classNames({
        [styles.popup]: props.fullClassCodeLink,
    });

    return (
        <PopupConfirm
            popupWindowClassName={popupWindowClassName}
            closeOnLayoutClick
            onClose={props.onClose}
        >
            <PopupConfirmIcon
                src={staticFiles.iconKeyBlue}
                alt="Key"
            />

            <PopupConfirmContent>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.buttonsDisabled}
                    onClick={props.onCopyToClipboard}
                >
                    {copyButtonText}
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmGenerateClassCode.defaultProps = {
    code: null,
    isLoading: false,
    fullClassCodeLink: false,
    buttonsDisabled: false,
    signUpByCodePath: "",
    onCopyToClipboard: () => {},
    onClose: () => {},
};

export default PopupConfirmGenerateClassCode;
