import React from "react";

import styles from "./styles.module.css";


const PopupConfirmContent = (props) => {
    return (
        <div className={styles.content}>
            {props.children}
        </div>
    );
};

PopupConfirmContent.defaultProps = {
    children: null,
};

export default PopupConfirmContent;
