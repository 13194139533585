import React, { useState, useEffect } from "react";

import classNames from "juice-base/lib/class-names.js";
import scroll from "juice-base/lib/scroll.js";
import Close from "juice-base/components/close/index.js";

import Page from "./page.js";
import Button from "./button.js";
import Menu from "./menu.js";

import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const PopupFullScreen = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        window.setTimeout(() => {
            scroll.hideBodyScroll();
        }, 300);
    }, []);

    useEffect(() => {
        if (!props.isMenuVisible) {
            props.onMenuVisibleChange(true);
        }
    }, [props.isMobile]);

    const onClose = () => {
        if (!isVisible) {
            return;
        }

        setIsVisible(false);

        scroll.unhideBodyScroll();

        window.setTimeout(() => {
            props.onClose();
        }, 300);
    };

    const onMenuBack = () => {
        props.onMenuVisibleChange(true);
    };

    let openMenuButton = null;

    if (props.isMobile && !props.isMenuVisible) {
        openMenuButton = (
            <div
                className={styles.openMenuButton}
                onClick={onMenuBack}
                onKeyPress={onMenuBack}
                tabIndex="-1"
                role="button"
            >
                <img
                    src={staticFiles.boldArrow}
                    alt="Open Menu"
                />
            </div>
        );
    }

    const popupFullScreenClassName = classNames({
        [styles.popupFullScreen]: true,
        [styles.popupFullScreenVisible]: isVisible,
    });

    const contentClassName = classNames({
        [styles.content]: true,
        [styles.contentMobile]: props.isMobile,
    });

    const menuClassName = classNames({
        [styles.menuFixed]: true,
        [styles.menuFixedMobile]: props.isMobile,
        [styles.menuFixedHidden]: !props.isMenuVisible,
    });

    return (
        <div className={popupFullScreenClassName}>
            <div className={styles.header}>
                {openMenuButton}
                <div>{props.popupTitle}</div>
                <Close
                    className={styles.close}
                    onClose={onClose}
                />
            </div>
            <div className={contentClassName}>
                <div className={menuClassName}>
                    {props.menu}
                </div>
                {props.pages}
            </div>
        </div>
    );
};

PopupFullScreen.defaultProps = {
    isMenuVisible: false,
    isMobile: false,
    popupTitle: "",
    menu: null,
    pages: null,
    onMenuVisibleChange: () => {},
    onClose: () => {},
};

export const PopupFullScreenMenu = Menu;
export const PopupFullScreenMenuButton = Button;

export const PopupFullScreenPage = Page;

export default PopupFullScreen;
