import React, { useState } from "react";

import Input from "juice-base/components/forms/input/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import {
    validate,
    required,
} from "juice-base/lib/form-validators.js";

import styles from "./styles.module.css";
import RequestLoader from "../request-loader/index.js";


const PopupNameChange = (props) => {
    const [inputValue, setInputValue] = useState(props.defaultValue);

    const nameError = validate(inputValue, [
        required("Please enter fullname"),
    ]);

    const renderContent = () => {
        let content = (
            <div>
                <Input
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                />
                <div className={styles.errorText}>
                    {nameError}
                </div>
            </div>
        );

        if (props.isSaving) {
            content = <RequestLoader />;
        }

        if (props.error) {
            content = props.error;
        }

        return (
            <div className={styles.popupContent}>
                <div className={styles.header}>
                    Edit Name
                </div>
                {content}
            </div>
        );
    };

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={nameError || props.isSaving || props.error}
                    onClick={() => {
                        props.onSave(inputValue);
                    }}
                >
                    Save
                </ButtonFlat>
                <ButtonFlat
                    disabled={props.isSaving}
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupNameChange.defaultProps = {
    defaultValue: "",
    isSaving: false,
    error: null,
    onSave: () => {},
    onClose: () => {},
};

export default PopupNameChange;
