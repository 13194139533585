import React, { useEffect, useState } from "react";

import Close from "../close/index.js";
import PopupCommon from "../popup-common/index.js";
import RequestLoader from "../request-loader/index.js";
import Tabs from "../tabs/index.js";

import UploaderImage from "../forms/uploader-image/index.js";
import TextEditor from "../forms/text-editor/index.js";

import styles from "./styles.module.css";
import ButtonDefault from "../button-default/index.js";


const PopupSponsorContent = (props) => {
    const [isImageChanged, setImageChanged] = useState(false);

    const imageRequiredWidth = 320;
    const imageRequiredHeight = 80;

    const isAllContentsSame = () => {
        const firstClassContent = props.sponsor.contents[0] || "";

        return props.sponsor.contents
            .every((content) => content === firstClassContent);
    };

    const isAllImagesSame = () => {
        const img = props.sponsor.images[0] || {};
        const firstImageUrl = img.url || "";

        return props.sponsor.images
            .every((sponsorImg) => sponsorImg.url === firstImageUrl);
    };

    const getAllClassesSponsorText = () => {
        let text = "";

        if (isAllContentsSame()) {
            text = props.sponsor.contents[0] || "";
        }

        return text;
    };

    const getText = () => {
        let text = "";

        if (props.isSelectedAllClasses) {
            text = getAllClassesSponsorText();
        } else {
            text = props.sponsor.content;
        }

        return text;
    };

    const getImage = () => {
        const img = {
            isLoading: props.sponsor.isImageLoading,
            imageId: "",
            imageUrl: "",
        };

        if (props.isSelectedAllClasses) {
            if (props.sponsor.imageUrl) {
                img.imageId = props.sponsor.imageId;
                img.imageUrl = props.sponsor.imageUrl;
            } else if (isAllImagesSame()) {
                const sImg = props.sponsor.images[0] || {};
                img.imageId = sImg.id || "";
                img.imageUrl = sImg.url || "";
            }
        } else {
            img.imageId = props.sponsor.imageId;
            img.imageUrl = props.sponsor.imageUrl;
        }

        return img;
    };

    const [sponsor, setSponsor] = useState(() => {
        const text = getText();
        return {
            text,
            size: text.length,
        };
    });

    const isContentChanged = () => {
        if (!props.isSelectedAllClasses && props.sponsor.content !== sponsor.text) {
            return true;
        }

        if (props.isSelectedAllClasses && getAllClassesSponsorText() !== sponsor.text) {
            return true;
        }

        if (isImageChanged) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        const text = getText();

        setSponsor((prev) => ({
            ...prev,
            text,
            size: text.length,
        }));
    }, [
        props.sponsor.content,
        props.sponsor.contents,
    ]);

    const onSaveText = () => {
        const values = {
            imageId: "",
            content: sponsor.text,
        };

        if (props.isSelectedAllClasses) {
            const img = getImage();
            values.imageId = img.imageId;
            props.onSaveAll(values);
        } else {
            values.imageId = props.sponsor.imageId;
            props.onSave(values);
        }
    };

    const renderMessages = () => {
        if (props.errors.length > 0) {
            return props.errors.map((err) => (
                <div className={styles.errorMessage}>
                    {err}
                </div>
            ));
        }

        return null;
    };

    const onUploadSponsorImage = (values) => {
        setImageChanged(true);
        props.onUploadSponsorImage(values);
    };

    const renderFileUploader = () => {
        if (!props.sponsor.isLoaded) {
            return null;
        }

        const image = getImage();

        let fileUploaderWarning = null;

        if (isImageChanged) {
            fileUploaderWarning = (
                <div className={styles.warningText}>
                    Some announcements already have an image.
                    Saving this tab will overwrite all images.
                </div>
            );
        }

        return (
            <>
                <div>
                    <div className={styles.blockHeader}>
                        Customize each edition of The Juice with an image of yourself,
                        your organization, or a local sponsor. Make it your own!
                    </div>
                    <div className={styles.blockDescription}>
                        <p>
                            Here&apos;s what an
                            <span
                                className={styles.previewAds}
                                onKeyPress={() => {
                                    props.onPreviewAds(image, sponsor.text);
                                }}
                                onClick={() => {
                                    props.onPreviewAds(image, sponsor.text);
                                }}
                                role="button"
                                tabIndex="-1"
                            >
                                example image
                            </span>
                            might look like in a published edition.
                        </p>
                    </div>
                </div>

                <UploaderImage
                    isLoading={image.isLoading}
                    imageUrl={image.imageUrl}
                    onFileChange={onUploadSponsorImage}
                    imageRequiredHeight={imageRequiredHeight}
                    imageRequiredWidth={imageRequiredWidth}
                />

                {fileUploaderWarning}
            </>
        );
    };

    const renderTextEditor = () => {
        const defaultText = getText();

        let rewriteMessage = null;

        if (props.isSelectedAllClasses) {
            if (!isAllContentsSame() || !isAllImagesSame()) {
                rewriteMessage = (
                    <p className={styles.rewriteMessage}>
                        Some announcements have already been written.
                        Saving this tab will overwrite all announcements.
                    </p>
                );
            }
        }

        return (
            <div>
                <div className={styles.blockHeader}>
                    Add some text
                </div>
                <div className={styles.blockDescription}>
                    <p>
                        Say hello to your students,
                        state your organization&apos;s mission or values,
                        or thank a local sponsor. Keep it short and simple!
                    </p>
                </div>

                <TextEditor
                    label=""
                    name="sponsorText"
                    defaultValue={defaultText}
                    onChange={(text, size) => {
                        setSponsor((prev) => ({
                            ...prev,
                            text,
                            size,
                        }));
                    }}
                />

                {rewriteMessage}
            </div>
        );
    };

    const renderControl = () => {
        if (!props.sponsor.isLoaded) {
            return null;
        }

        const charactersStyles = {};

        let textLengthLimitReached = false;

        if (sponsor.text.length > props.maxTextCharacters) {
            charactersStyles.color = "red";
            textLengthLimitReached = true;
        }

        return (
            <div className={styles.customContentControls}>
                <div>
                    <div style={charactersStyles}>
                        {`${sponsor.size}/${props.maxTextCharacters} characters`}
                    </div>
                </div>
                {renderMessages()}
                <div className={styles.customContentButtons}>
                    <ButtonDefault
                        isPrimary
                        onClick={onSaveText}
                        disabled={textLengthLimitReached || props.isSaving}
                        withLoader={props.isSaving}
                    >
                        Save
                    </ButtonDefault>
                </div>
            </div>
        );
    };

    const onClose = () => {
        const hasUnsavedContent = isContentChanged();

        props.onClose(hasUnsavedContent);
    };

    const renderContent = () => {
        let content = null;

        if (!props.sponsor.isLoaded) {
            content = (
                <div className={styles.contentLoader}>
                    <RequestLoader />
                </div>
            );
        } else {
            content = (
                <>
                    <Tabs
                        tabs={props.classes}
                        tabTheme="white"
                        selectedClassId={props.selectedClass}
                        dimensions={props.dimensions}
                        onChange={(label, id) => {
                            props.onClassChange(id);
                            setImageChanged(false);
                        }}
                    />

                    {renderFileUploader()}

                    {renderTextEditor()}

                    {renderControl()}
                </>
            );
        }

        return (
            <div className={styles.customContent}>
                <div className={styles.header}>
                    Upload an image
                </div>

                {content}
            </div>
        );
    };

    return (
        <PopupCommon
            onClose={onClose}
            popupClasses={styles.fixedPopup}
            showClose={false}
            closeOnLayoutClick
        >
            <div className={styles.popup}>
                <div className={styles.popupWrapper}>
                    <Close
                        onClose={onClose}
                        className={styles.close}
                    />
                    {renderContent()}
                </div>
            </div>
        </PopupCommon>
    );
};

PopupSponsorContent.defaultProps = {
    maxTextCharacters: 125,

    classes: [],
    selectedClass: {},

    sponsor: {},

    isSaving: false,
    errors: {},

    dimensions: {},

    onClose: () => {},
    onClassChange: () => {},

    onPreviewAds: () => {},
    onUploadSponsorImage: () => {},

    onSave: () => {},
    onSaveAll: () => {},
};

export default PopupSponsorContent;
