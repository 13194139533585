import React, { useState } from "react";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const ButtonBig = (props) => {
    const {
        type,
        disabled,
    } = props;

    const [animation, setAnimation] = useState({
        x: -1,
        y: -1,
        size: 0,
    });

    const onClick = (evt) => {
        setAnimation((prev) => ({
            ...prev,
            x: -1,
            y: -1,
            size: 0,
        }));

        const { offsetHeight, offsetWidth } = evt.target;
        const size = Math.max(offsetHeight, offsetWidth);

        const rect = evt.target.getBoundingClientRect();
        const x = evt.clientX - rect.left;
        const y = evt.clientY - rect.top;

        setAnimation((prev) => ({
            ...prev,
            x,
            y,
            size,
        }));

        const durationInSec = 0.5;

        window.setTimeout(() => {
            setAnimation((prev) => ({
                ...prev,
                x: -1,
                y: -1,
                size: 0,
            }));
        }, durationInSec * 1000);
    };

    const renderAnimation = () => {
        const aClassName = classNames({
            [styles.animation]: true,
            [styles.animate]: animation.x !== -1 && animation.y !== -1,
        });

        const aStyles = {};

        if (animation.x !== -1 && animation.y !== -1) {
            aStyles.top = animation.y - Math.round(animation.size / 2);
            aStyles.left = animation.x - Math.round(animation.size / 2);
            aStyles.width = `${animation.size}px`;
            aStyles.height = `${animation.size}px`;
        }

        return (
            <div
                className={aClassName}
                style={aStyles}
            />
        );
    };

    const classes = [styles.button];

    return (
        <button
            type={type} // eslint-disable-line react/button-has-type
            disabled={disabled}
            className={classes.join(" ")}
            onClick={onClick}
        >
            {renderAnimation()}
            {props.children}
        </button>
    );
};

ButtonBig.defaultProps = {
    type: "submit",
    disabled: false,
};

export default ButtonBig;
