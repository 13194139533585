import React, { useRef, useEffect, useState } from "react";

import scroll from "juice-base/lib/scroll.js";
import classNames from "juice-base/lib/class-names.js";

import Close from "juice-base/components/close/index.js";
import WithScroll from "juice-base/components/with-scroll/index.js";

import styles from "./styles.module.css";


const PopupWindow = (props) => {
    const popupLayout = useRef(null);

    const [isVisible, setIsVisible] = useState(false);

    const onAutoClose = () => {
        setIsVisible(false);

        window.setTimeout(() => {
            props.onClose();
        }, 1000);
    };

    const onClose = () => {
        if (!isVisible) {
            return;
        }

        setIsVisible(false);

        window.setTimeout(() => {
            props.onClose();
        }, 1000);
    };

    useEffect(() => {
        window.setTimeout(() => {
            setIsVisible(true);
        }, 100);

        if (props.autoClose) {
            const seconds = 3;
            window.setTimeout(() => {
                onAutoClose();
            }, 1000 * seconds);
        }

        scroll.hideBodyScroll();

        return () => {
            scroll.unhideBodyScroll();
        };
    }, []);

    const onLayoutClick = (evt) => {
        if (popupLayout && popupLayout.current === evt.target) {
            scroll.unhideBodyScroll();
            onClose();
        }
    };

    const hasMultipleControl = props.rightControl && props.rightControl.length > 1;

    const titleClassName = classNames({
        [styles.topTitleBar]: true,
        [styles.topTitleBarWithControl2]: hasMultipleControl,
    });

    const renderBar = () => {
        return (
            <div className={titleClassName}>
                <div className={styles.leftControl}>
                    {props.leftControl}
                </div>

                {props.title}

                <div className={styles.rightControl}>
                    {props.rightControl}
                    <Close
                        onClose={onClose}
                    />
                </div>
            </div>
        );
    };

    const popupClassName = classNames({
        [styles.popup]: true,
        [styles.popupSmall]: props.isSmall,
        [props.popupClassName]: props.popupClassName,
        [styles.popupVisible]: isVisible,
        [styles.popupCentered]: isVisible && props.isCentered,
    });

    const windowClassName = classNames({
        [styles.popupWindow]: true,
        [styles.popupWindowRoundedCorners]: props.isCentered,
    });

    const popupContentClassName = classNames({
        [styles.popupContent]: true,
        [styles.popupContentWithPlayer]: props.player && !props.playerExpanded,
        [styles.popupContentWithPlayerExpanded]: props.player && props.playerExpanded,
    });

    return (
        <div
            ref={popupLayout}
            className={styles.popupLayout}
            onClick={onLayoutClick}
            onKeyPress={onLayoutClick}
            role="button"
            tabIndex="-1"
            data-comment="popup-window"
        >
            <div className={popupClassName}>
                <div className={windowClassName}>
                    {renderBar()}

                    <WithScroll className={popupContentClassName}>
                        {props.children}
                    </WithScroll>

                    {props.player}
                </div>
            </div>
        </div>
    );
};

PopupWindow.defaultProps = {
    title: null,
    leftControl: [],
    rightControl: [],
    player: null,
    isCentered: false,
    isSmall: false,
    popupClassName: "",
    autoClose: false,
    hideClose: false,
    children: null,
    onClose: () => {},
};

export default PopupWindow;
