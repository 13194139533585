// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2vmlWsbQrwOOwY5PRBTVxD {\n    background-color: #fff;\n}\n\n/* --- */\n\n._1FLGtSTk_EVbAQmf_nl7zY {}\n\n/* --- */\n\n._1_uvA7iurUM6j8CzXY4slg {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n._2r0dCZuwbOF6Ce3PGSgxoc {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.khsqQthbBqPFcDjIwJa4F {\n    padding: 0 2rem 2rem 2rem;\n}\n\n._3_hJ4n6H636GEyyhMznH8Q {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    ._1_uvA7iurUM6j8CzXY4slg,\n    ._2r0dCZuwbOF6Ce3PGSgxoc,\n    .khsqQthbBqPFcDjIwJa4F,\n    ._3_hJ4n6H636GEyyhMznH8Q {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/daily-juice-story/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA,QAAQ;;AAER,0BAAyB;;AAEzB,QAAQ;;AAER;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;;;;QAII,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".juiceStory {\n    background-color: #fff;\n}\n\n/* --- */\n\n.featuredVideoContainer {}\n\n/* --- */\n\n.category {\n    padding: 2rem 2rem 1rem 2rem;\n}\n\n.headline {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.playerAudioToggleButton {\n    padding: 0 2rem 2rem 2rem;\n}\n\n.content {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 620px) {\n    .category,\n    .headline,\n    .playerAudioToggleButton,\n    .content {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"juiceStory": "_2vmlWsbQrwOOwY5PRBTVxD",
	"featuredVideoContainer": "_1FLGtSTk_EVbAQmf_nl7zY",
	"category": "_1_uvA7iurUM6j8CzXY4slg",
	"headline": "_2r0dCZuwbOF6Ce3PGSgxoc",
	"playerAudioToggleButton": "khsqQthbBqPFcDjIwJa4F",
	"content": "_3_hJ4n6H636GEyyhMznH8Q"
};
export default ___CSS_LOADER_EXPORT___;
