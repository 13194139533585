// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1nNlDjfN7lEMERnzdBIplr {\n    position: relative;\n}\n\n._2SJAoPukLUlcu3xAHBekYW {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n    outline: none;\n\n    min-height: 3rem;\n}\n\n._2-6_s6WEmBozMr9Zp4Q-Jb {\n    cursor: default;\n    opacity: 0.5;\n}\n\n.Qhy1nMDYWYRODs6nWJ7Nq {\n    position: absolute;\n    top: -7rem;\n    right: 0rem;\n\n    width: 17rem;\n\n    z-index: 2;\n\n    outline: none;\n}\n\n._2XMSl1jF6ny9IsU_w6RebC {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: transparent;\n    z-index: 1;\n    outline: none;\n}", "",{"version":3,"sources":["webpack://./base/components/button-menu/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,eAAe;IACf,aAAa;;IAEb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;;IAEX,YAAY;;IAEZ,UAAU;;IAEV,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,UAAU;IACV,aAAa;AACjB","sourcesContent":[".menu {\n    position: relative;\n}\n\n.menuButton {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n    outline: none;\n\n    min-height: 3rem;\n}\n\n.desabledMenuButton {\n    cursor: default;\n    opacity: 0.5;\n}\n\n.menuButtons {\n    position: absolute;\n    top: -7rem;\n    right: 0rem;\n\n    width: 17rem;\n\n    z-index: 2;\n\n    outline: none;\n}\n\n.layout {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: transparent;\n    z-index: 1;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "_1nNlDjfN7lEMERnzdBIplr",
	"menuButton": "_2SJAoPukLUlcu3xAHBekYW",
	"desabledMenuButton": "_2-6_s6WEmBozMr9Zp4Q-Jb",
	"menuButtons": "Qhy1nMDYWYRODs6nWJ7Nq",
	"layout": "_2XMSl1jF6ny9IsU_w6RebC"
};
export default ___CSS_LOADER_EXPORT___;
