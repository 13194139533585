// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LuMQypuyz9dK8yiJEwQ3m {\n    height: 100vh;\n\n    display: grid;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.qyDga0os03iYkEulPbOaN {\n    border: 0.1rem solid #ccc;\n    width: 100%;\n\n    margin: 0 0 1rem 0;\n    padding: 0;\n\n    border-collapse: collapse;\n    border-spacing: 0;\n}\n\n.qyDga0os03iYkEulPbOaN tr {\n    border: 0.1rem solid #ccc;\n    padding: 0.5rem;\n}\n\n.qyDga0os03iYkEulPbOaN th,\n.qyDga0os03iYkEulPbOaN td {\n    padding: 1rem;\n    text-align: left;\n}\n\n.qyDga0os03iYkEulPbOaN th {\n    text-transform: uppercase;\n    font-size: 1.4rem;\n    letter-spacing: 0.1rem;\n}\n\n@media only screen and (min-width: 1025px) {\n    .LuMQypuyz9dK8yiJEwQ3m {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/debug/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,WAAW;;IAEX,kBAAkB;IAClB,UAAU;;IAEV,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;;IAEI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".debug {\n    height: 100vh;\n\n    display: grid;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.table {\n    border: 0.1rem solid #ccc;\n    width: 100%;\n\n    margin: 0 0 1rem 0;\n    padding: 0;\n\n    border-collapse: collapse;\n    border-spacing: 0;\n}\n\n.table tr {\n    border: 0.1rem solid #ccc;\n    padding: 0.5rem;\n}\n\n.table th,\n.table td {\n    padding: 1rem;\n    text-align: left;\n}\n\n.table th {\n    text-transform: uppercase;\n    font-size: 1.4rem;\n    letter-spacing: 0.1rem;\n}\n\n@media only screen and (min-width: 1025px) {\n    .debug {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debug": "LuMQypuyz9dK8yiJEwQ3m",
	"table": "qyDga0os03iYkEulPbOaN"
};
export default ___CSS_LOADER_EXPORT___;
