import React, { useState } from "react";

import Header from "juice-base/components/header/index.js";
import MobileHeader from "juice-base/components/mobile-header/index.js";
// import Footer from "juice-base/components/footer/index.js";
import AsideNav from "juice-base/components/aside-nav/index.js";
import ShortNav from "juice-base/components/short-nav/index.js";

import styles from "./styles.module.css";


const Layout = (props) => {
    const {
        pathname,
        appName,
        // TODO: copyright,
        version,
        user,
        sectionsByRoles,
        children,
    } = props;

    const [isVisibleAsideNav, setVisibleAsideNav] = useState(false);

    let asideNav = null;

    if (isVisibleAsideNav) {
        asideNav = (
            <AsideNav
                name={appName}
                version={version}
                user={user}
                sectionsByRoles={sectionsByRoles}
                supportLink={props.supportLink}
                onSignOut={props.onSignOut}
                onClose={() => { setVisibleAsideNav(false); }}
            />
        );
    }

    let headerMobile = null;

    if (props.isVisibleMobileHeader) {
        headerMobile = (
            <MobileHeader />
        );
    }

    const mobileNav = (
        <div data-comment="nav-short" className={styles.layoutMobileNav}>
            <ShortNav
                pathname={pathname}
                user={user}
                sectionsByRoles={sectionsByRoles}
                onSignOut={props.onSignOut}
            />
        </div>
    );

    return (
        <>
            {asideNav}

            <div data-comment="header-desktop" className={styles.layoutHeader}>
                <Header
                    name={appName}
                    user={user}
                    pathname={pathname}
                    sectionsByRoles={sectionsByRoles}
                    onAsideToggle={() => {
                        setVisibleAsideNav(!isVisibleAsideNav);
                    }}
                    onSignOut={props.onSignOut}
                />
            </div>

            <div
                data-comment="header-mobile"
                className={styles.layoutMobileHeader}
            >
                {headerMobile}
            </div>

            <div data-comment="content" className={styles.layoutContent}>
                {children}
            </div>

            {/*
            <div data-comment="footer" className={styles.layoutFooter}>
                <Footer
                    copyright={copyright}
                />
            </div>
            */}

            {mobileNav}
        </>
    );
};

Layout.defaultProps = {
    pathname: "/",
    appName: "",
    copyright: "",
    version: "",
    sectionsByRoles: {},
    user: {},
    onSignOut: () => {},
    isVisibleMobileHeader: true,
};

export default Layout;
