import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import settings from "juice-app/settings.js";

import Index from "juice-app/views/index/index.js";
import NotFound from "juice-app/views/not-found/index.js";

import UserSignIn from "juice-app/views/user/sign-in/index.js";
import UserSignUp from "juice-app/views/user/sign-up/index.js";
import UserPasswordForgot from "juice-app/views/user/password-forgot/index.js";
import UserPasswordChange from "juice-app/views/user/password-change/index.js";
import UserProfile from "juice-app/views/user/profile/index.js";

import DailyJuices from "juice-app/views/daily-juices/index.js";
import DailyJuice from "juice-app/views/daily-juice/index.js";
import DailyJuiceStory from "juice-app/views/daily-juice-story/index.js";

import Class from "juice-app/views/class/index.js";
import Vocabulary from "juice-app/views/vocabulary/index.js";
import VocabularyWord from "juice-app/views/vocabulary-word/index.js";

import Student from "juice-app/views/student/index.js";

import Story from "juice-app/views/story/index.js";
import ExtraJuice from "juice-app/views/extra-juice/index.js";

import Search from "juice-app/views/search/index.js";
import Page from "juice-app/views/page/index.js";

import Debug from "juice-app/views/debug/index.js";


export default (
    <Switch>
        <Route exact path="/" component={Index} />

        <Redirect from="/user/login" to="/user/sign-in" />
        <Route path="/user/sign-in" component={UserSignIn} />
        <Route path="/user/set-password/:secretKey" component={UserSignIn} />
        <Route path="/user/reset-password/:secretKey" component={UserSignIn} />

        <Route path="/user/sign-up/:code" component={UserSignUp} />
        <Route path="/user/sign-up" component={UserSignUp} />

        <Route path="/user/profile" component={UserProfile} />

        <Route path="/user/forgot-password" component={UserPasswordForgot} />
        <Route path="/user/change-password" component={UserPasswordChange} />

        <Route path="/daily-juice/:juiceIdOrDate/:storyId" component={DailyJuiceStory} />
        <Route path="/daily-juice/:juiceIdOrDate" component={DailyJuice} />
        <Route path="/daily-juice" component={DailyJuices} />

        <Route path={`/daily-juice-preview-${settings.previewCode}/:juiceIdOrDate/:storyId`} component={DailyJuiceStory} />
        <Route path={`/daily-juice-preview-${settings.previewCode}/:juiceIdOrDate`} component={DailyJuice} />

        <Route path="/story/:storyId" component={Story} />
        <Route path="/extra-juice/:extraJuiceId" component={ExtraJuice} />

        <Route path="/class" component={Class} />
        <Route path="/search" component={Search} />

        <Route path="/vocabulary/:wordId" component={VocabularyWord} />
        <Route path="/vocabulary" component={Vocabulary} />

        <Route path="/student" component={Student} />

        <Route path="/page/:slug" component={Page} />

        <Route path="/telemetry" component={Debug} />

        <Route component={NotFound} />
    </Switch>
);
