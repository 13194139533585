import React from "react";
import { Formik } from "formik";

import { hasEmptyValue } from "juice-base/lib/object.js";
import * as v from "juice-base/lib/form-validators.js";

import Input from "juice-base/components/forms/input/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const PasswordForgotForm = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return hasEmptyValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsUsername = v.validate(values.username, [
            v.required("Please enter username or email"),
        ]);

        const errors = {};

        if (errorsUsername) {
            errors.username = errorsUsername;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Input
                    name="username"
                    label="Enter your email address or username"
                    placeholder="Email Address or Username"
                    autoComplete="username"
                    value={values.username}
                    error={errors.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Reset password
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

PasswordForgotForm.defaultProps = {
    initialValues: {},
    onSubmit: () => {},
};

export default PasswordForgotForm;
