// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ixsZEnkmzcW2ddcQ8Zlf3 {\n    display: flex;\n}\n\n.eQe2xaU32MgHUpiFHAZBD,\n._2jm1otXmBge5ortZjJYqy\n{\n    font-size: 2rem;\n    font-weight: 600;\n\n    color: grey;\n\n    cursor: pointer;\n\n    width: 3rem;\n}\n\n.eQe2xaU32MgHUpiFHAZBD {\n    padding-left: 0.3rem;\n}\n\n._2jm1otXmBge5ortZjJYqy {\n    padding-right: 0.3rem;\n    transform: scaleX(-1);\n}\n\n._Rm_6D3IDxeHmDzRVwTA9 {\n    display: none;\n}\n\n.CPegc6pZn2v6rk-cpWdu0 {\n    visibility: hidden;\n}", "",{"version":3,"sources":["webpack://./base/components/horizontal-scrolling-menu/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;;;IAGI,eAAe;IACf,gBAAgB;;IAEhB,WAAW;;IAEX,eAAe;;IAEf,WAAW;AACf;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".innerWrapperClass {\n    display: flex;\n}\n\n.arrowRight,\n.arrowLeft\n{\n    font-size: 2rem;\n    font-weight: 600;\n\n    color: grey;\n\n    cursor: pointer;\n\n    width: 3rem;\n}\n\n.arrowRight {\n    padding-left: 0.3rem;\n}\n\n.arrowLeft {\n    padding-right: 0.3rem;\n    transform: scaleX(-1);\n}\n\n.disabledArrows {\n    display: none;\n}\n\n.hiddenArrows {\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"innerWrapperClass": "_3ixsZEnkmzcW2ddcQ8Zlf3",
	"arrowRight": "eQe2xaU32MgHUpiFHAZBD",
	"arrowLeft": "_2jm1otXmBge5ortZjJYqy",
	"disabledArrows": "_Rm_6D3IDxeHmDzRVwTA9",
	"hiddenArrows": "CPegc6pZn2v6rk-cpWdu0"
};
export default ___CSS_LOADER_EXPORT___;
