// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2pXkatxi809-vZ9rRTobXF {}\n\n.aUsgnZqgayZWydziquDN {}\n", "",{"version":3,"sources":["webpack://./base/components/popup-daily-juice-stats/styles.module.css"],"names":[],"mappings":"AAAA,0BAAQ;;AAER,uBAAc","sourcesContent":[".popup {}\n\n.popupWindow {}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "_2pXkatxi809-vZ9rRTobXF",
	"popupWindow": "aUsgnZqgayZWydziquDN"
};
export default ___CSS_LOADER_EXPORT___;
