import React from "react";

import staticFiles from "juice-base/static-files.js";

import FeaturedImage from "juice-base/components/featured-image/index.js";
import JuiceContent from "juice-base/components/juice-content/index.js";
import Video from "juice-base/components/video/index.js";
import Collapse from "juice-base/components/collapse-section/index.js";
import ClassroomShareButton from "juice-base/components/google-classroom-share-button/index.js";

import styles from "./styles.module.css";


const TeacherJuiceContent = (props) => {
    const renderQuizzes = () => {
        if (props.quizzes.length === 0) {
            return null;
        }

        const quizzes = [];

        props.quizzes.forEach((quiz) => {
            const answers = [];

            for (let i = 0; i < quiz.answers.length; i += 1) {
                if (quiz.answers[i].is_correct_answer) {
                    answers.push(
                        <div className={styles.quizCorrectAnswear}>
                            <img
                                src={staticFiles.iconCheck}
                                alt="Correct"
                                title="Correct"
                            />
                            <div>
                                {quiz.answers[i].answer}
                            </div>
                        </div>,
                    );
                } else {
                    answers.push(
                        <div className={styles.quizIncorrectAnswear}>
                            <img
                                src={staticFiles.iconCancel}
                                alt="Incorrect"
                                title="Incorrect"
                            />
                            <div>
                                {quiz.answers[i].answer}
                            </div>
                        </div>,
                    );
                }
            }

            quizzes.push(
                <>
                    <span className={styles.contentSeperator} />

                    <div className={styles.quizzes}>
                        <div className={styles.questionTitle}>
                            {quiz.question}
                        </div>
                        <div className={styles.questionAnswers}>
                            {answers}
                        </div>
                    </div>
                </>,
            );
        });

        return (
            <div data-comment="juice-story-quiz">
                {quizzes}
            </div>
        );
    };


    const renderContent = () => {
        let media = null;

        if (props.featuredVideo) {
            media = (
                <Video
                    src={props.featuredVideo.src}
                    captionSrc={props.featuredVideo.captionSrc}
                    type={props.featuredVideo.type}
                    poster={props.featuredVideo.poster}
                    onPlayStart={props.onVideoPlayStart}
                    onPause={props.onVideoPause}
                    isDefault={props.isDefaultVideo}
                />
            );
        }

        const storyImageClasses = [styles.storyImage];

        if (!media) {
            let featuredImage = null;

            if (props.featuredImage && props.featuredImage.sizes) {
                featuredImage = props.featuredImage;
            } else if (props.backgroundImage && props.backgroundImage.sizes) {
                featuredImage = props.backgroundImage;
            }

            if (featuredImage) {
                media = (
                    <FeaturedImage
                        featuredImage={featuredImage}
                        className={styles.storyImageImg}
                        onFullscreenImage={(img) => {
                            props.onFeaturedImageFullScreen(img);
                        }}
                    />
                );
            }
        }

        const juiceContent = (
            <JuiceContent
                storyId={props.storyId}
                content={props.content}
                onWordClick={(word) => {
                    props.onWordClick(word);
                }}
                extraJuices={props.extraJuices}
                hideExtraJuicesButtons
            />
        );

        let contentSection = (
            <div className={styles.storyContentWithoutMedia}>
                {juiceContent}
            </div>
        );

        if (media) {
            contentSection = (
                <div className={styles.storyImageContent}>
                    <div className={storyImageClasses.join(" ")}>
                        {media}
                    </div>
                    <div>
                        {juiceContent}
                    </div>
                </div>
            );
        }

        return (contentSection);
    };

    const renderExtraJuices = () => {
        const extraJuices = [];

        const extraJuiceIcon = {
            title: "Orange",
            src: staticFiles.iconOrange,
        };

        const collapseButton = (
            <img
                src={staticFiles.iconMinusInCircle}
                alt="Expand"
            />
        );

        const extendButton = (
            <img
                src={staticFiles.iconPlusInCircle}
                alt="Expand"
            />
        );

        props.extraJuices.forEach((extraJuice) => {
            extraJuices.push(
                <div className={styles.extraJuiceCollapse}>
                    <Collapse
                        icon={extraJuiceIcon}
                        content={extraJuice.content}
                        title={`Extra Juice: ${extraJuice.title}`}
                        collapseButton={collapseButton}
                        extendButton={extendButton}
                        onSectionClick={(values) => {
                            props.onExtraJuiceClick({
                                ...values,
                                extraJuice,
                                juiceId: props.storyId,
                            });
                        }}
                    >
                        <JuiceContent
                            className={styles.juiceContent}
                            storyId={props.storyId}
                            content={extraJuice.content}
                            onWordClick={(word) => {
                                props.onWordClick(word, extraJuice.id);
                            }}
                        />
                    </Collapse>
                </div>,
            );
        });

        return (extraJuices);
    };

    return (
        <div className={styles.teacherJuice}>
            <div className={styles.story}>
                <div className={styles.storyHeader}>
                    <div className={styles.storyCategory}>
                        <img
                            className={styles.categoryImage}
                            alt="shapeHighlighter"
                            src={staticFiles.shapeHighlighter}
                        />
                        <div className={styles.categoryName}>
                            {props.storyCategory}
                        </div>
                    </div>
                    <div className={styles.storyTitle}>
                        {props.storyTitle}
                    </div>
                    <div>
                        <ClassroomShareButton
                            title={props.storyTitle}
                            url={`/story/${props.storyId}`}
                            dataComment="classroom-share-button-daily-juice-story"
                        />
                    </div>
                </div>

                <span className={styles.contentSeperator} />

                {renderContent()}

                {renderQuizzes()}
            </div>

            {renderExtraJuices()}
        </div>
    );
};

TeacherJuiceContent.defaultProps = {
    juiceId: -1,
    storyId: -1,
    storyTitle: null,
    storyCategory: "",
    content: null,
    featuredImage: {},
    backgroundImage: {},
    featuredVideo: {},
    isDefaultVideo: true,
    quizzes: [],
    extraJuices: [],
    onVideoPause: () => {},
    onVideoPlayStart: () => {},
    onExtraJuiceClick: () => {},
    onFeaturedImageFullScreen: () => {},
    onWordClick: () => {},
};

export default TeacherJuiceContent;
