import React from "react";

import styles from "./styles.module.css";


const CssIcon = (props) => {
    const {
        url,
        name,
        className,
        onClick,
    } = props;

    if (!url || !name) {
        return null;
    }

    return (
        <div
            onClick={onClick}
            onKeyPress={onClick}
            className={styles.container}
            tabIndex="-1"
            role="button"
        >
            <img
                src={url}
                title={name}
                alt={name}
                className={className}
            />
        </div>
    );
};

CssIcon.defaultProps = {
    url: "",
    name: "",
    className: "",
    onClick: () => {},
};

export default CssIcon;
