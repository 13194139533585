import {
    SET_STUDENT_SPONSOR,
    SET_STUDENT_ANNOUNCEMENT,
    SET_STUDENT_QUIZ_RESULTS,
} from "./types.js";


export const setStudentSponsor = ({ sponsor }) => ({
    type: SET_STUDENT_SPONSOR,
    payload: {
        sponsor,
    },
});

export const setStudentAnnouncement = ({ announcement }) => ({
    type: SET_STUDENT_ANNOUNCEMENT,
    payload: {
        announcement,
    },
});

export const setStudentQuizResults = ({ quizResults }) => ({
    type: SET_STUDENT_QUIZ_RESULTS,
    payload: {
        quizResults,
    },
});
