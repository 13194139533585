import {
    SET_TEACHER_SELECTED_CLASS,
    SET_TEACHER_STATS_DATE,
    SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS,
    SET_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD,
    SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING,
    CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID,
} from "./types.js";


export const setTeacherSelectedClass = ({ selectedClassId }) => ({
    type: SET_TEACHER_SELECTED_CLASS,
    payload: {
        selectedClassId,
    },
});

export const setTeacherStatsDate = ({ statsDate }) => ({
    type: SET_TEACHER_STATS_DATE,
    payload: {
        statsDate,
    },
});

export const setTeacherStudentWithForgottenPasswords = ({ students }) => ({
    type: SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS,
    payload: {
        students,
    },
});

export const deleteTeacherStudentWithForgottenPassword = ({ studentId }) => ({
    type: DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD,
    payload: {
        studentId,
    },
});

export const setTeacherStudentsWithPendingStatus = (payload) => ({
    type: SET_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    payload,
});

export const setTeacherStudentsWithPendingStatusLoading = () => ({
    type: SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING,
});

export const clearTeacherStudentsWithPendingStatus = () => ({
    type: CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS,
});

export const deleteTeacherStudentWithPendingStatusById = (payload) => ({
    type: DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID,
    payload,
});
