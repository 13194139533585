export default (apiURL, methods) => {
    return {
        all(params = {}) {
            // TODO: rename api
            // NOTE: action=search
            const url = `${apiURL}/juice-stories/search`;

            return methods.get(url, {
                session_id: params.session,
                s: params.search,
                page: params.page,
                grades: params.grades,
                order_by: params.orderBy,
                categories: params.cetegories,
                type: params.type,
            });
        },
    };
};
