const getFullName = (user) => {
    return [
        user.firstName || "",
        user.lastName || "",
    ].filter((val) => val).join(" ");
};

/* --- */

const isTypeTrial = (user) => {
    return user && user.userType === "Trial";
};

const isTypeRegular = (user) => {
    return user && user.userType === "Regular";
};

const isTypeHome = (user) => {
    return user && user.userType === "Home";
};

/* --- */

const isExpired = (user) => {
    return user && user.isExpired;
};

/* --- */

const hasRole = (user, role) => {
    return user && user.roles && user.roles.indexOf(role) !== -1;
};

const hasRoleTeacher = (user) => {
    return hasRole(user, "teacher");
};

const hasRoleStudent = (user) => {
    return hasRole(user, "student");
};

const hasRoleGuardian = (user) => {
    return hasRole(user, "guardian");
};

/* --- */

const optionsArrToObj = (options = []) => {
    const ret = {};

    options.forEach((opt) => {
        let val = opt.value;

        if (opt.value === "true") {
            val = true;
        } else if (opt.value === "false") {
            val = false;
        }

        ret[opt.key] = val;
    });

    return ret;
};

/* --- */

const getNavSections = (user, sectionsByRoles) => {
    let userSections = [];

    if (isTypeTrial(user)) {
        if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.trial.student || [];
        }
    } else if (isTypeHome(user)) {
        if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.home.student || [];
        } else if (hasRoleGuardian(user)) {
            userSections = sectionsByRoles.home.guardian || [];
        } else if (hasRoleTeacher(user)) {
            userSections = sectionsByRoles.home.teacher || [];
        }
    } else if (isTypeRegular(user)) {
        if (hasRoleStudent(user)) {
            userSections = sectionsByRoles.regular.student || [];
        } else if (hasRoleGuardian(user)) {
            userSections = sectionsByRoles.regular.guardian || [];
        } else if (hasRoleTeacher(user)) {
            userSections = sectionsByRoles.regular.teacher || [];
        }
    }

    return userSections;
};

/* --- */

export default {
    getFullName,

    isTypeTrial,
    isTypeRegular,
    isTypeHome,

    isExpired,

    hasRoleTeacher,
    hasRoleGuardian,
    hasRoleStudent,

    optionsArrToObj,

    getNavSections,
};
