// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1lYv7AdNLvL5CcKaHjRbds {\n    z-index: 100;\n    margin: 0 0 0.3rem 0;\n}\n\n.YxIDc1wV-pMHPMS-na5LF {\n    padding: 0;\n}\n\n._33etjP6NzCiYCluourl4uo {\n    padding: 2rem 1rem 1rem 1rem;\n\n    display: grid;\n    justify-content: center;\n\n    background-color: #fff;\n}\n\n._2Wgk1WG0r4N2v5cmYP9Xo7 {\n    margin: 0 0 3rem 0;\n    padding: 0 2rem;\n\n    display: grid;\n    justify-content: center;\n}\n\n\n@media only screen and (min-width: 1025px) {\n    ._1lYv7AdNLvL5CcKaHjRbds {\n        position: fixed;\n        top: 5rem;\n        left: 0;\n        right: 0;\n    }\n\n    .YxIDc1wV-pMHPMS-na5LF {\n        padding-top: var(--daily-juice-nav-height-desktop);\n    }\n\n    ._33etjP6NzCiYCluourl4uo {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/containers/student-daily-juice-story/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,4BAA4B;;IAE5B,aAAa;IACb,uBAAuB;;IAEvB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,eAAe;;IAEf,aAAa;IACb,uBAAuB;AAC3B;;;AAGA;IACI;QACI,eAAe;QACf,SAAS;QACT,OAAO;QACP,QAAQ;IACZ;;IAEA;QACI,kDAAkD;IACtD;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".nav {\n    z-index: 100;\n    margin: 0 0 0.3rem 0;\n}\n\n.stories {\n    padding: 0;\n}\n\n.activityOverview {\n    padding: 2rem 1rem 1rem 1rem;\n\n    display: grid;\n    justify-content: center;\n\n    background-color: #fff;\n}\n\n.nextStoryBlock {\n    margin: 0 0 3rem 0;\n    padding: 0 2rem;\n\n    display: grid;\n    justify-content: center;\n}\n\n\n@media only screen and (min-width: 1025px) {\n    .nav {\n        position: fixed;\n        top: 5rem;\n        left: 0;\n        right: 0;\n    }\n\n    .stories {\n        padding-top: var(--daily-juice-nav-height-desktop);\n    }\n\n    .activityOverview {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "_1lYv7AdNLvL5CcKaHjRbds",
	"stories": "YxIDc1wV-pMHPMS-na5LF",
	"activityOverview": "_33etjP6NzCiYCluourl4uo",
	"nextStoryBlock": "_2Wgk1WG0r4N2v5cmYP9Xo7"
};
export default ___CSS_LOADER_EXPORT___;
