// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AmWZMpHAij4YC1NjWqUkv {\n    height: 7rem;\n    padding: 2rem;\n\n    display: flex;\n}\n\n._219075SOFifqtqsGVJmBQM {\n    max-height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./base/components/mobile-header/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;;IAEb,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".header {\n    height: 7rem;\n    padding: 2rem;\n\n    display: flex;\n}\n\n.logoImg {\n    max-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_2AmWZMpHAij4YC1NjWqUkv",
	"logoImg": "_219075SOFifqtqsGVJmBQM"
};
export default ___CSS_LOADER_EXPORT___;
