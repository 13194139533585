import React, { useRef, useEffect } from "react";

import timer from "juice-base/lib/timer.js";

import DailyJuice from "juice-base/project/daily-juice.js";
import Text from "juice-base/components/text/index.js";
import QuizQuestionForm from "juice-base/forms/quiz-question/index.js";
import QuizQuestionAnswers from "juice-base/business/quiz-question-answers/index.js";

import styles from "./styles.module.css";


const QuizQuestion = (props) => {
    const { question } = props;

    if (!question) {
        return null;
    }

    const quizTimer = useRef(null);

    useEffect(() => {
        if (props.isVisible) {
            quizTimer.current = timer.startTimer();
        } else {
            quizTimer.current = null;
        }
    }, [props.isVisible]);

    const onSubmit = ({ answer }) => {
        const diffSeconds = quizTimer.current.getDiffSeconds() || 0;

        const isCorrect = DailyJuice.isValidQuiz(question);

        props.onSubmit({
            quizId: question.quizId,
            questionId: question.id,
            answerId: parseInt(answer, 10),
            timeTook: diffSeconds,
            isCorrect,
        });
    };

    const renderContent = () => {
        let content = null;

        const isAnswered = DailyJuice.isAnsweredQuiz(question);

        if (isAnswered) {
            const answers = (question.answers || []).map((ans) => {
                return {
                    ...ans,
                    isUserChoice: ans.is_user_choice,
                    isCorrect: ans.is_correct_answer,
                };
            });

            content = (
                <QuizQuestionAnswers
                    quizId={question.quizId}
                    answers={answers}
                />
            );
        } else {
            const initialValues = {
                answer: null,
            };

            if (props.allowAnswering) {
                content = (
                    <QuizQuestionForm
                        questionId={question.id}
                        answers={question.answers || []}
                        initialValues={initialValues}
                        allowAnswering={props.allowAnswering}
                        onSelectAnswer={(answerId) => {
                            props.onSelectAnswer({
                                quizId: question.quizId,
                                answerId,
                            });
                        }}
                        onSubmit={onSubmit}
                    />
                );
            } else {
                content = (
                    <QuizQuestionAnswers
                        quizId={question.quizId}
                        answers={question.answers || []}
                    />
                );
            }
        }

        return content;
    };

    return (
        <div className={styles.quizQuestion}>
            <div className={styles.header}>
                Question
            </div>

            <Text className={styles.question}>
                {question.question}
            </Text>

            {renderContent()}
        </div>
    );
};

QuizQuestion.defaultProps = {
    question: null,
    allowAnswering: true,
    onSelectAnswer: () => {},
    onSubmit: () => {},
};

export default QuizQuestion;
