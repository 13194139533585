import GTag from "juice-base/lib/gtag.js";
import device from "juice-base/lib/device.js";

import api from "juice-app/api.js";
import actions from "juice-base/store/actions.js";

import Settings from "juice-app/settings.js";


const initSiteInfo = (store) => {
    api.site.getInfo().then((res) => {
        if (res.ok && res.info && res.info.gtag) {
            GTag.init(res.info.gtag);
        }

        if (res.ok && res.info.sponsors) {
            store.dispatch(actions.info.setSponsors(res.info.sponsors));
        }
    });
};

const setTitle = () => {
    const els = document.getElementsByTagName("title");

    if (els.length > 0) {
        els[0].textContent = Settings.appName;
    }
};

const hideAppLoader = () => {
    const body = document.querySelector("body");

    if (body) {
        body.classList.remove("bodyLoading");
    }

    const els = document.getElementsByClassName("appLoader");

    if (els.length > 0) {
        els[0].remove();
    }
};

export const preInit = (store) => {
    initSiteInfo(store);
    setTitle();

    // NOTE: check webpack for global VERSION variable
    store.dispatch(actions.app.setVersion(VERSION)); // eslint-disable-line no-undef
    store.dispatch(actions.device.setIsPWA(device.isPWA));

    store.dispatch(actions.user.setUserLoading(true));
};

export const postInit = () => {
    hideAppLoader();
};
