// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2FxhRHpTQaLlCshG9nHnVS {\n    display: grid;\n    justify-content: center;\n\n    padding-top: 20%;\n}\n\n._3d0qmFpu0E9mrHTmUMUt6I {\n    z-index: 201;\n}\n\n._2LwCbouMOIC_laVXjWM50 {\n    position: relative;\n\n    height: min-content;\n    padding: 4rem;\n\n    background: #fff;\n    color: #000;\n    border-radius: 1rem;\n}\n\n._2ZXuTSenEit40xMi61CCtr {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n\n._2ZXuTSenEit40xMi61CCtr:before, ._2ZXuTSenEit40xMi61CCtr:after {\n    background-color: black;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-text/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;;IAEvB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;;IAElB,mBAAmB;IACnB,aAAa;;IAEb,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".textPopup {\n    display: grid;\n    justify-content: center;\n\n    padding-top: 20%;\n}\n\n.overlappedPopup {\n    z-index: 201;\n}\n\n.textContainer {\n    position: relative;\n\n    height: min-content;\n    padding: 4rem;\n\n    background: #fff;\n    color: #000;\n    border-radius: 1rem;\n}\n\n.close {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n\n.close:before, .close:after {\n    background-color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textPopup": "_2FxhRHpTQaLlCshG9nHnVS",
	"overlappedPopup": "_3d0qmFpu0E9mrHTmUMUt6I",
	"textContainer": "_2LwCbouMOIC_laVXjWM50",
	"close": "_2ZXuTSenEit40xMi61CCtr"
};
export default ___CSS_LOADER_EXPORT___;
