import React from "react";
import staticFiles from "../../static-files.js";
import ButtonDefault from "../button-default/index.js";

import ButtonWithMessage from "../button-with-message/index.js";
import SectionTeacher from "../section-teacher/index.js";
import Text from "../text/index.js";

// TODO: rename styles
import styles from "./styles.module.css";


const TeacherClassAnnouncement = (props) => {
    const { content } = props;

    const renderContent = () => {
        if (content) {
            return (
                <Text>
                    {content}
                </Text>
            );
        }

        return (
            <div className={styles.noContentText}>
                No content for this class.
            </div>
        );
    };

    const renderNote = () => {
        if (!content) {
            return null;
        }

        return (
            <div className={styles.classAnnouncementNote}>
                <img
                    src={staticFiles.iconCheck}
                    alt="Check"
                    title="Check"
                />
                Message will display to students every day this week
            </div>
        );
    };

    return (
        <SectionTeacher
            className={styles.section}
            dataComment="daily-juice-class-announcement"
        >
            <div className={styles.dailyJuiceClassAnnouncement}>
                <div className={styles.header}>
                    <div className={styles.headerName}>
                        <div className={styles.headerNameTitle}>
                            Class Announcement
                        </div>
                        <div>
                            <ButtonWithMessage
                                message="Write a custom message your students will see every day in the Daily Juice"
                                messageClassName={styles.messageBlock}
                                triangleClassName={styles.messageTriangle}
                                pageWidth={props.pageWidth}
                            >
                                <div className={styles.messageButton}>
                                    ?
                                </div>
                            </ButtonWithMessage>
                        </div>
                    </div>
                    <ButtonDefault
                        onClick={props.onEdit}
                        buttonClasses={styles.editButton}
                    >
                        <img
                            src={staticFiles.iconPencil}
                            alt="Edit"
                        />
                        <div>
                            Edit
                        </div>
                    </ButtonDefault>
                </div>

                <div className={styles.content}>
                    {renderContent()}
                </div>

                {renderNote()}
            </div>
        </SectionTeacher>
    );
};

TeacherClassAnnouncement.defaultProps = {
    pageWidth: 0,
    content: null,
    onEdit: () => {},
};

export default TeacherClassAnnouncement;
