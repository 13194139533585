// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._23_Sa5ZEQRPUp-yQJ1hQmi {\n    display: grid;\n}\n\n._1vdN-Tb0Pf7T-o0zeqBPrx {\n    margin: 0 auto;\n    width: 10rem;\n}\n\n.SZWiymeL_YFSwaXtW1Y7_ {\n    margin: 2rem 0;\n\n    font-family: \"Soleil-Semibold\";\n    font-size: 2rem;\n    text-align: center;\n}\n\n._1ZRwsecIgJG7uMZ75isUk6 {\n    color: #757575;\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./base/components/message-with-emoji/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,cAAc;;IAEd,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".messageWithEmoji {\n    display: grid;\n}\n\n.emojiImg {\n    margin: 0 auto;\n    width: 10rem;\n}\n\n.header {\n    margin: 2rem 0;\n\n    font-family: \"Soleil-Semibold\";\n    font-size: 2rem;\n    text-align: center;\n}\n\n.message {\n    color: #757575;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageWithEmoji": "_23_Sa5ZEQRPUp-yQJ1hQmi",
	"emojiImg": "_1vdN-Tb0Pf7T-o0zeqBPrx",
	"header": "SZWiymeL_YFSwaXtW1Y7_",
	"message": "_1ZRwsecIgJG7uMZ75isUk6"
};
export default ___CSS_LOADER_EXPORT___;
