import React from "react";
import RequestLoader from "../request-loader/index.js";

import styles from "./styles.module.css";


const TableStudentJuices = (props) => {
    const renderRows = () => {
        if (props.error) {
            return (
                <div className={styles.errorRow}>
                    {props.error}
                </div>
            );
        }

        if (props.tableRows.length === 0) {
            let content = props.noRowsTitle;

            if (props.isDailyJuicesLoading) {
                content = <RequestLoader />;
            }

            return (
                <div className={styles.errorRow}>
                    {content}
                </div>
            );
        }

        const rows = [];

        props.tableRows.forEach((row) => {
            const oneRow = row.map((rowItem) => (
                <div className={styles.rowItem}>
                    {rowItem}
                </div>
            ));

            const rowClasses = [styles.oneRow, styles.row];

            if (!props.hideMenuButtons) {
                rowClasses.push(styles.oneRowWithMenu);
            }

            rows.push(
                <div className={rowClasses.join(" ")}>
                    {oneRow}
                </div>,
            );
        });

        return (rows);
    };

    const renderTableContent = () => {
        const titles = props.columnsTitles.map((title) => <div>{title}</div>);

        const rowClasses = [styles.oneRow, styles.tableHeader, styles.boldText];

        if (!props.hideMenuButtons) {
            rowClasses.push(styles.oneRowWithMenu);
        }

        return (
            <div className={styles.tableRows}>
                <div className={rowClasses.join(" ")}>
                    {titles}
                </div>
                {renderRows()}
            </div>
        );
    };

    const renderLoadMoreButton = () => {
        if (!props.hasMorePages) {
            return null;
        }

        if (props.isDailyJuicesLoading) {
            return (
                <div className={styles.juicesLoader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <div
                tabIndex="-1"
                role="button"
                onClick={props.onLoadMoreJuices}
                onKeyPress={props.onLoadMoreJuices}
                className={styles.loadMoreButton}
            >
                Load More Juices
            </div>
        );
    };

    const renderCard = (row) => {
        const rows = [];

        props.columnsTitles.forEach((title, index) => {
            if (title !== "") {
                const rowItem = row[index] || null;

                let menu = null;

                const cardClasses = [styles.cardRow];

                if (index === 0) {
                    cardClasses.push(styles.cardFirstRow);
                    menu = row[row.length - 1];
                }

                rows.push(
                    <div className={cardClasses.join(" ")}>
                        <div className={styles.boldText}>
                            {title}
                        </div>
                        <div className={styles.cardRowItem}>
                            {rowItem}
                        </div>
                        <div>
                            {menu}
                        </div>
                    </div>,
                );
            }
        });

        return (
            <div className={styles.classTableCards}>
                <div className={styles.cardTableTitle}>
                    {props.tableTitle}
                </div>
                {rows}
            </div>
        );
    };

    if (props.isCards) {
        const cards = props.tableRows.map((row) => renderCard(row));

        return cards;
    }

    return (
        <div
            className={styles.classTable}
            data-comment="table-student-juices"
        >
            <div className={styles.tableSettings}>
                <div className={styles.tableTitle}>
                    {props.tableTitle}
                </div>
                <div className={styles.controls}>
                    {props.controls}
                </div>
            </div>

            {renderTableContent()}

            {renderLoadMoreButton()}
        </div>
    );
};

TableStudentJuices.defaultProps = {
    tableTitle: "Daily Juice",

    hasMorePages: false,
    isDailyJuicesLoading: false,

    controls: [],

    columnsTitles: [
        "Date",
        "Quiz Status",
        "Quiz Score",
        "Extra Juice",
        "Video",
        "",
    ],

    tableRows: [],

    isCards: false,
    hideMenuButtons: true,

    noRowsTitle: "Juices not found",
    error: null,

    onLoadMoreJuices: () => {},
};


export default TableStudentJuices;
