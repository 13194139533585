// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Bj0_3zthZSPD2VG8P9xlc {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    transform: translate3d(0, 0, 0);\n\n    width: 100vw;\n    height: 100vh;\n    min-height: 100%;\n\n    z-index: 200;\n\n    background-color: hsl(0deg 0% 46% / 90%);\n\n    outline: none;\n}\n\n._2QiZ0N_ssUsr2fv0nJ1NJk {\n    position: absolute;\n    top: 1rem;\n    right: 2.4rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-common/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,+BAA+B;;IAE/B,YAAY;IACZ,aAAa;IACb,gBAAgB;;IAEhB,YAAY;;IAEZ,wCAAwC;;IAExC,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,aAAa;AACjB","sourcesContent":[".popup {\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n\n    transform: translate3d(0, 0, 0);\n\n    width: 100vw;\n    height: 100vh;\n    min-height: 100%;\n\n    z-index: 200;\n\n    background-color: hsl(0deg 0% 46% / 90%);\n\n    outline: none;\n}\n\n.popupClose {\n    position: absolute;\n    top: 1rem;\n    right: 2.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "_1Bj0_3zthZSPD2VG8P9xlc",
	"popupClose": "_2QiZ0N_ssUsr2fv0nJ1NJk"
};
export default ___CSS_LOADER_EXPORT___;
