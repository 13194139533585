import React, { useState } from "react";

import RequestLoader from "juice-base/components/request-loader/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";

import classNames from "juice-base/lib/class-names.js";

import styles from "./styles.module.css";


const PopupGradeChange = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.defaultGrade);

    const renderContent = () => {
        let content = props.grades.map((grade) => {
            const isChecked = grade === selectedValue;

            const boxClassName = classNames({
                [styles.field]: true,
                [styles.fieldChecked]: isChecked,
            });

            const inputId = `grade-level-${grade}`;

            return (
                <div className={boxClassName}>
                    <div className={styles.fieldWrapper}>
                        <input
                            id={inputId}
                            name="answer"
                            type="radio"
                            value={grade}
                            checked={isChecked}
                            onChange={() => {
                                setSelectedValue(grade);
                            }}
                        />
                    </div>
                    <label
                        htmlFor={inputId}
                        className={styles.fieldLabel}
                    >
                        {grade}
                    </label>
                </div>
            );
        });

        if (props.isSaving) {
            content = <RequestLoader />;
        }

        if (props.error) {
            content = props.error;
        }

        return (
            <div>
                <div className={styles.header}>
                    Edit Reading Level
                </div>
                <div>
                    {content}
                </div>
            </div>
        );
    };

    return (
        <PopupConfirm>
            <PopupConfirmContent>
                {renderContent()}
            </PopupConfirmContent>
            <PopupConfirmButtons>
                <ButtonFlat
                    disabled={props.isSaving || props.error}
                    onClick={() => {
                        props.onSave(selectedValue);
                    }}
                >
                    Save
                </ButtonFlat>
                <ButtonFlat
                    disabled={props.isSaving}
                    onClick={props.onClose}
                >
                    Cancel
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupGradeChange.defaultProps = {
    defaultGrade: "",
    isSaving: false,
    error: null,
    grades: [],
    onSave: () => {},
    onClose: () => {},
};

export default PopupGradeChange;
