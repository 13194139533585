import React, { useEffect } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import ErrorBoundary from "juice-base/components/error-boundary/index.js";
import Layout from "juice-base/components/layout/index.js";

import useDimensions from "juice-base/hooks/use-dimensions/index.js";
import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import * as events from "juice-base/events.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import rootRoutes from "juice-app/routes/root.js";


const RootLayout = () => {
    const loc = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const dimensions = useDimensions();

    const {
        version,
        session,
        user,
        location,
        prevLocation,
    } = useSelector((state) => ({
        version: state.app.version,
        session: state.user.session,
        user: state.user.user,
        location: state.navigation.location,
        prevLocation: state.navigation.prevLocation,
    }));

    const getIsVisibleMobileHeader = () => {
        for (let i = 0; i < settings.mobileHeaderHiddenPaths.length; i += 1) {
            const path = settings.mobileHeaderHiddenPaths[i];
            const match = useRouteMatch(path);

            if (match) {
                return false;
            }
        }

        return true;
    };

    const loadUserOptions = (userSession) => {
        api.user.getOptions({
            session: userSession,
        }).then((res) => {
            const options = res.ok ? res.data || [] : [];

            dispatch(actions.user.setUserOptions({
                options: User.optionsArrToObj(options),
            }));
        });
    };

    const loadUser = () => {
        let userSession = storage.session.loadSession();

        if (!userSession) {
            userSession = storage.local.loadSession();
        }

        dispatch(actions.user.setUserSession(userSession));

        if (userSession) {
            api.user.checkSession({
                session: userSession,
            }).then((res) => {
                if (res.ok) {
                    dispatch(actions.user.setUser(res.user));
                    loadUserOptions(userSession);
                } else {
                    storage.local.clearSession();
                    history.push("/user/sign-in");
                }

                dispatch(actions.user.setUserLoading(false));
            });
        } else {
            dispatch(actions.user.setUserLoading(false));
        }
    };

    const isClosedDailyJuice = (prevLoc) => {
        const currLoc = loc.pathname.split("/").filter((path) => path !== "");

        const dailyJuiceLink = "daily-juice";

        if (prevLoc[0] && currLoc[0]) {
            if (prevLoc[0] === dailyJuiceLink
                && currLoc[0] !== dailyJuiceLink
                && prevLoc.length > 1) {
                return true;
            }
            if (prevLoc[0] === dailyJuiceLink
                && currLoc[0] === dailyJuiceLink
                && currLoc.length === 1) {
                return true;
            }
        } else if (prevLoc[0] && prevLoc[0] === dailyJuiceLink && currLoc.length === 0) {
            return true;
        }

        return false;
    };

    const isTeacherClosedDailyJuice = (prevLoc) => {
        const currLoc = loc.pathname.split("/").filter((path) => path !== "");

        const dailyJuiceLink = "daily-juice";

        if (prevLoc?.[0] === dailyJuiceLink && currLoc?.[0] !== dailyJuiceLink) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        if (window.zE) {
            window.zE("webWidget", "hide");
        }

        if (loc && user && Object.keys(user).length > 0) {
            const expiredUserPage = "/page/user-expired";
            const isUserExpired = User.isExpired(user);
            const currPath = loc.pathname;

            if (isUserExpired && currPath !== expiredUserPage) {
                history.push(expiredUserPage);
            } else if (!isUserExpired && currPath === expiredUserPage) {
                history.push("/");
            }
        }
    }, [user, loc]);

    useEffect(() => {
        dispatch(actions.device.setDimensions(dimensions));
    }, [dimensions.height, dimensions.width]);

    useEffect(() => {
        dispatch(actions.navigation.setLocation(loc));
    }, [loc]);

    useEffect(() => {
        if (prevLocation.pathname) {
            const prevLoc = prevLocation.pathname.split("/").filter((path) => path !== "");

            if (User.hasRoleTeacher(user) && isTeacherClosedDailyJuice(prevLoc)) {
                events.teacherDailyJuiceClose({
                    session,
                });
            } else if (isClosedDailyJuice(prevLoc) && prevLoc.length >= 2) {
                events.dailyJuiceClose({
                    session,
                    juiceId: prevLoc[1],
                });
            }
        }
    }, [prevLocation]);

    const onSignOut = () => {
        events.userSignOut({
            session,
            location: location.pathname,
        });

        api.user.signOut({ session });
        storage.local.clearSession();

        dispatch(actions.user.clearUser());

        dispatch(actions.juices.clearJuices());
        dispatch(actions.juices.clearJuicesDates());

        dispatch(actions.studentTodo.clearIncompletedJuices());

        history.push("/user/sign-in");
    };

    return (
        <ErrorBoundary>
            <Layout
                pathname={loc.pathname}
                appName={settings.appName}
                copyright={settings.copyright}
                version={version}
                sectionsByRoles={settings.sectionsByRoles}
                user={user}
                supportLink={settings.supportLink}
                onSignOut={onSignOut}
                isVisibleMobileHeader={getIsVisibleMobileHeader()}
            >
                {rootRoutes}
            </Layout>
        </ErrorBoundary>
    );
};

export default RootLayout;
