import React from "react";

import styles from "./styles.module.css";


const PopupConfirmIcon = (props) => {
    return (
        <img
            src={props.src}
            alt={props.alt}
            className={styles.icon}
        />
    );
};

PopupConfirmIcon.defaultProps = {
    src: "",
    alt: "",
};

export default PopupConfirmIcon;
