import {
    SET_VERSION,
} from "./types.js";


export const setVersion = (version) => ({
    type: SET_VERSION,
    payload: {
        version,
    },
});

export default {};
