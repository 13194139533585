import React from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const ImageWithZoom = (props) => {
    const {
        src,
        className,
        title,
        buttonZoomLevel,
    } = props;

    return (
        <TransformWrapper
            zoomIn={{
                step: buttonZoomLevel,
            }}
            zoomOut={{
                step: buttonZoomLevel,
            }}
        >
            {({ zoomIn, zoomOut }) => (
                <div
                    style={props.containerStyle}
                    className={props.containerClassName}
                >
                    <TransformComponent>
                        <img
                            src={src}
                            alt={title}
                            title={title}
                            style={props.imageStyle}
                            className={[className, styles.imageWithZoom].join(" ")}
                        />
                    </TransformComponent>
                    <div
                        className={[
                            styles.tools,
                            props.toolsClassName,
                        ].join(" ")}
                    >
                        <button type="button" onClick={zoomIn}>
                            <img
                                src={staticFiles.zoomIn}
                                alt="Zoom in"
                                title="Zoom in"
                            />
                        </button>
                        <button type="button" onClick={zoomOut}>
                            <img
                                src={staticFiles.zoomOut}
                                alt="Zoom out"
                                title="Zoom out"
                            />
                        </button>
                    </div>
                </div>
            )}
        </TransformWrapper>
    );
};

ImageWithZoom.defaultProps = {
    src: "",
    title: "",
    className: "",
    toolsClassName: "",
    containerStyle: {},
    imageStyle: {},
    containerClassName: "",
    buttonZoomLevel: 10,
};

export default ImageWithZoom;
