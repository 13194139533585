// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1BWN_7q1nDiUot5ZmxlFsi {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 1rem;\n    justify-content: center;\n\n    border-bottom: 2px solid transparent;\n\n    cursor: pointer;\n    outline: none;\n}\n\n._1BWN_7q1nDiUot5ZmxlFsi:hover {\n    border-bottom: 2px solid #ec407A;\n}\n\n._3VyU2b8HpzuFxhMwgkmQQy {\n    color: #ec407A;\n\n    font-family: \"Soleil\";\n    font-weight: 800;\n    font-size: 1.4rem;\n    line-height: 2.4rem;\n    letter-spacing: 0.15rem;\n    text-align: center;\n    text-transform: uppercase;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    overflow: hidden;\n}\n\n._1oGhrmjBEmuI-In_Duxq9S {\n    width: 1rem;\n    padding: 0.4rem 0 0 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/link-next/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,uBAAuB;;IAEvB,oCAAoC;;IAEpC,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,cAAc;;IAEd,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;IACzB,uBAAuB;IACvB,mBAAmB;;IAEnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB","sourcesContent":[".linkNext {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 1rem;\n    justify-content: center;\n\n    border-bottom: 2px solid transparent;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.linkNext:hover {\n    border-bottom: 2px solid #ec407A;\n}\n\n.linkNextText {\n    color: #ec407A;\n\n    font-family: \"Soleil\";\n    font-weight: 800;\n    font-size: 1.4rem;\n    line-height: 2.4rem;\n    letter-spacing: 0.15rem;\n    text-align: center;\n    text-transform: uppercase;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    overflow: hidden;\n}\n\n.linkNextImg {\n    width: 1rem;\n    padding: 0.4rem 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkNext": "_1BWN_7q1nDiUot5ZmxlFsi",
	"linkNextText": "_3VyU2b8HpzuFxhMwgkmQQy",
	"linkNextImg": "_1oGhrmjBEmuI-In_Duxq9S"
};
export default ___CSS_LOADER_EXPORT___;
