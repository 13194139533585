import React from "react";

import { Link } from "react-router-dom";

import SectionStudent from "../section-student/index.js";
import Dropdown from "../dropdown/index.js";
import Collapse from "../collapse-section/index.js";
import LinkBlockWithImage from "../link-block-with-image/index.js";
import staticFiles from "../../static-files.js";

import RequestLoader from "../request-loader/index.js";

import { tryFormatDate, formatMonthDayDate } from "../../lib/date.js";

import styles from "./styles.module.css";


const DailyJuiceTodoList = (props) => {
    const {
        incompleteTodos,
        pastIssues,
    } = props;

    const renderIncompleteTodos = () => {
        const todos = [];

        incompleteTodos.forEach((todo) => {
            todos.push(
                <LinkBlockWithImage
                    linkClassName={styles.link}
                    showImage={todo.showImage}
                    imgUrl={todo.imgUrl}
                    date={todo.date}
                    title={todo.title}
                    juiceUrl={todo.juiceUrl}
                />,
            );
        });

        if (props.hasMoreTodoPages && !props.isTodosLoading) {
            todos.push(
                <div
                    onClick={props.onLoadMoreTodos}
                    onKeyPress={props.onLoadMoreTodos}
                    tabIndex="-1"
                    role="button"
                    className={styles.loadMoreButton}
                >
                    Load More
                </div>,
            );
        } else if (props.isTodosLoading) {
            todos.push(
                <RequestLoader />,
            );
        } else if (todos.length === 0) {
            todos.push(
                <div className={styles.noTodos}>
                    <div className={styles.emojisHappy}>
                        <img
                            src={staticFiles.emojisHappy}
                            alt="Emoji Happy"
                            title="Emoji Happy"
                        />
                    </div>
                    <div className={styles.noTodosText}>
                        <div>
                            You&apos;ve finished
                        </div>
                        <div>
                            All your daily juice!
                        </div>
                    </div>
                </div>,
            );
        }

        return (
            <div data-comment="section-daily-juices-todo-list">
                <div className={styles.todoHeader}>
                    To-Do List
                </div>
                {todos}
            </div>
        );
    };

    const renderReviewButton = (juice) => {
        if (!juice.completed || !juice.quizId) {
            return null;
        }

        return (
            <div
                onClick={() => {
                    props.onReviewClick(juice.juiceId, juice.quizId);
                }}
                onKeyPress={() => {
                    props.onReviewClick(juice.juiceId, juice.quizId);
                }}
                tabIndex="-1"
                role="button"
                className={styles.linkButtons}
            >
                review quiz
            </div>
        );
    };

    const renderPastIssues = () => {
        const issues = pastIssues.map((issue) => {
            let completedOn = "-";

            if (issue.completed && issue.completedOn && issue.completedOn !== "-") {
                completedOn = tryFormatDate(issue.completedOn, formatMonthDayDate);
            }

            let withBlueIndicator = true;

            if (issue.completed || issue.withoutQuizzes) {
                withBlueIndicator = false;
            }

            return (
                <Collapse
                    title={issue.title}
                    collapseClassName={styles.collapse}
                    collapseButtonClassName={styles.collapseButton}
                    extendedContentClassName={styles.extendedContent}
                    withDot={withBlueIndicator}
                >
                    <div className={styles.expandedContent}>
                        <div className={styles.pastIssuesQuizScore}>
                            <div className={styles.greySmallText}>
                                quiz score
                            </div>
                            <div className={styles.boldText}>
                                {issue.quizScore}
                            </div>
                        </div>
                        <div className={styles.pastIssuesCompletedOn}>
                            <div className={styles.greySmallText}>
                                completed on
                            </div>
                            <div className={styles.boldText}>
                                {completedOn}
                            </div>
                        </div>
                        <div className={styles.pastIssuesControls}>
                            <Link
                                className={styles.linkButtons}
                                to={issue.dailyJuiceLink}
                            >
                                open daily juice
                            </Link>
                            {renderReviewButton(issue)}
                        </div>
                    </div>
                </Collapse>
            );
        });

        if (props.hasMoreIssuesPages && !props.isIssuesLoading) {
            issues.push(
                <div
                    onClick={props.onLoadMorePastIssues}
                    onKeyPress={props.onLoadMorePastIssues}
                    tabIndex="-1"
                    role="button"
                    className={styles.loadMoreButton}
                >
                    Load More
                </div>,
            );
        } else if (props.isIssuesLoading) {
            issues.push(
                <RequestLoader />,
            );
        }

        return (issues);
    };

    return (
        <SectionStudent
            title="Daily Juice"
            dataComment="section-daily-juice-todo"
            showSeeMore={false}
        >
            <div className={styles.todoList}>
                {renderIncompleteTodos()}

                <div
                    className={styles.todoListPastIssues}
                    data-comment="section-daily-juices-past-issues"
                >
                    <div className={styles.pastIssuesHeader}>
                        <div className={styles.pastIssuesHeaderTitle}>
                            Past Issues
                        </div>
                        <div className={styles.hiddenDropdown}>
                            <Dropdown
                                values={[
                                    { value: "this week", label: "This Week" },
                                    { value: "last week", label: "Last Week" },
                                    { value: "this month", label: "This Month" },
                                ]}
                                onChange={() => {
                                    // TODO: save value
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        {renderPastIssues()}
                    </div>
                </div>
            </div>
        </SectionStudent>
    );
};

DailyJuiceTodoList.defaultProps = {
    incompleteTodos: [],
    pastIssues: [],

    isIssuesLoading: false,
    hasMoreIssuesPages: false,

    isTodosLoading: false,
    hasMoreTodoPages: false,

    onLoadMoreTodos: () => {},
    onLoadMorePastIssues: () => {},
    onReviewClick: () => {},
};

export default DailyJuiceTodoList;
