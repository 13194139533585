// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qZvciVcUKqRk2Lm_3w5_b {\n    display: grid;\n    grid-row-gap: 3rem;\n    justify-content: center;\n}\n\n._2DlmJ2SetZLTiaxi2gS6CN {}\n\n._2P9Eh6xMwW7gL_Fd-YvZwN {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.2rem solid #cccccc;\n    border-radius: 0.5rem;\n\n    background-color: #fff;\n    color: #000000;\n}\n\n._1m-hXFjYcESTOKVJAN-nt1 {\n    width: 4rem;\n    height: 4rem;\n}\n\n.Driklv-9sSR-un5Nh2H9f {\n    grid-template-columns: 5rem auto;\n}\n\n._2Yh1cfcOK92NQ_ZnZ9niTk {\n    grid-template-columns: 5rem auto;\n}\n\n._2GngdVY1X_5yNSTHUI9Jbl {\n    grid-template-columns: 5rem auto;\n}\n", "",{"version":3,"sources":["webpack://./base/business/quiz-question-answers/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA,0BAAU;;AAEV;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;IAEnB,kBAAkB;IAClB,oBAAoB;;IAEpB,4BAA4B;IAC5B,qBAAqB;;IAErB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".answerEmoji {\n    display: grid;\n    grid-row-gap: 3rem;\n    justify-content: center;\n}\n\n.answers {}\n\n.answer {\n    min-height: 6.4rem;\n\n    display: grid;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.2rem solid #cccccc;\n    border-radius: 0.5rem;\n\n    background-color: #fff;\n    color: #000000;\n}\n\n.answerIcon {\n    width: 4rem;\n    height: 4rem;\n}\n\n.answerCorrect {\n    grid-template-columns: 5rem auto;\n}\n\n.answerIncorrect {\n    grid-template-columns: 5rem auto;\n}\n\n.answerOther {\n    grid-template-columns: 5rem auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"answerEmoji": "_2qZvciVcUKqRk2Lm_3w5_b",
	"answers": "_2DlmJ2SetZLTiaxi2gS6CN",
	"answer": "_2P9Eh6xMwW7gL_Fd-YvZwN",
	"answerIcon": "_1m-hXFjYcESTOKVJAN-nt1",
	"answerCorrect": "Driklv-9sSR-un5Nh2H9f",
	"answerIncorrect": "_2Yh1cfcOK92NQ_ZnZ9niTk",
	"answerOther": "_2GngdVY1X_5yNSTHUI9Jbl"
};
export default ___CSS_LOADER_EXPORT___;
