const StoryStates = {
    storyNew: "Check out this fresh new story!",

    quizSkipped: "Ack! You skipped the quiz!",
    quizCorrect: "1 ouf of 1. You nailed it!",
    quizIncorrect: "0 ouf of 1. You'll get it next time!",
    quizEmpty: "No quiz today! You're doing great!",

    videoNew: "Hit play and check out this great new video!",
    videoWatched: "You're all caught up on videos. Strong work!",
};

const StoryStatesLegend = {
    iconNotepadExclamation: "Article + Quiz Skipped",

    iconNotepadStop: "Quiz Skipped",
    iconNotepadCheck: "Quiz Complete (Correct)",
    iconNotepadX: "Quiz Complete (Incorrect)",

    iconPlayBlue: "Video Skipped",
    iconPlayGreen: "Video Played",
};

const getStoryById = (stories, storyId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const s = stories[i];

        if (s.ID === storyId) {
            return s;
        }
    }

    return null;
};

const getNextStory = (stories, storyId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const s = stories[i];

        if (s.ID === storyId) {
            if (stories[i + 1]) {
                return stories[i + 1];
            }

            return null;
        }
    }

    return null;
};

const getPrevStory = (stories, storyId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const s = stories[i];

        if (s.ID === storyId) {
            if (stories[i - 1]) {
                return stories[i - 1];
            }

            return null;
        }
    }

    return null;
};

const getExtraJuiceById = (stories, extraJuiceId) => {
    for (let i = 0; i < stories.length; i += 1) {
        const ejs = stories[i].extraJuices || [];

        for (let j = 0; j < ejs.length; j += 1) {
            const ej = ejs[j];

            if (ej.id === extraJuiceId) {
                return ej;
            }
        }
    }

    return null;
};

/* --- */

const getStoryQuiz = (story) => {
    const quizzes = story.quizzes || [];

    if (quizzes.length === 0) {
        return null;
    }

    return quizzes[0] || null;
};

const isValidQuiz = (quiz) => {
    const anss = quiz.answers || [];

    for (let i = 0; i < anss.length; i += 1) {
        const ans = anss[i];

        if (ans.is_correct_answer !== ""
            && ans.is_correct_answer === true
            && ans.is_user_choice === true) {
            return true;
        }
    }

    return false;
};

const isAnsweredQuiz = (quiz) => {
    const isAnswered = quiz.answers.length > 0
        && quiz.answers[0].is_user_choice !== "";

    return isAnswered;
};

const getQuestionAnswer = (question) => {
    const ans = question.answers || [];

    for (let i = 0; i < ans.length; i += 1) {
        if (ans[i].is_user_choice) {
            return ans[i];
        }
    }

    return false;
};

/* --- */

const getQuizzesStats = (stories) => {
    const stats = {
        quizIds: [],

        firstSkipped: null,

        total: 0,
        answered: 0,
        correct: 0,
    };

    stories.forEach((s) => {
        const quizzes = s.quizzes || [];

        if (quizzes.length > 0) {
            const q = quizzes[0];
            const answer = getQuestionAnswer(q);

            stats.quizIds.push(q.quizId);

            if (answer) {
                stats.answered += 1;

                if (answer.is_correct_answer
                    && answer.is_user_choice) {
                    stats.correct += 1;
                }
            } else if (!stats.firstSkipped) {
                stats.firstSkipped = s.ID;
            }

            stats.total += 1;
        }
    });

    return stats;
};


export default {
    StoryStates,
    StoryStatesLegend,

    getStoryById,
    getNextStory,
    getPrevStory,
    getExtraJuiceById,

    getStoryQuiz,
    isValidQuiz,
    isAnsweredQuiz,
    getQuestionAnswer,
    getQuizzesStats,
};
