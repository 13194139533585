import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import PopupWindow from "../popup-window/index.js";
import IconStatus from "../icon-status/index.js";
import TableStudentJuices from "../table-student-juices/index.js";
import ButtonMenu from "../button-menu/index.js";
import HorizontalScrollingMenu from "../horizontal-scrolling-menu/index.js";

import staticFiles from "../../static-files.js";

import { getGradeGroup } from "../../project/grades.js";
import classNames from "../../lib/class-names.js";

import styles from "./styles.module.css";
import RequestLoader from "../request-loader/index.js";


const StudentInfo = (props) => {
    const [selectedStudentId, setSelectedStudentId] = useState(() => {
        if (props?.studentId) {
            return props.studentId;
        }

        return null;
    });

    const popupRef = useRef(null);

    const history = useHistory();

    const onSelectStudent = (key) => {
        const id = Number(key);
        props.onSelectStudent(id);
        setSelectedStudentId(id);
    };

    const renderInfoBlock = (dataComment, params) => {
        const rows = params.rows.map((row) => {
            return (
                <div className={styles.blockInfoRow}>
                    <div className={styles.blockInfoRowField}>
                        <div className={styles.blockInfoRowFieldLabel}>{row.label}</div>
                        <div className={styles.blockInfoRowColumn}>{row.field}</div>
                        <div>{row.bottomMessage}</div>
                    </div>
                    <div>{row.icon}</div>
                </div>
            );
        });

        return (
            <div
                className={styles.infoBlock}
                data-comment={dataComment}
            >
                <div className={styles.infoBlockTitle}>
                    {params.title}
                </div>
                {rows}
            </div>
        );
    };

    const renderStudentSelector = () => {
        if (props.studentsList.length === 0) {
            return null;
        }

        let popupWidth = 1100;

        if (popupRef?.current?.clientWidth) {
            popupWidth = popupRef.current.clientWidth;
        }

        const optionStyles = {
            width: `${(popupWidth / 3) - 23}px`,
        };

        const menu = [];

        for (let i = 0; i < props.studentsList.length; i += 1) {
            const optionClassName = classNames({
                [styles.option]: true,
                [styles.optionSelected]: selectedStudentId === props.studentsList[i].id,
            });

            menu.push(
                <div
                    style={optionStyles}
                    className={optionClassName}
                    key={props.studentsList[i].id}
                >
                    {props.studentsList[i].fullName}
                </div>,
            );
        }

        return (
            <div className={styles.studentSelectorMenu}>
                <HorizontalScrollingMenu
                    menu={menu}
                    selected={`${selectedStudentId}`}
                    onSelect={onSelectStudent}
                    hideArrows={props.hideStudentSelectorArrows}
                />
            </div>
        );
    };

    const renderDate = (date) => {
        if (!date) {
            return null;
        }

        return (
            <div className={styles.juiceDate}>
                {date}
            </div>
        );
    };

    const renderIconStatus = (statusId) => {
        return (
            <IconStatus
                statusId={statusId}
            />
        );
    };

    const renderButtonMenu = (dj) => {
        const menuButtons = (
            <div className={styles.menuButtons}>
                <div
                    className={styles.menuButton}
                    onClick={() => {
                        history.push(`/daily-juice/${dj.id}`);
                    }}
                    onKeyPress={() => {
                        history.push(`/daily-juice/${dj.id}`);
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    Open This Issue
                </div>
            </div>
        );

        let dataComment = "student-daily-juice-menu";

        if (props.isCards) {
            dataComment = "student-daily-mobile-juice-menu";
        }

        return (
            <ButtonMenu
                menuButtonsClassName={styles.menuButtonsContainer}
                menuButtons={[menuButtons]}
                dataComment={dataComment}
            />
        );
    };

    const renderEditButton = (onClickFn) => {
        return (
            <div
                className={styles.settingsIcons}
                tabIndex="-1"
                role="button"
                onKeyPress={onClickFn}
                onClick={onClickFn}
            >
                <img
                    src={staticFiles.iconPencilFilled}
                    alt="Edit"
                    title="Edit"
                />
            </div>
        );
    };

    const renderContent = () => {
        if (!props.isStudentLoaded) {
            return (
                <div className={styles.loader}>
                    <RequestLoader />
                </div>
            );
        }

        if (!props?.student?.account) {
            return null;
        }

        const accountBlockTitle = (
            <div className={styles.accountBlockTitle}>
                <div>Account</div>
                <div
                    className={styles.accountBlockTitleImage}
                    onClick={props.onRemoveStudent}
                    onKeyPress={props.onRemoveStudent}
                    tabIndex="-1"
                    role="button"
                >
                    <img
                        src={staticFiles.iconTrashV1}
                        title="Delete student"
                        alt="Delete student"
                    />
                </div>
            </div>
        );

        let resetPasswordBottomMessage = null;

        if (props.student.isForgotPassword) {
            resetPasswordBottomMessage = (
                <div className={styles.bottomMessageRed}>
                    This student has requested a password reset.
                </div>
            );
        }


        const resetIcon = (
            <div
                onClick={props.onResetPassword}
                onKeyPress={props.onResetPassword}
                tabIndex="-1"
                role="button"
                className={styles.settingsIcons}
            >
                <img
                    src={staticFiles.iconReset}
                    alt="Reset password"
                    title="Reset password"
                />
            </div>
        );

        const accountBlock = {
            title: accountBlockTitle,
            rows: [
                {
                    label: "Name",
                    field: props.student.account.fullName,
                    icon: renderEditButton(props.onEditName),
                    bottomMessage: null,
                },
                {
                    label: "Email",
                    field: props.student.account.email,
                    icon: renderEditButton(props.onEditEmail),
                    bottomMessage: null,
                },
                {
                    label: "Username",
                    field: props.student.account.userName,
                    icon: null,
                    bottomMessage: null,
                },
                {
                    label: "Password",
                    field: "***********",
                    icon: resetIcon,
                    bottomMessage: resetPasswordBottomMessage,
                },
            ],
        };

        const tableRows = [];

        if (props?.dailyJuices?.juices) {
            props.dailyJuices.juices.forEach((dj) => {
                let extraJuices = [];
                let videos = [];

                if (dj?.extraJuices && dj?.lifeHacks) {
                    extraJuices = dj.extraJuices.map((ej) => renderIconStatus(ej.statusId));
                    videos = dj.lifeHacks.map((video) => renderIconStatus(video.statusId));
                }

                const menuButton = props.hideMenuButtons ? null : renderButtonMenu(dj);

                tableRows.push([
                    renderDate(dj.date),
                    renderIconStatus(dj.statusId),
                    `${dj.quizScore}%`,
                    extraJuices,
                    videos,
                    menuButton,
                ]);
            });
        }

        const contentClasses = [styles.content];

        if (props.isCards) {
            contentClasses.push(styles.cardsContent);
        }

        let hasMorePages = false;

        if (props?.dailyJuices?.hasMore) {
            hasMorePages = true;
        }

        let gradeField = getGradeGroup(props.student.overview.studentGrade);

        if (gradeField === "11-12") {
            gradeField = "11+";
        }

        return (
            <div className={contentClasses.join(" ")}>
                <div className={styles.accountInfo}>
                    {renderInfoBlock("student-account", accountBlock)}
                    {renderInfoBlock("student-overview", {
                        title: "Student overview",
                        rows: [
                            {
                                label: "Reading level",
                                field: gradeField,
                                icon: renderEditButton(props.onGradeEdit),
                                bottomMessage: null,
                            },
                            {
                                label: "Cumulative Quiz Average",
                                field: `${props.student.overview.cumulativeQuizAverage}%`,
                                icon: "",
                                bottomMessage: null,
                            },
                        ],
                    })}
                </div>
                <TableStudentJuices
                    isCards={props.isCards}
                    hasMorePages={hasMorePages}
                    hideMenuButtons={props.hideMenuButtons}
                    tableRows={tableRows}
                    isDailyJuicesLoading={props.isDailyJuicesLoading}
                    onLoadMoreJuices={props.onLoadMoreJuices}
                />
            </div>
        );
    };

    if (!props.isPopup) {
        return (
            <>
                {renderStudentSelector()}
                {renderContent()}
            </>
        );
    }

    return (
        <PopupWindow
            popupClassName={styles.popupContainer}
            title="Student View"
            onClose={props.onClose}
        >
            <div ref={popupRef} className={styles.popup}>
                {renderStudentSelector()}
                {renderContent()}
            </div>
        </PopupWindow>
    );
};

StudentInfo.defaultProps = {
    studentId: null,
    student: {},
    isStudentLoaded: false,
    studentsList: [],
    dailyJuices: {},
    isPopup: true,
    isCards: false,

    hideMenuButtons: true,
    hideStudentSelectorArrows: false,
    isDailyJuicesLoading: false,

    onEditName: () => {},
    onEditEmail: () => {},
    onGradeEdit: () => {},

    // TODO:
    onRemoveStudent: () => {},

    onLoadMoreJuices: () => {},
    onSelectStudent: () => {},
    onResetPassword: () => {},
    onClose: () => {},
};

export default StudentInfo;
