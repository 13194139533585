import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import RequestLoader from "juice-base/components/request-loader/index.js";
import { withAuth } from "juice-base/components/auth/index.js";
import classNames from "juice-base/lib/class-names.js";
import copyToClipboard from "juice-base/lib/clipboard.js";
import { getDateFromDate, getDatesByRange } from "juice-base/lib/date.js";
import storage from "juice-base/lib/storage/index.js";

import LoaderSmall from "juice-base/components/loader-small/index.js";
import Tabs from "juice-base/components/tabs/index.js";
import TableStudents from "juice-base/components/table-students/index.js";
import StudentInfo from "juice-base/components/student-info/index.js";
import AlertBox from "juice-base/components/alert-box/index.js";
import Snackbar from "juice-base/components/snackbar/index.js";

import PopupResetPassword from "juice-base/components/popup-reset-password/index.js";
import PopupNewPassword from "juice-base/components/popup-new-password/index.js";
import PopupGradeChange from "juice-base/components/popup-grade-change/index.js";
import PopupNameChange from "juice-base/components/popup-name-change/index.js";
import PopupEmailChange from "juice-base/components/popup-email-change/index.js";
import PopupAddStudentsProgress from "juice-base/components/popup-add-students-progress/index.js";
import PopupConfirmGenerateClassCode from "juice-base/business/popup-confirm-generate-class-code/index.js";
import PopupConfirmClassCodeSendEmails from "juice-base/business/popup-confirm-class-code-send-emails/index.js";
import PopupConfirmDeleteStudent from "juice-base/business/popup-confirm-delete-student/index.js";
import PopupConfirmError from "juice-base/business/popup-confirm-error/index.js";
import PopupFullScreenAddStudent from "juice-base/business/popup-full-screen-add-student/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import * as events from "juice-base/events.js";
import api from "juice-app/api.js";

import actions from "juice-base/store/actions.js";

import * as Students from "juice-base/project/students.js";
import Grades from "juice-base/project/grades.js";

import settings from "juice-app/settings.js";

import styles from "./styles.module.css";


const getStudentPopupState = () => ({
    isOpen: false,
    isLoaded: false,
    userId: -1,
    user: {},
});

const getAddStudentProgressState = () => ({
    inProgress: false,
    totalLoaded: 0,
    students: [],
    currentLoading: {
        index: null,
        errorCode: -1,
    },
});

const Class = () => {
    const defaultDropdownRange = "today";

    // TODO: move to redux
    const [teacherClasses, setTeacherClasses] = useState({
        isLoaded: false,
        classes: [],
        selectedClassId: -1,
    });

    const [dateRange, setDateRange] = useState({
        dropdownRange: defaultDropdownRange,
        startDate: null,
        endDate: null,
    });

    const [studentInfoPopup, setStudentInfoPopup] = useState(() => getStudentPopupState());

    /* ---------- */

    const [isVisibleAddStudentWindow, setVisibleAddStudentWindow] = useState(false);

    const [isVisibleAddStudentsPopup, setVisibleAddStudentsPopup] = useState(false);

    const [
        addStudentsProgressState,
        setAddStudentsProgressState,
    ] = useState(() => getAddStudentProgressState());

    const [classCodePopup, setClassCodePopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        code: null,
    });

    const [classCodeDirectLinkPopup, setClassCodeDirectLinkPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        code: null,
    });

    const [sendClassCodeEmailsPopup, setSendClassCodeEmailsPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
    });

    const [copiedToClipboardSnackbarState, setCopiedToClipboardSnackbar] = useState({
        isOpen: false,
        message: "",
    });

    /* ---------- */

    const [studentDismissNewPasswordSnackbar, setStudentDismissNewPasswordSnackbar] = useState({
        isVisibleSnackbar: false,
        id: null,
        error: null,
    });

    const [studentAcceptToClassSnackbar, setStudentAcceptToClassSnackbar] = useState({
        isVisibleSnackbar: false,
        isLoading: false,
        id: null,
        message: null,
    });

    const [studentRejectToClassSnackbar, setStudentRejectToClassSnackbar] = useState({
        isVisibleSnackbar: false,
        id: null,
        error: null,
    });

    /* ---------- */

    const [resetStudentPasswordPopup, setResetStudentPasswordPopup] = useState({
        isOpen: false,
        isLoading: false,
        message: null,
    });

    const [deleteSelectedUsersPopup, setDeleteSelectedUsersPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        students: [],
    });

    const [deleteStudentPopup, setDeleteStudentPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        id: -1,
    });

    const [studentsState, setStudents] = useState({
        isLoaded: false,
        isOverview: false,
        message: null,
        students: [],
    });

    const [newPasswordPopupState, setNewPasswordPopupState] = useState({
        isOpen: false,
        isLoading: false,
        isSubmitted: true,
        message: null,
        id: null,
    });

    const [selectedSortByValue, setSelectedSortByValue] = useState(() => {
        const sValues = Students.getSortValues();

        if (sValues && sValues.length > 0) {
            return sValues[0].value;
        }

        return "";
    });

    const [nameChangePopupState, setNameChangePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [emailChangePopupState, setEmailChangePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [changeGradePopupState, setChangeGradePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [importStudentsTemplateUrl, setImportStudentsTemplateUrl] = useState(null);

    const [
        lastTouchedStudentIdWithPendingStatus,
        setLastTouchedStudentIdWithPendingStatus,
    ] = useState(null);

    const [
        lastTouchedStudentIdWithResetPasswordRequest,
        setLastTouchedStudentIdWithResetPasswordRequest,
    ] = useState(null);

    let sortedStudents = studentsState.students.filter((student) => {
        return Students.isStatusActive(student);
    });

    sortedStudents = Students.sortBy(selectedSortByValue, sortedStudents, studentsState.isOverview);

    const store = useSelector((state) => ({
        session: state.user.session,
        dimensions: state.device.dimensions,
        juices: state.juices,
        teacher: state.teacher,
    }));

    const dispatch = useDispatch();

    let hostname = "";

    if (window?.location?.hostname) {
        hostname = `https://${window.location.hostname}`;
    }

    /* --- */

    const getTeacherClassId = (classes) => {
        let classId = storage.local.loadTeacherSelectedClass();

        let classStillExists = false;

        if (classId) {
            for (let i = 0; i < classes.length; i += 1) {
                if (classes[i].id === parseInt(classId, 10)) {
                    classStillExists = true;
                    break;
                }
            }
        }

        classId = parseInt(classId, 10);

        if (!classStillExists && classes[0]) {
            classId = classes[0].id || -1;
            storage.local.saveTeacherSelectedClass(classId);
        }

        return classId;
    };

    const getClassCode = (classId) => {
        let classCode = null;

        if (teacherClasses.isLoaded) {
            for (let i = 0; i < teacherClasses.classes.length; i += 1) {
                if (teacherClasses.classes[i].id === classId) {
                    classCode = teacherClasses.classes[i].classCode;
                    break;
                }
            }
        }

        return classCode;
    };

    const getClassGradeById = (classId) => {
        let defaultGrade = "G7";

        for (let i = 0; i < teacherClasses.classes.length; i += 1) {
            if (teacherClasses.classes[i].id === classId) {
                defaultGrade = teacherClasses.classes[i].grade;
                break;
            }
        }

        return defaultGrade;
    };

    const getStudentWithPendingStatusById = (id) => {
        let student = null;

        for (let i = 0; i < store.teacher.studentsWithPendingStatus.length; i += 1) {
            if (store.teacher.studentsWithPendingStatus[i].id === id) {
                student = store.teacher.studentsWithPendingStatus[i];
                break;
            }
        }

        return student;
    };

    const getStudentWithForgottenPasswordById = (id) => {
        const { studentsWithForgottenPasswords } = store.teacher;

        for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
            if (studentsWithForgottenPasswords[i].id === id) {
                return studentsWithForgottenPasswords[i];
            }
        }

        return null;
    };

    // TODO: move teacher classes to redux
    const setClassCodeToClass = (classId, code) => {
        const newClasses = [];

        for (let i = 0; i < teacherClasses.classes.length; i += 1) {
            if (teacherClasses.classes[i].id === classId) {
                newClasses.push({
                    ...teacherClasses.classes[i],
                    classCode: code,
                });
            } else {
                newClasses.push(teacherClasses.classes[i]);
            }
        }

        setTeacherClasses((prev) => ({
            ...prev,
            classes: newClasses,
        }));
    };

    /* --- */

    const loadImportStudentsTemplate = () => {
        api.students.getImportStudentsTemplate({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                setImportStudentsTemplateUrl(res.templateLink);
            }
        });
    };

    const loadStudentDailyJuices = (studentId, clearJuices = true) => {
        if (clearJuices) {
            dispatch(actions.juices.clearStudentJuicesById({
                studentId,
            }));
        }

        dispatch(actions.juices.setStudentJuicesByIdLoading({
            studentId,
        }));

        let page = 0;

        if (!clearJuices && store.juices?.studentJuicesById[studentId]?.page) {
            page = store.juices.studentJuicesById[studentId].page;
        }

        api.students.getStudentDailyJuicesResultsByPage({
            session: store.session,
            studentId,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setStudentJuicesById({
                    studentId,
                    juices: res.juices,
                    hasMore: res.hasMore,
                    page: page + 1,
                }));
            }
        });
    };

    const loadStudentInfo = (studentId) => {
        setStudentInfoPopup((prev) => ({
            ...prev,
            isLoaded: false,
            isOpen: true,
            userId: studentId,
            user: {},
        }));

        api.students.getStudentInfo({
            session: store.session,
            studentId,
        }).then((res) => {
            setStudentInfoPopup((prev) => ({
                ...prev,
                isLoaded: true,
                user: res.ok ? res.student : {},
            }));
        });
    };

    const onShareClassCodeSendEmails = (studentEmails, classCode) => {
        setSendClassCodeEmailsPopup((prev) => ({
            ...prev,
            isOpen: true,
            isLoading: true,
            error: null,
        }));

        if (!classCode) {
            api.signup.generateClassCode({
                session: store.session,
                classId: teacherClasses.selectedClassId,
            }).then((res) => {
                if (res.ok) {
                    setClassCodeToClass(teacherClasses.selectedClassId, res.code);
                    onShareClassCodeSendEmails(studentEmails, res.code);
                } else {
                    setSendClassCodeEmailsPopup((prev) => ({
                        ...prev,
                        isLoading: false,
                        error: res.error || "Error!",
                    }));
                }
            });
        } else {
            const emails = studentEmails.map((email) => {
                return email.emailValue;
            });

            api.signup.sendInvitation({
                session: store.session,
                classId: teacherClasses.selectedClassId,
                emails,
            }).then((res) => {
                setSendClassCodeEmailsPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.ok ? null : res.error,
                }));
            });
        }
    };

    const onGenerateClassCode = () => {
        setClassCodePopup((prev) => ({
            ...prev,
            isOpen: true,
            isLoading: true,
            error: null,
            code: null,
        }));

        const classCode = getClassCode(teacherClasses.selectedClassId);

        if (classCode) {
            setClassCodePopup((prev) => ({
                ...prev,
                isLoading: false,
                code: classCode,
            }));

            setClassCodeToClass(teacherClasses.selectedClassId, classCode);
        } else {
            api.signup.generateClassCode({
                session: store.session,
                classId: teacherClasses.selectedClassId,
            }).then((res) => {
                const code = res.ok ? res.code : null;

                setClassCodePopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.ok ? null : res.error,
                    code,
                }));

                setClassCodeToClass(teacherClasses.selectedClassId, code);
            });
        }
    };

    const onDirectLinkClick = () => {
        setClassCodeDirectLinkPopup((prev) => ({
            ...prev,
            isOpen: true,
            isLoading: true,
            error: null,
            code: null,
        }));

        const classCode = getClassCode(teacherClasses.selectedClassId);

        if (classCode) {
            setClassCodeDirectLinkPopup((prev) => ({
                ...prev,
                isLoading: false,
                code: classCode,
            }));

            setClassCodeToClass(teacherClasses.selectedClassId, classCode);
        } else {
            api.signup.generateClassCode({
                session: store.session,
                classId: teacherClasses.selectedClassId,
            }).then((res) => {
                const code = res.ok ? res.code : null;

                setClassCodeDirectLinkPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.ok ? null : res.error,
                    code,
                }));

                setClassCodeToClass(teacherClasses.selectedClassId, code);
            });
        }
    };

    const onAddStudentsToClass = (idx, students, addNewOnDuplicate = false) => {
        if (!students[idx]) {
            setAddStudentsProgressState((prev) => ({
                ...prev,
                inProgress: false,
            }));

            return;
        }

        const newStudents = [
            ...students,
        ];

        newStudents[idx] = {
            ...newStudents[idx],
            status: "loading",
        };

        setAddStudentsProgressState((prev) => ({
            ...prev,
            inProgress: true,
            students: newStudents,
            currentLoading: {
                index: idx,
                errorCode: -1,
            },
        }));

        const student = newStudents[idx];

        const selectedGrade = student.gradeValue.split("-")[1];

        api.classes.addStudentV2({
            session: store.session,
            classId: teacherClasses.selectedClassId,
            firstname: student.nameValue,
            lastname: student.lastNameValue,
            email: student.emailValue,
            grade: selectedGrade,
            addNewOnDuplicate,
        }).then((res) => {
            if (res.ok) {
                newStudents[idx] = {
                    ...newStudents[idx],
                    status: "success",
                };

                setAddStudentsProgressState((prev) => ({
                    ...prev,
                    students: newStudents,
                    totalLoaded: prev.totalLoaded + 1,
                }));

                onAddStudentsToClass(idx + 1, newStudents);
            } else {
                let errorCode = -1;

                if (res.error.indexOf("Fullname duplicate") !== -1) {
                    errorCode = 0;
                }

                if (res.error.indexOf("Email duplicate") !== -1) {
                    errorCode = 1;
                }

                if (errorCode === -1) {
                    newStudents[idx] = {
                        ...newStudents[idx],
                        status: "failed",
                    };
                }

                setAddStudentsProgressState((prev) => ({
                    ...prev,
                    students: newStudents,
                    currentLoading: {
                        ...prev.currentLoading,
                        errorCode,
                    },
                }));

                if (errorCode === -1) {
                    onAddStudentsToClass(idx + 1, newStudents);
                }
            }
        });
    };

    const loadClassStudents = (id, dropdownRange, customDates = null) => {
        setDateRange((prev) => ({
            ...prev,
            dropdownRange,
            startDate: customDates ? customDates.dateFrom : null,
            endDate: customDates ? customDates.dateTo : null,
        }));

        setStudents((prev) => ({
            ...prev,
            isLoaded: false,
            isOverview: false,
            message: null,
            students: [],
        }));

        if (customDates) {
            const dateFrom = customDates.dateFrom.format("YYYY-MM-DD");
            const dateTo = customDates.dateTo.format("YYYY-MM-DD");

            api.classes.getClassStudentsById({
                session: store.session,
                classId: id,
                dateFrom,
                dateTo,
            }).then((classRes) => {
                const newStudents = classRes.ok ? classRes.data.students : [];
                const isOverview = classRes.ok ? classRes.data.isOverview : false;

                setStudents((prev) => ({
                    ...prev,
                    isLoaded: true,
                    isOverview,
                    message: classRes.ok ? null : classRes.error,
                    students: newStudents,
                }));
            });
        } else {
            api.site.getSiteDate().then((res) => {
                const siteDate = res.ok ? res.date : null;

                const dates = getDatesByRange(siteDate, dropdownRange);

                let { dateFrom, dateTo } = dates;

                if (dateFrom !== "all") {
                    dateFrom = getDateFromDate(dateFrom);
                }

                if (dateTo !== "") {
                    dateTo = getDateFromDate(dateTo);
                }

                if (dateFrom !== "all" && dateTo !== "") {
                    setDateRange((prev) => ({
                        ...prev,
                        startDate: moment(dateFrom),
                        endDate: moment(dateTo),
                    }));
                }

                api.classes.getClassStudentsById({
                    session: store.session,
                    classId: id,
                    dateFrom,
                    dateTo,
                }).then((classRes) => {
                    const newStudents = classRes.ok ? classRes.data.students : [];
                    const isOverview = classRes.ok ? classRes.data.isOverview : false;

                    setStudents({
                        isLoaded: true,
                        isOverview,
                        message: classRes.ok ? null : classRes.error,
                        students: newStudents,
                    });
                });
            });
        }
    };

    const onCloseAddStudentProgressPopup = () => {
        const range = store.teacher.statsDate ? store.teacher.statsDate : defaultDropdownRange;
        loadClassStudents(teacherClasses.selectedClassId, range);

        setVisibleAddStudentsPopup(false);
        setAddStudentsProgressState(getAddStudentProgressState());
    };

    const onAddDuplicate = () => {
        onAddStudentsToClass(
            addStudentsProgressState.currentLoading.index,
            addStudentsProgressState.students,
            true,
        );
    };

    const onSkipDuplicate = () => {
        const newStudents = [
            ...addStudentsProgressState.students,
        ];

        newStudents[addStudentsProgressState.currentLoading.index] = {
            ...newStudents[addStudentsProgressState.currentLoading.index],
            status: "skipped",
        };

        setAddStudentsProgressState((prev) => ({
            ...prev,
            students: newStudents,
            totalLoaded: prev.totalLoaded + 1,
            currentLoading: {
                ...prev.currentLoading,
                errorCode: -1,
            },
        }));

        onAddStudentsToClass(
            addStudentsProgressState.currentLoading.index + 1,
            newStudents,
        );
    };

    const onAddStudentsToClassButtonClick = (students) => {
        setVisibleAddStudentsPopup(true);

        onAddStudentsToClass(0, students);
    };

    const onShowAddStudentPopup = () => {
        setVisibleAddStudentWindow(true);

        loadImportStudentsTemplate();
    };

    const closeAddStudentPopup = () => {
        setVisibleAddStudentWindow(false);

        setVisibleAddStudentsPopup(false);
    };

    const onOpenStudentPopup = (userId) => {
        events.classOpenStudentInfo({
            session: store.session,
            classId: teacherClasses.selectedClassId,
        });

        loadStudentInfo(userId);

        loadStudentDailyJuices(userId);
    };

    const onCloseAddStudentPopup = () => {
        closeAddStudentPopup();
    };

    const onCloseResetStudentPopup = () => {
        setResetStudentPasswordPopup({
            isOpen: false,
            isLoading: true,
            message: null,
        });
    };

    const onCloseNewPasswordPopup = () => {
        setNewPasswordPopupState({
            isOpen: false,
            isSubmitted: false,
            isLoading: false,
            message: null,
            id: null,
        });
    };

    const onChangeStudentPassword = (newPassword) => {
        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSubmitted: true,
            isLoading: true,
        }));

        api.students.setPassword({
            session: store.session,
            studentId: newPasswordPopupState.id,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                setLastTouchedStudentIdWithResetPasswordRequest(newPasswordPopupState.id);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                        studentId: newPasswordPopupState.id,
                    }));
                }, 200);
            }

            setNewPasswordPopupState((prev) => ({
                ...prev,
                isLoading: false,
                message: res.ok ? "Password successfully updated!" : res.error,
            }));
        });
    };

    const onDismissUserWithForgottenPassowrd = (studentId) => {
        let isVisibleSnackbar = false;
        let userId = null;
        let error = null;

        api.classes.dismissStudentWithForgottenPassword({
            session: store.session,
            studentId,
        }).then((res) => {
            if (res.ok) {
                setLastTouchedStudentIdWithResetPasswordRequest(studentId);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                        studentId,
                    }));
                }, 200);
            } else {
                isVisibleSnackbar = true;
                userId = studentId;
                error = res.error;
            }

            setStudentDismissNewPasswordSnackbar({
                isVisibleSnackbar,
                id: userId,
                error,
            });
        });
    };

    const onAlertDismissUserWithForgottenPassowrd = (student) => {
        setStudentDismissNewPasswordSnackbar({
            isVisibleSnackbar: true,
            id: student.id,
            error: null,
        });
    };

    const onCloseDeleteSelectedUsersPopup = () => {
        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
            students: [],
        }));
    };

    const onCloseDeletingPopup = () => {
        setDeleteStudentPopup({
            isOpen: false,
            isLoading: false,
            error: null,
            id: -1,
        });
    };

    const onCloseStudentPopup = () => {
        setStudentInfoPopup(getStudentPopupState());
    };

    const onRemoveStudent = (studentId) => {
        setDeleteStudentPopup({
            isOpen: true,
            isLoading: false,
            error: null,
            id: studentId,
        });
    };

    /* --- */

    const onSelectStudent = (id) => {
        loadStudentInfo(id);

        loadStudentDailyJuices(id);
    };

    const onCloseAllChangingDataPopups = () => {
        setNameChangePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });

        setEmailChangePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });

        setChangeGradePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });
    };

    const onAcceptStudentToClass = (studentId) => {
        setStudentAcceptToClassSnackbar({
            isVisibleSnackbar: false,
            isLoading: true,
            id: studentId,
            message: null,
        });

        api.classes.acceptStudentToClass({
            session: store.session,
            studentId,
        }).then((res) => {
            let message = "Student has been added to the class.";

            if (res.ok) {
                const studentData = getStudentWithPendingStatusById(studentId);

                if (studentData?.fullName) {
                    message = `${studentData.fullName} has been added to the class`;
                }

                setLastTouchedStudentIdWithPendingStatus(studentId);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithPendingStatusById({
                        studentId,
                    }));
                }, 200);

                const range = store.teacher.statsDate
                    ? store.teacher.statsDate
                    : defaultDropdownRange;

                loadClassStudents(teacherClasses.selectedClassId, range);
            } else {
                message = res.error || "Error!";
            }

            setStudentAcceptToClassSnackbar((prev) => ({
                ...prev,
                isVisibleSnackbar: true,
                isLoading: false,
                message,
            }));
        });
    };

    const onRejectStudentToClass = (studentId) => {
        api.classes.rejectStudentToClass({
            session: store.session,
            studentId,
            classId: teacherClasses.selectedClassId,
        }).then((res) => {
            let isVisibleSnackbar = false;
            let userId = null;
            let error = null;

            if (res.ok) {
                setLastTouchedStudentIdWithPendingStatus(studentId);

                setTimeout(() => {
                    dispatch(actions.teacher.deleteTeacherStudentWithPendingStatusById({
                        studentId,
                    }));
                }, 200);
            } else {
                isVisibleSnackbar = true;
                userId = studentId;
                error = res.error;
            }

            setStudentRejectToClassSnackbar({
                isVisibleSnackbar,
                id: userId,
                error,
            });
        });
    };

    const onUpdateStudentInfo = (params) => {
        if (params.newFullname) {
            setNameChangePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        } else if (params.newEmail) {
            setEmailChangePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        } else if (params.newGrade) {
            setChangeGradePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        }

        api.students.updateStudentDataV2({
            session: store.session,
            studentId: studentInfoPopup.userId,

            fullname: params.newFullname || "",
            email: params.newEmail || "",
            grade: params.newGrade || "",
        }).then((res) => {
            events.classEditStudent({
                session: store.session,
                classId: teacherClasses.selectedClassId,
            });

            if (res.ok) {
                onCloseAllChangingDataPopups();

                loadStudentInfo(studentInfoPopup.userId);

                const range = {
                    dateFrom: dateRange.startDate,
                    dateTo: dateRange.endDate,
                };

                loadClassStudents(
                    teacherClasses.selectedClassId,
                    dateRange.dropdownRange,
                    range,
                );
            } else {
                const error = res.error || "Error!";

                if (params.newFullname) {
                    setNameChangePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                } else if (params.newEmail) {
                    setEmailChangePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                } else if (params.newGrade) {
                    setChangeGradePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                }
            }
        });
    };

    const removeSelectedUsers = () => {
        const { students } = deleteSelectedUsersPopup;

        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        for (let i = 0; i < students.length; i += 1) {
            api.classes.removeStudentById({
                session: store.session,
                studentId: students[i].id,
            }).then((res) => {
                if (i === students.length - 1) {
                    if (res.ok) {
                        loadClassStudents(teacherClasses.selectedClassId, defaultDropdownRange);

                        onCloseDeleteSelectedUsersPopup();
                    } else {
                        setDeleteSelectedUsersPopup((prev) => ({
                            ...prev,
                            isOpen: true,
                            isLoading: false,
                            error: res.error,
                        }));
                    }
                }
            });
        }
    };

    const onClassChange = (classId) => {
        events.classChangeClass({
            session: store.session,
            classId,
        });

        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: classId,
        }));

        storage.local.saveTeacherSelectedClass(classId);

        setTeacherClasses((prevState) => ({
            ...prevState,
            selectedClassId: classId,
        }));

        if (store.teacher.statsDate) {
            loadClassStudents(classId, store.teacher.statsDate);
        } else {
            loadClassStudents(classId, defaultDropdownRange);
        }
    };

    const onDateRangeChange = (values) => {
        const classId = teacherClasses.selectedClassId;

        if (values.dropdown && (!values.startDate && !values.endDate)) {
            dispatch(actions.teacher.setTeacherStatsDate({
                statsDate: values.dropdown.value,
            }));

            loadClassStudents(classId, values.dropdown.value);
        } else if (values.startDate && values.endDate) {
            const range = {
                dateFrom: values.startDate,
                dateTo: values.endDate,
            };

            loadClassStudents(classId, "custom", range);
        }
    };

    const removeStudent = () => {
        setDeleteStudentPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        api.classes.removeStudentById({
            session: store.session,
            studentId: deleteStudentPopup.id,
        }).then((res) => {
            events.classDeleteStudent({
                session: store.session,
                classId: teacherClasses.selectedClassId,
            });

            if (res.ok) {
                onCloseDeletingPopup();
                onCloseStudentPopup();

                loadClassStudents(
                    teacherClasses.selectedClassId,
                    defaultDropdownRange,
                );
            } else {
                setDeleteStudentPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.error,
                }));
            }
        });
    };

    const onAlertResetPassword = (student) => {
        loadStudentInfo(student.id);

        loadStudentDailyJuices(student.id);

        setNewPasswordPopupState({
            isOpen: true,
            isLoading: false,
            isSubmitted: false,
            message: null,
            id: student.id,
        });
    };

    const onAlertAcceptStudent = (student) => {
        onAcceptStudentToClass(student.id);
    };

    const onAlertRejectStudent = (student) => {
        setStudentRejectToClassSnackbar({
            isVisibleSnackbar: true,
            id: student.id,
            error: null,
        });
    };

    const onOpenNewPasswordPopup = (studentId) => {
        setNewPasswordPopupState({
            isLoading: false,
            isSubmitted: false,
            message: null,
            isOpen: true,
            id: studentId,
        });
    };

    const onCloseClassCodePopup = () => {
        setClassCodePopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
            message: "",
        }));
    };

    const onCloseClassCodeDirectLinkPopup = () => {
        setClassCodeDirectLinkPopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
            message: "",
        }));
    };

    const onCloseSendClassCodeEmailsPopup = () => {
        setSendClassCodeEmailsPopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
        }));
    };

    const onCopyToClipboard = (toCopy, snackbarMessage) => {
        copyToClipboard(toCopy);

        setCopiedToClipboardSnackbar({
            isOpen: true,
            message: snackbarMessage,
        });
    };

    const onCloseCopiedToClipboardSnackbar = () => {
        setCopiedToClipboardSnackbar({
            isOpen: false,
            message: "",
        });
    };

    const onCloseRejectSnackbar = (params) => {
        if (params.withUndo) {
            setStudentRejectToClassSnackbar({
                isVisibleSnackbar: false,
                id: null,
                error: null,
            });
        } else {
            onRejectStudentToClass(studentRejectToClassSnackbar.id);
        }
    };

    const onCloseDismissSnackbar = (params) => {
        if (params.withUndo) {
            setStudentDismissNewPasswordSnackbar({
                isVisibleSnackbar: false,
                id: null,
                error: null,
            });
        } else {
            onDismissUserWithForgottenPassowrd(studentDismissNewPasswordSnackbar.id);
        }
    };

    const onCloseAcceptSnackbar = () => {
        setStudentAcceptToClassSnackbar({
            isVisibleSnackbar: false,
            isLoading: false,
            id: null,
            message: null,
        });
    };

    const onResetStudentPassword = (student) => {
        if (student.hasEmail) {
            setResetStudentPasswordPopup({
                isLoading: true,
                isOpen: true,
                message: null,
            });

            api.classes.resetStudentPasswordById({
                session: store.session,
                studentId: student.id,
            }).then((res) => {
                events.classResetPassword({
                    session: store.session,
                    classId: teacherClasses.selectedClassId,
                });

                setResetStudentPasswordPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    message: res.ok ? "Email successfully sent!" : res.error,
                }));
            });
        } else {
            onOpenNewPasswordPopup(student.id);
        }
    };

    const loadTeacherClasses = () => {
        api.classes.getTeacherClasses({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                const classes = res.classes || [];
                const classId = getTeacherClassId(classes);

                dispatch(actions.teacher.setTeacherSelectedClass({
                    selectedClassId: classId,
                }));

                setTeacherClasses((prev) => ({
                    ...prev,
                    isLoaded: true,
                    selectedClassId: classId,
                    classes,
                }));

                if (store.teacher.statsDate) {
                    loadClassStudents(classId, store.teacher.statsDate);
                } else {
                    loadClassStudents(classId, defaultDropdownRange);
                }
            }
        });
    };

    const loadStudentsForgotttenPassword = () => {
        api.classes.getStudentsWithForgottenPasswords({
            session: store.session,
            classId: teacherClasses.selectedClassId,
        }).then((res) => {
            dispatch(actions.teacher.setTeacherStudentWithForgottenPasswords({
                students: res.ok ? res.students : [],
            }));
        });
    };

    const loadClassStudentWithPendingStatus = (
        newPage,
        prevStudents = store.teacher.studentsWithPendingStatus,
    ) => {
        dispatch(actions.teacher.setTeacherStudentsWithPendingStatusLoading());

        api.classes.getStudentsWithPendingStatus({
            session: store.session,
            classId: teacherClasses.selectedClassId,
            page: newPage,
        }).then((res) => {
            if (res.ok) {
                const newStudents = [
                    ...prevStudents,
                    ...res.students,
                ];

                dispatch(actions.teacher.setTeacherStudentsWithPendingStatus({
                    students: newStudents,
                    hasMore: res.hasMore,
                    page: newPage,
                }));
            }
        });
    };

    useEffect(() => {
        if (store.session) {
            loadTeacherClasses();
        }
    }, [store.session]);

    useEffect(() => {
        if (teacherClasses.selectedClassId !== -1) {
            loadStudentsForgotttenPassword();

            dispatch(actions.teacher.clearTeacherStudentsWithPendingStatus());

            loadClassStudentWithPendingStatus(0, []);
        }
    }, [teacherClasses.selectedClassId]);

    /* --- */

    const renderTutorial = () => {
        if (!studentsState.isLoaded
            || studentInfoPopup.isOpen) {
            return null;
        }

        return (
            <Tutorial name="teacher-class" />
        );
    };

    const renderNameChangePopup = () => {
        if (!nameChangePopupState.isOpen) {
            return null;
        }

        let defaultValue = "";

        if (studentInfoPopup?.user?.account?.fullName) {
            defaultValue = studentInfoPopup.user.account.fullName;
        }

        return (
            <PopupNameChange
                defaultValue={defaultValue}
                isSaving={nameChangePopupState.isSaving}
                error={nameChangePopupState.error}
                onSave={(newFullname) => {
                    onUpdateStudentInfo({
                        newFullname,
                    });
                }}
                onClose={() => {
                    setNameChangePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderEmailChangePopup = () => {
        if (!emailChangePopupState.isOpen) {
            return null;
        }

        let defaultValue = "";

        if (studentInfoPopup?.user?.account?.email) {
            defaultValue = studentInfoPopup.user.account.email;
        }

        return (
            <PopupEmailChange
                defaultValue={defaultValue}
                isSaving={emailChangePopupState.isSaving}
                error={emailChangePopupState.error}
                onSave={(newEmail) => {
                    onUpdateStudentInfo({
                        newEmail,
                    });
                }}
                onClose={() => {
                    setEmailChangePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderGradeChangePopup = () => {
        if (!changeGradePopupState.isOpen) {
            return null;
        }

        let defaultGrade = null;

        if (studentInfoPopup?.user?.overview?.studentGrade) {
            defaultGrade = Grades.getGradeGroup(studentInfoPopup.user.overview.studentGrade);
        }

        return (
            <PopupGradeChange
                defaultGrade={defaultGrade}
                grades={["5-6", "7-8", "9-10", "11-12"]}
                isSaving={changeGradePopupState.isSaving}
                error={changeGradePopupState.error}
                onSave={(newGrades) => {
                    const sGrades = newGrades.split("-");

                    if (sGrades.length > 1) {
                        onUpdateStudentInfo({
                            newGrade: `g${sGrades[1]}`,
                        });
                    }
                }}
                onClose={() => {
                    setChangeGradePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderStudentInfoPopup = () => {
        if (!studentInfoPopup.isOpen) {
            return null;
        }

        let isStudentForgotPassword = false;
        let studentData = {};

        if (studentInfoPopup?.user?.account?.ID) {
            const { studentsWithForgottenPasswords } = store.teacher;

            for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
                if (studentsWithForgottenPasswords[i].id === studentInfoPopup.user.account.ID) {
                    isStudentForgotPassword = true;
                    break;
                }
            }

            studentData = {
                ...studentInfoPopup.user,
                isForgotPassword: isStudentForgotPassword,
            };
        }

        let dailyJuices = {};
        let isDailyJuicesLoading = false;

        if (store.juices?.studentJuicesById[studentInfoPopup.userId]) {
            dailyJuices = store.juices.studentJuicesById[studentInfoPopup.userId];

            if (store.juices.studentJuicesById[studentInfoPopup.userId].isLoading) {
                isDailyJuicesLoading = true;
            }
        }

        return (
            <StudentInfo
                studentId={studentInfoPopup.userId}
                student={studentData}
                isStudentLoaded={studentInfoPopup.isLoaded}
                studentsList={sortedStudents}
                dailyJuices={dailyJuices}
                isDailyJuicesLoading={isDailyJuicesLoading}
                isCards={store.dimensions.width < 900}
                hideStudentSelectorArrows={store.dimensions.width < 500}
                onEditName={() => {
                    setNameChangePopupState((prev) => ({
                        ...prev,
                        isOpen: true,
                    }));
                }}
                onEditEmail={() => {
                    setEmailChangePopupState((prev) => ({
                        ...prev,
                        isOpen: true,
                    }));
                }}
                onGradeEdit={() => {
                    setChangeGradePopupState((prev) => ({
                        ...prev,
                        isOpen: true,
                    }));
                }}
                onSelectStudent={onSelectStudent}
                onRemoveStudent={() => {
                    onRemoveStudent(studentInfoPopup.userId);
                }}
                onResetPassword={() => {
                    onOpenNewPasswordPopup(studentInfoPopup.userId);
                }}
                onLoadMoreJuices={() => {
                    loadStudentDailyJuices(studentInfoPopup.userId, false);
                }}
                onClose={onCloseStudentPopup}
            />
        );
    };

    /* --- */

    const renderAddStudentWindow = () => {
        if (!isVisibleAddStudentWindow) {
            return null;
        }

        const classCode = getClassCode(teacherClasses.selectedClassId);
        const defaultGrade = Grades.getValidGrade(getClassGradeById(teacherClasses.selectedClassId));

        return (
            <PopupFullScreenAddStudent
                classCode={classCode}
                importStudentsTemplateUrl={importStudentsTemplateUrl}
                isMobile={store.dimensions.width < 800}
                grades={settings.grades}
                defaultGrade={defaultGrade}
                onShareClassCodeSendEmails={onShareClassCodeSendEmails}
                onGenerateClassCode={onGenerateClassCode}
                onDirectLinkClick={onDirectLinkClick}
                onAddStudentsToClass={onAddStudentsToClassButtonClick}
                onClose={onCloseAddStudentPopup}
            />
        );
    };

    const renderAddStudentMessagePopup = () => {
        if (!isVisibleAddStudentsPopup) {
            return null;
        }

        return (
            <PopupAddStudentsProgress
                data={addStudentsProgressState.students}
                inProgress={addStudentsProgressState.inProgress}
                totalLoaded={addStudentsProgressState.totalLoaded}
                currentStudentErrorCode={addStudentsProgressState.currentLoading.errorCode}
                currentLoadingStudentIndex={addStudentsProgressState.currentLoading.index}
                onAddDuplicate={onAddDuplicate}
                onSkipDuplicate={onSkipDuplicate}
                onClose={onCloseAddStudentProgressPopup}
            />
        );
    };

    const renderClassCodePopup = () => {
        if (!classCodePopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmGenerateClassCode
                code={classCodePopup.code}
                signUpByCodePath={settings.signUpByCodePath}
                buttonsDisabled={classCodePopup.isLoading || classCodePopup.error}
                isLoading={classCodePopup.isLoading}
                onCopyToClipboard={() => {
                    onCopyToClipboard(classCodePopup.code, "Class code copied to clipboard.");
                }}
                onClose={onCloseClassCodePopup}
            />
        );
    };

    const renderClassCodeDirectLinkPopup = () => {
        if (!classCodeDirectLinkPopup.isOpen) {
            return null;
        }

        const registrationLink = `${hostname}${settings.signUpByCodePath}/${classCodeDirectLinkPopup.code}`;

        return (
            <PopupConfirmGenerateClassCode
                fullClassCodeLink
                code={registrationLink}
                signUpByCodePath={settings.signUpByCodePath}
                buttonsDisabled={classCodeDirectLinkPopup.isLoading}
                isLoading={classCodeDirectLinkPopup.isLoading || classCodeDirectLinkPopup.error}
                onCopyToClipboard={() => {
                    onCopyToClipboard(registrationLink, "Direct link copied to clipboard.");
                }}
                onClose={onCloseClassCodeDirectLinkPopup}
            />
        );
    };

    const renderSendClassCodeEmailsPopup = () => {
        if (!sendClassCodeEmailsPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmClassCodeSendEmails
                isLoading={sendClassCodeEmailsPopup.isLoading}
                error={sendClassCodeEmailsPopup.error}
                onClose={onCloseSendClassCodeEmailsPopup}
            />
        );
    };

    /* --- */

    const renderResetStudentPopup = () => {
        if (!resetStudentPasswordPopup.isOpen) {
            return null;
        }

        return (
            <PopupResetPassword
                onClose={onCloseResetStudentPopup}
                isLoading={resetStudentPasswordPopup.isLoading}
                message={resetStudentPasswordPopup.message}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopupState.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={newPasswordPopupState.message}
                isSubmitted={newPasswordPopupState.isSubmitted}
                isLoading={newPasswordPopupState.isLoading}
                onSave={onChangeStudentPassword}
                onClose={onCloseNewPasswordPopup}
            />
        );
    };

    const renderDismissSetNewPasswordSnackbar = () => {
        if (!studentDismissNewPasswordSnackbar.isVisibleSnackbar) {
            return null;
        }

        if (studentDismissNewPasswordSnackbar.error) {
            return (
                <PopupConfirmError
                    error={studentDismissNewPasswordSnackbar.error}
                    onClose={() => {
                        setStudentDismissNewPasswordSnackbar({
                            isVisibleSnackbar: false,
                            id: null,
                            error: null,
                        });
                    }}
                />
            );
        }

        const student = getStudentWithForgottenPasswordById(studentDismissNewPasswordSnackbar.id);

        return (
            <Snackbar
                message={`${student?.fullName}'s password request has been dismissed.`}
                isVisibleUndoButton
                onClose={onCloseDismissSnackbar}
            />
        );
    };

    const renderAcceptStudentSnackbar = () => {
        if (!studentAcceptToClassSnackbar.isVisibleSnackbar
            || studentAcceptToClassSnackbar.isLoading) {
            return null;
        }

        return (
            <Snackbar
                message={studentAcceptToClassSnackbar.message}
                onClose={onCloseAcceptSnackbar}
            />
        );
    };

    const renderRejectStudentSnackbar = () => {
        if (!studentRejectToClassSnackbar.isVisibleSnackbar) {
            return null;
        }

        if (studentRejectToClassSnackbar.error) {
            return (
                <PopupConfirmError
                    error={studentRejectToClassSnackbar.error}
                    onClose={() => {
                        setStudentRejectToClassSnackbar({
                            isVisibleSnackbar: false,
                            id: null,
                            error: null,
                        });
                    }}
                />
            );
        }

        const student = getStudentWithPendingStatusById(studentRejectToClassSnackbar.id);

        return (
            <Snackbar
                message={`${student?.fullName} will be rejected from the class.`}
                isVisibleUndoButton
                onClose={onCloseRejectSnackbar}
            />
        );
    };

    const renderDeletePopup = () => {
        if (!deleteStudentPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                isLoading={deleteStudentPopup.isLoading}
                error={deleteStudentPopup.error}
                onDelete={removeStudent}
                onClose={onCloseDeletingPopup}
            />
        );
    };

    const renderDeleteSelectedUsersPopup = () => {
        if (!deleteSelectedUsersPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                students={deleteSelectedUsersPopup.students}
                isLoading={deleteSelectedUsersPopup.isLoading}
                error={deleteSelectedUsersPopup.error}
                onDelete={removeSelectedUsers}
                onClose={onCloseDeleteSelectedUsersPopup}
            />
        );
    };

    /* --- */

    const renderTabs = () => {
        return (
            <div className={styles.classesTabs}>
                <Tabs
                    tabs={teacherClasses.classes}
                    selectedClassId={teacherClasses.selectedClassId}
                    dimensions={store.dimensions}
                    dataComment="teacher-classes"
                    onChange={(title, id) => {
                        onClassChange(id);
                    }}
                />
            </div>
        );
    };

    const renderAlerts = () => {
        if (!store.teacher.isStudentsWithForgottenPasswordsLoaded
            || !store.teacher.isStudentsWithPendingStatusLoaded) {
            return null;
        }

        const alerts = [];

        store.teacher.studentsWithForgottenPasswords.forEach((student) => {
            let isVisibleBox = true;

            if (student.id === lastTouchedStudentIdWithResetPasswordRequest) {
                isVisibleBox = false;
            }

            const isDismissSnackbarVisible = studentDismissNewPasswordSnackbar.isVisibleSnackbar;

            const buttonClassName = classNames({
                [styles.alertControlButton]: true,
                [styles.alertControlButtonDisabled]: isDismissSnackbarVisible,
            });

            let controls = [
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isDismissSnackbarVisible) {
                            onAlertResetPassword(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isDismissSnackbarVisible) {
                            onAlertResetPassword(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Reset password
                </div>,
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isDismissSnackbarVisible) {
                            onAlertDismissUserWithForgottenPassowrd(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isDismissSnackbarVisible) {
                            onAlertDismissUserWithForgottenPassowrd(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Dismiss
                </div>,
            ];

            if (isDismissSnackbarVisible && (student.id === studentDismissNewPasswordSnackbar.id)) {
                controls = [<LoaderSmall />];
            }

            alerts.push(
                <AlertBox
                    isVisibleBox={isVisibleBox}
                    theme="danger"
                    rightControls={controls}
                >
                    {`A student in this class, ${student.fullName}(${student.userName}), has forgotten their password.`}
                </AlertBox>,
            );
        });

        store.teacher.studentsWithPendingStatus.forEach((student) => {
            let isVisibleBox = true;

            if (student.id === lastTouchedStudentIdWithPendingStatus) {
                isVisibleBox = false;
            }

            const isRejectSnackbarVisible = studentRejectToClassSnackbar.isVisibleSnackbar;
            const isAcceptSnackbarLoading = studentAcceptToClassSnackbar.isLoading;

            const buttonClassName = classNames({
                [styles.alertControlButton]: true,
                [styles.alertControlButtonDisabled]: isRejectSnackbarVisible
                    || isAcceptSnackbarLoading,
            });

            let controls = [
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isRejectSnackbarVisible && !isAcceptSnackbarLoading) {
                            onAlertAcceptStudent(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isRejectSnackbarVisible && !isAcceptSnackbarLoading) {
                            onAlertAcceptStudent(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Accept
                </div>,
                <div
                    className={buttonClassName}
                    onClick={() => {
                        if (!isRejectSnackbarVisible && !isAcceptSnackbarLoading) {
                            onAlertRejectStudent(student);
                        }
                    }}
                    onKeyPress={() => {
                        if (!isRejectSnackbarVisible && !isAcceptSnackbarLoading) {
                            onAlertRejectStudent(student);
                        }
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Reject
                </div>,
            ];

            if ((isRejectSnackbarVisible || isAcceptSnackbarLoading)
                && (student.id === studentRejectToClassSnackbar.id
                    || student.id === studentAcceptToClassSnackbar.id)) {
                controls = [<LoaderSmall />];
            }

            alerts.push(
                <AlertBox
                    isVisibleBox={isVisibleBox}
                    theme="danger"
                    rightControls={controls}
                >
                    {`${student.fullName} has requested to join your class on The Juice.`}
                </AlertBox>,
            );
        });

        let loadMoreStudentsWithPendingStatusButton = null;

        if (store.teacher.studentsWithPendingStatusHasMorePages) {
            loadMoreStudentsWithPendingStatusButton = (
                <div
                    className={styles.loadMoreButton}
                    onClick={() => {
                        loadClassStudentWithPendingStatus(
                            store.teacher.studentsWithPendingStatusPage + 1,
                        );
                    }}
                    onKeyPress={() => {
                        loadClassStudentWithPendingStatus(
                            store.teacher.studentsWithPendingStatusPage + 1,
                        );
                    }}
                    tabIndex="-1"
                    role="button"
                >
                    Load more students who requested to join to your class
                </div>
            );
        }

        if (store.teacher.isStudentsWithPendingStatusLoading
            && store.teacher.isStudentsWithPendingStatusLoaded) {
            loadMoreStudentsWithPendingStatusButton = (
                <RequestLoader />
            );
        }


        return (
            <div className={styles.alerts}>
                {alerts}
                {loadMoreStudentsWithPendingStatusButton}
            </div>
        );
    };

    const renderTable = () => {
        return (
            <TableStudents
                dateRange={dateRange}
                calendarDates={settings.calendarDates}
                onDateRangeChange={onDateRangeChange}
                onChangeSortValue={(value) => {
                    setSelectedSortByValue(value);
                }}
                sortValue={selectedSortByValue}
                data={sortedStudents}
                error={studentsState.message}
                onOpenStudentPopup={onOpenStudentPopup}
                onAddStudent={onShowAddStudentPopup}
                onRemoveStudent={onRemoveStudent}
                onRemoveStudents={(s) => {
                    setDeleteSelectedUsersPopup((prev) => ({
                        ...prev,
                        isOpen: true,
                        students: s,
                    }));
                }}
                onResetStudentPassword={onResetStudentPassword}
                isCards={store.dimensions.width < 1200}
                isOverview={studentsState.isOverview}
                isLoading={!studentsState.isLoaded}
            />
        );
    };

    const renderCopiedToClipboardSnackbar = () => {
        if (!copiedToClipboardSnackbarState.isOpen) {
            return null;
        }

        return (
            <Snackbar
                message={copiedToClipboardSnackbarState.message}
                autoCloseInSeconds={3}
                onClose={onCloseCopiedToClipboardSnackbar}
            />
        );
    };

    if (!teacherClasses.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    return (
        <>
            {renderTutorial()}

            {renderStudentInfoPopup()}

            {renderAddStudentWindow()}
            {renderAddStudentMessagePopup()}
            {renderClassCodePopup()}
            {renderClassCodeDirectLinkPopup()}
            {renderSendClassCodeEmailsPopup()}
            {renderCopiedToClipboardSnackbar()}

            {renderResetStudentPopup()}
            {renderNewPasswordPopup()}
            {renderDismissSetNewPasswordSnackbar()}

            {renderAcceptStudentSnackbar()}
            {renderRejectStudentSnackbar()}

            {renderDeletePopup()}
            {renderDeleteSelectedUsersPopup()}

            {renderNameChangePopup()}
            {renderGradeChangePopup()}
            {renderEmailChangePopup()}

            <div className={styles.indexWrapper}>
                <div className={styles.index}>
                    {renderTabs()}
                    {renderAlerts()}
                    {renderTable()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["teacher"])(Class);
