import React from "react";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const MobileHeader = () => {
    return (
        <header className={styles.header}>
            <img
                src={staticFiles.juiceLogoWhite}
                alt="TheJuice home logo"
                title="TheJuice home logo"
                className={styles.logoImg}
            />
        </header>
    );
};

export default MobileHeader;
