import React from "react";

import styles from "./styles.module.css";


const PopupFullScreenPage = (props) => {
    if (!props.isOpen) {
        return null;
    }

    return (
        <div className={styles.page}>
            {props.children}
        </div>
    );
};

PopupFullScreenPage.defaultProps = {
    isOpen: false,
    children: null,
};

export default PopupFullScreenPage;
