// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2GMdRKMVckikDKGma9ba9Z {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 1025px) {\n    ._2GMdRKMVckikDKGma9ba9Z {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/vocabulary-word/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".word {\n    padding: 0 2rem 2rem 2rem;\n}\n\n@media only screen and (min-width: 1025px) {\n    .word {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"word": "_2GMdRKMVckikDKGma9ba9Z"
};
export default ___CSS_LOADER_EXPORT___;
