// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3YS6UsuqIUWsW7kqFGAAId {\n    position: relative;\n\n    width: 100%;\n    height: auto;\n\n    outline: none;\n}\n\n.TulWmTL_eg_HRa2YfSwfw {\n    cursor: pointer;\n    outline: none;\n}\n\n._23LLcGfidCXVnq-LhH7d2c {\n    position: relative;\n}\n\n._28jYNtUhXAORTweU8iwTLj {\n    margin: 0 auto;\n    display: block;\n\n    width: 100%;\n    max-width: 100%;\n\n    height: auto;\n}\n\n._1Ntpudxhl6kU0ka6FqjQtW {\n    position: absolute;\n    bottom: 0.7rem;\n    right: 0.8rem;\n}\n\n@media only screen and (min-width: 601px) {\n    ._28jYNtUhXAORTweU8iwTLj {\n        border-radius: 0.8rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/featured-image/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;;IAEZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,cAAc;;IAEd,WAAW;IACX,eAAe;;IAEf,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".featuredImgContainer {\n    position: relative;\n\n    width: 100%;\n    height: auto;\n\n    outline: none;\n}\n\n.featuredImgContainerClickable {\n    cursor: pointer;\n    outline: none;\n}\n\n.featuredImgWrapper {\n    position: relative;\n}\n\n.featuredImg {\n    margin: 0 auto;\n    display: block;\n\n    width: 100%;\n    max-width: 100%;\n\n    height: auto;\n}\n\n.featuredImgFullscreen {\n    position: absolute;\n    bottom: 0.7rem;\n    right: 0.8rem;\n}\n\n@media only screen and (min-width: 601px) {\n    .featuredImg {\n        border-radius: 0.8rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featuredImgContainer": "_3YS6UsuqIUWsW7kqFGAAId",
	"featuredImgContainerClickable": "TulWmTL_eg_HRa2YfSwfw",
	"featuredImgWrapper": "_23LLcGfidCXVnq-LhH7d2c",
	"featuredImg": "_28jYNtUhXAORTweU8iwTLj",
	"featuredImgFullscreen": "_1Ntpudxhl6kU0ka6FqjQtW"
};
export default ___CSS_LOADER_EXPORT___;
