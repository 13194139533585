// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3QaEf4NP2DEV9_oJHNDc_f {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.nrhHxR6Juyh6bM87Fun_k {\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-password-reset/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".form {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n.submitButtonBlock {\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_3QaEf4NP2DEV9_oJHNDc_f",
	"submitButtonBlock": "nrhHxR6Juyh6bM87Fun_k"
};
export default ___CSS_LOADER_EXPORT___;
