// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._3iQPsrqCYSbOiEBCwb-DyE {}\n\n._1tODcI1PFPfH013nyhxfP7 {\n    display: grid;\n    grid-gap: 0.5rem;\n\n    justify-content: center;\n}\n\n._2tH_FKfpZU6tw-fWuefwX {\n    font-weight: 600;\n    font-size: 2rem;\n    word-break: break-all;\n}\n\n._1y_8cr5ChX4ABK3-WEGB7R {\n    margin: 0 0 1.5rem 0;\n}\n\n@media only screen and (min-width: 650px) {\n    ._3iQPsrqCYSbOiEBCwb-DyE {\n        max-width: initial;\n        width: auto;\n    }\n\n    ._2tH_FKfpZU6tw-fWuefwX {\n        white-space: nowrap;\n        word-break: initial;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/popup-confirm-generate-class-code/styles.module.css"],"names":[],"mappings":";AACA,0BAAQ;;AAER;IACI,aAAa;IACb,gBAAgB;;IAEhB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI;QACI,kBAAkB;QAClB,WAAW;IACf;;IAEA;QACI,mBAAmB;QACnB,mBAAmB;IACvB;AACJ","sourcesContent":["\n.popup {}\n\n.classCode {\n    display: grid;\n    grid-gap: 0.5rem;\n\n    justify-content: center;\n}\n\n.classCodeLink {\n    font-weight: 600;\n    font-size: 2rem;\n    word-break: break-all;\n}\n\n.contentTitle {\n    margin: 0 0 1.5rem 0;\n}\n\n@media only screen and (min-width: 650px) {\n    .popup {\n        max-width: initial;\n        width: auto;\n    }\n\n    .classCodeLink {\n        white-space: nowrap;\n        word-break: initial;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "_3iQPsrqCYSbOiEBCwb-DyE",
	"classCode": "_1tODcI1PFPfH013nyhxfP7",
	"classCodeLink": "_2tH_FKfpZU6tw-fWuefwX",
	"contentTitle": "_1y_8cr5ChX4ABK3-WEGB7R"
};
export default ___CSS_LOADER_EXPORT___;
