// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._3oGTCbMKRUdj5jv13uVwXQ {\n    position: relative;\n}\n\n.iGgllEOuApvHqtxUM-6BY {\n    position: absolute;\n    top: -5rem;\n    right: 0rem;\n\n    background-color: #F7FA00;\n    color: #000;\n\n    border-radius: 5rem;\n\n    cursor: pointer;\n    outline: none;\n\n    width: 3.6rem;\n    height: 3.6rem;\n\n    box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem #b4b4b4;\n\n    z-index: 1;\n}\n\n.iGgllEOuApvHqtxUM-6BY:before, .iGgllEOuApvHqtxUM-6BY:after {\n    content: \" \";\n\n    position: absolute;\n    top: 0.9rem;\n    left: 1.6rem;\n\n    height: 1.8rem;\n    width: 0.3rem;\n\n    background-color: #000;\n}", "",{"version":3,"sources":["webpack://./base/components/popup-account-settings/styles.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;;IAEX,yBAAyB;IACzB,WAAW;;IAEX,mBAAmB;;IAEnB,eAAe;IACf,aAAa;;IAEb,aAAa;IACb,cAAc;;IAEd,+CAA+C;;IAE/C,UAAU;AACd;;AAEA;IACI,YAAY;;IAEZ,kBAAkB;IAClB,WAAW;IACX,YAAY;;IAEZ,cAAc;IACd,aAAa;;IAEb,sBAAsB;AAC1B","sourcesContent":["\n.settings {\n    position: relative;\n}\n\n.close {\n    position: absolute;\n    top: -5rem;\n    right: 0rem;\n\n    background-color: #F7FA00;\n    color: #000;\n\n    border-radius: 5rem;\n\n    cursor: pointer;\n    outline: none;\n\n    width: 3.6rem;\n    height: 3.6rem;\n\n    box-shadow: 0.1rem 0.1rem 0.3rem 0.1rem #b4b4b4;\n\n    z-index: 1;\n}\n\n.close:before, .close:after {\n    content: \" \";\n\n    position: absolute;\n    top: 0.9rem;\n    left: 1.6rem;\n\n    height: 1.8rem;\n    width: 0.3rem;\n\n    background-color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": "_3oGTCbMKRUdj5jv13uVwXQ",
	"close": "iGgllEOuApvHqtxUM-6BY"
};
export default ___CSS_LOADER_EXPORT___;
