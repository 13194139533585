import React from "react";
import classNames from "../../lib/class-names.js";
import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const AlertBox = (props) => {
    const isPrimary = props.theme === "primary";
    const isDanger = props.theme === "danger";

    const boxClassName = classNames({
        [styles.box]: true,
        [styles.primary]: isPrimary,
        [styles.danger]: isDanger,
        [styles.isHiddenBox]: !props.isVisibleBox,
    });

    let icon = null;

    if (isDanger) {
        icon = (
            <div className={styles.icon}>
                <img
                    src={staticFiles.iconDanger}
                    alt="danger"
                    title="danger"
                />
            </div>
        );
    }

    const boxMessageClassName = classNames({
        [styles.boxMessage]: true,
        [styles.boxMessageWithIcon]: icon,
    });

    return (
        <div className={boxClassName}>
            <div className={boxMessageClassName}>
                {icon}
                <div className={styles.boxMessageWithControls}>
                    {props.children}
                    <div className={styles.rightControls}>
                        {props.rightControls}
                    </div>
                </div>
            </div>
        </div>
    );
};

AlertBox.defaultProps = {
    isVisibleBox: true,
    theme: "primary",
    children: null,
    rightControls: [],
};

export default AlertBox;
