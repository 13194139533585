// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1jkH3EKFJsgPJrkVN0k4T_ {\n    position: fixed;\n    bottom: -8rem;\n    left: 0;\n    right: 0;\n\n    z-index: 100;\n\n    transition: bottom 300ms ease 0s,\n                width 300ms ease 0s;\n}\n\n._1NdHo-MEJKWZPJUPkkybMP {\n    bottom: 7rem;\n}\n\n@media only screen and (min-width: 501px) {\n    ._1jkH3EKFJsgPJrkVN0k4T_ {\n        display: grid;\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    ._1NdHo-MEJKWZPJUPkkybMP {\n        bottom: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-player-audio/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,OAAO;IACP,QAAQ;;IAER,YAAY;;IAEZ;mCAC+B;AACnC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,4BAA4B;QAC5B,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":[".popup {\n    position: fixed;\n    bottom: -8rem;\n    left: 0;\n    right: 0;\n\n    z-index: 100;\n\n    transition: bottom 300ms ease 0s,\n                width 300ms ease 0s;\n}\n\n.popupVisible {\n    bottom: 7rem;\n}\n\n@media only screen and (min-width: 501px) {\n    .popup {\n        display: grid;\n        grid-template-columns: 50rem;\n        justify-content: center;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .popupVisible {\n        bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "_1jkH3EKFJsgPJrkVN0k4T_",
	"popupVisible": "_1NdHo-MEJKWZPJUPkkybMP"
};
export default ___CSS_LOADER_EXPORT___;
