export const getMaxGrades = () => {
    return ["G11", "G12"];
};

export const getGradeGroup = (grades = []) => {
    if (typeof grades !== "object") {
        return grades;
    }

    if (grades.length === 1) {
        const grade = grades.join("");

        if (grade === "G5" || grade === "G6") {
            return "5-6";
        }

        if (grade === "G7" || grade === "G8") {
            return "7-8";
        }

        if (grade === "G9" || grade === "G10") {
            return "9-10";
        }

        if (grade === "G11" || grade === "G12") {
            return "11+";
        }
    }

    return grades.join(", ");
};

export const getValidGrade = (grade = "", defaultGrade = "G7-G8") => {
    if (grade.indexOf("5") !== -1 || grade.indexOf("6") !== -1) {
        return "G5-G6";
    }

    if (grade.indexOf("7") !== -1 || grade.indexOf("8") !== -1) {
        return "G7-G8";
    }

    if (grade.indexOf("9") !== -1 || grade.indexOf("10") !== -1) {
        return "G9-G10";
    }

    if (grade.indexOf("11") !== -1 || grade.indexOf("12") !== -1) {
        return "G11-G12";
    }

    return defaultGrade;
};

export default {
    getMaxGrades,
    getGradeGroup,
    getValidGrade,
};
