import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { withAuth } from "juice-base/components/auth/index.js";
import PasswordChangeForm from "juice-base/forms/user-password-change/index.js";

import actions from "juice-base/store/actions.js";
import storage from "juice-base/lib/storage/index.js";

import settings from "juice-app/settings.js";

import * as events from "juice-base/events.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const PasswordChange = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        session: state.user.session,
    }));

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        events.userChangePassword({
            session: store.session,
        });

        api.user.changePassword({
            session: store.session,
            password: values.password,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.user.setUserSession(""));
                dispatch(actions.user.setUser({}));

                api.user.signOut({ session: store.session });
                storage.local.clearSession();

                history.push("/user/sign-in");
            } else {
                setErrors({
                    password: res.error || "Error!",
                });
            }
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <div className={styles.passwordChange}>
            <div className={styles.content}>
                <PasswordChangeForm
                    passwordMinLength={settings.password.minLength}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    );
};

export default withAuth([
    "teacher",
    "guardian",
    "student",
])(PasswordChange);
