// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._10bYj4Tt68oTZlj04i0rkI {}\n\n._3AwSU_1L6-O_h5qnKxBmPx {\n    margin-top: 2.5rem;\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-sign-up-class-code/styles.module.css"],"names":[],"mappings":"AAAA,0BAAO;;AAEP;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".form {}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_10bYj4Tt68oTZlj04i0rkI",
	"submitButtonBlock": "_3AwSU_1L6-O_h5qnKxBmPx"
};
export default ___CSS_LOADER_EXPORT___;
