import React from "react";

import Text from "juice-base/components/text/index.js";

import classAnnouncementStyles from "juice-base/components/class-announcement/styles.module.css";
import dailyJuiceCommonStyles from "juice-base/components/daily-juice-common/styles.module.css";
import styles from "./styles.module.css";


const DailyJuiceSponsor = (props) => {
    if (!props.sponsor) {
        return null;
    }

    const { sponsor } = props;

    if (!sponsor.sponsorImage && !sponsor.sponsorContent) {
        return null;
    }

    const renderImage = () => {
        if (sponsor.sponsorImage) {
            let sponsorImg = null;

            const sponsorImgTitle = "Sponsor image";

            sponsorImg = (
                <img
                    src={sponsor.sponsorImage}
                    alt={sponsorImgTitle}
                    title={sponsorImgTitle}
                    className={styles.sponsorImg}
                />
            );

            if (sponsor.sponsorImageUrl) {
                sponsorImg = (
                    <a
                        href={sponsor.sponsorImageUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {sponsorImg}
                    </a>
                );
            }

            return sponsorImg;
        }

        return null;
    };

    const renderContent = () => {
        if (sponsor.sponsorContent) {
            return (
                <Text className={classAnnouncementStyles.content}>
                    {sponsor.sponsorContent}
                </Text>
            );
        }

        return null;
    };

    const sponsorClassName = [
        dailyJuiceCommonStyles.block,
        dailyJuiceCommonStyles.blockWithPadding,
    ].join(" ");

    const title = sponsor.sponsoredByTitle || "Sponsored by";

    return (
        <div className={sponsorClassName}>
            <div className={dailyJuiceCommonStyles.header}>
                {title}
            </div>
            {renderImage()}
            {renderContent()}
        </div>
    );
};

DailyJuiceSponsor.defaultProps = {
    sponsor: null,
};

export default DailyJuiceSponsor;
