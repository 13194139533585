import moment from "moment";


export default {
    appName: "The Juice",
    copyright: "The Juice 2021",

    previewCode: "ba6q7efg2a",

    sectionsByRoles: {
        home: {
            student: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
                {
                    to: "/search",
                    name: "Search",
                    icon: "search",
                    dataComment: "link-search",
                },
            ],
            guardian: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
                {
                    to: "/student",
                    name: "Student",
                    icon: "juice",
                    dataComment: "link-student",
                },
                {
                    to: "/search",
                    name: "Search",
                    icon: "search",
                    dataComment: "link-search",
                },
            ],
            teacher: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
                {
                    to: "/class",
                    name: "Class",
                    icon: "users",
                    dataComment: "link-class",
                },
                {
                    to: "/search",
                    name: "Search",
                    icon: "search",
                    dataComment: "link-search",
                },
            ],
        },
        trial: {
            student: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
            ],
        },
        regular: {
            student: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
                {
                    to: "/search",
                    name: "Search",
                    icon: "search",
                    dataComment: "link-search",
                },
            ],
            guardian: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
                {
                    to: "/student",
                    name: "Student",
                    icon: "juice",
                    dataComment: "link-student",
                },
                {
                    to: "/search",
                    name: "Search",
                    icon: "search",
                    dataComment: "link-search",
                },
            ],
            teacher: [
                {
                    to: "/daily-juice",
                    name: "Daily Juice",
                    icon: "juice",
                    dataComment: "link-daily-juice",
                },
                {
                    to: "/class",
                    name: "Class",
                    icon: "users",
                    dataComment: "link-class",
                },
                // {
                //     to: "/user/profile",
                //     name: "Account",
                //     icon: "account",
                //     dataComment: "link-account",
                // },
                {
                    to: "/search",
                    name: "Search",
                    icon: "search",
                    dataComment: "link-search",
                },
            ],
        },
    },

    mobileHeaderHiddenPaths: [
        "/daily-juice/:juiceId/:storyId",
    ],

    grades: [
        { value: "G5-G6", label: "Level 5-6" },
        { value: "G7-G8", label: "Level 7-8" },
        { value: "G9-G10", label: "Level 9-10" },
        { value: "G11-G12", label: "Level 11+" },
    ],

    calendarDates: {
        min: moment("2020-01-01"),
        max: moment().add(1, "year"),
    },

    password: {
        minLength: 8,
    },

    signUpByCodePath: "/user/sign-up",

    signUpLink: "//thejuicelearning.com/signup",

    privacyPolicyLink: "//thejuicelearning.com/privacy-policy",

    supportLink: "//thejuicelearning.com/support",
};
