import React, { useState } from "react";

import staticFiles from "juice-base/static-files.js";

import Error from "../error/index.js";

import styles from "./styles.module.css";


const Password = (props) => {
    const {
        autoComplete,
        name,
        label,
        value,
        error,
        maxLength,
        onChange,
        onBlur,
    } = props;

    const [isTypePassword, setIsTypePassword] = useState(true);

    const onToggleType = () => {
        setIsTypePassword((prev) => !prev);
    };

    let inputError = null;

    if (error) {
        inputError = (
            <Error>
                {error}
            </Error>
        );
    }

    let eyeIcon = (
        <img
            src={staticFiles.iconEyeClosed}
            alt="Show password"
            title="Show password"
        />
    );

    if (!isTypePassword) {
        eyeIcon = (
            <img
                src={staticFiles.iconEye}
                alt="Hide password"
                title="Hide password"
            />
        );
    }

    const eye = (
        <div
            className={styles.passwordEye}
            onClick={onToggleType}
            onKeyClick={onToggleType}
            role="button"
            tabIndex="-1"
        >
            {eyeIcon}
        </div>
    );

    return (
        <div className={styles.field}>
            <label htmlFor={name} className={styles.fieldLabel}>
                {label}
            </label>

            <div className={styles.password}>
                <input
                    id={name}
                    name={name}
                    placeholder={props.placeholder}
                    type={isTypePassword ? "password" : "text"}
                    autoComplete={autoComplete}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={maxLength}
                    className={styles.fieldInput}
                />
                {eye}
            </div>

            {inputError}
        </div>
    );
};

Password.defaultProps = {
    autoComplete: "",
    name: "",
    label: "",
    placeholder: "",
    value: "",
    error: "",
    maxLength: 255,
    onChange: () => { },
    onBlur: () => { },
};

export default Password;
