// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2ECAzFAY2bKjGeZ71kcRJ0 {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n/* --- */\n\n.DtDS-Ln17Z2E8H2S_NnqN {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 2rem;\n    grid-template-columns: 1fr 1fr;\n}\n\n._1GJQAt1g_R7nh56C8hV-w0 {\n    display: flex;\n    flex-direction: column;\n}\n\n._1GJQAt1g_R7nh56C8hV-w0 > div {\n    margin-bottom: 2rem;\n}\n\n._1GJQAt1g_R7nh56C8hV-w0 > div:last-child {\n    margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/tiles/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".tilesMobile {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n/* --- */\n\n.tilesDesktop {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 2rem;\n    grid-template-columns: 1fr 1fr;\n}\n\n.tilesDesktopCol {\n    display: flex;\n    flex-direction: column;\n}\n\n.tilesDesktopCol > div {\n    margin-bottom: 2rem;\n}\n\n.tilesDesktopCol > div:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tilesMobile": "_2ECAzFAY2bKjGeZ71kcRJ0",
	"tilesDesktop": "DtDS-Ln17Z2E8H2S_NnqN",
	"tilesDesktopCol": "_1GJQAt1g_R7nh56C8hV-w0"
};
export default ___CSS_LOADER_EXPORT___;
