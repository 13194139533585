// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1lBoFCNS5VGhigWTR_4pjP {\n    position: relative;\n\n    background-color: transparent;\n    color: #000;\n    border-radius: 0.2rem;\n\n    cursor: pointer;\n    outline: none;\n\n    width: 2.4rem;\n    height: 2.4rem;\n}\n\n._1lBoFCNS5VGhigWTR_4pjP:hover {\n    opacity: 1;\n}\n\n._1lBoFCNS5VGhigWTR_4pjP:before,\n._1lBoFCNS5VGhigWTR_4pjP:after {\n    position: absolute;\n    top: 0.35rem;\n    left: 1.1rem;\n    content: \" \";\n    height: 1.6rem;\n    width: 0.3rem;\n    background-color: #000;\n}\n\n._1lBoFCNS5VGhigWTR_4pjP:before {\n    transform: rotate(45deg);\n}\n\n._1lBoFCNS5VGhigWTR_4pjP:after {\n    transform: rotate(-45deg);\n}\n\n\n", "",{"version":3,"sources":["webpack://./base/components/close/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,6BAA6B;IAC7B,WAAW;IACX,qBAAqB;;IAErB,eAAe;IACf,aAAa;;IAEb,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".close {\n    position: relative;\n\n    background-color: transparent;\n    color: #000;\n    border-radius: 0.2rem;\n\n    cursor: pointer;\n    outline: none;\n\n    width: 2.4rem;\n    height: 2.4rem;\n}\n\n.close:hover {\n    opacity: 1;\n}\n\n.close:before,\n.close:after {\n    position: absolute;\n    top: 0.35rem;\n    left: 1.1rem;\n    content: \" \";\n    height: 1.6rem;\n    width: 0.3rem;\n    background-color: #000;\n}\n\n.close:before {\n    transform: rotate(45deg);\n}\n\n.close:after {\n    transform: rotate(-45deg);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": "_1lBoFCNS5VGhigWTR_4pjP"
};
export default ___CSS_LOADER_EXPORT___;
