import React from "react";

import SectionTeacher from "../section-teacher/index.js";
import IconStatus from "../icon-status/index.js";
import ButtonWithMessage from "../button-with-message/index.js";
import RequestLoader from "../request-loader/index.js";


import styles from "./styles.module.css";


const TeacherStudentsActivity = (props) => {
    const renderAssignments = () => {
        let assignmentsSections = null;

        if (props.isLoaded) {
            let isJuiceExists = false;

            const users = props.students.map((student) => {
                if (student.juiceStatusId !== -1) {
                    isJuiceExists = true;
                }

                return (
                    <div className={styles.assignmentSectionItem}>
                        <div
                            className={styles.assignmentSectionItemName}
                            onClick={() => {
                                props.onStudentClick(student.id);
                            }}
                            onKeyPress={() => {
                                props.onStudentClick(student.id);
                            }}
                            role="button"
                            tabIndex="-1"
                        >
                            {student.fullName}
                        </div>
                        <IconStatus
                            statusId={student.juiceStatusId}
                        />
                    </div>
                );
            });

            if (!isJuiceExists && users.length > 0) {
                assignmentsSections = (
                    <div className={styles.message}>
                        Nothing to see here either.
                        Change the timeframe to see individual student activity data.
                    </div>
                );
            } else if (users.length === 0) {
                assignmentsSections = (
                    <div className={styles.message}>
                        No students found in this class
                    </div>
                );
            } else if (users.length > 0) {
                assignmentsSections = (
                    <div className={styles.assignmentSection}>
                        <div className={styles.assignmentSectionHeader}>
                            {props.title}
                        </div>
                        <div className={styles.assignmentSectionItems}>
                            {users}
                        </div>
                    </div>
                );
            }
        } else {
            assignmentsSections = (
                <div className={styles.assignmentsLoader}>
                    <RequestLoader />
                </div>
            );
        }

        return (
            <div className={styles.dailyJuiceAssignments}>
                <div className={styles.header}>
                    <div className={styles.headerName}>
                        <div className={styles.headerNameTitle}>
                            Student Activity
                        </div>
                        <div>
                            <ButtonWithMessage
                                message="Daily Juice quizzes or issues that your students haven't completed yet."
                                messageClassName={styles.messageBlock}
                                triangleClassName={styles.messageTriangle}
                                pageWidth={props.pageWidth}
                            >
                                <div className={styles.messageButton}>
                                    ?
                                </div>
                            </ButtonWithMessage>
                        </div>
                    </div>
                </div>
                <div className={styles.assignmentsSections}>
                    {assignmentsSections}
                </div>
            </div>
        );
    };

    return (
        <SectionTeacher
            className={styles.section}
            dataComment="daily-juice-activity"
        >
            {renderAssignments()}
        </SectionTeacher>
    );
};

TeacherStudentsActivity.defaultProps = {
    pageWidth: 0,
    title: "",
    students: [],
    isLoaded: false,
    onStudentClick: () => {},
    onCloseStudentPopup: () => {},
};

export default TeacherStudentsActivity;
