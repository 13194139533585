import React, { useState, useRef } from "react";
import papa from "papaparse";

import classNames from "juice-base/lib/class-names.js";

import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const UploaderCSV = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [uploadErrors, setUploadErrors] = useState([]);

    const [isVisibleField, setVisibleField] = useState(true);
    const [isDragOverField, setDragOverField] = useState(false);

    const dropAreaRef = useRef(null);

    const onFileChange = (evt) => {
        if (evt.target.files.length > 0) {
            setDragOverField(false);

            const file = evt.target.files[0];

            const uploadedFileSizeKB = file.size / 1000;

            if (uploadedFileSizeKB > props.maxFileSizeInKB) {
                setUploadErrors([
                    "The file is larger than the size allowed.",
                    `Maximum file size ${Number(props.maxFileSizeInKB / 1000).toFixed(0)}MB.`,
                    `You tried to import a ${Number(uploadedFileSizeKB / 1000).toFixed(2)}MB file.`,
                ]);
            } else {
                setUploadErrors([]);
                setLoading(true);

                props.onFileChange(evt);

                let count = 0;
                const results = [];

                papa.parse(file, {
                    step: (result) => {
                        results.push(result);
                        count += 1;
                    },
                    complete: () => {
                        setLoading(false);
                        props.onUploadComplete({ results, count });
                    },
                });
            }

            // NOTE: This is HACK to upload the same file multiple times in a row
            if (dropAreaRef.current && dropAreaRef.current.value) {
                dropAreaRef.current.value = null;
            }
        }
    };

    const renderField = () => {
        if (isLoading) {
            if (isDragOverField) {
                setDragOverField(false);
            }

            return (
                <div className={styles.formFieldLoader}>
                    Loading...
                </div>
            );
        }

        const hideElements = !isVisibleField || isDragOverField;

        const formFieldClassName = classNames({
            [styles.formField]: true,
            [styles.formFieldOnDragOver]: isDragOverField,
        });

        const browserTextClassName = classNames({
            [styles.browseText]: true,
            [styles.hiddenElement]: hideElements,
        });

        const buttonsClassName = classNames({
            [styles.buttons]: true,
            [styles.hiddenElement]: hideElements,
        });

        const acceptedTypesClassName = classNames({
            [styles.acceptedTypes]: true,
            [styles.hiddenElement]: hideElements,
        });

        const uploadIconClassName = classNames({
            [styles.uploadIcon]: true,
            [styles.hiddenElement]: hideElements,
        });

        let dragFileHereText = null;

        if (isDragOverField) {
            dragFileHereText = (
                <div className={styles.dragFileHereText}>
                    Drop the file here
                </div>
            );
        }

        return (
            <div
                className={formFieldClassName}
                onFocus={() => {
                    setVisibleField(true);
                }}
                onMouseOver={() => {
                    setVisibleField(true);
                }}
                onDragOver={() => {
                    setDragOverField(true);
                }}
                onDragLeave={() => {
                    setDragOverField(false);
                }}
            >
                <label
                    htmlFor="drop-area"
                    className={styles.label}
                >
                    <input
                        className={styles.input}
                        ref={dropAreaRef}
                        id="drop-area-csv"
                        name="drop-area-csv"
                        type="file"
                        accept=".csv"
                        onChange={onFileChange}
                    />

                    <div className={uploadIconClassName}>
                        <img
                            src={staticFiles.iconFileUploadBlue}
                            alt="Upload"
                        />
                    </div>

                    <div className={browserTextClassName}>
                        Drag and drop a file here or
                    </div>

                    <div className={buttonsClassName}>
                        <div className={styles.button}>
                            Upload
                        </div>
                    </div>

                    <div className={acceptedTypesClassName}>
                        Accepted File Types: .csv
                    </div>

                    {dragFileHereText}
                </label>
            </div>
        );
    };

    return (
        <div className={styles.uploader}>
            {renderField()}
            <div className={styles.errorMessage}>
                {uploadErrors.map((error) => {
                    return (<div>{error}</div>);
                })}
            </div>
        </div>
    );
};


UploaderCSV.defaultProps = {
    maxFileSizeInKB: 1000,
    onUploadComplete: () => {},
    onFileChange: () => {},
};

export default UploaderCSV;
