import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getGradeGroup } from "juice-base/project/grades.js";

import {
    getDatesByRange,
    getDateFromDate,
} from "juice-base/lib/date.js";

import storage from "juice-base/lib/storage/index.js";

import { withAuth } from "juice-base/components/auth/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import DailyJuiceLinks from "juice-base/components/daily-juice-links/index.js";
import TeacherStudentsActivity from "juice-base/components/teacher-students-activity/index.js";
import TeacherClassStats from "juice-base/components/teacher-class-stats/index.js";
import TeacherClassAnnouncement from "juice-base/components/teacher-class-announcement/index.js";
import StudentInfo from "juice-base/components/student-info/index.js";
import Tabs from "juice-base/components/tabs/index.js";

import PopupAnnouncement from "juice-base/components/popup-announcement/index.js";
import PopupSponsorContent from "juice-base/components/popup-sponsor-content/index.js";
import PopupAdsPreview from "juice-base/components/popup-ads-preview/index.js";
import PopupNewPassword from "juice-base/components/popup-new-password/index.js";
import PopupGradeChange from "juice-base/components/popup-grade-change/index.js";
import PopupNameChange from "juice-base/components/popup-name-change/index.js";
import PopupEmailChange from "juice-base/components/popup-email-change/index.js";
import PopupConfirmUnsavedProgress from "juice-base/business/popup-confirm-unsaved-progress/index.js";
import PopupConfirmDeleteStudent from "juice-base/business/popup-confirm-delete-student/index.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import actions from "juice-base/store/actions.js";

import settings from "juice-app/settings.js";
import * as events from "juice-base/events.js";
import api from "juice-app/api.js";

import styles from "./styles.module.css";


const getSponsorPopupState = () => ({
    selectedClass: -1,

    isLoaded: false,
    isImageLoading: false,
    isSaving: false,

    sponsor: {},
    errors: [],
});

const getAnnouncementPopupState = () => ({
    selectedClass: -1,

    isLoaded: false,
    isSaving: false,

    announcement: {},
    errors: [],
});


const getStudentPopupState = () => ({
    isOpen: false,
    isLoaded: false,
    userId: -1,
    user: {},
});

const TeacherHomePage = () => {
    const [
        isVisibleConfirmCloseAnnouncementPopup,
        setIsVisibleConfirmCloseAnnouncementPopup,
    ] = useState(false);

    const [isVisibleUnsavedSponsorPopup, setIsVisibleUnsavedSponsorPopup] = useState(false);
    const [isVisibleAnnouncementPopup, setIsVisibleAnnouncementPopup] = useState(false);
    const [isVisibleSponsorPopup, setIsVisibleSponsorPopup] = useState(false);

    const [adsPreviewPopup, setAdsPreviewPopup] = useState({
        isOpen: false,
        img: "",
        text: "",
    });

    const [removeStudentPopup, setRemoveStudentPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        studentId: -1,
    });

    const [teacherClasses, setTeacherClasses] = useState({
        selectedClass: -1,
        isLoaded: false,
        classes: [],
    });

    const [selectedClassAnnouncement, setSelectedClassAnnouncement] = useState({
        isLoaded: false,
        content: "",
        title: "",
    });

    const [studentsActivity, setStudentsActivity] = useState({
        isLoaded: false,
        students: [],
    });

    const [juiceStats, setJuiceStats] = useState({
        isLoading: true,
        stats: null,
        error: null,
    });

    const [studentInfoPopup, setStudentInfoPopup] = useState(() => getStudentPopupState());

    const [nameChangePopupState, setNameChangePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [emailChangePopupState, setEmailChangePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [changeGradePopupState, setChangeGradePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [sponsorPopup, setSponsorPopup] = useState(() => getSponsorPopupState());

    const [announcementPopup, setAnnoucementPopup] = useState(() => getAnnouncementPopupState());

    const [newPasswordPopupState, setNewPasswordPopupState] = useState({
        isOpen: false,
        isSubmitted: false,
        isLoading: false,
        message: null,
        id: null,
    });

    const defaultRange = "today";

    const [dropdownRange, setDropDownRange] = useState(defaultRange);

    const allClassesTitle = "All";

    const dispatch = useDispatch();

    const history = useHistory();

    const store = useSelector((state) => ({
        session: state.user.session,
        dimensions: state.device.dimensions,
        juices: state.juices,
        teacher: state.teacher,
    }));

    let pageWidth = 0;

    if (store.dimensions?.width) {
        pageWidth = store.dimensions.width;
    }

    const loadClassSelectedAnnouncementByClassId = (classId) => {
        setSelectedClassAnnouncement({
            isLoaded: false,
            content: "",
            title: "",
        });

        api.classes.getClassAnnouncement({ classId: classId || -1 }).then((res) => {
            let content = "";
            let title = "";

            if (res.ok) {
                content = res.data.announcementContent;
                title = res.data.announcementTitle;
            }

            setSelectedClassAnnouncement({
                isLoaded: true,
                title,
                content,
            });
        });
    };

    const loadJuiceStats = (classId, rangeValue = null) => {
        setJuiceStats({
            isLoading: true,
            stats: null,
            error: null,
        });

        let selectedRange = dropdownRange;

        if (rangeValue) {
            selectedRange = rangeValue;
            setDropDownRange(selectedRange);
        }

        api.site.getSiteDate().then((dateRes) => {
            const siteDate = dateRes.ok ? dateRes.date : null;
            if (siteDate) {
                const range = getDatesByRange(siteDate, selectedRange);

                if (range.dateFrom !== "all") {
                    range.dateFrom = getDateFromDate(range.dateFrom);
                }

                if (range.dateTo !== "") {
                    range.dateTo = getDateFromDate(range.dateTo);
                }

                api.classes.getJuiceStats({
                    session: store.session,
                    classId,
                    dateFrom: range.dateFrom,
                    dateTo: range.dateTo,
                }).then((res) => {
                    let stats = null;

                    if (res.ok && Object.keys(res.stats).length !== 0) {
                        stats = res.stats;
                    }

                    setJuiceStats({
                        isLoading: false,
                        stats,
                        error: res.ok ? null : res.error,
                    });
                });
            } else {
                setJuiceStats({
                    isLoading: false,
                    stats: null,
                    error: dateRes.ok ? null : dateRes.error,
                });
            }
        });
    };

    const loadAssignmentsByClassId = (classId, range = dropdownRange) => {
        setStudentsActivity({
            isLoaded: false,
            students: [],
        });

        api.site.getSiteDate().then((resDate) => {
            const ranges = getDatesByRange(resDate.date, range);

            ranges.dateFrom = getDateFromDate(ranges.dateFrom);
            ranges.dateTo = getDateFromDate(ranges.dateTo);

            api.classes.getStudentsActivity({
                session: store.session,
                classId,
                dateFrom: ranges.dateFrom,
                dateTo: ranges.dateTo,
            }).then((res) => {
                setStudentsActivity({
                    isLoaded: true,
                    students: res.ok ? res.studentActivity : [],
                });
            });
        });
    };

    const loadStudentsForgotttenPassword = () => {
        api.classes.getStudentsWithForgottenPasswords({
            session: store.session,
            classId: teacherClasses.selectedClass,
        }).then((res) => {
            dispatch(actions.teacher.setTeacherStudentWithForgottenPasswords({
                students: res.ok ? res.students : [],
            }));
        });
    };

    useEffect(() => {
        if (store.session) {
            dispatch(actions.teacher.setTeacherStatsDate({
                statsDate: null,
            }));

            api.classes.getTeacherClasses({
                session: store.session,
            }).then((res) => {
                if (res.ok) {
                    const classes = res.classes || [];

                    let classId = storage.local.loadTeacherSelectedClass();

                    let classStillExists = false;

                    if (classId) {
                        for (let i = 0; i < classes.length; i += 1) {
                            if (classes[i].id === parseInt(classId, 10)) {
                                classStillExists = true;
                                break;
                            }
                        }
                    }

                    classId = parseInt(classId, 10);

                    if (!classStillExists && classes[0]) {
                        classId = classes[0].id || -1;
                        storage.local.saveTeacherSelectedClass(classId);
                    }

                    dispatch(actions.teacher.setTeacherSelectedClass({
                        selectedClassId: classId,
                    }));

                    setTeacherClasses({
                        selectedClass: classId,
                        isLoaded: true,
                        classes,
                    });

                    loadClassSelectedAnnouncementByClassId(classId);
                    loadAssignmentsByClassId(classId);
                    loadJuiceStats(classId);
                }
            });
        }
    }, [store.session]);

    useEffect(() => {
        setAnnoucementPopup((prev) => ({
            ...prev,
            selectedClass: teacherClasses.selectedClass,
        }));

        setSponsorPopup((prev) => ({
            ...prev,
            selectedClass: teacherClasses.selectedClass,
        }));

        if (teacherClasses.selectedClassId !== -1) {
            loadStudentsForgotttenPassword();
        }
    }, [teacherClasses.selectedClass]);

    const loadStudentDailyJuices = (studentId) => {
        dispatch(actions.juices.setStudentJuicesByIdLoading({
            studentId,
        }));

        let page = 0;

        if (store.juices?.studentJuicesById[studentId]?.page) {
            page = store.juices.studentJuicesById[studentId].page;
        }

        api.students.getStudentDailyJuicesResultsByPage({
            session: store.session,
            studentId,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setStudentJuicesById({
                    studentId,
                    juices: res.juices,
                    hasMore: res.hasMore,
                    page: page + 1,
                }));
            }
        });
    };

    const loadStudentInfo = (studentId) => {
        setStudentInfoPopup({
            isLoaded: false,
            isOpen: true,
            userId: studentId,
            user: {},
        });

        api.students.getStudentInfo({
            session: store.session,
            studentId,
        }).then((res) => {
            setStudentInfoPopup((prev) => ({
                ...prev,
                isLoaded: true,
                user: res.ok ? res.student : {},
            }));
        });
    };

    const onOpenStudentPopup = (userId) => {
        loadStudentInfo(userId);

        if (!store.juices.studentJuicesById[userId]) {
            loadStudentDailyJuices(userId);
        }
    };

    const onCloseStudentPopup = () => {
        setStudentInfoPopup(getStudentPopupState());
    };

    const onOpenNewPasswordPopup = (studentId) => {
        setNewPasswordPopupState({
            isLoading: false,
            isSubmitted: false,
            message: null,
            isOpen: true,
            id: studentId,
        });
    };

    const onRemoveStudent = (id) => {
        setRemoveStudentPopup({
            isOpen: true,
            isLoading: false,
            error: null,
            studentId: id,
        });
    };

    const onCloseAllChangingDataPopups = () => {
        setNameChangePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });

        setEmailChangePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });

        setChangeGradePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });
    };

    const onUpdateStudentInfo = (params) => {
        if (params.newFullname) {
            setNameChangePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        } else if (params.newEmail) {
            setEmailChangePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        } else if (params.newGrade) {
            setChangeGradePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        }

        api.students.updateStudentDataV2({
            session: store.session,
            studentId: studentInfoPopup.user.account.ID,
            fullname: params.newFullname || "",
            email: params.newEmail || "",
            grade: params.newGrade || "",
        }).then((res) => {
            if (res.ok) {
                onCloseAllChangingDataPopups();

                loadStudentInfo(studentInfoPopup.userId);

                loadAssignmentsByClassId(teacherClasses.selectedClass);
            } else {
                const error = res.error || "Error!";

                if (params.newFullname) {
                    setNameChangePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                } else if (params.newEmail) {
                    setEmailChangePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                } else if (params.newGrade) {
                    setChangeGradePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                }
            }
        });
    };

    const onUploadSponsorImage = (evt) => {
        events.teacherHomeUploadAnImage({
            session: store.session,
            classId: teacherClasses.selectedClass,
        });

        if (!evt) {
            setSponsorPopup((prev) => {
                const allImages = (prev.sponsor.images || []).map(() => ({
                    id: "",
                    url: "",
                }));

                return {
                    ...prev,
                    isImageLoading: false,
                    sponsor: {
                        ...prev.sponsor,
                        imageId: 0,
                        imageUrl: "",
                        images: allImages,
                    },
                };
            });
        } else if (evt.target.files.length > 0) {
            const file = evt.target.files[0];

            setSponsorPopup((prev) => ({
                ...prev,
                isImageLoading: true,
            }));

            api.classes.uploadSponsorMedia({
                session: store.session,
                file,
            }).then((res) => {
                if (res.ok) {
                    setSponsorPopup((prev) => ({
                        ...prev,
                        isImageLoading: false,
                        sponsor: {
                            ...prev.sponsor,
                            imageId: res.data.id,
                            imageUrl: res.data.url,
                        },
                    }));
                }
            });
        }
    };

    const loadSponsorsByClassId = (classId) => {
        setSponsorPopup((prev) => ({
            ...prev,
            isLoaded: false,
            sponsor: {},
            selectedClass: classId,
        }));

        if (classId !== allClassesTitle) {
            api.classes.getClassSponsors({ classId: classId || -1 }).then((res) => {
                let content = "";
                let imageId = "";
                let imageUrl = "";

                if (res.ok) {
                    content = res.data.sponsorContent;
                    imageId = res.data.sponsorImageId;
                    imageUrl = res.data.sponsorImageUrl;
                }

                setSponsorPopup((prev) => ({
                    ...prev,
                    isLoaded: true,
                    sponsor: {
                        ...prev.sponsor,
                        content,
                        imageId,
                        imageUrl,

                        contents: [],
                        images: [],
                    },
                }));
            });
        } else {
            api.classes.getAllSponsorsByTeacher({
                session: store.session,
            }).then((res) => {
                const contents = [];
                const images = [];

                if (res.ok) {
                    res.data.forEach((oneSponsor) => {
                        contents.push(oneSponsor.sponsorContent);
                        images.push({
                            id: oneSponsor.sponsorImageId,
                            url: oneSponsor.sponsorImageUrl,
                        });
                    });
                }

                setSponsorPopup((prev) => ({
                    ...prev,
                    isLoaded: true,
                    sponsor: {
                        ...prev.sponsor,

                        content: "",
                        imageId: "",
                        imageUrl: "",

                        contents,
                        images,
                    },
                }));
            });
        }
    };

    const loadAnnouncementByClassId = (classId) => {
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            isLoaded: false,
            selectedClass: classId,
        }));

        if (classId !== allClassesTitle) {
            api.classes.getClassAnnouncement({ classId: classId || -1 }).then((res) => {
                const content = res.ok ? res.data.announcementContent : "";
                const title = res.ok ? res.data.announcementTitle : "";

                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isLoaded: true,
                    announcement: {
                        content,
                        title,
                        contents: [],
                    },
                }));
            });
        } else {
            api.classes.getAllClassesAnnouncementsByTeacher({
                session: store.session,
            }).then((res) => {
                const allClassesContent = [];

                if (res.ok) {
                    res.data.forEach((oneClass) => {
                        allClassesContent.push(oneClass.announcementContent);
                    });
                }

                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isLoaded: true,
                    announcement: {
                        ...prevState.announcement,
                        contents: allClassesContent,
                    },
                }));
            });
        }
    };

    const onOpenSponsorPopup = () => {
        const classId = teacherClasses.selectedClass;
        loadSponsorsByClassId(classId);
        setIsVisibleSponsorPopup(true);
    };

    const onStatsRangeChange = (values) => {
        dispatch(actions.teacher.setTeacherStatsDate({
            statsDate: values.value,
        }));

        loadJuiceStats(teacherClasses.selectedClass, values.value);

        loadAssignmentsByClassId(teacherClasses.selectedClass, values.value);
    };

    const onViewAllScores = () => {
        events.teacherHomeViewAllScores({
            session: store.session,
        });
        history.push("/class");
    };

    const onOpenAnnouncementPopup = () => {
        const classId = teacherClasses.selectedClass;
        loadAnnouncementByClassId(classId);
        setIsVisibleAnnouncementPopup(true);
    };

    const onCloseSponsorPopup = (hasUnsavedContent = false) => {
        if (hasUnsavedContent) {
            setIsVisibleUnsavedSponsorPopup(true);
        } else {
            setSponsorPopup(getSponsorPopupState());
            setSponsorPopup((prevState) => ({
                ...prevState,
                selectedClass: teacherClasses.selectedClass,
            }));
            setIsVisibleSponsorPopup(false);
            setIsVisibleUnsavedSponsorPopup(false);
        }
    };

    const onCloseAnnouncementPopup = (hasUnsavedContent = false) => {
        if (hasUnsavedContent) {
            setIsVisibleConfirmCloseAnnouncementPopup(true);
        } else {
            const { selectedClass } = teacherClasses;

            setAnnoucementPopup(getAnnouncementPopupState());

            setAnnoucementPopup((prevState) => ({
                ...prevState,
                selectedClass,
            }));

            loadClassSelectedAnnouncementByClassId(selectedClass);

            setIsVisibleAnnouncementPopup(false);
            setIsVisibleConfirmCloseAnnouncementPopup(false);
        }
    };

    const saveAnnouncementForAllClasses = (values) => {
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            isSaving: true,
            errors: [],
        }));

        api.classes.updateAllTeacherClassesAnnouncements({
            session: store.session,
            content: values.content,
        }).then((res) => {
            if (res.ok) {
                onCloseAnnouncementPopup();
            } else {
                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isSaving: false,
                    errors: [res.error],
                }));
            }
        });
    };

    const saveAndCloseAnnouncementPopup = (values) => {
        setAnnoucementPopup((prevState) => ({
            ...prevState,
            isSaving: true,
            errors: [],
        }));

        events.teacherHomeEditClassAnnouncement({
            session: store.session,
            classId: announcementPopup.selectedClass,
        });

        api.classes.updateClassAnnouncement({
            session: store.session,
            id: announcementPopup.selectedClass,
            content: values.content,
        }).then((res) => {
            if (res.ok) {
                onCloseAnnouncementPopup();
            } else {
                setAnnoucementPopup((prevState) => ({
                    ...prevState,
                    isSaving: false,
                    errors: [res.error],
                }));
            }
        });
    };

    const saveAndCloseSponsorPopup = (values) => {
        setSponsorPopup((prev) => ({
            ...prev,
            isSaving: true,
            errors: [],
        }));

        api.classes.updateClassSponsors({
            session: store.session,
            classId: sponsorPopup.selectedClass,
            sponsorContent: values.content,
            sponsorImageId: values.imageId,
        }).then((res) => {
            if (res.ok) {
                onCloseSponsorPopup();
            } else {
                setSponsorPopup((prev) => ({
                    ...prev,
                    isSaving: false,
                    errors: res.error ? [res.error] : [],
                }));
            }
        });
    };

    const saveSponsorContentForAllTeacherClasses = (values) => {
        setSponsorPopup((prevState) => ({
            ...prevState,
            isSaving: true,
            errors: [],
        }));

        api.classes.updateAllTeacherClassesSponsor({
            session: store.session,
            sponsorImageId: values.imageId,
            sponsorContent: values.content,
        }).then((res) => {
            if (res.ok) {
                onCloseSponsorPopup();
            } else {
                setSponsorPopup((prev) => ({
                    ...prev,
                    isSaving: false,
                    errors: res.error ? [res.error] : [],
                }));
            }
        });
    };

    const onPreviewAds = (img, text) => {
        setAdsPreviewPopup({
            isOpen: true,
            img: img.imageUrl,
            text,
        });
    };

    const onCloseNewPasswordPopup = () => {
        setNewPasswordPopupState({
            isOpen: false,
            isSubmitted: false,
            isLoading: false,
            message: null,
            id: null,
        });
    };

    const onChangeStudentPassword = (newPassword) => {
        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSubmitted: true,
            isLoading: true,
        }));

        api.students.setPassword({
            session: store.session,
            studentId: newPasswordPopupState.id,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                    studentId: newPasswordPopupState.id,
                }));
            }

            setNewPasswordPopupState((prev) => ({
                ...prev,
                isLoading: false,
                message: res.ok ? "Password successfully updated!" : res.error,
            }));
        });
    };

    const renderTutorial = () => {
        if (!selectedClassAnnouncement.isLoaded
            || juiceStats.isLoading
            || !studentsActivity.isLoaded
            || isVisibleSponsorPopup
            || (announcementPopup && announcementPopup.isLoaded)) {
            return null;
        }

        return (
            <Tutorial name="teacher-index" />
        );
    };

    const renderAnnouncementPopup = () => {
        if (!isVisibleAnnouncementPopup || !announcementPopup.announcement) {
            return null;
        }

        const classes = [...teacherClasses.classes.map((cl) => ({ id: cl.id, title: cl.title }))];

        if (classes.length > 1) {
            classes.push({
                title: allClassesTitle,
                id: allClassesTitle,
            });
        }

        const isSelectedAllClasses = announcementPopup.selectedClass === allClassesTitle;

        const announcement = {
            isLoaded: announcementPopup.isLoaded,

            contents: announcementPopup.announcement.contents || [],
            content: announcementPopup.announcement.content || "",
        };

        return (
            <PopupAnnouncement
                classes={classes || []}
                selectedClass={announcementPopup.selectedClass}
                dimensions={store.dimensions}
                announcement={announcement}
                errors={announcementPopup.errors}
                isSaving={announcementPopup.isSaving}
                isSelectedAllClasses={isSelectedAllClasses}
                onPreviewAds={onPreviewAds}
                onClose={onCloseAnnouncementPopup}
                onSaveAndClose={saveAndCloseAnnouncementPopup}
                onSaveForAllClasses={saveAnnouncementForAllClasses}
                onClassChange={loadAnnouncementByClassId}
            />
        );
    };

    const renderSponsorUnsavedProgressPopup = () => {
        if (!isVisibleUnsavedSponsorPopup) {
            return null;
        }

        return (
            <PopupConfirmUnsavedProgress
                onLeave={() => {
                    onCloseSponsorPopup();
                }}
                onClose={() => {
                    setIsVisibleUnsavedSponsorPopup(false);
                }}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopupState.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={newPasswordPopupState.message}
                isSubmitted={newPasswordPopupState.isSubmitted}
                isLoading={newPasswordPopupState.isLoading}
                onClose={onCloseNewPasswordPopup}
                onSave={onChangeStudentPassword}
            />
        );
    };

    const renderConfirmCloseAnnouncementPopup = () => {
        if (!isVisibleConfirmCloseAnnouncementPopup) {
            return null;
        }

        return (
            <PopupConfirmUnsavedProgress
                onLeave={() => {
                    onCloseAnnouncementPopup();
                }}
                onClose={() => {
                    setIsVisibleConfirmCloseAnnouncementPopup(false);
                }}
            />
        );
    };

    const onCloseDeletingPopup = () => {
        setRemoveStudentPopup({
            isOpen: false,
            isLoading: false,
            error: null,
            studentId: -1,
        });
    };

    const removeStudent = () => {
        setRemoveStudentPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        api.classes.removeStudentById({
            session: store.session,
            studentId: removeStudentPopup.studentId,
        }).then((res) => {
            if (res.ok) {
                onCloseDeletingPopup();
                onCloseStudentPopup();
                loadAssignmentsByClassId(teacherClasses.selectedClass);
            } else {
                setRemoveStudentPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.error,
                }));
            }
        });
    };

    const renderAdsPreviewPopup = () => {
        if (!adsPreviewPopup.isOpen) {
            return null;
        }

        return (
            <PopupAdsPreview
                data={{
                    sponsorImage: adsPreviewPopup.img,
                    announcementContent: adsPreviewPopup.text,
                }}
                onClose={() => {
                    setAdsPreviewPopup({
                        isOpen: false,
                        img: "",
                        text: "",
                    });
                }}
            />
        );
    };

    const renderRemoveStudentPopup = () => {
        if (!removeStudentPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                isLoading={removeStudentPopup.isLoading}
                error={removeStudentPopup.error}
                onDelete={removeStudent}
                onClose={onCloseDeletingPopup}
            />
        );
    };

    const renderNameChangePopup = () => {
        if (!nameChangePopupState.isOpen) {
            return null;
        }

        let defaultValue = "";

        if (studentInfoPopup?.user?.account?.fullName) {
            defaultValue = studentInfoPopup.user.account.fullName;
        }

        return (
            <PopupNameChange
                defaultValue={defaultValue}
                isSaving={nameChangePopupState.isSaving}
                error={nameChangePopupState.error}
                onSave={(newFullname) => {
                    onUpdateStudentInfo({
                        newFullname,
                    });
                }}
                onClose={() => {
                    setNameChangePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderEmailChangePopup = () => {
        if (!emailChangePopupState.isOpen) {
            return null;
        }

        let defaultValue = "";

        if (studentInfoPopup?.user?.account?.email) {
            defaultValue = studentInfoPopup.user.account.email;
        }

        return (
            <PopupEmailChange
                defaultValue={defaultValue}
                isSaving={emailChangePopupState.isSaving}
                error={emailChangePopupState.error}
                onSave={(newEmail) => {
                    onUpdateStudentInfo({
                        newEmail,
                    });
                }}
                onClose={() => {
                    setEmailChangePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderGradeChangePopup = () => {
        if (!changeGradePopupState.isOpen) {
            return null;
        }

        let defaultGrade = null;

        if (studentInfoPopup?.user?.overview?.studentGrade) {
            defaultGrade = getGradeGroup(studentInfoPopup.user.overview.studentGrade);
        }

        return (
            <PopupGradeChange
                defaultGrade={defaultGrade}
                grades={["5-6", "7-8", "9-10", "11-12"]}
                isSaving={changeGradePopupState.isSaving}
                error={changeGradePopupState.error}
                onSave={(newGrades) => {
                    const sGrades = newGrades.split("-");

                    if (sGrades.length > 1) {
                        onUpdateStudentInfo({
                            newGrade: `g${sGrades[1]}`,
                        });
                    }
                }}
                onClose={() => {
                    setChangeGradePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderStudentInfoPopup = () => {
        if (!studentInfoPopup.isOpen) {
            return null;
        }

        let isStudentForgotPassword = false;
        let studentData = {};

        if (studentInfoPopup?.user?.account?.ID) {
            const { studentsWithForgottenPasswords } = store.teacher;

            for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
                if (studentsWithForgottenPasswords[i].id === studentInfoPopup.user.account.ID) {
                    isStudentForgotPassword = true;
                    break;
                }
            }

            studentData = {
                ...studentInfoPopup.user,
                isForgotPassword: isStudentForgotPassword,
            };
        }

        let dailyJuices = {};
        let isDailyJuicesLoading = false;

        if (store.juices?.studentJuicesById[studentInfoPopup.userId]) {
            dailyJuices = store.juices.studentJuicesById[studentInfoPopup.userId];

            if (store.juices.studentJuicesById[studentInfoPopup.userId].isLoading) {
                isDailyJuicesLoading = true;
            }
        }

        return (
            <>
                <StudentInfo
                    studentId={studentInfoPopup.userId}
                    student={studentData}
                    isStudentLoaded={studentInfoPopup.isLoaded}
                    dailyJuices={dailyJuices}
                    isDailyJuicesLoading={isDailyJuicesLoading}
                    isCards={store.dimensions.width < 900}
                    hideStudentSelectorArrows
                    onEditName={() => {
                        setNameChangePopupState((prev) => ({
                            ...prev,
                            isOpen: true,
                        }));
                    }}
                    onEditEmail={() => {
                        setEmailChangePopupState((prev) => ({
                            ...prev,
                            isOpen: true,
                        }));
                    }}
                    onGradeEdit={() => {
                        setChangeGradePopupState((prev) => ({
                            ...prev,
                            isOpen: true,
                        }));
                    }}
                    onRemoveStudent={() => {
                        onRemoveStudent(studentInfoPopup.userId);
                    }}
                    onResetPassword={() => {
                        onOpenNewPasswordPopup(studentInfoPopup.userId);
                    }}
                    onLoadMoreJuices={() => {
                        loadStudentDailyJuices(studentInfoPopup.userId);
                    }}
                    onClose={onCloseStudentPopup}
                />
            </>
        );
    };

    const renderSponsorPopup = () => {
        if (!isVisibleSponsorPopup || !sponsorPopup.sponsor) {
            return null;
        }

        const classes = [...teacherClasses.classes.map((cl) => ({ id: cl.id, title: cl.title }))];

        if (classes.length > 1) {
            classes.push({
                title: allClassesTitle,
                id: allClassesTitle,
            });
        }

        const sponsor = {
            isLoaded: sponsorPopup.isLoaded,
            isImageLoading: sponsorPopup.isImageLoading,

            content: sponsorPopup.sponsor.content || "",
            imageId: sponsorPopup.sponsor.imageId || "",
            imageUrl: sponsorPopup.sponsor.imageUrl || "",

            contents: sponsorPopup.sponsor.contents || [],
            images: sponsorPopup.sponsor.images || [],
        };

        const isSelectedAllClasses = sponsorPopup.selectedClass === allClassesTitle;

        return (
            <PopupSponsorContent
                classes={classes}
                selectedClass={sponsorPopup.selectedClass}
                isSelectedAllClasses={isSelectedAllClasses}
                sponsor={sponsor}
                dimensions={store.dimensions}
                errors={sponsorPopup.errors || []}
                isSaving={sponsorPopup.isSaving}
                onPreviewAds={onPreviewAds}
                onClose={onCloseSponsorPopup}
                onClassChange={loadSponsorsByClassId}
                onSave={saveAndCloseSponsorPopup}
                onSaveAll={saveSponsorContentForAllTeacherClasses}
                onUploadSponsorImage={onUploadSponsorImage}
            />
        );
    };

    const onTabChange = (id) => {
        dispatch(actions.teacher.setTeacherSelectedClass({
            selectedClassId: id,
        }));
        storage.local.saveTeacherSelectedClass(id);

        events.teacherHomeChangeClass({
            session: store.session,
            classId: id,
        });

        setTeacherClasses((prevState) => ({
            ...prevState,
            selectedClass: id,
        }));
        loadClassSelectedAnnouncementByClassId(id);
        loadAssignmentsByClassId(id);
        loadJuiceStats(id, dropdownRange);
    };

    const renderTabs = () => {
        return (
            <div className={styles.classesTabs}>
                <Tabs
                    tabs={teacherClasses.classes}
                    onChange={(title, id) => {
                        onTabChange(id);
                    }}
                    selectedClassId={teacherClasses.selectedClass}
                    dimensions={store.dimensions}
                />
            </div>
        );
    };

    // eslint-disable-next-line no-unused-vars
    const renderRow1 = () => {
        const quickLinks = [
            {
                name: "Upload an image",
                dataComment: "daily-juice-upload-image",
                callback: () => {
                    onOpenSponsorPopup();
                },
            },
            // TODO:
            // {
            //     name: "Check grades",
            //     callback: () => {},
            // },
            // {
            //     name: "Export grades",
            //     callback: () => {},
            // },
        ];

        let stats = null;
        let chartPie = null;

        if (juiceStats.stats) {
            let avgScore = `${juiceStats.stats.averageScore}%`;

            if (juiceStats.stats.averageScore === "") {
                avgScore = "--";
            }

            stats = {
                average: avgScore,
                range: {
                    min: juiceStats.stats.minScore,
                    max: juiceStats.stats.maxScore,
                },
            };

            chartPie = {
                complete: juiceStats.stats.complete,
                inProgress: juiceStats.stats.inProgress,
                unopened: juiceStats.stats.unopened,
            };
        }

        const rowClasses = [
            styles.widgetRow,
            styles.widgetColumn2,
        ];

        return (
            <div className={rowClasses.join(" ")}>
                <TeacherClassStats
                    pageWidth={pageWidth}
                    stats={stats}
                    chartPie={chartPie}
                    error={juiceStats.error}
                    isLoading={juiceStats.isLoading}
                    dropdownRange={dropdownRange}
                    onRangeChange={onStatsRangeChange}
                    onViewAllScores={onViewAllScores}
                />

                <DailyJuiceLinks
                    links={quickLinks}
                />
            </div>
        );
    };

    const renderRow2 = () => {
        let activityTitle = "";

        if (studentsActivity.isLoaded) {
            if (dropdownRange === "today") {
                activityTitle = "today's daily juice";
            }

            if (dropdownRange === "yesterday") {
                activityTitle = "yesterday's daily juice";
            }

            if (dropdownRange === "this-week") {
                activityTitle = "this week daily juice's";
            }

            if (dropdownRange === "last-week") {
                activityTitle = "last week daily juice's";
            }

            if (dropdownRange === "this-month") {
                activityTitle = "this month daily juice's";
            }
        }

        const rowClasses = [
            styles.widgetRow,
            styles.widgetColumn3,
        ];

        return (
            <div className={styles.homePage}>
                <div className={rowClasses.join(" ")}>
                    <TeacherStudentsActivity
                        pageWidth={pageWidth}
                        title={activityTitle}
                        students={studentsActivity.students}
                        isLoaded={studentsActivity.isLoaded}
                        onStudentClick={onOpenStudentPopup}
                    />

                    <TeacherClassAnnouncement
                        pageWidth={pageWidth}
                        content={selectedClassAnnouncement.content}
                        onEdit={onOpenAnnouncementPopup}
                    />
                </div>
            </div>
        );
    };

    const renderRows = () => {
        if (!selectedClassAnnouncement.isLoaded) {
            return (
                <RequestLoader />
            );
        }

        return [
            renderRow1(),
            renderRow2(),
        ];
    };

    if (!teacherClasses.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    if (teacherClasses.selectedClass === -1) {
        return null;
    }

    return (
        <>
            {renderTutorial()}

            {renderStudentInfoPopup()}

            {renderNameChangePopup()}
            {renderEmailChangePopup()}
            {renderGradeChangePopup()}

            {renderAnnouncementPopup()}
            {renderConfirmCloseAnnouncementPopup()}

            {renderNewPasswordPopup()}

            {renderSponsorPopup()}
            {renderRemoveStudentPopup()}
            {renderSponsorUnsavedProgressPopup()}
            {renderAdsPreviewPopup()}

            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    {renderTabs()}
                    {renderRows()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["teacher"])(TeacherHomePage);
