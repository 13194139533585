// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._36A7X-WyRIJDsReNFuzEar {}\n\n.wUf-RKUqPE_XAbwnx81qV {\n    display: grid;\n    grid-gap: 2.5rem;\n}\n\n._2KWyDPWqqxNT_oww8RNXZ3 {\n    color: var(--cerulean);\n    font-size: 1.4rem;\n    font-weight: 600;\n    text-decoration: none;\n}\n\n._3fNNgkCCvVue2a7KM1U4mB {\n    margin-top: 2.5rem;\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .wUf-RKUqPE_XAbwnx81qV {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}", "",{"version":3,"sources":["webpack://./base/forms/user-sign-in/styles.module.css"],"names":[],"mappings":"AAAA,0BAAO;;AAEP;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;QACtB,8BAA8B;QAC9B,mBAAmB;IACvB;AACJ","sourcesContent":[".form {}\n\n.formLinksSection {\n    display: grid;\n    grid-gap: 2.5rem;\n}\n\n.accountLinkPassword {\n    color: var(--cerulean);\n    font-size: 1.4rem;\n    font-weight: 600;\n    text-decoration: none;\n}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n\n@media only screen and (min-width: 500px) {\n    .formLinksSection {\n        grid-auto-flow: column;\n        justify-content: space-between;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_36A7X-WyRIJDsReNFuzEar",
	"formLinksSection": "wUf-RKUqPE_XAbwnx81qV",
	"accountLinkPassword": "_2KWyDPWqqxNT_oww8RNXZ3",
	"submitButtonBlock": "_3fNNgkCCvVue2a7KM1U4mB"
};
export default ___CSS_LOADER_EXPORT___;
