import React from "react";

import Text from "../../text/index.js";

import styles from "./styles.module.css";


const Radio = (props) => {
    const {
        id,
        name,
        label,
        dangerousLabel,
        value,
        checked,
        disabled,
        onChange,
        onBlur,
    } = props;

    const classes = [styles.field];

    if (checked) {
        classes.push(styles.fieldChecked);
    }

    let labelElem = null;

    if (dangerousLabel) {
        labelElem = (
            <label
                htmlFor={`${id}-${name}-${value}`}
                className={styles.fieldLabel}
            >
                <Text>
                    {label}
                </Text>
            </label>
        );
    } else {
        labelElem = (
            <label
                htmlFor={`${id}-${name}-${value}`}
                className={styles.fieldLabel}
            >
                {label}
            </label>
        );
    }

    return (
        <div className={classes.join(" ")}>
            <div className={styles.fieldWrapper}>
                <input
                    id={`${id}-${name}-${value}`}
                    name={name}
                    type="radio"
                    value={value}
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    className={styles.fieldInput}
                />
            </div>
            {labelElem}
        </div>
    );
};

Radio.defaultProps = {
    id: "",
    name: "",
    label: "",
    dangerousLabel: false,
    value: "",
    checked: false,
    disabled: false,
    onChange: () => { },
    onBlur: () => { },
};

export default Radio;
