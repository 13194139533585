import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import staticFiles from "juice-base/static-files.js";

import User from "juice-base/project/user.js";
import DailyJuice from "juice-base/project/daily-juice.js";
import DailyJuices from "juice-base/project/daily-juices.js";

import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";

import RequestLoader from "juice-base/components/request-loader/index.js";
import MessageWithEmoji from "juice-base/components/message-with-emoji/index.js";
import Dropdown from "juice-base/components/dropdown/index.js";
import Tiles from "juice-base/components/tiles/index.js";
import DatePickerSingle from "juice-base/components/date-picker-single/index.js";

import DailyJuiceSponsor from "juice-base/components/daily-juice-sponsor/index.js";
import DailyJuiceClassAnnouncement from "juice-base/components/daily-juice-class-announcement/index.js";
import DailyJuiceStoryCard from "juice-base/components/daily-juice-story-card/index.js";
import DailyJuiceActivity from "juice-base/business/daily-juice-activity/index.js";

import PopupDailyJuiceStats from "juice-base/components/popup-daily-juice-stats/index.js";
import PopupPlayerAudio from "juice-base/components/popup-player-audio/index.js";
import PopupDailyJuiceStatesLegend from "juice-base/components/popup-daily-juice-states-legend/index.js";

import actions from "juice-base/store/actions.js";
import device from "juice-base/lib/device.js";
import storage from "juice-base/lib/storage/index.js";
import scroll from "juice-base/lib/scroll.js";
import classNames from "juice-base/lib/class-names.js";

import { newDate, tryFormatDate, getDateFromDate } from "juice-base/lib/date.js";

import settings from "juice-app/settings.js";
import * as events from "juice-base/events.js";
import api from "juice-app/api.js";

import Tutorial from "juice-app/containers/tutorial/index.js";

import dailyJuiceCommonStyles from "juice-base/components/daily-juice-common/styles.module.css";
import linkStyles from "juice-base/components/link/styles.module.css";
import styles from "./styles.module.css";


const getDefaultFilterGrades = (grades) => {
    let defaultValue = "";
    const defaultGrades = storage.local.loadDailyJuicesDefaultGrades();

    if (grades && grades.length > 0) {
        if (defaultGrades) {
            for (let i = 0; i < grades.length; i += 1) {
                const grade = grades[i];
                if (grade.value === defaultGrades) {
                    defaultValue = { ...grade };
                    break;
                }
            }
        } else {
            defaultValue = grades[settings.grades.length - 1];
        }
    }

    return defaultValue;
};

const storeSelector = (state) => ({
    dimensions: state.device.dimensions,
    session: state.user.session,
    user: state.user.user,
    juicesById: state.juices.juicesById,
    juicesDates: state.juices.juicesDates,
    isJuicesDatesLoaded: state.juices.isJuicesDatesLoaded,
    sponsors: state.info.sponsors,
    t2sJuiceStories: state.text2speech.juiceStories,
    guardian: state.guardian,
});

const StudentDailyJuice = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    // TODO: setFilterByGrade
    const [filterByGrade] = useState(() => {
        const gs = getDefaultFilterGrades(settings.grades);
        return gs ? gs.value : "";
    });

    const [loadingError, setLoadingError] = useState(false);

    const [datePickerState, setDatePicker] = useState({
        isLoaded: false,
        date: null,
    });

    const [isVisibleStatsPopup, setIsVisibleStatsPopup] = useState(false);
    const [isVisibleIconsLegendPopup, setIsVisibleIconsLegendPopup] = useState(false);

    const [audioPlayer, setAudioPlayer] = useState({
        storyId: -1,
    });

    const store = useSelector(storeSelector);

    const { isJuiceById, juiceId } = DailyJuices.getJuiceId(params.juiceIdOrDate, store.juicesById);
    const juiceGrades = (store.juicesById[juiceId] || {}).grades || [];

    const isPreview = history.location.pathname.indexOf("preview") !== -1;
    const hasRoleGuardian = User.hasRoleGuardian(store.user);
    const isMobile = store.dimensions.width <= 1024;

    const am = useAudioManager({
        isMac: device.isMac,
    });

    /* --- */

    const getJuiceDate = () => {
        const { juiceIdOrDate } = params;

        if (isJuiceById) {
            const juice = store.juicesById[juiceIdOrDate];

            if (juice) {
                return juice.juiceDate;
            }

            return null;
        }

        const juice = DailyJuices.getJuiceByDate(store.juicesById, juiceIdOrDate);

        if (juice) {
            return juice.juiceDate;
        }

        return null;
    };

    const onGoToStory = (storyId) => {
        const { juiceIdOrDate } = params;

        let storyUrl = "";

        if (isPreview) {
            storyUrl = `/daily-juice-preview-${settings.previewCode}/${juiceIdOrDate}/${storyId}`;
        } else {
            storyUrl = `/daily-juice/${juiceIdOrDate}/${storyId}`;
        }

        scroll.scrollToTop();
        history.push(storyUrl);
    };

    const loadJuiceCallback = (res) => {
        if (res.ok) {
            if (!isPreview) {
                events.dailyJuiceOpen({
                    session: store.session,
                    juiceId: res.data.id,
                    grades: res.data.grades,
                });
            }

            dispatch(actions.juices.setJuice({
                juice: res.data,
            }));
            return;
        }

        setLoadingError(true);
    };

    const loadJuiceById = (studentId) => {
        const { juiceIdOrDate } = params;

        const myGetJuice = isPreview
            ? api.dailyJuices.getUnpublishedJuice
            : api.dailyJuices.getJuice;

        const req = {
            session: store.session,
            juiceId: juiceIdOrDate,
            studentId,
        };

        myGetJuice(req).then(loadJuiceCallback);
    };

    const loadJuiceByDate = () => {
        const { juiceIdOrDate } = params;

        api.dailyJuices.getJuiceByDate({
            session: store.session,
            date: juiceIdOrDate,
        }).then(loadJuiceCallback);
    };

    const loadJuice = (studentId) => {
        if (isJuiceById) {
            loadJuiceById(studentId);
        } else {
            loadJuiceByDate();
        }
    };

    const loadGuardianStudents = () => {
        // TODO: support for dates
        const { juiceIdOrDate } = params;

        const req = {
            session: store.session,
            juiceId: juiceIdOrDate,
            studentId: store.guardian.selectedStudent,
        };

        api.students.getStudentsByGuardian(req).then((res) => {
            const students = res.students || [];

            let selectedStudentId = store.guardian.selectedStudent;
            if (!selectedStudentId) {
                selectedStudentId = students[0] ? students[0].id || null : null;
            }

            dispatch(actions.guardian.setStudents({
                students,
            }));

            dispatch(actions.guardian.setSelectedStudent({
                selectedStudent: selectedStudentId,
            }));

            loadJuice(selectedStudentId);
        });
    };

    const loadJuicesDates = (guardianStudentId = null) => {
        dispatch(actions.juices.clearJuicesDates());

        api.dailyJuices.getJuicesDates({
            session: store.session,
            studentId: guardianStudentId,
        }).then((res) => {
            let dates = [];

            if (res.ok) {
                dates = res.juices.map((juice) => {
                    return {
                        date: juice.date,
                        juiceId: juice.id,
                    };
                });
            }

            dispatch(actions.juices.setJuicesDates({
                dates,
            }));
        });
    };

    const getJuiceIdByDate = (date) => {
        for (let i = 0; i < store.juicesDates.length; i += 1) {
            const selectedDate = tryFormatDate(date, getDateFromDate);
            const juiceDate = tryFormatDate(store.juicesDates[i].date, getDateFromDate);

            if (selectedDate === juiceDate) {
                return store.juicesDates[i].juiceId;
            }
        }

        return null;
    };

    const loadAndGoToJuiceByDate = (date) => {
        const newJuiceId = getJuiceIdByDate(date);

        if (newJuiceId) {
            history.push(`/daily-juice/${newJuiceId}`);
        }
    };

    /* --- */

    const addEventReadStoryClick = (values) => {
        if (!isPreview) {
            events.dailyJuiceStoryOpen({
                session: store.session,
                ...values,
            });
        }
    };

    const addEventVideoPlay = (values) => {
        if (!isPreview) {
            events.dailyJuiceVideoPlay({
                session: store.session,
                ...values,
            });
        }
    };

    const addEventVideoPause = (values) => {
        if (!isPreview) {
            events.dailyJuiceVideoPause({
                session: store.session,
                ...values,
            });
        }
    };

    const addEventVideoEnded = (values) => {
        if (!isPreview) {
            events.dailyJuiceVideoEnded({
                session: store.session,
                ...values,
            });
        }
    };

    /* --- */

    const onOpenStatsPopup = () => {
        setIsVisibleStatsPopup(true);
    };

    const onCloseStatsPopup = () => {
        setIsVisibleStatsPopup(false);
    };

    /* --- */

    const onOpenAudioPlayer = (storyId) => {
        if (audioPlayer.storyId === -1) {
            setAudioPlayer((prev) => ({
                ...prev,
                storyId,
            }));
        } else {
            setAudioPlayer((prev) => ({
                ...prev,
                storyId: -1,
            }));

            window.setTimeout(() => {
                setAudioPlayer((prev) => ({
                    ...prev,
                    storyId,
                }));
            }, 300);
        }
    };

    const onCloseAudioPlayer = () => {
        setAudioPlayer((prev) => ({
            ...prev,
            storyId: -1,
        }));
    };

    const onPlayJuiceStory = (values) => {
        dispatch(actions.t2s.setJuiceStoryLoading({
            storyId: values.storyId,
        }));

        api.t2s.juice({
            juiceId: values.juiceId,
            storyId: values.storyId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("juiceStories", values.storyId);

                dispatch(actions.t2s.setJuiceStory({
                    juiceId: values.juiceId,
                    storyId: values.storyId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onStudentChange = (value) => {
        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value.value,
        }));

        dispatch(actions.juices.clearJuices());

        dispatch(actions.juices.clearJuicesDates());

        // TODO:
        history.push("/daily-juice");
    };

    /* --- */

    const onDatePickerDateChange = (value) => {
        const nDate = newDate(value);

        setDatePicker({
            date: nDate,
            isLoaded: true,
        });

        loadAndGoToJuiceByDate(nDate);
    };

    /* --- */

    useEffect(() => {
        const studentId = store.guardian.selectedStudent;

        if (hasRoleGuardian && studentId) {
            loadJuicesDates(studentId);
        } else if (!hasRoleGuardian && !store.isJuicesDatesLoaded) {
            loadJuicesDates();
        }
    }, [store.guardian.selectedStudent]);

    useEffect(() => {
        setDatePicker({
            isLoaded: false,
            date: null,
        });

        const juiceDate = getJuiceDate();

        if (juiceDate) {
            setDatePicker({
                isLoaded: true,
                date: juiceDate,
            });
        }
    }, [store.juicesById]);

    useEffect(() => {
        am.setFiles({
            juiceStories: store.t2sJuiceStories,
        });
    }, [store.t2sJuiceStories]);

    useEffect(() => {
        // TODO: getJuiceId
        dispatch(actions.juices.setLastVisitedJuice({
            lastVisitedJuice: params.juiceIdOrDate,
        }));

        if (hasRoleGuardian) {
            loadGuardianStudents();
        } else {
            loadJuice(store.guardian.selectedStudent);
        }
    }, [params.juiceIdOrDate]);

    /* --- */

    const renderTutorial = () => {
        return (
            <Tutorial name="student-daily-juice" />
        );
    };

    const renderStatsPopup = (stories) => {
        if (!isVisibleStatsPopup) {
            return null;
        }

        const quizzesStats = DailyJuice.getQuizzesStats(stories);

        return (
            <PopupDailyJuiceStats
                stats={quizzesStats}
                sponsors={store.sponsors}
                isWindows={device.isWindows}
                onSponsorClick={() => {
                    if (!isPreview) {
                        events.dailyJuiceLastPageSponsor({
                            session: store.session,
                            juiceId,
                            grades: juiceGrades,
                        });
                    }
                }}
                onScrollTo={() => {
                    if (quizzesStats.firstSkipped) {
                        onGoToStory(quizzesStats.firstSkipped);
                    }
                }}
                onClose={onCloseStatsPopup}
            />
        );
    };

    const renderLegendPopup = () => {
        return (
            <PopupDailyJuiceStatesLegend
                onClose={() => {
                    setIsVisibleIconsLegendPopup(false);
                }}
            />
        );
    };

    const renderPlayerAudioPopup = (stories) => {
        const story = DailyJuice.getStoryById(stories, audioPlayer.storyId);

        if (!story) {
            return null;
        }

        const storyId = story.ID;

        let img = null;

        if (story.featuredImage && story.featuredImage.url) {
            img = story.featuredImage.url;
        }

        const trackGroupName = "juiceStories";
        const trackId = storyId;
        const audioData = (am.state[trackGroupName] || {})[trackId];

        return (
            <PopupPlayerAudio
                key={`player-audio-story-${story.ID}`}
                image={img}
                title={story.title}
                category={story.categoryName}
                audio={audioData}
                onFirstPlay={() => {
                    am.playerPauseAll();

                    if (am.state[trackGroupName]
                        && am.state[trackGroupName][trackId]) {
                        am.playerPlay(trackGroupName, trackId);
                    } else {
                        onPlayJuiceStory({
                            juiceId,
                            storyId,
                        });
                    }
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onCloseAudioPlayer();
                }}
            />
        );
    };

    const renderPopups = (stories) => {
        const ps = [];

        if (isVisibleStatsPopup) {
            ps.push(renderStatsPopup(stories));
        }

        if (isVisibleIconsLegendPopup) {
            ps.push(renderLegendPopup());
        }

        if (audioPlayer.storyId) {
            ps.push(renderPlayerAudioPopup(stories));
        }

        return ps;
    };

    const renderStudentsSelector = () => {
        if (!hasRoleGuardian
            || store.guardian.students.length === 0) {
            return null;
        }

        const students = store.guardian.students.map((student) => ({
            value: student.id,
            label: student.fullName,
        }));

        return (
            <div
                data-comment="juice-scroller-student-selector"
            >
                <Dropdown
                    values={students}
                    selectedValue={store.guardian.selectedStudent}
                    onChange={onStudentChange}
                />
            </div>
        );
    };

    const renderGradeSelector = () => {
        return (
            <div className={styles.gradeSelector}>
                <Dropdown
                    values={settings.grades}
                    selectedValue={filterByGrade}
                    onChange={() => {
                        // TODO:
                    }}
                />
            </div>
        );
    };

    const renderJuiceSelector = () => {
        if (!store.isJuicesDatesLoaded
            || !datePickerState.isLoaded) {
            return null;
        }

        const customInputIcon = (
            <img
                className={styles.caret}
                src={staticFiles.caret}
                alt="Open Menu"
            />
        );

        const availableDates = store.juicesDates.map((juice) => {
            return juice.date;
        });

        const pickerClassName = classNames({
            [styles.dailyJuiceSelector]: true,
            [dailyJuiceCommonStyles.block]: true,
        });

        return (
            <div className={pickerClassName}>
                <DatePickerSingle
                    date={datePickerState.date}
                    customInputIcon={customInputIcon}
                    availableDates={availableDates}
                    isDropdownPicker
                    onChange={onDatePickerDateChange}
                />
            </div>
        );
    };

    const renderStory = (story, index) => {
        let videoCaptionUrl = "";

        if (story.featuredVideo?.url) {
            videoCaptionUrl = api.videos.getVideoCaptionURL({
                session: store.session,
                id: story.featuredVideo.id,
            });
        }

        const storyId = story.ID;

        return (
            <DailyJuiceStoryCard
                story={story}
                storyIndex={index}
                isDefaultVideo={!device.isChrome}
                videoCaptionUrl={videoCaptionUrl}
                onAudioPlay={() => {
                    events.dailyJuiceStoryListen({
                        session: store.session,
                        storyId,
                    });

                    onOpenAudioPlayer(storyId);
                }}
                onVideoPlayStart={(videoSrc, videoId) => {
                    dispatch(actions.juices.setStoryVideoViewed({
                        juiceId,
                        storyId,
                        videoId,
                    }));

                    addEventVideoPlay({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoSrc,
                        videoId,
                    });
                }}
                onVideoPlayPause={(videoSrc, videoId) => {
                    addEventVideoPause({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoSrc,
                        videoId,
                    });
                }}
                onVideoPlayEnd={(videoSrc, videoId) => {
                    addEventVideoEnded({
                        juiceId,
                        grades: juiceGrades,
                        storyId,
                        videoSrc,
                        videoId,
                    });
                }}
                onReadMore={() => {
                    addEventReadStoryClick({
                        juiceId,
                        storyId,
                    });

                    dispatch(actions.juices.setStoryViewed({
                        juiceId,
                        storyId,
                    }));

                    onGoToStory(storyId);
                }}
            />
        );
    };

    const renderStories = (stories, classCustomContent) => {
        const items = [];

        if (classCustomContent) {
            const storyAds = (
                <DailyJuiceSponsor
                    sponsor={classCustomContent}
                />
            );

            items.push(storyAds);

            const classAnnouncement = (
                <DailyJuiceClassAnnouncement
                    announcement={classCustomContent}
                />
            );

            items.push(classAnnouncement);
        }

        stories.forEach((s, index) => {
            items.push(renderStory(s, index));
        });

        const djActivity = (
            <DailyJuiceActivity
                stories={stories}
                onGoToStory={onGoToStory}
                onShowReport={onOpenStatsPopup}
                onInfoClick={() => {
                    setIsVisibleIconsLegendPopup(true);
                }}
            />
        );

        items.push(djActivity);

        return items;
    };

    /* --- */

    if (hasRoleGuardian && !store.guardian.selectedStudent) {
        return null;
    }

    if (loadingError) {
        let message = "Can't load daily juice";

        if (!isJuiceById) {
            message = "Daily juice is not published today";
        }

        return (
            <div className={styles.errorMessage}>
                <MessageWithEmoji
                    header="Issue not available"
                    message={message}
                />

                <div className={styles.errorMessageLinks}>
                    <Link
                        to="/daily-juice"
                        className={linkStyles.link}
                    >
                        Go to Daily Juices
                    </Link>
                </div>
            </div>
        );
    }

    if (!store.juicesById[juiceId]
        || (hasRoleGuardian && !store.guardian.isStudentsLoaded)) {
        return (
            <RequestLoader />
        );
    }

    const juice = store.juicesById[juiceId];

    const stories = renderStories(
        juice.stories.juice,
        juice.classCustomContent,
    );

    return (
        <>
            {renderTutorial()}
            {renderPopups(juice.stories.juice)}

            <div className={styles.dailyJuice}>
                {renderStudentsSelector()}
                <div className={styles.juiceSelectors}>
                    {renderJuiceSelector()}
                    {renderGradeSelector()}
                </div>
                <Tiles isMobile={isMobile}>
                    {stories}
                </Tiles>
            </div>
        </>
    );
};

export default StudentDailyJuice;
