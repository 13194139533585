import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import User from "juice-base/project/user.js";

import device from "juice-base/lib/device.js";
import classNames from "juice-base/lib/class-names.js";

import { withAuth } from "juice-base/components/auth/index.js";
import useAudioManager from "juice-base/hooks/use-audio-manager/index.js";
import RequestLoader from "juice-base/components/request-loader/index.js";
import Dropdown from "juice-base/components/dropdown/index.js";
import SectionSponsor from "juice-base/components/section-sponsor/index.js";
import SectionAnnouncement from "juice-base/components/section-announcement/index.js";
import DailyJuiceExplore from "juice-base/components/daily-juice-explore/index.js";
import DailyJuiceQuizResults from "juice-base/components/daily-juice-quiz-results/index.js";
import DailyJuiceCarousel from "juice-base/components/daily-juice-carousel/index.js";

import PopupLoading from "juice-base/components/popup-loading/index.js";
import PopupWordCard from "juice-base/components/popup-word-card/index.js";
import PopupText from "juice-base/components/popup-text/index.js";
import PopupDailyJuiceExplore from "juice-base/components/popup-daily-juice-explore/index.js";

import * as events from "juice-base/events.js";
import actions from "juice-base/store/actions.js";

import Tutorial from "juice-app/containers/tutorial/index.js";
import UserFooter from "juice-app/containers/user-footer/index.js";

import api from "juice-app/api.js";

import styles from "./styles.module.css";


const StudentIndexRegular = () => {
    const [isVisibleExplorePopup, setVisibleExplorePopup] = useState(false);

    const [explorePopupExtraJuice, setExplorePopupExtraJuices] = useState({
        id: null,
        isVisiblePlayer: false,
    });

    const [explorePopupVideo, setExplorePopupVideo] = useState({
        id: null,
        isLoading: false,
        video: {},
    });

    const [wordPopup, setWordPopup] = useState({
        isOpen: false,
        isLoading: false,
        word: "",
        errors: {},
    });

    const [juiceCarousel, setJuiceCarousel] = useState({
        isLoaded: false,
        juice: {},
    });

    const dispatch = useDispatch();

    const history = useHistory();

    const store = useSelector((state) => ({
        session: state.user.session,
        dimensions: state.device.dimensions,
        wordsByName: state.vocabulary.wordsByName,
        student: state.student,
        extraJuices: state.extraJuices,
        juicesVideos: state.juicesVideos,
        isSponsorsLoaded: state.info.isSponsorsLoaded,
        sponsors: state.info.sponsors,
        t2sExtraJuices: state.text2speech.extraJuices,
        t2sWords: state.text2speech.words,
        user: state.user.user,
        guardian: state.guardian,
    }));

    if (User.isTypeTrial(store.user)) {
        history.push("/daily-juice");
        return null;
    }

    const hasGuardianRole = User.hasRoleGuardian(store.user);

    const am = useAudioManager({
        isMac: device.isMac,
    });

    const loadExtraJuices = (page = store.extraJuices.page) => {
        dispatch(actions.extraJuices.setExtraJuicesLoading());

        api.extraJuices.getExtraJuices({
            page,
        }).then((res) => {
            let extraJuices = [];

            if (res.ok) {
                extraJuices = [
                    ...store.extraJuices.extraJuices,
                    ...res.extraJuices,
                ];
            }

            // TODO: incorrect redux usage
            dispatch(actions.extraJuices.setExtraJuices({
                extraJuices,
                page,
                hasMorePages: res.ok ? res.hasMore : false,
            }));
        });
    };

    const loadVideos = (page) => {
        dispatch(actions.juicesVideos.setJuicesVideosLoading());

        api.dailyJuices.getJuicesVideos({
            page,
        }).then((res) => {
            let videos = [];

            if (res.ok) {
                videos = [
                    ...store.juicesVideos.videos,
                    ...res.featuredVideos,
                ];
            }

            // TODO: incorrect redux usage
            dispatch(actions.juicesVideos.setJuicesVideos({
                videos,
                page,
                hasMorePages: res.ok ? res.hasMore : false,
            }));
        });
    };

    const loadGuardianStudents = (req) => {
        api.students.getStudentsByGuardian(req).then((res) => {
            const students = res.students || [];

            let selectedStudentId = store.guardian.selectedStudent;

            if (!selectedStudentId) {
                selectedStudentId = students[0] ? students[0].id || null : null;
            }

            dispatch(actions.guardian.setSelectedStudent({
                selectedStudent: selectedStudentId,
            }));

            dispatch(actions.guardian.setStudents({
                students,
            }));
        });
    };

    const loadStudentClassInfo = (req) => {
        api.students.getStudentClassInfo(req).then((res) => {
            if (res.ok) {
                const classData = res.data || {};

                dispatch(actions.student.setStudentSponsor({
                    sponsor: {
                        imageId: classData.sponsorImageId || "",
                        imageUrl: classData.sponsorImageUrl || "",

                        sponsoredBy: classData.sponsoredBy || "",
                        sponsorLink: classData.sponsorLink || "",

                        content: classData.sponsorContent || "",
                    },
                }));

                dispatch(actions.student.setStudentAnnouncement({
                    announcement: {
                        title: classData.announcementTitle || "Class announcement",
                        content: classData.announcementContent || "",
                    },
                }));
            }
        });
    };

    useEffect(() => {
        const req = {
            session: store.session,
            studentId: hasGuardianRole ? store.guardian.selectedStudent : null,
        };

        if (!store.guardian.isStudentsLoaded) {
            if (hasGuardianRole) {
                loadGuardianStudents(req);
            } else {
                dispatch(actions.guardian.setStudents({
                    students: [],
                }));
            }
        } else {
            loadStudentClassInfo(req);

            api.dailyJuices.getLastJuice(req).then((res) => {
                setJuiceCarousel({
                    isLoaded: true,
                    juice: res.ok ? res.juice : null,
                });
            });

            if (store.extraJuices.page === 0 && !store.extraJuices.isExtraJuicesLoaded) {
                loadExtraJuices();
            }

            if (store.juicesVideos.page === 0 && !store.juicesVideos.isVideosLoaded) {
                loadVideos(store.juicesVideos.page);
            }

            api.students.getStudentQuizChart(req).then((res) => {
                dispatch(actions.student.setStudentQuizResults({
                    quizResults: res.ok ? res.data : [],
                }));
            });
        }
    }, [store.guardian]);

    useEffect(() => {
        am.setFiles({
            extraJuices: store.t2sExtraJuices,
            words: store.t2sWords,
        });
    }, [
        store.t2sExtraJuices,
        store.t2sWords,
    ]);

    const loadVideoById = (id) => {
        setVisibleExplorePopup(true);

        setExplorePopupVideo({
            isLoading: true,
            id,
            video: {},
        });

        api.videos.getJuiceVideo({
            videoId: id,
            session: store.session,
        }).then((res) => {
            setExplorePopupVideo((prev) => ({
                ...prev,
                isLoading: false,
                video: res.ok ? res.video : {},
            }));
        });
    };

    const closeWordPopup = () => {
        setWordPopup({
            isOpen: false,
            isLoading: false,
            word: "",
            errors: {},
        });
    };

    const onWordClick = (word) => {
        setWordPopup((prev) => ({
            ...prev,
            word,
            isOpen: true,
            isLoading: true,
        }));

        api.vocabulary.getWordByText({
            session: store.session,
            word,
        }).then((res) => {
            let wordValue = "";

            if (res.ok) {
                wordValue = res.word.word || "";

                dispatch(actions.vocabulary.setVocabularyWord({
                    word: res.word,
                    definitions: res.definitions,
                }));
            } else {
                setWordPopup((prev) => ({
                    ...prev,
                    errors: {
                        ...prev.errors,
                        [word]: "Cannot load word",
                    },
                }));
            }

            setWordPopup((prev) => ({
                ...prev,
                isLoading: false,
                word: wordValue,
            }));
        }).catch(() => {
            setWordPopup((prev) => ({
                isLoading: false,
                word: "",
                errors: {
                    ...prev.errors,
                    [word]: "Cannot load word",
                },
            }));
        });
    };

    const onShowExtraJuiceStoryPlayer = (extraJuiceId) => {
        dispatch(actions.t2s.setExtraJuiceLoading({
            extraJuiceId,
        }));

        setExplorePopupExtraJuices((prev) => ({
            ...prev,
            isVisiblePlayer: true,
        }));
    };

    const onHideExtraJuiceStoryPlayer = () => {
        setExplorePopupExtraJuices((prev) => ({
            ...prev,
            isVisiblePlayer: false,
        }));
    };

    const onLoadExtraJuiceStory = (extraJuiceId) => {
        api.t2s.juice({
            juiceId: null,
            extraJuiceId,
        }).then((res) => {
            if (res.ok) {
                am.playerPlayNext("extraJuices", extraJuiceId);

                dispatch(actions.t2s.setExtraJuice({
                    juiceId: null,
                    extraJuiceId,
                    audioFiles: res.audioFiles,
                }));
            }
        });
    };

    const onChangeGuardianStudent = (value) => {
        events.guardianHomeSelectStudent({
            session: store.session,
            studentId: value.value,
        });

        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value.value,
        }));

        setJuiceCarousel((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        setJuiceCarousel((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        dispatch(actions.guardian.setSelectedStudent({
            selectedStudent: value.value,
        }));
    };

    const onExploreViewMoreClick = () => {
        setVisibleExplorePopup(true);
    };

    const onCloseExplorePopup = () => {
        setVisibleExplorePopup(false);

        setExplorePopupVideo({
            id: null,
            isLoading: false,
            video: {},
        });

        setExplorePopupExtraJuices({
            id: null,
            isVisiblePlayer: false,
        });
    };

    const onExploreVideoClick = (videoId) => {
        loadVideoById(videoId);
    };

    const onExploreExtraJuiceClick = (extraJuiceId) => {
        setVisibleExplorePopup(true);

        setExplorePopupExtraJuices({
            id: extraJuiceId,
            isVisiblePlayer: false,
        });
    };

    const onExploreBackToList = () => {
        setExplorePopupExtraJuices({
            id: null,
            isVisiblePlayer: false,
        });

        setExplorePopupVideo({
            id: null,
            isLoading: false,
            video: {},
        });
    };

    const onLoadMoreExtraJuices = () => {
        loadExtraJuices(store.extraJuices.page + 1);
    };

    const onLoadMoreVideos = () => {
        loadVideos(store.juicesVideos.page + 1);
    };

    const getExtraJuiceById = (id) => {
        for (let i = 0; i < store.extraJuices.extraJuices.length; i += 1) {
            if (store.extraJuices.extraJuices[i].id === id) {
                return store.extraJuices.extraJuices[i];
            }
        }

        return null;
    };

    const getVideoById = (id) => {
        for (let i = 0; i < store.juicesVideos.videos.length; i += 1) {
            if (store.juicesVideos.videos[i].videoID === id) {
                let poster = null;

                if (store.juicesVideos.videos[i]?.featuredImage?.sizes?.medium?.src) {
                    poster = store.juicesVideos.videos[i].featuredImage.sizes.medium.src;
                }

                const captionSrc = api.videos.getVideoCaptionURL({
                    session: store.session,
                    id: store.juicesVideos.videos[i].videoID,
                });

                return {
                    id: store.juicesVideos.videos[i].videoID,
                    title: store.juicesVideos.videos[i].newsTitle,
                    poster,
                    captionSrc,
                };
            }
        }

        return null;
    };

    const renderTutorial = () => {
        if (hasGuardianRole) {
            return (
                <Tutorial name="guardian-index" />
            );
        }

        return (
            <Tutorial name="student-index" />
        );
    };

    const renderGuardianStudentsList = () => {
        if (!hasGuardianRole) {
            return null;
        }

        const studentsValues = [];

        store.guardian.students.forEach((student) => {
            studentsValues.push({ value: student.ID, label: student.fullName });
        });

        return (
            <div className={styles.guardianStudentsList}>
                <Dropdown
                    values={studentsValues}
                    selectedValue={store.guardian.selectedStudent}
                    dataComment="guardian-students-list"
                    onChange={onChangeGuardianStudent}
                />
            </div>
        );
    };

    const renderJuiceCard = () => {
        const stories = [];

        if (juiceCarousel.juice
            && juiceCarousel.juice.stories
            && juiceCarousel.juice.stories.juice
        ) {
            juiceCarousel.juice.stories.juice.forEach((juice) => {
                stories.push({
                    juiceId: juiceCarousel.juice.id,
                    date: juiceCarousel.juice.juiceDate,
                    featuredImage: juice.featuredImage,
                    backgroundImage: juice.backgroundImage,
                    title: juice.title,
                });
            });
        }

        return (
            <DailyJuiceCarousel
                items={stories}
            />
        );
    };

    const renderSponsor = () => {
        if (!store.isSponsorsLoaded) {
            return null;
        }

        return (
            <SectionSponsor
                sponsor={store.sponsors}
                onSponsorClick={() => {
                    // TODO:
                }}
            />
        );
    };

    const renderAnnouncement = () => {
        if (!store.student.isAnnouncementLoaded) {
            return null;
        }

        const cnt = store.student.announcement.content || "";
        const title = store.student.announcement.title || "";

        return (
            <SectionAnnouncement
                content={cnt}
                title={title}
            />
        );
    };

    const renderQuizResults = () => {
        return (
            <DailyJuiceQuizResults
                quizResults={store.student.quizResults}
                isMobile={store.dimensions.width < 430}
            />
        );
    };

    const renderExplorePopup = () => {
        if (!isVisibleExplorePopup) {
            return null;
        }

        let selectedExtraJuice = null;
        let selectedVideo = null;

        if (explorePopupExtraJuice.id) {
            selectedExtraJuice = getExtraJuiceById(explorePopupExtraJuice.id);
        }

        if (explorePopupVideo.id) {
            const sVideo = getVideoById(explorePopupVideo.id);
            selectedVideo = {
                ...sVideo,
                ...explorePopupVideo.video,
                isVideoLoading: explorePopupVideo.isLoading,
            };
        }

        const trackGroupName = "extraJuices";
        const trackId = explorePopupExtraJuice.id;

        let audioData = null;

        if (explorePopupExtraJuice.isVisiblePlayer) {
            audioData = (am.state[trackGroupName] || {})[trackId] || null;
        }

        return (
            <PopupDailyJuiceExplore
                extraJuices={store.extraJuices.extraJuices}
                videos={store.juicesVideos.videos}
                isDefaultVideo={!device.isChrome}
                selectedVideo={selectedVideo || {}}
                selectedExtraJuice={selectedExtraJuice || {}}
                showLoadMoreExtraJuices={store.extraJuices.hasMorePages}
                showLoadMoreVideos={store.juicesVideos.hasMorePages}
                isExtraJuicesLoading={store.extraJuices.isExtraJuicesLoading}
                isVideosLoading={store.juicesVideos.isVideosLoading}
                audio={audioData}
                onBackToList={onExploreBackToList}
                onExtraJuiceClick={onExploreExtraJuiceClick}
                onVideoClick={onExploreVideoClick}
                onLoadMoreVideos={onLoadMoreVideos}
                onLoadMoreExtraJuices={onLoadMoreExtraJuices}
                onWordClick={onWordClick}
                onAudioPlay={() => {
                    onShowExtraJuiceStoryPlayer(explorePopupExtraJuice.id);
                }}
                onFirstPlay={() => {
                    onLoadExtraJuiceStory(explorePopupExtraJuice.id);
                }}
                onPlay={() => {
                    am.playerPlay(trackGroupName, trackId);
                }}
                onPause={() => {
                    am.playerPause(trackGroupName, trackId);
                }}
                onRewind={() => {
                    am.playerRewind(trackGroupName, trackId);
                }}
                onForward={() => {
                    am.playerForward(trackGroupName, trackId);
                }}
                onChangeRate={(rate) => {
                    am.playerChangeRate(trackGroupName, trackId, rate);
                }}
                onPlayerClose={() => {
                    am.playerStop(trackGroupName, trackId);
                    onHideExtraJuiceStoryPlayer();
                }}
                onClose={onCloseExplorePopup}
            />
        );
    };

    const renderExplore = () => {
        let pageWidth = 0;

        if (store?.dimensions?.width) {
            pageWidth = store.dimensions.width;
        }

        return (
            <DailyJuiceExplore
                pageWidth={pageWidth}
                extraJuices={store.extraJuices.extraJuices}
                videos={store.juicesVideos.videos}
                isExtraJuicesLoaded={store.extraJuices.isExtraJuicesLoaded}
                isVideosLoaded={store.juicesVideos.isVideosLoaded}
                onViewMore={onExploreViewMoreClick}
                onVideoClick={onExploreVideoClick}
                onExtraJuiceClick={onExploreExtraJuiceClick}
            />
        );
    };

    const renderWordPopup = () => {
        if (!wordPopup.isOpen) {
            return null;
        }

        if (wordPopup.isLoading) {
            return (
                <PopupLoading
                    popupClasses={styles.fixedPopup}
                />
            );
        }

        const wordLowerCase = wordPopup.word.toLowerCase();

        let word = store.wordsByName[wordPopup.word];

        if (!word && store.wordsByName[wordLowerCase]) {
            word = store.wordsByName[wordLowerCase];
        }

        if (!word) {
            return (
                <PopupText
                    lines={["Unknown word"]}
                    popupClasses={styles.fixedPopup}
                    onClose={closeWordPopup}
                />
            );
        }

        const trackGroupName = "words";

        const audioData = am.state[trackGroupName] || {};

        return (
            <PopupWordCard
                word={word}
                audio={audioData}
                popupClasses={styles.fixedPopup}
                onLoad={(text) => {
                    am.playerPauseAll();

                    dispatch(actions.t2s.setWordLoading({
                        text,
                    }));

                    api.t2s.vocabulary({
                        session: store.session,
                        text,
                    }).then((res) => {
                        if (res.ok) {
                            am.playerPlayNext(trackGroupName, text);

                            dispatch(actions.t2s.setWord({
                                text,
                                audioFiles: [res] || [],
                            }));
                        }
                    });
                }}
                onPlay={(text) => {
                    am.playerPlay(trackGroupName, text);
                }}
                onStop={(text) => {
                    am.playerStop(trackGroupName, text);
                }}
                onStopAll={(words) => {
                    am.playerStopAll(trackGroupName, words);
                }}
                onClose={closeWordPopup}
                closeOnLayoutClick
            />
        );
    };

    if (!juiceCarousel.isLoaded) {
        return (
            <RequestLoader />
        );
    }

    const indexClassName = classNames({
        [styles.index]: true,
        [styles.indexWithGuardian]: hasGuardianRole,
    });

    return (
        <>
            {renderTutorial()}
            {renderExplorePopup()}
            {renderWordPopup()}

            <div className={indexClassName}>
                {renderGuardianStudentsList()}

                <div className={styles.juiceCarousel}>
                    {renderJuiceCard()}
                </div>

                <div className={styles.exploreSection}>
                    {renderExplore()}
                </div>

                <div className={styles.asideWidgets}>
                    {renderSponsor()}

                    {renderAnnouncement()}

                    {renderQuizResults()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};


export default withAuth([
    "student",
    "guardian",
])(StudentIndexRegular);
