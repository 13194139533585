import React from "react";

import SectionTeacher from "../section-teacher/index.js";

import styles from "./styles.module.css";


const DailyJuiceLinks = (props) => {
    const { links } = props;

    const renderLinks = () => {
        return links.map((link) => (
            <div
                className={styles.quickLink}
                onClick={link.callback}
                onKeyPress={link.callback}
                role="button"
                tabIndex="-1"
                data-comment={link.dataComment || ""}
            >
                {link.name}
            </div>
        ));
    };

    return (
        <SectionTeacher>
            <div className={styles.dailyJuiceLinks}>
                <div className={styles.dailyJuiceTitle}>
                    <div className={styles.dailyJuiceTitleName}>
                        Quick Link
                    </div>
                </div>
                <div className={styles.quickLinks}>
                    {renderLinks()}
                </div>
            </div>
        </SectionTeacher>
    );
};

DailyJuiceLinks.defaultProps = {
    links: [],
};

export default DailyJuiceLinks;
