import React from "react";
import { Link } from "react-router-dom";

import staticFiles from "juice-base/static-files.js";
import User from "juice-base/project/user.js";
import CssIcon from "juice-base/components/css-icon/index.js";

import styles from "./styles.module.css";


const AsideNav = (props) => {
    const {
        name,
        version,
        user,
        sectionsByRoles,
        onClose,
    } = props;

    const onSignOut = () => {
        props.onClose();
        props.onSignOut();
    };

    const renderLink = (to, linkName) => {
        return (
            <Link
                key={linkName}
                to={to}
                className={styles.section}
                onClick={() => onClose()}
            >
                {linkName}
            </Link>
        );
    };

    const renderLinkWithCssIcon = (to, linkName, iconUrl, inNewTab = false) => {
        let icon = null;

        if (iconUrl) {
            icon = (
                <CssIcon
                    url={iconUrl}
                    name={linkName}
                />
            );
        }

        if (inNewTab) {
            return (
                <a
                    className={styles.sectionWithIcon}
                    href={to}
                    key={linkName}
                    target="_blank"
                    rel="noreferrer"
                >
                    {icon}
                    {linkName}
                </a>
            );
        }

        return (
            <Link
                key={linkName}
                to={to}
                className={styles.sectionWithIcon}
                onClick={onClose}
            >
                {icon}
                {linkName}
            </Link>
        );
    };

    const renderSections = () => {
        if (!user.userId) {
            return renderLink("/", "Home");
        }

        let sections = [];

        if (User.isTypeRegular(user)) {
            if (User.hasRoleTeacher(user)) {
                const ss = sectionsByRoles.regular.teacher || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            } else if (User.hasRoleStudent(user)) {
                const ss = sectionsByRoles.regular.student || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            } else if (User.hasRoleGuardian(user)) {
                const ss = sectionsByRoles.regular.guardian || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            }
        } else if (User.isTypeTrial(user)) {
            if (User.hasRoleStudent(user)) {
                const ss = sectionsByRoles.trial.student || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            }
        } else if (User.isTypeHome(user)) {
            if (User.hasRoleGuardian(user)) {
                const ss = sectionsByRoles.home.guardian || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            } else if (User.hasRoleStudent(user)) {
                const ss = sectionsByRoles.home.student || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            } else if (User.hasRoleTeacher(user)) {
                const ss = sectionsByRoles.home.teacher || [];
                sections = ss.map((sec) => renderLink(sec.to, sec.name));
            }
        }

        return (
            <>
                {renderLink("/", "Home")}
                {sections}
            </>
        );
    };

    const renderUser = () => {
        const supportLink = renderLinkWithCssIcon(props.supportLink, "Support", staticFiles.iconQuestionMark, true);

        if (!user.userId) {
            return (
                <>
                    {renderLinkWithCssIcon("/user/sign-in", "Sign In", staticFiles.iconInfo)}
                    {supportLink}
                </>
            );
        }

        return (
            <>
                {renderLinkWithCssIcon("/user/profile", user.fullName, staticFiles.iconUser)}
                {supportLink}
                <div
                    className={[styles.sectionWithIcon, styles.signout].join(" ")}
                    onClick={onSignOut}
                    onKeyPress={onSignOut}
                    tabIndex="-1"
                    role="button"
                >
                    <CssIcon
                        url={staticFiles.iconLogOut}
                        name="Sign Out"
                    />
                    Sign Out
                </div>
            </>
        );
    };

    /* eslint-disable */
    const layout = (
        <div
            className={styles.asideLayout}
            onClick={onClose}
            onKeyPress={onClose}
            tabIndex="-1"
            role="button"
        />
    );
    /* eslint-enable */

    return (
        <div className={styles.asideNav}>
            {layout}
            <aside className={styles.aside}>
                <div className={styles.header}>
                    <div className={styles.headerName}>
                        <img
                            src={staticFiles.juiceHomeLogo}
                            alt={name}
                            title={name}
                            className={styles.logoImg}
                        />
                    </div>

                    <CssIcon
                        url={staticFiles.iconClose}
                        name="Close"
                        className={styles.close}
                        onClick={onClose}
                    />
                </div>

                <div className={styles.sections}>
                    <div className={styles.sectionGroup}>
                        {renderSections()}
                    </div>
                    <div className={styles.sectionGroup}>
                        {renderUser()}
                    </div>
                </div>

                <div className={styles.sysinfo}>
                    {`Build: ${version}`}
                </div>
            </aside>
        </div>
    );
};

AsideNav.defaultProps = {
    name: "",
    version: "",
    user: {},
    sectionsByRoles: {},
    supportLink: "",
    onSignOut: () => {},
    onClose: () => {},
};

export default AsideNav;
