import React from "react";

import styles from "./styles.module.css";


const SectionTeacher = (props) => {
    const sectionClasses = [
        styles.section,
    ];

    if (props.className) {
        sectionClasses.push(props.className);
    }

    return (
        <section
            data-comment={props.dataComment}
            className={sectionClasses.join(" ")}
        >
            {props.children}
        </section>
    );
};

SectionTeacher.defaultProps = {
    className: "",
    dataComment: "section-teacher",
    children: null,
};

export default SectionTeacher;
