// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oLCwOxCgiBnphE0sYZ6JV {\n    display: grid;\n    justify-content: center;\n}\n\n@media only screen and (min-width: 600px) {\n    .oLCwOxCgiBnphE0sYZ6JV {\n        grid-template-columns: 60rem;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .oLCwOxCgiBnphE0sYZ6JV {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/story/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".story {\n    display: grid;\n    justify-content: center;\n}\n\n@media only screen and (min-width: 600px) {\n    .story {\n        grid-template-columns: 60rem;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .story {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"story": "oLCwOxCgiBnphE0sYZ6JV"
};
export default ___CSS_LOADER_EXPORT___;
