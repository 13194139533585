export default (apiURL, methods) => {
    return {
        checkRegistrationCode(params = {}) {
            // NOTE: action=validation_code_get
            const url = `${apiURL}/user/registration-code`;

            return methods.get3(url, {
                secret: params.secret,
            });
        },

        confirmRegistration(params = {}) {
            // NOTE: action=user_set_password
            const url = `${apiURL}/user/password/confirm`;

            return methods.post(url, {
                secret: params.secret,
                password: params.password,
            });
        },

        /* --- */

        signIn(params = {}) {
            // NOTE: action=get_session
            const url = `${apiURL}/session`;

            return methods.post(url, {
                username: params.username,
                password: params.password,
            });
        },

        signOut(params = {}) {
            // NOTE: action=kill_session
            const url = `${apiURL}/session`;

            return methods.del(url, {
                session_id: params.session,
            });
        },

        checkSession(params = {}) {
            // NOTE: action=check_session
            const url = `${apiURL}/session`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        changePassword(params = {}) {
            // NOTE: action=user_update_password
            const url = `${apiURL}/user/password`;

            return methods.post(url, {
                session_id: params.session,
                password: params.password,
            });
        },

        resetPassword(params = {}) {
            // NOTE: action=user_reset_password
            const url = `${apiURL}/user/password/reset`;

            return methods.get(url, {
                username: params.username,
            });
        },

        /* --- */

        getOptions(params = {}) {
            // NOTE: action=get_options
            const url = `${apiURL}/user/options`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getOption(params = {}) {
            // NOTE: action=get_option
            const url = `${apiURL}/user/options/${params.key}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        setOption(params = {}) {
            // NOTE: action=get_option
            const url = `${apiURL}/user/options/${params.key}/set`;

            return methods.get(url, {
                session_id: params.session,
                value: params.value,
            });
        },
    };
};
