// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._31wM-8zYLM7RvoD__REWSE {\n    padding: 0 2rem 2rem 2rem;\n\n    display: grid;\n}\n\n._3AeFUkUlEj79eNyvgxctkF {\n    position: fixed;\n}\n\n@media only screen and (min-width: 655px) {\n    ._31wM-8zYLM7RvoD__REWSE {\n        justify-content: center;\n        grid-template-columns: 65rem;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    ._31wM-8zYLM7RvoD__REWSE {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/containers/student-daily-juices-regular/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;;IAEzB,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,uBAAuB;QACvB,4BAA4B;IAChC;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".index {\n    padding: 0 2rem 2rem 2rem;\n\n    display: grid;\n}\n\n.fixedPopup {\n    position: fixed;\n}\n\n@media only screen and (min-width: 655px) {\n    .index {\n        justify-content: center;\n        grid-template-columns: 65rem;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .index {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "_31wM-8zYLM7RvoD__REWSE",
	"fixedPopup": "_3AeFUkUlEj79eNyvgxctkF"
};
export default ___CSS_LOADER_EXPORT___;
