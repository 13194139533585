import React from "react";

import styles from "./styles.module.css";


const PopupFullScreenMenu = (props) => {
    return (
        <div className={styles.menu}>
            {props.children}
        </div>
    );
};

PopupFullScreenMenu.defaultProps = {
    children: null,
};

export default PopupFullScreenMenu;
