import {
    SET_TEACHER_SELECTED_CLASS,
    SET_TEACHER_STATS_DATE,
    SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS,
    SET_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING,
    DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD,
    CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS,
    DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID,
} from "./types.js";


const initialState = {
    selectedClassId: -1,
    statsDate: null,

    isStudentsWithPendingStatusLoaded: false,
    isStudentsWithPendingStatusLoading: false,
    studentsWithPendingStatus: [],
    studentsWithPendingStatusPage: 0,
    studentsWithPendingStatusHasMorePages: false,

    isStudentsWithForgottenPasswordsLoaded: false,
    studentsWithForgottenPasswords: [],
};

export default (state, action) => {
    switch (action.type) {
        case SET_TEACHER_SELECTED_CLASS: {
            return {
                ...state,
                selectedClassId: action.payload.selectedClassId || -1,
            };
        }

        case SET_TEACHER_STATS_DATE: {
            return {
                ...state,
                statsDate: action.payload.statsDate || null,
            };
        }

        case SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS: {
            return {
                ...state,
                isStudentsWithForgottenPasswordsLoaded: true,
                studentsWithForgottenPasswords: action.payload.students || [],
            };
        }

        case SET_TEACHER_STUDENTS_WITH_PENDING_STATUS: {
            return {
                ...state,
                isStudentsWithPendingStatusLoaded: true,
                isStudentsWithPendingStatusLoading: false,
                studentsWithPendingStatus: action.payload.students || [],
                studentsWithPendingStatusPage: action.payload.page || 0,
                studentsWithPendingStatusHasMorePages: action.payload.hasMore || false,
            };
        }

        case SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING: {
            return {
                ...state,
                isStudentsWithPendingStatusLoading: true,
            };
        }

        case CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS: {
            return {
                ...state,
                isStudentsWithPendingStatusLoaded: false,
                isStudentsWithPendingStatusLoading: false,
                studentsWithPendingStatus: [],
                studentsWithPendingStatusPage: 0,
                studentsWithPendingStatusHasMorePages: false,
            };
        }

        case DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID: {
            const newStudents = state.studentsWithPendingStatus
                .filter((s) => s.id !== action.payload.studentId);

            return {
                ...state,
                studentsWithPendingStatus: newStudents,
            };
        }

        case DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD: {
            const { studentId } = action.payload;

            if (!studentId) {
                return state;
            }

            const newStudents = state.studentsWithForgottenPasswords
                .filter((s) => s.id !== studentId);

            return {
                ...state,
                isStudentsWithForgottenPasswordsLoaded: true,
                studentsWithForgottenPasswords: newStudents,
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};
