export const SET_TEACHER_SELECTED_CLASS = "SET_TEACHER_SELECTED_CLASS";
export const SET_TEACHER_STATS_DATE = "SET_TEACHER_STATS_DATE";

export const SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS = "SET_TEACHER_STUDENTS_WITH_FORGOTTEN_PASSWORDS";
export const DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD = "DELETE_TEACHER_STUDENT_WITH_FORGOTTEN_PASSWORD";

export const SET_TEACHER_STUDENTS_WITH_PENDING_STATUS = "SET_TEACHER_STUDENTS_WITH_PENDING_STATUS";
export const SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING = "SET_TEACHER_STUDENTS_WITH_PENDING_STATUS_LOADING";
export const CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS = "CLEAR_TEACHER_STUDENTS_WITH_PENDING_STATUS";
export const DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID = "DELETE_TEACHER_STUDENT_WITH_PENDING_STATUS_BY_ID";
