import {
    applyMiddleware,
    combineReducers,
    createStore,
} from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import app from "./app/reducer.js";
import device from "./device/reducer.js";
import info from "./info/reducer.js";
import navigation from "./navigation/reducer.js";
import user from "./user/reducer.js";
import templates from "./templates/reducer.js";
import tutorials from "./tutorials/reducer.js";
import juices from "./juices/reducer.js";
import juiceStories from "./juice-stories/reducer.js";
import vocabulary from "./vocabulary/reducer.js";
import extraJuices from "./extra-juices/reducer.js";
import juicesVideos from "./juices-videos/reducer.js";
import guardian from "./guardian/reducer.js";
import student from "./student/reducer.js";
import teacher from "./teacher/reducer.js";
import studentTodo from "./student-todo/reducer.js";
import text2speech from "./text2speech/reducer.js";


const configureStore = () => {
    const rootReducer = combineReducers({
        app,
        device,
        navigation,
        user,
        templates,
        tutorials,
        juices,
        juiceStories,
        vocabulary,
        extraJuices,
        juicesVideos,
        info,
        guardian,
        student,
        teacher,
        studentTodo,
        text2speech,
    });

    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const initialState = {};
    return createStore(rootReducer, initialState, composedEnhancers);
};

export default configureStore;
