import React from "react";

import { Formik } from "formik";

import { hasEmptyValue } from "juice-base/lib/object.js";
import * as v from "juice-base/lib/form-validators.js";

import Input from "juice-base/components/forms/input/index.js";
import ButtonBig from "juice-base/components/button-big/index.js";

import styles from "./styles.module.css";


const UserSignUpClassCodeForm = (props) => {
    const isDisabled = (formProps) => {
        const hasErrors = Object.keys(formProps.errors).length > 0;

        return hasEmptyValue(formProps.values)
            || hasErrors
            || formProps.isSubmitting;
    };

    const validateForm = (values) => {
        const errorsCode = v.validate(values.code, [
            v.required("Please enter class code"),
        ]);

        const errors = {};

        if (errorsCode) {
            errors.code = errorsCode;
        }

        return errors;
    };

    const renderForm = (formProps) => {
        const {
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
        } = formProps;

        return (
            <form onSubmit={handleSubmit} className={styles.form}>
                <Input
                    name="code"
                    label=""
                    placeholder="Class Code *"
                    value={values.code}
                    error={errors.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <div className={styles.submitButtonBlock}>
                    <ButtonBig
                        type="submit"
                        disabled={isDisabled(formProps)}
                    >
                        Next
                    </ButtonBig>
                </div>
            </form>
        );
    };

    return (
        <Formik
            initialValues={props.initialValues}
            initialErrors={props.initialErrors}
            validate={validateForm}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

UserSignUpClassCodeForm.defaultProps = {
    initialValues: {},
    initialErrors: {},
    onSubmit: () => {},
};

export default UserSignUpClassCodeForm;
