import React from "react";

import styles from "./styles.module.css";


const Icon = (props) => {
    const {
        url,
        name,
        className,
        withName,
        onClick,
    } = props;

    if (!url || !name) {
        return null;
    }

    let iconName = null;

    if (withName) {
        iconName = (
            <div className={styles.iconName}>
                {name}
            </div>
        );
    }

    return (
        <div
            onClick={onClick}
            onKeyPress={onClick}
            tabIndex="-1"
            role="button"
            className={[
                styles.container,
                className,
            ].join(" ")}
        >
            <img
                src={url}
                title={name}
                alt={name}
                className={styles.img}
            />
            {iconName}
        </div>
    );
};

Icon.defaultProps = {
    url: "",
    name: "",
    className: "",
    withName: false,
    onClick: () => { },
};

export default Icon;
