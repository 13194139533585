import React from "react";
import { useSelector } from "react-redux";

import User from "juice-base/project/user.js";

import { withAuth } from "juice-base/components/auth/index.js";

import StudentDailyJuicesRegular from "juice-app/containers/student-daily-juices-regular/index.js";
import StudentDailyJuicesTrial from "juice-app/containers/student-daily-juices-trial/index.js";

import TeacherDailyJuices from "juice-app/containers/teacher-daily-juices/index.js";

import GuardianDailyJuices from "juice-app/containers/guardian-daily-juices/index.js";


const DailyJuices = () => {
    const {
        isUserLoading,
        user,
    } = useSelector((state) => ({
        isUserLoading: state.user.isUserLoading,
        user: state.user.user,
    }));

    if (isUserLoading) {
        return null;
    }

    if (User.hasRoleTeacher(user)) {
        return (
            <TeacherDailyJuices />
        );
    }

    if (User.hasRoleStudent(user)) {
        if (User.isTypeRegular(user) || User.isTypeHome(user)) {
            return (
                <StudentDailyJuicesRegular />
            );
        }

        if (User.isTypeTrial(user)) {
            return (
                <StudentDailyJuicesTrial />
            );
        }
    }

    if (User.hasRoleGuardian(user)) {
        return (
            <GuardianDailyJuices />
        );
    }

    return null;
};

export default withAuth([
    "teacher",
    "student",
    "guardian",
])(DailyJuices);
