import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import configureStore from "juice-base/store/index.js";
import configureRoutes from "./routes/index.js";
import { preInit, postInit } from "./init.js";

import "./index.css";


const main = () => {
    const history = createBrowserHistory();
    const store = configureStore(history);
    const routes = configureRoutes(store);

    preInit(store);

    const rootElement = document.getElementById("app");

    ReactDOM.render(routes, rootElement, () => {
        postInit();
    });
};

main();
