import React, { useEffect, useRef } from "react";

import scroll from "juice-base/lib/scroll.js";
import classNames from "juice-base/lib/class-names.js";

import Content from "./content.js";
import Icon from "./icon.js";
import Buttons from "./buttons.js";
import styles from "./styles.module.css";


const PopupConfirm = (props) => {
    const bgLayout = useRef(null);

    useEffect(() => {
        scroll.hideBodyScroll();

        return () => {
            scroll.unhideBodyScroll();
        };
    }, []);

    const onClose = (evt) => {
        if (props.closeOnLayoutClick && bgLayout?.current === evt.target) {
            props.onClose();
        }
    };

    const popupWindowClassName = classNames({
        [styles.popupWindow]: true,
        [props.popupWindowClassName]: props.popupWindowClassName,
    });

    return (
        <div
            data-comment="popup-confirm"
            ref={bgLayout}
            className={styles.popup}
            onClick={onClose}
            onKeyPress={onClose}
            role="button"
            tabIndex="-1"
        >
            <div className={popupWindowClassName}>
                {props.children}
            </div>
        </div>
    );
};

PopupConfirm.defaultProps = {
    closeOnLayoutClick: false,
    popupWindowClassName: "",
    children: null,
    onClose: () => {},
};


export const PopupConfirmIcon = Icon;
export const PopupConfirmContent = Content;
export const PopupConfirmButtons = Buttons;
export default PopupConfirm;
