// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2xyoOiTkUeaX3Onc9byrPQ {}\n\n._2xyoOiTkUeaX3Onc9byrPQ p {\n    margin: 0;\n    padding: 0;\n}\n\n._2xyoOiTkUeaX3Onc9byrPQ ul,\n._2xyoOiTkUeaX3Onc9byrPQ ol {\n    margin: 1rem 1.5rem;\n    padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/text/styles.module.css"],"names":[],"mappings":"AAAA,0BAAO;;AAEP;IACI,SAAS;IACT,UAAU;AACd;;AAEA;;IAEI,mBAAmB;IACnB,UAAU;AACd","sourcesContent":[".text {}\n\n.text p {\n    margin: 0;\n    padding: 0;\n}\n\n.text ul,\n.text ol {\n    margin: 1rem 1.5rem;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": "_2xyoOiTkUeaX3Onc9byrPQ"
};
export default ___CSS_LOADER_EXPORT___;
