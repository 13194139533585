// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1qDeovQIKSqtW2ECw-DMJK {\n    padding: 2rem;\n\n    background-color: #fff;\n\n    border: 0.1rem solid #c5c5c5;\n    border-radius: 1rem;\n}\n\n._34fxS5krq9nK0c-GiZDana {\n    margin-bottom: 2rem;\n\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n._38dchZhMppIfJgyMJqhUoy {\n    margin-top: 2.5rem;\n    display: grid;\n}\n", "",{"version":3,"sources":["webpack://./base/forms/user-password-change/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,sBAAsB;;IAEtB,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;;IAEnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".form {\n    padding: 2rem;\n\n    background-color: #fff;\n\n    border: 0.1rem solid #c5c5c5;\n    border-radius: 1rem;\n}\n\n.header {\n    margin-bottom: 2rem;\n\n    font-size: 2rem;\n    font-weight: 600;\n}\n\n.submitButtonBlock {\n    margin-top: 2.5rem;\n    display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "_1qDeovQIKSqtW2ECw-DMJK",
	"header": "_34fxS5krq9nK0c-GiZDana",
	"submitButtonBlock": "_38dchZhMppIfJgyMJqhUoy"
};
export default ___CSS_LOADER_EXPORT___;
