// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Br9NWt-yLazxxT7SNPbzZ {\n    background: white;\n\n    border-radius: 1rem;\n\n    min-width: 25rem;\n    max-width: 41rem;\n\n    overflow: hidden;\n}\n\n\n._3XNZDDOqQDm45IxSY78Fl7 {\n\n}\n\n._28Qx6745MoaHS5JwIE3VJf {\n    display: grid;\n    grid-gap: 1rem;\n\n    padding: 2rem;\n\n    max-height: 50vh;\n    min-height: 10rem;\n    overflow: auto;\n}\n\n._2mGmVjoBR-ieegzCoQBVsF {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.AFwKpKYNr_89PTajfWowc {\n    text-align: center;\n\n    margin: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-add-students-progress/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;IAEjB,mBAAmB;;IAEnB,gBAAgB;IAChB,gBAAgB;;IAEhB,gBAAgB;AACpB;;;AAGA;;AAEA;;AAEA;IACI,aAAa;IACb,cAAc;;IAEd,aAAa;;IAEb,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;;IAEd,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;;IAElB,YAAY;AAChB","sourcesContent":[".popup {\n    background: white;\n\n    border-radius: 1rem;\n\n    min-width: 25rem;\n    max-width: 41rem;\n\n    overflow: hidden;\n}\n\n\n.content {\n\n}\n\n.studentsList {\n    display: grid;\n    grid-gap: 1rem;\n\n    padding: 2rem;\n\n    max-height: 50vh;\n    min-height: 10rem;\n    overflow: auto;\n}\n\n.studentRow {\n    display: grid;\n    grid-auto-flow: column;\n    grid-gap: 1rem;\n\n    align-items: center;\n    justify-content: space-between;\n}\n\n.progress {\n    text-align: center;\n\n    margin: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "Br9NWt-yLazxxT7SNPbzZ",
	"content": "_3XNZDDOqQDm45IxSY78Fl7",
	"studentsList": "_28Qx6745MoaHS5JwIE3VJf",
	"studentRow": "_2mGmVjoBR-ieegzCoQBVsF",
	"progress": "AFwKpKYNr_89PTajfWowc"
};
export default ___CSS_LOADER_EXPORT___;
