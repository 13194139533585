// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6hvL52zfTpN_J5xFCq470 {\n    margin: 0;\n    padding: 3rem 2rem;\n}\n\n._2vM5pwlNzr-MwY1oTjCoG {\n    margin: 3rem 0;\n    font-weight: 600;\n}\n\n.bbC8Tg4cJ1h49U-iDhISK {\n    color: #ec407a;\n    font-family: 'Grifter-Bold';\n    font-size: 1.2rem;\n    font-weight: 600;\n    text-transform: uppercase;\n}\n\n@media only screen and (min-width: 621px) {\n    ._6hvL52zfTpN_J5xFCq470 {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/quiz-question/styles.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI;QACI,eAAe;QACf,gBAAgB;IACpB;AACJ","sourcesContent":[".quizQuestion {\n    margin: 0;\n    padding: 3rem 2rem;\n}\n\n.question {\n    margin: 3rem 0;\n    font-weight: 600;\n}\n\n.header {\n    color: #ec407a;\n    font-family: 'Grifter-Bold';\n    font-size: 1.2rem;\n    font-weight: 600;\n    text-transform: uppercase;\n}\n\n@media only screen and (min-width: 621px) {\n    .quizQuestion {\n        padding-left: 0;\n        padding-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quizQuestion": "_6hvL52zfTpN_J5xFCq470",
	"question": "_2vM5pwlNzr-MwY1oTjCoG",
	"header": "bbC8Tg4cJ1h49U-iDhISK"
};
export default ___CSS_LOADER_EXPORT___;
