import React from "react";

import styles from "./styles.module.css";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error) {
        console.error(error); // eslint-disable-line no-console
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.error}>
                    Error!
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
