import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import { withAuth } from "juice-base/components/auth/index.js";
import actions from "juice-base/store/actions.js";

import { getDateFromDate, getDatesByRange } from "juice-base/lib/date.js";

import User from "juice-base/project/user.js";
import * as Students from "juice-base/project/students.js";
import { getGradeGroup } from "juice-base/project/grades.js";

import TableStudents from "juice-base/components/table-students/index.js";
import StudentInfo from "juice-base/components/student-info/index.js";

import PopupGradeChange from "juice-base/components/popup-grade-change/index.js";
import PopupNameChange from "juice-base/components/popup-name-change/index.js";
import PopupEmailChange from "juice-base/components/popup-email-change/index.js";
import PopupAddStudentsProgress from "juice-base/components/popup-add-students-progress/index.js";
import PopupNewPassword from "juice-base/components/popup-new-password/index.js";
import PopupResetPassword from "juice-base/components/popup-reset-password/index.js";
import PopupConfirmDeleteStudent from "juice-base/business/popup-confirm-delete-student/index.js";
import PopupFullScreenAddStudent from "juice-base/business/popup-full-screen-add-student/index.js";

import UserFooter from "juice-app/containers/user-footer/index.js";

import settings from "juice-app/settings.js";
import api from "juice-app/api.js";
import * as events from "juice-base/events.js";

import styles from "./styles.module.css";


const getStudentPopupState = () => ({
    isOpen: false,
    isLoaded: false,
    userId: -1,
    user: {},
});


const getAddStudentProgressState = () => ({
    inProgress: false,
    totalLoaded: 0,
    students: [],
    currentLoading: {
        index: null,
        errorCode: -1,
    },
});


const Student = () => {
    const defaultDropdownRange = "today";

    const [selectedSortByValue, setSelectedSortByValue] = useState(() => {
        const sValues = Students.getSortValues();

        if (sValues && sValues.length > 0) {
            return sValues[0].value;
        }

        return "";
    });

    const [dateRange, setDateRange] = useState({
        dropdownRange: defaultDropdownRange,
        startDate: null,
        endDate: null,
    });

    const [tableState, setTableState] = useState({
        isLoaded: false,
        isOverview: false,
        message: null,
        studentsClassId: null,
        students: [],
    });

    const [isVisibleAddStudentWindow, setVisibleAddStudentWindow] = useState(false);
    const [isVisibleAddStudentsPopup, setVisibleAddStudentsPopup] = useState(false);

    const [
        addStudentsProgressState,
        setAddStudentsProgressState,
    ] = useState(() => getAddStudentProgressState());

    const [studentInfoPopup, setStudentInfoPopup] = useState(() => getStudentPopupState());

    const [nameChangePopupState, setNameChangePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [emailChangePopupState, setEmailChangePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [changeGradePopupState, setChangeGradePopupState] = useState({
        isOpen: false,
        isSaving: false,
        error: null,
    });

    const [deleteStudentPopup, setDeleteStudentPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        id: -1,
    });

    const [resetStudentPasswordPopup, setResetStudentPasswordPopup] = useState({
        isOpen: false,
        isLoading: false,
        message: null,
    });

    const [deleteSelectedUsersPopup, setDeleteSelectedUsersPopup] = useState({
        isOpen: false,
        isLoading: false,
        error: null,
        students: [],
    });

    const [newPasswordPopupState, setNewPasswordPopupState] = useState({
        isOpen: false,
        isSubmitted: false,
        isLoading: false,
        message: null,
        id: null,
    });

    const [importStudentsTemplateUrl, setImportStudentsTemplateUrl] = useState(null);

    const store = useSelector((state) => ({
        user: state.user.user,
        session: state.user.session,
        guardian: state.guardian,
        dimensions: state.device.dimensions,
        juices: state.juices,
        teacher: state.teacher,
    }));

    let sortedStudents = tableState.students.filter((student) => {
        return Students.isStatusActive(student);
    });

    sortedStudents = Students.sortBy(selectedSortByValue, sortedStudents, tableState.isOverview);

    const dispatch = useDispatch();

    /* ------ */

    const loadImportStudentsTemplate = () => {
        api.students.getImportStudentsTemplate({
            session: store.session,
        }).then((res) => {
            if (res.ok) {
                setImportStudentsTemplateUrl(res.templateLink);
            }
        });
    };

    const loadGuardianStudents = (dropdownRange, customDates = null) => {
        setDateRange((prev) => ({
            ...prev,
            dropdownRange,
            startDate: customDates ? customDates.dateFrom : null,
            endDate: customDates ? customDates.dateTo : null,
        }));

        setTableState((prev) => ({
            ...prev,
            isLoaded: false,
            isOverview: false,
            message: null,
            students: [],
        }));

        if (customDates?.dateFrom && customDates?.dateTo) {
            const dateFrom = customDates.dateFrom.format("YYYY-MM-DD");
            const dateTo = customDates.dateTo.format("YYYY-MM-DD");

            api.students.getStudentsStatsByGuardian({
                session: store.session,
                dateFrom,
                dateTo,
            }).then((res) => {
                const newStudents = res.ok ? res.data.students : [];
                const isOverview = res.ok ? res.data.isOverview : false;
                const message = res.ok ? null : res.error;
                const studentsClassId = res.ok ? res.data.classId : null;

                setTableState((prev) => ({
                    ...prev,
                    isLoaded: true,
                    isOverview,
                    message,
                    studentsClassId: studentsClassId || null,
                    students: newStudents,
                }));
            });
        } else {
            api.site.getSiteDate().then((res) => {
                const siteDate = res.ok ? res.date : null;

                const dates = getDatesByRange(siteDate, dropdownRange);

                let { dateFrom, dateTo } = dates;

                if (dateFrom !== "all") {
                    dateFrom = getDateFromDate(dateFrom);
                }

                if (dateTo !== "") {
                    dateTo = getDateFromDate(dateTo);
                }

                if (dateFrom !== "all" && dateTo !== "") {
                    setDateRange((prev) => ({
                        ...prev,
                        startDate: moment(dateFrom),
                        endDate: moment(dateTo),
                    }));
                }

                api.students.getStudentsStatsByGuardian({
                    session: store.session,
                    dateFrom,
                    dateTo,
                }).then((sRes) => {
                    const newStudents = sRes.ok ? sRes.data.students : [];
                    const isOverview = sRes.ok ? sRes.data.isOverview : false;
                    const studentsClassId = sRes.ok ? sRes.data.classId : null;

                    setTableState({
                        isLoaded: true,
                        isOverview,
                        message: sRes.ok ? null : sRes.error,
                        studentsClassId: studentsClassId || null,
                        students: newStudents,
                    });
                });
            });
        }
    };

    const loadStudentInfo = (studentId) => {
        setStudentInfoPopup((prev) => ({
            ...prev,
            isLoaded: false,
            isOpen: true,
            userId: studentId,
            user: {},
        }));

        api.students.getStudentInfo({
            session: store.session,
            studentId,
        }).then((res) => {
            setStudentInfoPopup((prev) => ({
                ...prev,
                isLoaded: true,
                user: res.ok ? res.student : {},
            }));
        });
    };

    const onCloseAllChangingDataPopups = () => {
        setNameChangePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });

        setEmailChangePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });

        setChangeGradePopupState({
            isOpen: false,
            isSaving: false,
            error: null,
        });
    };

    const onUpdateStudentInfo = (params) => {
        events.guardianStudentEditStudent({
            session: store.session,
            studentId: studentInfoPopup.userId,
        });

        if (params.newFullname) {
            setNameChangePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        } else if (params.newEmail) {
            setEmailChangePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        } else if (params.newGrade) {
            setChangeGradePopupState((prev) => ({
                ...prev,
                isSaving: true,
                error: null,
            }));
        }

        api.students.updateStudentDataV2({
            session: store.session,
            studentId: studentInfoPopup.userId,

            fullname: params.newFullname || "",
            email: params.newEmail || "",
            grade: params.newGrade || "",
        }).then((res) => {
            if (res.ok) {
                onCloseAllChangingDataPopups();

                loadStudentInfo(studentInfoPopup.userId);

                const range = {
                    dateFrom: dateRange.startDate,
                    dateTo: dateRange.endDate,
                };

                loadGuardianStudents(dateRange.dropdownRange, range);
            } else {
                const error = res.error || "Error!";

                if (params.newFullname) {
                    setNameChangePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                } else if (params.newEmail) {
                    setEmailChangePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                } else if (params.newGrade) {
                    setChangeGradePopupState((prev) => ({
                        ...prev,
                        isSaving: false,
                        error,
                    }));
                }
            }
        });
    };

    useEffect(() => {
        if (store.session) {
            const range = {
                dateFrom: dateRange.startDate,
                dateTo: dateRange.endDate,
            };

            loadGuardianStudents(dateRange.dropdownRange, range);
        }
    }, [store.session]);

    const onDateRangeChange = (values) => {
        if (values.dropdown && (!values.startDate && !values.endDate)) {
            loadGuardianStudents(values.dropdown.value);
        } else if (values.startDate && values.endDate) {
            const range = {
                dateFrom: values.startDate,
                dateTo: values.endDate,
            };

            loadGuardianStudents("custom", range);
        }
    };

    const loadStudentDailyJuices = (studentId, clearJuices = true) => {
        if (clearJuices) {
            dispatch(actions.juices.clearStudentJuicesById({
                studentId,
            }));
        }

        dispatch(actions.juices.setStudentJuicesByIdLoading({
            studentId,
        }));

        let page = 0;

        if (!clearJuices && store.juices?.studentJuicesById[studentId]?.page) {
            page = store.juices.studentJuicesById[studentId].page;
        }

        api.students.getStudentDailyJuicesResultsByPage({
            session: store.session,
            studentId,
            page,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.juices.setStudentJuicesById({
                    studentId,
                    juices: res.juices,
                    hasMore: res.hasMore,
                    page: page + 1,
                }));
            }
        });
    };

    const onAddStudentsToClass = (idx, students, addNewOnDuplicate = false) => {
        if (!students[idx]) {
            setAddStudentsProgressState((prev) => ({
                ...prev,
                inProgress: false,
            }));

            return;
        }

        const newStudents = [
            ...students,
        ];

        newStudents[idx] = {
            ...newStudents[idx],
            status: "loading",
        };

        setAddStudentsProgressState((prev) => ({
            ...prev,
            inProgress: true,
            students: newStudents,
            currentLoading: {
                index: idx,
                errorCode: -1,
            },
        }));

        const student = newStudents[idx];

        const selectedGrade = student.gradeValue.split("-")[1];

        api.classes.addStudentV2({
            session: store.session,
            classId: tableState.studentsClassId,
            firstname: student.nameValue,
            lastname: student.lastNameValue,
            email: student.emailValue,
            grade: selectedGrade,
            addNewOnDuplicate,
        }).then((res) => {
            if (res.ok) {
                newStudents[idx] = {
                    ...newStudents[idx],
                    status: "success",
                };

                setAddStudentsProgressState((prev) => ({
                    ...prev,
                    students: newStudents,
                    totalLoaded: prev.totalLoaded + 1,
                }));

                onAddStudentsToClass(idx + 1, newStudents);
            } else {
                let errorCode = -1;

                if (res.error.indexOf("Fullname duplicate") !== -1) {
                    errorCode = 0;
                }

                if (res.error.indexOf("Email duplicate") !== -1) {
                    errorCode = 1;
                }

                if (errorCode === -1) {
                    newStudents[idx] = {
                        ...newStudents[idx],
                        status: "failed",
                    };
                }

                setAddStudentsProgressState((prev) => ({
                    ...prev,
                    students: newStudents,
                    currentLoading: {
                        ...prev.currentLoading,
                        errorCode,
                    },
                }));

                if (errorCode === -1) {
                    onAddStudentsToClass(idx + 1, newStudents);
                }
            }
        });
    };

    const onCloseNewPasswordPopup = () => {
        setNewPasswordPopupState({
            isOpen: false,
            isSubmitted: false,
            isLoading: false,
            message: null,
            id: null,
        });
    };

    const onChangeStudentPassword = (newPassword) => {
        setNewPasswordPopupState((prev) => ({
            ...prev,
            isSubmitted: true,
            isLoading: true,
        }));

        api.students.setPassword({
            session: store.session,
            studentId: newPasswordPopupState.id,
            password: newPassword,
        }).then((res) => {
            if (res.ok) {
                dispatch(actions.teacher.deleteTeacherStudentWithForgottenPassword({
                    studentId: newPasswordPopupState.id,
                }));
            }

            setNewPasswordPopupState((prev) => ({
                ...prev,
                isLoading: false,
                message: res.ok ? "Password successfully updated!" : res.error,
            }));
        });
    };

    const closeAddStudentPopup = () => {
        setVisibleAddStudentWindow(false);

        setVisibleAddStudentsPopup(false);
    };

    const onCloseAddStudentPopup = () => {
        closeAddStudentPopup();
    };

    const onCloseResetStudentPopup = () => {
        setResetStudentPasswordPopup({
            isOpen: false,
            isLoading: true,
            message: null,
        });
    };

    const onCloseDeleteSelectedUsersPopup = () => {
        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isOpen: false,
            isLoading: false,
            error: null,
            students: [],
        }));
    };

    const removeSelectedUsers = () => {
        const { students } = deleteSelectedUsersPopup;

        setDeleteSelectedUsersPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        for (let i = 0; i < students.length; i += 1) {
            api.classes.removeStudentById({
                session: store.session,
                studentId: students[i].id,
            }).then((res) => {
                if (i === students.length - 1) {
                    if (res.ok) {
                        loadGuardianStudents(defaultDropdownRange);

                        onCloseDeleteSelectedUsersPopup();
                    } else {
                        setDeleteSelectedUsersPopup((prev) => ({
                            ...prev,
                            isOpen: true,
                            isLoading: false,
                            error: res.error,
                        }));
                    }
                }
            });
        }
    };

    const onOpenStudentPopup = (userId) => {
        events.guardianStudentSelectStudent({
            session: store.session,
            studentId: userId,
        });

        loadStudentInfo(userId);

        loadStudentDailyJuices(userId);
    };

    const onCloseAddStudentProgressPopup = () => {
        setVisibleAddStudentsPopup(false);

        setAddStudentsProgressState(getAddStudentProgressState());
    };

    const onAddDuplicate = () => {
        onAddStudentsToClass(
            addStudentsProgressState.currentLoading.index,
            addStudentsProgressState.students,
            true,
        );
    };

    const onSkipDuplicate = () => {
        const newStudents = [
            ...addStudentsProgressState.students,
        ];

        newStudents[addStudentsProgressState.currentLoading.index] = {
            ...newStudents[addStudentsProgressState.currentLoading.index],
            status: "skipped",
        };

        setAddStudentsProgressState((prev) => ({
            ...prev,
            students: newStudents,
            totalLoaded: prev.totalLoaded + 1,
            currentLoading: {
                ...prev.currentLoading,
                errorCode: -1,
            },
        }));

        onAddStudentsToClass(
            addStudentsProgressState.currentLoading.index + 1,
            newStudents,
        );
    };

    const onAddStudentsToClassButtonClick = (students) => {
        setVisibleAddStudentsPopup(true);

        onAddStudentsToClass(0, students);
    };

    const onShowAddStudentPopup = () => {
        setVisibleAddStudentWindow(true);

        loadImportStudentsTemplate();
    };

    const onCloseStudentPopup = () => {
        setStudentInfoPopup(getStudentPopupState());
    };

    const onCloseDeletingPopup = () => {
        setDeleteStudentPopup({
            isOpen: false,
            isLoading: false,
            error: null,
            id: -1,
        });
    };

    const onRemoveStudent = (studentId) => {
        setDeleteStudentPopup({
            isOpen: true,
            isLoading: false,
            error: null,
            id: studentId,
        });
    };

    const removeStudent = () => {
        setDeleteStudentPopup((prev) => ({
            ...prev,
            isLoading: true,
        }));

        api.classes.removeStudentById({
            session: store.session,
            studentId: deleteStudentPopup.id,
        }).then((res) => {
            if (res.ok) {
                onCloseDeletingPopup();
                onCloseStudentPopup();

                loadGuardianStudents(defaultDropdownRange);
            } else {
                setDeleteStudentPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    error: res.error,
                }));
            }
        });
    };

    const onOpenNewPasswordPopup = (studentId) => {
        setNewPasswordPopupState({
            isLoading: false,
            isSubmitted: false,
            message: null,
            isOpen: true,
            id: studentId,
        });
    };

    const onResetStudentPassword = (student) => {
        if (student.hasEmail) {
            setResetStudentPasswordPopup({
                isLoading: true,
                isOpen: true,
                message: null,
            });

            api.classes.resetStudentPasswordById({
                session: store.session,
                studentId: student.id,
            }).then((res) => {
                setResetStudentPasswordPopup((prev) => ({
                    ...prev,
                    isLoading: false,
                    message: res.ok ? "Email successfully sent!" : res.error,
                }));
            });
        } else {
            onOpenNewPasswordPopup(student.id);
        }
    };

    const onSelectStudent = (id) => {
        events.guardianStudentSelectStudent({
            session: store.session,
            studentId: id,
        });

        loadStudentInfo(id);

        loadStudentDailyJuices(id);
    };

    const renderAddStudentPopup = () => {
        if (!isVisibleAddStudentWindow) {
            return null;
        }

        return (
            <PopupFullScreenAddStudent
                isMobile={store.dimensions.width < 800}
                importStudentsTemplateUrl={importStudentsTemplateUrl}
                grades={settings.grades}
                hideShareClassCodeTab
                onAddStudentsToClass={onAddStudentsToClassButtonClick}
                onClose={onCloseAddStudentPopup}
            />
        );
    };

    const renderNameChangePopup = () => {
        if (!nameChangePopupState.isOpen) {
            return null;
        }

        let defaultValue = "";

        if (studentInfoPopup?.user?.account?.fullName) {
            defaultValue = studentInfoPopup.user.account.fullName;
        }

        return (
            <PopupNameChange
                defaultValue={defaultValue}
                isSaving={nameChangePopupState.isSaving}
                error={nameChangePopupState.error}
                onSave={(newFullname) => {
                    onUpdateStudentInfo({
                        newFullname,
                    });
                }}
                onClose={() => {
                    setNameChangePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderEmailChangePopup = () => {
        if (!emailChangePopupState.isOpen) {
            return null;
        }

        let defaultValue = "";

        if (studentInfoPopup?.user?.account?.email) {
            defaultValue = studentInfoPopup.user.account.email;
        }

        return (
            <PopupEmailChange
                defaultValue={defaultValue}
                isSaving={emailChangePopupState.isSaving}
                error={emailChangePopupState.error}
                onSave={(newEmail) => {
                    onUpdateStudentInfo({
                        newEmail,
                    });
                }}
                onClose={() => {
                    setEmailChangePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderGradeChangePopup = () => {
        if (!changeGradePopupState.isOpen) {
            return null;
        }

        let defaultGrade = null;

        if (studentInfoPopup?.user?.overview?.studentGrade) {
            defaultGrade = getGradeGroup(studentInfoPopup.user.overview.studentGrade);
        }

        return (
            <PopupGradeChange
                defaultGrade={defaultGrade}
                grades={["5-6", "7-8", "9-10", "11-12"]}
                isSaving={changeGradePopupState.isSaving}
                error={changeGradePopupState.error}
                onSave={(newGrades) => {
                    const sGrades = newGrades.split("-");

                    if (sGrades.length > 1) {
                        onUpdateStudentInfo({
                            newGrade: `g${sGrades[1]}`,
                        });
                    }
                }}
                onClose={() => {
                    setChangeGradePopupState({
                        isOpen: false,
                        isSaving: false,
                        error: null,
                    });
                }}
            />
        );
    };

    const renderDeletePopup = () => {
        if (!deleteStudentPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                isLoading={deleteStudentPopup.isLoading}
                error={deleteStudentPopup.error}
                onDelete={removeStudent}
                onClose={onCloseDeletingPopup}
            />
        );
    };

    const renderNewPasswordPopup = () => {
        if (!newPasswordPopupState.isOpen) {
            return null;
        }

        return (
            <PopupNewPassword
                passwordMinLength={settings.password.minLength}
                message={newPasswordPopupState.message}
                isSubmitted={newPasswordPopupState.isSubmitted}
                isLoading={newPasswordPopupState.isLoading}
                onClose={onCloseNewPasswordPopup}
                onSave={onChangeStudentPassword}
            />
        );
    };

    const renderDeleteSelectedUsersPopup = () => {
        if (!deleteSelectedUsersPopup.isOpen) {
            return null;
        }

        return (
            <PopupConfirmDeleteStudent
                students={deleteSelectedUsersPopup.students}
                isLoading={deleteSelectedUsersPopup.isLoading}
                error={deleteSelectedUsersPopup.error}
                onDelete={removeSelectedUsers}
                onClose={onCloseDeleteSelectedUsersPopup}
            />
        );
    };

    const renderAddStudentMessagePopup = () => {
        if (!isVisibleAddStudentsPopup) {
            return null;
        }

        return (
            <PopupAddStudentsProgress
                data={addStudentsProgressState.students}
                inProgress={addStudentsProgressState.inProgress}
                totalLoaded={addStudentsProgressState.totalLoaded}
                currentStudentErrorCode={addStudentsProgressState.currentLoading.errorCode}
                currentLoadingStudentIndex={addStudentsProgressState.currentLoading.index}
                onAddDuplicate={onAddDuplicate}
                onSkipDuplicate={onSkipDuplicate}
                onClose={onCloseAddStudentProgressPopup}
            />
        );
    };

    const renderResetStudentPopup = () => {
        if (!resetStudentPasswordPopup.isOpen) {
            return null;
        }

        return (
            <PopupResetPassword
                onClose={onCloseResetStudentPopup}
                isLoading={resetStudentPasswordPopup.isLoading}
                message={resetStudentPasswordPopup.message}
            />
        );
    };

    const renderTable = () => {
        return (
            <TableStudents
                dateRange={dateRange}
                calendarDates={settings.calendarDates}
                onDateRangeChange={onDateRangeChange}
                sortValue={selectedSortByValue}
                data={sortedStudents}
                error={tableState.message}
                onOpenStudentPopup={onOpenStudentPopup}
                onAddStudent={onShowAddStudentPopup}
                onRemoveStudent={onRemoveStudent}
                onResetStudentPassword={onResetStudentPassword}
                onChangeSortValue={(value) => {
                    setSelectedSortByValue(value);
                }}
                onRemoveStudents={(s) => {
                    setDeleteSelectedUsersPopup((prev) => ({
                        ...prev,
                        isOpen: true,
                        students: s,
                    }));
                }}
                hideAddStudentButton={User.isTypeRegular(store.user)}
                isCards={store.dimensions.width < 1200}
                isOverview={tableState.isOverview}
                isLoading={!tableState.isLoaded}
            />
        );
    };

    const renderStudentInfoPopup = () => {
        if (!studentInfoPopup.isOpen) {
            return null;
        }

        let isStudentForgotPassword = false;
        let studentData = {};

        if (studentInfoPopup?.user?.account?.ID) {
            const { studentsWithForgottenPasswords } = store.teacher;

            for (let i = 0; i < studentsWithForgottenPasswords.length; i += 1) {
                if (studentsWithForgottenPasswords[i].id === studentInfoPopup.user.account.ID) {
                    isStudentForgotPassword = true;
                    break;
                }
            }

            studentData = {
                ...studentInfoPopup.user,
                isForgotPassword: isStudentForgotPassword,
            };
        }

        let dailyJuices = {};
        let isDailyJuicesLoading = false;

        if (store.juices?.studentJuicesById[studentInfoPopup.userId]) {
            dailyJuices = store.juices.studentJuicesById[studentInfoPopup.userId];

            if (store.juices.studentJuicesById[studentInfoPopup.userId].isLoading) {
                isDailyJuicesLoading = true;
            }
        }

        return (
            <StudentInfo
                studentId={studentInfoPopup.userId}
                student={studentData}
                isStudentLoaded={studentInfoPopup.isLoaded}
                studentsList={sortedStudents}
                dailyJuices={dailyJuices}
                isDailyJuicesLoading={isDailyJuicesLoading}
                isCards={store.dimensions.width < 900}
                hideStudentSelectorArrows={store.dimensions.width < 500}
                onEditName={() => {
                    setNameChangePopupState((prev) => ({
                        ...prev,
                        isOpen: true,
                    }));
                }}
                onEditEmail={() => {
                    setEmailChangePopupState((prev) => ({
                        ...prev,
                        isOpen: true,
                    }));
                }}
                onGradeEdit={() => {
                    setChangeGradePopupState((prev) => ({
                        ...prev,
                        isOpen: true,
                    }));
                }}
                onSelectStudent={onSelectStudent}
                onRemoveStudent={() => {
                    onRemoveStudent(studentInfoPopup.userId);
                }}
                onResetPassword={() => {
                    onOpenNewPasswordPopup(studentInfoPopup.userId);
                }}
                onLoadMoreJuices={() => {
                    loadStudentDailyJuices(studentInfoPopup.userId, false);
                }}
                onClose={onCloseStudentPopup}
            />
        );
    };

    return (
        <>
            {renderStudentInfoPopup()}
            {renderAddStudentPopup()}
            {renderAddStudentMessagePopup()}

            {renderResetStudentPopup()}
            {renderNewPasswordPopup()}

            {renderDeleteSelectedUsersPopup()}
            {renderDeletePopup()}

            {renderNameChangePopup()}
            {renderEmailChangePopup()}
            {renderGradeChangePopup()}

            <div className={styles.indexWrapper}>
                <div className={styles.index}>
                    {renderTable()}
                </div>
            </div>

            <UserFooter />
        </>
    );
};

export default withAuth(["guardian"])(Student);
