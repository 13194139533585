import React, { useEffect, useState } from "react";

import staticFiles from "juice-base/static-files.js";

import { validate, required, email } from "juice-base/lib/form-validators.js";
import classNames from "juice-base/lib/class-names.js";

import Grades from "juice-base/project/grades.js";

import Input from "juice-base/components/forms/input/index.js";
import Dropdown from "juice-base/components/dropdown/index.js";
import UploaderCSV from "juice-base/components/forms/uploader-csv";
import RequestLoader from "juice-base/components/request-loader/index.js";
import WizardMultiStep from "juice-base/components/wizard-multi-step/index.js";
import PopupFullScreen, {
    PopupFullScreenMenu,
    PopupFullScreenMenuButton,
    PopupFullScreenPage,
} from "juice-base/components/popup-full-screen/index.js";

import styles from "./styles.module.css";


const PopupFullScreenAddStudent = (props) => {
    const addStudentRowValues = {
        nameValue: "",
        nameError: null,

        lastNameValue: "",
        lastNameError: null,

        emailValue: "",
        emailError: null,

        gradeValue: props.defaultGrade,
    };

    // TODO: remove later
    // const shareCodeAddEmailRowValues = {
    //     emailValue: "",
    //     emailError: null,
    // };

    const pagesValues = {
        addManuallyValue: "add manually",
        shareClassCodeValue: "share class code",
        importDataValue: "import data",
    };

    const initSelectedPage = pagesValues.addManuallyValue;

    /* -------- */

    const [isMenuVisible, setMenuVisible] = useState(true);

    /* -------- */

    const [pageState, setPageState] = useState({
        selected: initSelectedPage,
    });

    const [addStudentsRowsLoading, setAddStudentsRowsLoading] = useState(false);

    const [addManuallyPageStudentsRows, setAddManuallyPageStudentsRows] = useState([
        { ...addStudentRowValues },
    ]);

    // TODO: remove later
    // const [shareClassCodeEmails, setShareClassCodeEmails] = useState([
    //     { ...shareCodeAddEmailRowValues },
    // ]);

    const [importDataRowsLoading, setImportDataRowsLoading] = useState(false);

    const [importDataPageState, setImportDataPageState] = useState({
        step: 0,
        importedStudents: [],
    });


    useEffect(() => {
        setPageState({
            selected: props.isMobile ? null : initSelectedPage,
        });
    }, [props.isMobile]);

    /* -------- */

    const onGenerateClassCode = () => {
        props.onGenerateClassCode();
    };

    const onDirectLinkClick = () => {
        props.onDirectLinkClick();
    };

    /* -------- */

    const validateName = (value) => {
        return validate(value, [
            required("Please enter a name"),
        ]);
    };

    const validateLastName = (value) => {
        return validate(value, [
            required("Please enter a last name"),
        ]);
    };

    const validateEmail = (value) => {
        return validate(value, [
            email("Sorry, that email address isn't valid. Please enter a valid one and try again."),
        ]);
    };

    /* -------- */

    const onAddManuallyPageInputNameChange = (value, id) => {
        const error = validateName(value);

        const newRows = [...addManuallyPageStudentsRows];

        newRows[id] = {
            ...newRows[id],
            nameValue: value,
            nameError: error,
        };

        setAddManuallyPageStudentsRows(newRows);
    };

    const onAddManuallyPageInputLastNameChange = (value, id) => {
        const error = validateLastName(value);

        const newRows = [...addManuallyPageStudentsRows];

        newRows[id] = {
            ...newRows[id],
            lastNameValue: value,
            lastNameError: error,
        };

        setAddManuallyPageStudentsRows(newRows);
    };

    const onAddManuallyPageInputEmailChange = (value, id, withValidation = true) => {
        let error = null;

        if (withValidation) {
            error = validateEmail(value);
        }

        const newRows = [...addManuallyPageStudentsRows];

        newRows[id] = {
            ...newRows[id],
            emailValue: value,
            emailError: error,
        };

        setAddManuallyPageStudentsRows(newRows);
    };

    const onAddManuallyPageGradeChange = (value, id) => {
        const newRows = [...addManuallyPageStudentsRows];

        newRows[id] = {
            ...newRows[id],
            gradeValue: value,
        };

        setAddManuallyPageStudentsRows(newRows);
    };

    const onAddManuallyPageAddAnotherStudent = () => {
        setAddManuallyPageStudentsRows([
            ...addManuallyPageStudentsRows,
            { ...addStudentRowValues },
        ]);
    };

    const onAddManuallyPageRemoveRow = (idx) => {
        setAddStudentsRowsLoading(true);

        const newRows = [];

        for (let i = 0; i < addManuallyPageStudentsRows.length; i += 1) {
            if (i !== idx) {
                newRows.push(addManuallyPageStudentsRows[i]);
            }
        }

        // NOTE: hack to show correct dropdown values
        setTimeout(() => {
            setAddManuallyPageStudentsRows(newRows);
            setAddStudentsRowsLoading(false);
        }, 500);
    };

    const onManuallyPageAddStudentsToClass = () => {
        props.onAddStudentsToClass(addManuallyPageStudentsRows);

        setAddManuallyPageStudentsRows([
            { ...addStudentRowValues },
        ]);
    };

    const isRowsHasErrorsOnAddManuallyPage = () => {
        for (let i = 0; i < addManuallyPageStudentsRows.length; i += 1) {
            if ((addManuallyPageStudentsRows[i].nameError
                    || !addManuallyPageStudentsRows[i].nameValue)
                || (addManuallyPageStudentsRows[i].lastNameError
                    || !addManuallyPageStudentsRows[i].lastNameValue)
                || addManuallyPageStudentsRows[i].emailError
                || !addManuallyPageStudentsRows[i].gradeValue) {
                return true;
            }
        }
        return false;
    };

    /* -------- */

    // TODO: remove later
    // const onShareCodePageEmailChange = (value, id) => {
    //     const error = validateEmail(value);

    //     const newRows = [...shareClassCodeEmails];

    //     newRows[id] = {
    //         emailValue: value,
    //         emailError: error,
    //     };

    //     setShareClassCodeEmails(newRows);
    // };

    // TODO: remove later
    // const onShareCodePageAddAnotherEmail = () => {
    //     setShareClassCodeEmails([
    //         ...shareClassCodeEmails,
    //         { ...shareCodeAddEmailRowValues },
    //     ]);
    // };

    // TODO: remove later
    // const onShareClassCodeRemoveEmailRow = (idx) => {
    //     const newRows = [];

    //     for (let i = 0; i < shareClassCodeEmails.length; i += 1) {
    //         if (i !== idx) {
    //             newRows.push(shareClassCodeEmails[i]);
    //         }
    //     }

    //     setShareClassCodeEmails(newRows);
    // };

    // TODO: remove later
    // const isEmailsHasErrorsOnShareClassCodePage = () => {
    //     for (let i = 0; i < shareClassCodeEmails.length; i += 1) {
    //         if (shareClassCodeEmails[i].emailError || !shareClassCodeEmails[i].emailValue) {
    //             return true;
    //         }
    //     }

    //     return false;
    // };

    // TODO: remove later
    // const onShareClassCodeSendEmailWithLink = () => {
    //     if (!isEmailsHasErrorsOnShareClassCodePage()) {
    //         props.onShareClassCodeSendEmails(shareClassCodeEmails, props.classCode);
    //     }
    // };

    /* -------- */

    const onImportDataPageImportComplete = (params) => {
        const rows = params.results.map((student, index) => {
            // NOTE: header row
            if (index === 0) {
                return null;
            }

            const studentData = {
                name: student?.data?.[0] || "",
                lastname: student?.data?.[1] || "",
                email: student?.data?.[2] || "",
                grade: Grades.getValidGrade(student?.data?.[3] || props.defaultGrade),
            };

            if (!studentData.name && !studentData.lastname && !studentData.email) {
                return null;
            }

            const nameError = validateName(studentData.name);
            const lastNameError = validateLastName(studentData.lastname);
            const emailError = studentData.email ? validateEmail(studentData.email) : null;

            return {
                nameValue: studentData.name,
                nameError,

                lastNameValue: studentData.lastname,
                lastNameError,

                emailValue: studentData.email,
                emailError,

                gradeValue: studentData.grade,
            };
        }).filter((row) => row !== null);

        setImportDataPageState((prev) => ({
            ...prev,
            step: 1,
            importedStudents: rows,
        }));
    };

    const onImportDataPageInputNameChange = (value, id) => {
        const error = validateName(value);

        const newRows = [...importDataPageState.importedStudents];

        newRows[id] = {
            ...newRows[id],
            nameValue: value,
            nameError: error,
        };

        setImportDataPageState((prev) => ({
            ...prev,
            importedStudents: newRows,
        }));
    };

    const onImportDataPageInputLastNameChange = (value, id) => {
        const error = validateLastName(value);

        const newRows = [...importDataPageState.importedStudents];

        newRows[id] = {
            ...newRows[id],
            lastNameValue: value,
            lastNameError: error,
        };

        setImportDataPageState((prev) => ({
            ...prev,
            importedStudents: newRows,
        }));
    };

    const onImportDataPageInputEmailChange = (value, id, withValidation = true) => {
        let error = null;

        if (withValidation) {
            error = validateEmail(value);
        }

        const newRows = [...importDataPageState.importedStudents];

        newRows[id] = {
            ...newRows[id],
            emailValue: value,
            emailError: error,
        };

        setImportDataPageState((prev) => ({
            ...prev,
            importedStudents: newRows,
        }));
    };

    const onImportDataPageGradeChange = (value, id) => {
        const newRows = [...importDataPageState.importedStudents];

        newRows[id] = {
            ...newRows[id],
            gradeValue: value,
        };

        setImportDataPageState((prev) => ({
            ...prev,
            importedStudents: newRows,
        }));
    };

    const onImportDataPageRemoveRow = (idx) => {
        setImportDataRowsLoading(true);

        const newRows = [];

        for (let i = 0; i < importDataPageState.importedStudents.length; i += 1) {
            if (i !== idx) {
                newRows.push(importDataPageState.importedStudents[i]);
            }
        }

        // NOTE: hack to show correct dropdown values
        setTimeout(() => {
            setImportDataPageState((prev) => ({
                ...prev,
                importedStudents: newRows,
            }));
            setImportDataRowsLoading(false);
        }, 500);
    };

    const isRowsHasErrorsOnImportDataPage = () => {
        const { importedStudents } = importDataPageState;

        for (let i = 0; i < importedStudents.length; i += 1) {
            if ((importedStudents[i].nameError
                    || !importedStudents[i].nameValue)
                || (importedStudents[i].lastNameError
                    || !importedStudents[i].lastNameValue)
                || importedStudents[i].emailError
                || !importedStudents[i].gradeValue) {
                return true;
            }
        }

        return false;
    };

    const onImportDataPageUploadDifferentFile = () => {
        setImportDataPageState({
            step: 0,
            importedStudents: [],
        });
    };

    const onImportDataPageProccedToConfirmation = () => {
        if (!isRowsHasErrorsOnImportDataPage()) {
            setImportDataPageState((prev) => ({
                ...prev,
                step: 2,
            }));
        }
    };

    const onImportDataPageBackToEdit = () => {
        setImportDataPageState((prev) => ({
            ...prev,
            step: 1,
        }));
    };

    const onImportDataPageAddStudents = () => {
        if (!isRowsHasErrorsOnImportDataPage()) {
            props.onAddStudentsToClass(importDataPageState.importedStudents);
            setImportDataPageState({
                step: 0,
                importedStudents: [],
            });
        }
    };

    /* -------- */

    const onChangePage = (value) => {
        if (props.isMobile) {
            setMenuVisible(false);
        }

        setPageState({
            selected: value,
        });
    };

    /* -------- */

    const renderInputField = (params) => {
        return (
            <Input
                name={params.name}
                placeholder={params.placeholder}
                label={params.label}
                error={params.error}
                value={params.value}
                iconSrc={params.iconSrc ? params.iconSrc : null}
                onChange={(evt) => {
                    params.onChange(evt.target.value);
                }}
                onBlur={() => {
                    if (params.onBlur) {
                        params.onBlur();
                    }
                }}
            />
        );
    };

    const renderGradeLevelDropdown = (params = {}) => {
        let gradeLabel = (params.rowValue);

        for (let i = 0; i < props.grades.length; i += 1) {
            if (props.grades[i].value === params.rowValue) {
                gradeLabel = props.grades[i].label;
                break;
            }
        }

        let selector = (gradeLabel);

        if (params.isEditable) {
            selector = (
                <Dropdown
                    selectedValue={params.rowValue}
                    values={props.grades}
                    addStudentTheme
                    onChange={(value) => {
                        params.onGradeChange(value.value, params.idx);
                    }}
                />
            );
        }

        return (
            <div className={styles.gradeDropdown}>
                <div className={styles.gradeDropdownLabel}>
                    Reading level *
                </div>
                {selector}
            </div>
        );
    };

    const renderAddStudentsRows = (params = {}) => {
        const isMoreThanOneRow = params.rows.length > 1;

        return params.rows.map((row, idx) => {
            let removeRowButton = null;

            if (isMoreThanOneRow && params.isEditable) {
                removeRowButton = (
                    <div
                        className={styles.removeRowButton}
                        onClick={() => {
                            params.onRemoveRow(idx);
                        }}
                        onKeyPress={() => {
                            params.onRemoveRow(idx);
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        <img
                            src={staticFiles.iconTrashV2}
                            alt="Remove row"
                            title="Remove row"
                        />
                    </div>
                );
            }

            const rowClassName = classNames({
                [styles.contentRowFields]: true,
                [styles.rowWithTrash]: removeRowButton,
            });

            const firstNameLabel = "First Name *";
            const lastNameLabel = "Last Name *";
            const emailLabel = "Email";

            let nameField = (
                <div className={styles.reviewField}>
                    <div>{firstNameLabel}</div>
                    <div className={styles.reviewFieldValues}>{row.nameValue}</div>
                </div>
            );

            let lastNameField = (
                <div className={styles.reviewField}>
                    <div>{lastNameLabel}</div>
                    <div className={styles.reviewFieldValues}>{row.lastNameValue}</div>
                </div>
            );

            let emailField = (
                <div className={styles.reviewField}>
                    <div>{emailLabel}</div>
                    <div className={styles.reviewFieldValues}>{row.emailValue}</div>
                </div>
            );

            if (params.isEditable) {
                nameField = (renderInputField({
                    name: `name-field-${idx}`,
                    label: firstNameLabel,
                    placeholder: "Example: Dylan",
                    value: row.nameValue,
                    error: row.nameError,
                    onChange: (value) => {
                        params.onInputNameChange(value, idx);
                    },
                }));

                lastNameField = (renderInputField({
                    name: `last-name-field-${idx}`,
                    label: lastNameLabel,
                    placeholder: "Example: Ement",
                    value: row.lastNameValue,
                    error: row.lastNameError,
                    onChange: (value) => {
                        params.onInputLastNameChange(value, idx);
                    },
                }));

                emailField = (renderInputField({
                    name: `email-field-${idx}`,
                    label: emailLabel,
                    placeholder: "example@thejuice.news",
                    value: row.emailValue,
                    error: row.emailError,
                    onChange: (value) => {
                        params.onInputEmailChange(value, idx, true);
                    },
                    onBlur: () => {
                        if (!row.emailValue) {
                            params.onInputEmailChange("", idx, false);
                        }
                    },
                }));
            }

            return (
                <div className={rowClassName}>
                    <div>{nameField}</div>
                    <div>{lastNameField}</div>
                    <div>{emailField}</div>
                    {renderGradeLevelDropdown({
                        rowValue: row.gradeValue,
                        idx,
                        isEditable: params.isEditable,
                        onGradeChange: (value, index) => {
                            params.onGradeChange(value, index);
                        },
                    })}
                    {removeRowButton}
                </div>
            );
        });
    };

    const renderShareClassCodePage = () => {
        const menu = [
            {
                label: "Class Code",
                onClick: () => {
                    onGenerateClassCode();
                },
            },
            {
                label: "Direct Link",
                onClick: () => {
                    onDirectLinkClick();
                },
            },
        ];

        const blocks = menu.map((block) => {
            return (
                <div className={styles.shareClassCodePageBlock}>
                    <div className={styles.shareClassCodePageBlockLabel}>
                        {block.label}
                    </div>
                    <div
                        className={styles.generateButton}
                        onClick={() => {
                            block.onClick();
                        }}
                        onKeyPress={() => {
                            block.onClick();
                        }}
                        tabIndex="-1"
                        role="button"
                    >
                        Generate
                    </div>
                </div>
            );
        });

        // TODO: remove later
        // const isMoreThanOneRow = shareClassCodeEmails.length > 1;

        // const emails = shareClassCodeEmails.map((sEmail, idx) => {
        //     const emailRowClassName = classNames({
        //         [styles.emailRow]: true,
        //         [styles.emailRowWithTrash]: isMoreThanOneRow,
        //     });

        //     let removeRowButton = null;

        //     if (isMoreThanOneRow) {
        //         removeRowButton = (
        //             <div
        //                 className={styles.removeRowButton}
        //                 onClick={() => {
        //                     onShareClassCodeRemoveEmailRow(idx);
        //                 }}
        //                 onKeyPress={() => {
        //                     onShareClassCodeRemoveEmailRow(idx);
        //                 }}
        //                 tabIndex="-1"
        //                 role="button"
        //             >
        //                 <img
        //                     src={staticFiles.iconTrashV2}
        //                     alt="Remove row"
        //                     title="Remove row"
        //                 />
        //             </div>
        //         );
        //     }

        //     return (
        //         <div className={emailRowClassName}>
        //             <div className={styles.shareClassCodeEmailField}>
        //                 {renderInputField({
        //                     name: `share-class-code-email-${idx}`,
        //                     label: "Email",
        //                     placeholder: "Student's Email Address",
        //                     value: sEmail.emailValue,
        //                     error: sEmail.emailError,
        //                     iconSrc: staticFiles.iconEmailGrey,
        //                     onChange: (value) => {
        //                         onShareCodePageEmailChange(value, idx);
        //                     },
        //                 })}
        //                 <div className={styles.shareClassCodeEmailExample}>
        //                     Example: johnsmith@thejuice.news
        //                 </div>
        //             </div>
        //             {removeRowButton}
        //         </div>
        //     );
        // });

        // TODO: remove later
        // const sendEmailButtonClassName = classNames({
        //     [styles.sendEmailWithLinkButton]: true,
        //     [styles.buttonDisabled]: isEmailsHasErrorsOnShareClassCodePage(),
        // });

        return (
            <div className={styles.shareClassCodePage}>
                <div className={styles.shareClassCodePageTitle}>
                    Share the code or link with your students.
                </div>
                <div className={styles.shareClassCodePageBlocks}>
                    {blocks}
                </div>
                {/* TODO: remove later */}
                {/* <div className={styles.shareClassCodePageEmail}>
                    <div className={styles.shareClassCodePageTitle}>
                        Send an email with the link to your students.
                    </div>
                    <div className={styles.shareClassCodePageEmails}>
                        {emails}
                    </div>
                    <div
                        className={styles.addAnotherEmailButton}
                        onClick={onShareCodePageAddAnotherEmail}
                        onKeyPress={onShareCodePageAddAnotherEmail}
                        role="button"
                        tabIndex="-1"
                    >
                        <img
                            src={staticFiles.iconAddInCircleBlue}
                            alt="Add"
                        />
                        Add another email
                    </div>
                    <div
                        className={sendEmailButtonClassName}
                        onClick={onShareClassCodeSendEmailWithLink}
                        onKeyPress={onShareClassCodeSendEmailWithLink}
                        tabIndex="-1"
                        role="button"
                    >
                        Send email with link
                    </div>
                </div> */}
            </div>
        );
    };

    const renderAddStudentsManuallyPage = () => {
        if (addStudentsRowsLoading) {
            return (
                <div className={styles.contentRow}>
                    <RequestLoader />
                </div>
            );
        }

        const isAddStudentsButtonDisabled = isRowsHasErrorsOnAddManuallyPage();

        const addStudentsButtonClassName = classNames({
            [styles.buttonBlue]: true,
            [styles.buttonDisabled]: isAddStudentsButtonDisabled,
        });

        return (
            <div className={styles.contentRow}>
                {renderAddStudentsRows({
                    isEditable: true,
                    rows: addManuallyPageStudentsRows,
                    onRemoveRow: (idx) => {
                        onAddManuallyPageRemoveRow(idx);
                    },
                    onInputNameChange: (value, idx) => {
                        onAddManuallyPageInputNameChange(value, idx);
                    },
                    onInputLastNameChange: (value, idx) => {
                        onAddManuallyPageInputLastNameChange(value, idx);
                    },
                    onInputEmailChange: (value, idx, withValidation) => {
                        onAddManuallyPageInputEmailChange(value, idx, withValidation);
                    },
                    onGradeChange: (value, index) => {
                        onAddManuallyPageGradeChange(value, index);
                    },
                })}
                <div
                    className={styles.addAnotherStudentButton}
                    onClick={onAddManuallyPageAddAnotherStudent}
                    onKeyPress={onAddManuallyPageAddAnotherStudent}
                    role="button"
                    tabIndex="-1"
                >
                    <img
                        src={staticFiles.iconAddInCircleBlue}
                        alt="Add"
                    />
                    Add another student
                </div>

                <div
                    className={addStudentsButtonClassName}
                    onClick={() => {
                        if (!isAddStudentsButtonDisabled) {
                            onManuallyPageAddStudentsToClass();
                        }
                    }}
                    onKeyPress={() => {
                        if (!isAddStudentsButtonDisabled) {
                            onManuallyPageAddStudentsToClass();
                        }
                    }}
                    role="button"
                    tabIndex="-1"
                >
                    Add students to class
                </div>
            </div>
        );
    };

    const renderImportDataValuePage = () => {
        let importTemplate = null;

        if (props.importStudentsTemplateUrl) {
            importTemplate = (
                <div className={styles.importDataPageTemplate}>
                    <div>
                        Not sure what to upload?
                    </div>
                    <a
                        href={props.importStudentsTemplateUrl}
                    >
                        Download and edit this template
                    </a>
                </div>
            );
        }

        const uploadFileContent = (
            <div>
                <UploaderCSV
                    onUploadComplete={onImportDataPageImportComplete}
                />
                {importTemplate}
            </div>
        );

        const proccedButtonClassName = classNames({
            [styles.buttonBlue]: true,
            [styles.buttonDisabled]: isRowsHasErrorsOnImportDataPage(),
        });

        let reviewDataControls = null;

        if (importDataPageState.importedStudents.length !== 0) {
            reviewDataControls = (
                <div className={styles.importDataPageButtons}>
                    <div
                        className={[styles.buttonBlue, styles.buttonBlueOutlined].join(" ")}
                        onClick={onImportDataPageUploadDifferentFile}
                        onKeyPress={onImportDataPageUploadDifferentFile}
                        tabIndex="-1"
                        role="button"
                    >
                        Upload a different file
                    </div>
                    <div
                        className={proccedButtonClassName}
                        onClick={onImportDataPageProccedToConfirmation}
                        onKeyPress={onImportDataPageProccedToConfirmation}
                        tabIndex="-1"
                        role="button"
                    >
                        Proceed to confirmation
                    </div>
                </div>
            );
        }

        let reviewDataContent = (
            <div>
                <div>
                    {renderAddStudentsRows({
                        isEditable: true,
                        rows: importDataPageState.importedStudents,
                        onRemoveRow: (idx) => {
                            onImportDataPageRemoveRow(idx);
                        },
                        onInputNameChange: (value, idx) => {
                            onImportDataPageInputNameChange(value, idx);
                        },
                        onInputLastNameChange: (value, idx) => {
                            onImportDataPageInputLastNameChange(value, idx);
                        },
                        onInputEmailChange: (value, idx, withValidation) => {
                            onImportDataPageInputEmailChange(value, idx, withValidation);
                        },
                        onGradeChange: (value, index) => {
                            onImportDataPageGradeChange(value, index);
                        },
                    })}
                </div>
                {reviewDataControls}
            </div>
        );

        if (importDataRowsLoading) {
            reviewDataContent = (
                <div className={styles.contentRow}>
                    <RequestLoader />
                </div>
            );
        }

        const addStudentsContent = (
            <div>
                <div>
                    {renderAddStudentsRows({
                        isEditable: false,
                        rows: importDataPageState.importedStudents,
                    })}
                </div>
                <div className={styles.importDataPageButtons}>
                    <div
                        className={[styles.buttonBlue, styles.buttonBlueOutlined].join(" ")}
                        onClick={onImportDataPageBackToEdit}
                        onKeyPress={onImportDataPageBackToEdit}
                        tabIndex="-1"
                        role="button"
                    >
                        Back to edit
                    </div>
                    <div
                        className={proccedButtonClassName}
                        onClick={onImportDataPageAddStudents}
                        onKeyPress={onImportDataPageAddStudents}
                        tabIndex="-1"
                        role="button"
                    >
                        Add students
                    </div>
                </div>
            </div>
        );

        const wizardSteps = [
            { name: "Upload File", content: uploadFileContent },
            { name: "Review Data", content: reviewDataContent },
            { name: "Add Students", content: addStudentsContent },
        ];

        return (
            <div className={styles.importDataPage}>
                <WizardMultiStep
                    steps={wizardSteps}
                    step={importDataPageState.step}
                    onStepChange={(step) => {
                        setImportDataPageState((prev) => ({
                            ...prev,
                            step,
                        }));
                    }}
                />
            </div>
        );
    };

    const isShareClassCodePageSelected = pageState.selected === pagesValues.shareClassCodeValue;
    const isImportDataPageSelected = pageState.selected === pagesValues.importDataValue;
    const isAddManuallyPageSelected = pageState.selected === pagesValues.addManuallyValue;

    let shareClassCodeTab = null;

    if (!props.hideShareClassCodeTab) {
        shareClassCodeTab = (
            <PopupFullScreenMenuButton
                selected={isShareClassCodePageSelected}
                onClick={() => {
                    onChangePage(pagesValues.shareClassCodeValue);
                }}
            >
                Share Class Code
            </PopupFullScreenMenuButton>
        );
    }

    const menu = (
        <PopupFullScreenMenu>
            <PopupFullScreenMenuButton
                selected={isAddManuallyPageSelected}
                onClick={() => {
                    onChangePage(pagesValues.addManuallyValue);
                }}
            >
                Add Manually
            </PopupFullScreenMenuButton>
            {shareClassCodeTab}
            <PopupFullScreenMenuButton
                selected={isImportDataPageSelected}
                onClick={() => {
                    onChangePage(pagesValues.importDataValue);
                }}
            >
                Import Data
            </PopupFullScreenMenuButton>
        </PopupFullScreenMenu>
    );

    const pages = [
        <PopupFullScreenPage isOpen={isShareClassCodePageSelected}>
            {renderShareClassCodePage()}
        </PopupFullScreenPage>,
        <PopupFullScreenPage isOpen={isImportDataPageSelected}>
            {renderImportDataValuePage()}
        </PopupFullScreenPage>,
        <PopupFullScreenPage isOpen={isAddManuallyPageSelected}>
            {renderAddStudentsManuallyPage()}
        </PopupFullScreenPage>,
    ];

    return (
        <PopupFullScreen
            popupTitle="Add Student"
            isMenuVisible={isMenuVisible}
            isMobile={props.isMobile}
            menu={menu}
            pages={pages}
            onMenuVisibleChange={(value) => {
                setMenuVisible(value);
            }}
            onClose={props.onClose}
        />
    );
};

PopupFullScreenAddStudent.defaultProps = {
    isMobile: false,
    importStudentsTemplateUrl: null,
    hideShareClassCodeTab: false,
    classCode: null,
    defaultGrade: "G7-G8",
    grades: [],
    onDirectLinkClick: () => {},
    onGenerateClassCode: () => {},
    onShareClassCodeSendEmails: () => {},
    onAddStudentsToClass: () => {},
    onClose: () => {},
};

export default PopupFullScreenAddStudent;
