// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kgFiUFNBTtV5AeTnnm_7o {\n    background-color: #fff;\n}\n\n.sc0Y7jjNU2eNFsgpnD0Tq {\n    padding: 0;\n\n    display: grid;\n    justify-content: center;\n\n    background-color: #fff;\n}\n\n._2Brtv6NKlepTGAsXpAJhGO {\n    display: grid;\n\n    background-color: #f3f2f7;\n}\n\n@media only screen and (min-width: 621px) {\n    .sc0Y7jjNU2eNFsgpnD0Tq {\n        grid-template-columns: 60rem;\n    }\n\n    ._2Brtv6NKlepTGAsXpAJhGO {\n        justify-content: center;\n        grid-template-columns: 60rem;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .sc0Y7jjNU2eNFsgpnD0Tq {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./base/business/swiper-daily-juice-story-page/styles.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;;IAEV,aAAa;IACb,uBAAuB;;IAEvB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;;IAEb,yBAAyB;AAC7B;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,uBAAuB;QACvB,4BAA4B;IAChC;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".page {\n    background-color: #fff;\n}\n\n.story {\n    padding: 0;\n\n    display: grid;\n    justify-content: center;\n\n    background-color: #fff;\n}\n\n.quiz {\n    display: grid;\n\n    background-color: #f3f2f7;\n}\n\n@media only screen and (min-width: 621px) {\n    .story {\n        grid-template-columns: 60rem;\n    }\n\n    .quiz {\n        justify-content: center;\n        grid-template-columns: 60rem;\n    }\n}\n\n@media only screen and (min-width: 1025px) {\n    .story {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "kgFiUFNBTtV5AeTnnm_7o",
	"story": "sc0Y7jjNU2eNFsgpnD0Tq",
	"quiz": "_2Brtv6NKlepTGAsXpAJhGO"
};
export default ___CSS_LOADER_EXPORT___;
