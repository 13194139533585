import React, { useState } from "react";
import Select, { components } from "react-select";

import classNames from "juice-base/lib/class-names.js";
import staticFiles from "juice-base/static-files.js";

import styles from "./styles.module.css";


const selectStyles = {
    indicatorSeparator: () => ({
        display: "none",
    }),

    placeholder: () => ({
        fontSize: "1.5rem",
        color: "black",
        marginLeft: "0.4rem",
    }),

    control: (provided, state) => ({
        display: "flex",
        minHeight: state.selectProps.customControlStyles.minHeight,
        minWidth: state.selectProps.customControlStyles.minWidth,
        backgroundColor: state.selectProps.customControlStyles.backgroundColor,
        border: state.selectProps.customControlStyles.border,
        borderRadius: "0.5rem",
        opacity: state.selectProps.customControlStyles.opacity,
        cursor: "pointer",
        outline: "none",
        boxShadow: state.selectProps.customControlStyles.controlBoxShadow,
    }),

    option: (provided, state) => ({
        ...provided,
        color: state.isSelected
            ? state.selectProps.customOptionStyles.colorSelected
            : state.selectProps.customOptionStyles.colorDefault,
        backgroundColor: state.isFocused
            ? state.selectProps.customOptionStyles.backgroundColorFocused
            : state.selectProps.customOptionStyles.backgroundColorDefault,
        fontWeight: 600,
        borderBottom: "solid 0.1rem #ebebeb",
        padding: state.selectProps.customOptionStyles.padding,
        cursor: "pointer",
    }),

    menu: () => ({
        zIndex: 999999,
        position: "absolute",
        left: "0",
        boxShadow: "0.1rem 0.1rem 0.3rem 0.1rem #d5d5d5",
        width: "100%",
        borderRadius: "0.5rem",
    }),

    menuList: (provided) => ({
        ...provided,
        paddingBottom: "0",
    }),

    singleValue: (provided, state) => ({
        opacity: state.selectProps.customSingleValueStyles.opacity,
        fontWeight: state.selectProps.customSingleValueStyles.fontWeight,
    }),
};

const Control = ({ children, ...props }) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.Control {...props}>
            <span className={styles.icon}>
                {props.selectProps.icon}
            </span>
            {children}
        </components.Control>
    );
};

const DropdownIndicator = ({ children, ...props }) => {
    let indicator = children;

    if (props.selectProps.customIndicator) {
        indicator = props.selectProps.customIndicator;
    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.DropdownIndicator {...props}>
            {indicator}
        </components.DropdownIndicator>
    );
};

const Dropdown = (props) => {
    const {
        name,
        values,
        selectedValue,
        isDisabled,
    } = props;

    const [dropdownSelectedValue, setDropdownSelectedValue] = useState(selectedValue || null);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const onChange = (val) => {
        props.onChange(val);
        setDropdownSelectedValue(val.value);
    };

    let defaultValue = {
        label: props.defaultValueLabel,
        value: -1,
    };

    const selectedItem = values.filter((item) => item.value === selectedValue);

    let newValue = values;

    if (selectedItem[0]) {
        defaultValue = {
            label: selectedItem[0].label,
            value: selectedItem[0].value,
        };

        newValue = newValue.map((value) => {
            let newLabel = value.label;

            let isSelected = false;

            if (!dropdownSelectedValue
                && value.value === selectedItem[0].value
            ) {
                isSelected = true;
            } else if (value.value === dropdownSelectedValue) {
                isSelected = true;
            }

            if (isSelected) {
                const selectedValueClassName = classNames({
                    [styles.selectedValue]: true,
                    [styles.selectedValueOrange]: props.orangeTheme,
                });

                let check = (<div>&#10003;</div>);

                if (props.orangeTheme) {
                    check = (
                        <div className={styles.checkOrange}>
                            <img
                                src={staticFiles.iconCheckOrange}
                                alt="check"
                            />
                        </div>
                    );
                }

                newLabel = (
                    <div className={selectedValueClassName}>
                        {value.label}
                        {check}
                    </div>
                );
            }

            return {
                ...value,
                label: newLabel,
            };
        });
    }

    const customSingleValueStyles = {
        opacity: isDisabled ? 0.5 : 1,
        fontWeight: "normal",
    };

    const customOptionStyles = {
        colorDefault: "#585858",
        colorSelected: "#2D9CDB",

        backgroundColorDefault: "#fff",
        backgroundColorFocused: "#dbe7ff",

        padding: "1rem 1.5rem",
    };

    const customControlStyles = {
        backgroundColor: "#fff",
        opacity: "1",
        border: "0.1rem solid #C6C6C6",
        controlBoxShadow: "0rem 0rem 0.1rem 0.1rem #00000017",
        minWidth: props.isMobile ? "11rem" : "17rem",
        minHeight: "auto",
    };

    if (props.addStudentTheme) {
        customControlStyles.minHeight = "4.5rem";
    }

    if (props.orangeTheme) {
        customControlStyles.backgroundColor = "transparent";
        customControlStyles.opacity = "0.6";
        customControlStyles.border = "none";
        customControlStyles.transparent = "none";
        customControlStyles.minHeight = "5.4rem";

        customSingleValueStyles.fontWeight = "600";

        customOptionStyles.colorDefault = "#000";
        customOptionStyles.colorSelected = "#F97316";
        customOptionStyles.backgroundColorFocused = "#fffaf2";
        customOptionStyles.padding = "1.5rem 2rem";
    }

    if (!props.withControlBoxShadow || props.orangeTheme) {
        customControlStyles.controlBoxShadow = "none";
    }

    let icon = null;

    if (Object.keys(props.icon).length > 0 && props.icon.src) {
        icon = (
            <div
                className={[
                    props.iconContainerClassName,
                    styles.icon,
                ].join(" ")}
            >
                <img
                    src={props.icon.src}
                    alt={props.icon.alt || ""}
                    title={props.icon.title || ""}
                />
            </div>
        );
    }

    let indicator = props.customIndicator;

    if (!indicator && props.orangeTheme) {
        if (isMenuOpen) {
            indicator = (
                <img
                    className={styles.caret}
                    src={staticFiles.caretOrange}
                    alt="Opened Menu"
                />
            );
        } else {
            indicator = (
                <img
                    className={styles.caret}
                    src={staticFiles.caret}
                    alt="Open Menu"
                />
            );
        }
    }

    const dropdownClassName = classNames({
        [styles.field]: true,
        [styles.fieldOrange]: props.orangeTheme,
        [styles.fieldOrangeOpened]: props.orangeTheme && isMenuOpen,
        [props.fieldContainerClassName]: props.fieldContainerClassName,
    });

    return (
        <div
            className={dropdownClassName}
            data-comment={props.dataComment}
        >
            <Select
                id={name}
                name={name}
                icon={icon}
                customIndicator={indicator}
                components={{ Control, DropdownIndicator }}
                options={newValue}
                defaultValue={defaultValue}
                styles={selectStyles}
                isDisabled={isDisabled}
                isSearchable={false}
                customOptionStyles={customOptionStyles}
                customControlStyles={customControlStyles}
                customSingleValueStyles={customSingleValueStyles}
                onChange={onChange}
                onMenuOpen={() => {
                    setMenuOpen(true);
                }}
                onMenuClose={() => {
                    setMenuOpen(false);
                }}
            />
        </div>
    );
};

Dropdown.defaultProps = {
    dataComment: "",
    name: "",

    values: [],

    selectedValue: 0,
    defaultValueLabel: "Select...",

    isMobile: false,
    isDisabled: false,
    orangeTheme: false,

    addStudentTheme: null,
    icon: {},
    customIndicator: null,

    iconContainerClassName: "",
    fieldContainerClassName: "",
    onChange: () => {},
};

export default Dropdown;
