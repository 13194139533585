import React from "react";

import staticFiles from "juice-base/static-files.js";

import Text from "juice-base/components/text/index.js";
import ButtonFlat from "juice-base/components/button-flat/index.js";
import PopupConfirm, {
    PopupConfirmIcon,
    PopupConfirmContent,
    PopupConfirmButtons,
} from "juice-base/components/popup-confirm/index.js";


const PopupConfirmClassCode = (props) => {
    let content = `
        It looks like you're trying to join ${props.teacherFullName}'s class!
        That's great!
    `;

    if (props.message) {
        content = (
            <Text>
                {props.message}
            </Text>
        );
    }

    return (
        <PopupConfirm>
            <PopupConfirmIcon
                src={staticFiles.iconUsersBlue}
                alt="Users"
            />

            <PopupConfirmContent>
                {content}
            </PopupConfirmContent>

            <PopupConfirmButtons>
                <ButtonFlat onClick={props.onContinue}>
                    Continue
                </ButtonFlat>

                <ButtonFlat onClick={props.onClose}>
                    Enter a Different Class Code
                </ButtonFlat>
            </PopupConfirmButtons>
        </PopupConfirm>
    );
};

PopupConfirmClassCode.defaultProps = {
    teacherFullName: "",
    message: "",
    onContinue: () => {},
    onClose: () => {},
};

export default PopupConfirmClassCode;
