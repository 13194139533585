export default (apiURL, methods) => {
    return {
        getAllClassesAnnouncementsByTeacher(params = {}) {
            const url = `${apiURL}/classes/announcements/by-teacher`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        updateAllTeacherClassesAnnouncements(params = {}) {
            const url = `${apiURL}/classes/announcements/by-teacher`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.id,
                announcement_content: params.content,
                announcement_title: params.title,
            });
        },

        /* --- */

        getAllSponsorsByTeacher(params = {}) {
            const url = `${apiURL}/classes/sponsors`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        updateAllTeacherClassesSponsor(params = {}) {
            const url = `${apiURL}/classes/sponsors/by-teacher`;

            return methods.post(url, {
                session_id: params.session,
                sponsor_image: params.sponsorImageId,
                sponsor_content: params.sponsorContent,
            });
        },

        uploadSponsorMedia(params = {}) {
            const url = `${apiURL}/classes/sponsors/media`;

            return methods.postForm(url, {
                session_id: params.session,
                file: params.file,
            });
        },

        /* --- */

        getTeacherClasses(params = {}) {
            const url = `${apiURL}/classes/by-teacher`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        /* --- */

        getClassAnnouncement(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/announcement`;

            return methods.get(url);
        },

        updateClassAnnouncement(params = {}) {
            const url = `${apiURL}/classes/${params.id}/announcement`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.id,
                announcement_content: params.content,
                announcement_title: params.title,
            });
        },

        getClassJuices(params = {}) {
            // TODO: delete request
            const url = `${apiURL}/classes/${params.classId}/daily-juices`;

            return methods.get(url);
        },

        /* --- */

        getClassSponsors(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/sponsors`;

            return methods.get(url);
        },

        updateClassSponsors(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/sponsors`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,
                sponsor_image: params.sponsorImageId,
                sponsor_content: params.sponsorContent,
            });
        },

        // TOOD: delete request
        addStudent(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/students/add`;

            return methods.get(url, {
                action: "add_student",
                session_id: params.session,
                full_name: params.fullname,
                email: params.email,
            });
        },

        addStudentV2(params = {}) {
            // NOTE: action=add_student_v2
            const url = `${apiURL}/classes/${params.classId}/students/add`;

            return methods.post(url, {
                session_id: params.session,
                class_id: params.classId,

                firstname: params.firstname,
                lastname: params.lastname,
                email: params.email,
                grade: params.grade,
                add_new_on_duplicate: params.addNewOnDuplicate,
            });
        },

        resetStudentPasswordById(params = {}) {
            // NOTE: action=reset_student_password
            const url = `${apiURL}/classes/students/reset-password`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },

        acceptStudentToClass(params = {}) {
            // NOTE: action=class_approve_student_by_teacher
            const url = `${apiURL}/students/${params.studentId}/approve`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        rejectStudentToClass(params = {}) {
            // NOTE: action=class_reject_student_by_teacher
            const url = `${apiURL}/students/${params.studentId}/reject`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
                class_id: params.classId,
            });
        },

        dismissStudentWithForgottenPassword(params = {}) {
            // NOTE: action=class_dismiss_student_by_teacher_with_forgotten_password
            const url = `${apiURL}/students/${params.studentId}/dismiss`;

            return methods.get(url, {
                session_id: params.session,
            });
        },

        removeStudentById(params = {}) {
            const url = `${apiURL}/classes/students/remove`;

            return methods.get(url, {
                session_id: params.session,
                student_id: params.studentId,
            });
        },

        getClassStudentsById({
            session,
            classId,
            dateFrom,
            dateTo,
        }) {
            const url = `${apiURL}/classes/${classId}/students`;

            return methods.get(url, {
                session_id: session,
                date_from: dateFrom,
                date_to: dateTo,
            });
        },

        getJuiceStats(params = {}) {
            const url = `${apiURL}/classes/${params.classId}/stats/juice`;

            return methods.get(url, {
                session_id: params.session,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        getStudentsActivity(params = {}) {
            // NOTE: action=get_student_activity
            const url = `${apiURL}/classes/${params.classId}/activity`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
                date_from: params.dateFrom,
                date_to: params.dateTo,
            });
        },

        // TODO: delete request
        updateStudentData(params = {}) {
            // NOTE: action=update_student_data
            const url = `${apiURL}/classes/students`;

            return methods.post(url, {
                session_id: params.session,
                user_id: params.studentId,
                fullname: params.fullname,
                email: params.email,
            });
        },

        getStudentsWithForgottenPasswords(params = {}) {
            // NOTE: action=get_students_with_forgotten_passwords
            const url = `${apiURL}/students/with-forgotten-passwords`;

            return methods.get(url, {
                session_id: params.session,
                class_id: params.classId,
            });
        },

        getStudentsWithPendingStatus(params = {}) {
            // NOTE: action=get_class_students_with_pending_status
            const url = `${apiURL}/classes/${params.classId}/students/pending`;

            return methods.get(url, {
                session_id: params.session,
                page: params.page,
            });
        },
    };
};
