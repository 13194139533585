import React from "react";

import ScrollMenu from "react-horizontal-scrolling-menu";
import staticFiles from "../../static-files.js";

import styles from "./styles.module.css";


const HorizontalScrollingMenu = (props) => {
    const arrowRightClasses = [styles.arrowRight];
    const arrowLeftClasses = [styles.arrowLeft];

    if (props.hideArrows) {
        arrowRightClasses.push(styles.disabledArrows);
        arrowLeftClasses.push(styles.disabledArrows);
    }

    const arrowRight = (
        <img
            className={arrowRightClasses.join(" ")}
            src={staticFiles.arrowRight}
            alt="Scroll Left"
        />
    );

    const arrowLeft = (
        <img
            className={arrowLeftClasses.join(" ")}
            src={staticFiles.arrowRight}
            alt="Scroll Left"
        />
    );

    return (
        <div
            className={props.menuClassName}
            data-comment={props.dataComment}
        >
            <ScrollMenu
                data={props.menu}
                alignCenter={false}
                wheel={false}
                innerWrapperClass={styles.innerWrapperClass}
                onSelect={props.onSelect}
                arrowRight={arrowRight}
                arrowLeft={arrowLeft}
                arrowDisabledClass={styles.hiddenArrows}
                hideSingleArrow
                disableTabindex
                inertiaScrolling
                inertiaScrollingSlowdown={1}
                onLastItemVisible={props.onLastItemVisible}
                onFirstItemVisible={props.onFirstItemVisible}
                selected={props.selected}
                scrollToSelected
            />
        </div>
    );
};

HorizontalScrollingMenu.defaultProps = {
    menu: [],
    hideArrows: false,
    menuClassName: "",
    dataComment: "",
    selected: 0,
    onSelect: () => {},
    onLastItemVisible: () => {},
    onFirstItemVisible: () => {},
};

export default HorizontalScrollingMenu;
