import React from "react";
import AsyncSelect from "react-select/async"; // eslint-disable-line import/extensions


const styles = {
    dropdownIndicator: () => ({
        display: "none",
    }),

    indicatorSeparator: () => ({
        display: "none",
    }),

    placeholder: () => ({
        fontSize: "1.2rem",
        color: "#bfbfbf",
        marginLeft: "0.2rem",
    }),

    control: () => ({
        display: "flex",
        backgroundColor: "#eaeaea",
        borderRadius: 20,
    }),
};

const Searcher = (props) => {
    const {
        onLoad,
        onChange,
    } = props;

    return (
        <AsyncSelect
            cacheOptions
            defaultOptions
            placeholder="Search"
            loadOptions={onLoad}
            onChange={onChange}
            isClearable
            styles={styles}
        />
    );
};

Searcher.defaultProps = {
    onLoad: () => {
        return new Promise((resolve) => { // eslint-disable-line compat/compat
            resolve([]);
        });
    },
    onChange: () => { },
};

export default Searcher;
