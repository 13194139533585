import React, { useEffect, useState, useRef } from "react";

import Dropdown from "../dropdown/index.js";

import styles from "./styles.module.css";


const Tabs = (props) => {
    const {
        tabs,
        selectedClassId,
        onChange,
        dimensions,
    } = props;

    const tabsRef = useRef(null);

    const tabsElements = useRef([]);

    const [selectedTab, setSelectedTab] = useState(0);

    const [isDropdownView, setDropdownView] = useState(false);

    const isEllipsisActive = (e) => {
        return (e.offsetWidth < e.scrollWidth);
    };

    useEffect(() => {
        let ellipsisActive = false;

        for (let i = 0; i < tabsElements.current.length; i += 1) {
            if (tabsElements.current[i]) {
                if (isEllipsisActive(tabsElements.current[i])) {
                    ellipsisActive = true;
                    break;
                }
            }
        }

        if (tabsRef.current && isEllipsisActive(tabsRef.current)) {
            ellipsisActive = true;
        }

        if (ellipsisActive) {
            setDropdownView(true);
        } else {
            setDropdownView(false);
        }
    }, [dimensions]);

    useEffect(() => {
        for (let i = 0; i < tabs.length; i += 1) {
            if (tabs[i].id === selectedClassId) {
                setSelectedTab(i);
                break;
            }
        }
    }, [selectedClassId]);

    const onSelect = (index, tab) => {
        setSelectedTab(index);
        onChange(tab.title, tab.id);
    };

    const onSelectDropdown = (tab) => {
        for (let i = 0; i < tabs.length; i += 1) {
            if (tabs[i].id === tab.id) {
                setSelectedTab(i);
                break;
            }
        }

        onChange(tab.label, tab.value);
    };


    let dropdown = null;

    if (isDropdownView) {
        const values = [];

        tabs.forEach((tab) => {
            values.push({
                value: tab.id,
                label: tab.title,
            });
        });

        dropdown = (
            <Dropdown
                values={values}
                onChange={onSelectDropdown}
                selectedValue={selectedClassId}
            />
        );
    }

    const ts = [];

    tabs.forEach((tab, index) => {
        let tabClasses = [styles.tab];
        const tabTitleClasses = [styles.tabTitle];

        if (props.tabTheme === "white") {
            tabClasses = [styles.tabWhite];
        }

        if (index === selectedTab) {
            if (props.tabTheme === "white") {
                tabClasses.push(styles.whiteTabSelected);
                tabTitleClasses.push(styles.whiteTabTitleSelected);
            } else {
                tabClasses.push(styles.tabSelected);
                tabTitleClasses.push(styles.tabTitleSelected);
            }
        }

        const title = tab.title || "";

        const onTabClick = () => {
            onSelect(index, tab);
        };

        const elem = (
            <div
                key={tab.id}
                className={tabClasses.join(" ")}
                role="button"
                tabIndex="-1"
                onClick={onTabClick}
                onKeyPress={onTabClick}
            >
                <div
                    className={tabTitleClasses.join(" ")}
                    ref={(currElem) => {
                        tabsElements.current[index] = currElem;
                    }}
                >
                    {title}
                </div>
            </div>
        );

        ts.push(elem);
    });

    const classes = [styles.tabs];

    if (isDropdownView) {
        classes.push(styles.hiddenTabs);
    }

    if (props.tabTheme === "white") {
        classes.push(styles.tabsWhite);
    }

    if (props.tabsClasses) {
        classes.push(props.tabsClasses);
    }

    return (
        <div data-comment={props.dataComment}>
            <div
                className={classes.join(" ")}
                ref={tabsRef}
            >
                {ts}
            </div>
            {dropdown}
        </div>
    );
};

Tabs.defaultProps = {
    tabs: [],
    selectedClassId: 0,
    dimensions: {},
    dataComment: "",
    tabsClasses: "",
    onChange: () => {},
};

export default Tabs;
