// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1oVd_7BWZGO3d12e8diqPz {\n    padding: 0 2rem 2rem 2rem;\n\n    display: grid;\n    justify-content: center;\n}\n\n._2yg1oWp5Lo9LUl15kyyUra {\n    max-width: 90rem;\n\n    word-break: break-word;\n}\n\n@media only screen and (min-width: 1025px) {\n    ._1oVd_7BWZGO3d12e8diqPz {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/page/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;;IAEzB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;;IAEhB,sBAAsB;AAC1B;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".index {\n    padding: 0 2rem 2rem 2rem;\n\n    display: grid;\n    justify-content: center;\n}\n\n.content {\n    max-width: 90rem;\n\n    word-break: break-word;\n}\n\n@media only screen and (min-width: 1025px) {\n    .index {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "_1oVd_7BWZGO3d12e8diqPz",
	"content": "_2yg1oWp5Lo9LUl15kyyUra"
};
export default ___CSS_LOADER_EXPORT___;
