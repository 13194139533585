// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._13m30QCHX5WuaFOD1ON2I- {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n\n    width: 100%;\n    height: 100%;\n\n    z-index: 200;\n\n    background-color: #c4c4c4e6;\n    color: #ffffff;\n}\n\n._3ogbj9enm2xPKbGsoflN2T {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./base/components/popup-scroller/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;;IAER,WAAW;IACX,YAAY;;IAEZ,YAAY;;IAEZ,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf","sourcesContent":[".popup {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n\n    width: 100%;\n    height: 100%;\n\n    z-index: 200;\n\n    background-color: #c4c4c4e6;\n    color: #ffffff;\n}\n\n.popupClose {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "_13m30QCHX5WuaFOD1ON2I-",
	"popupClose": "_3ogbj9enm2xPKbGsoflN2T"
};
export default ___CSS_LOADER_EXPORT___;
