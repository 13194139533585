// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.mpSdJ16HwLRYx1IBm0QHw {\n    display: grid;\n    padding: 0 2rem 2rem 2rem;\n}\n\n._2a0kHeF1SffWMLJktZcNWB {\n    width: 100%;\n    max-width: 135rem;\n\n    justify-self: center;\n}\n\n._3QGRB88AGXBOOLQ-VkGQ5y {\n    display: grid;\n    justify-content: start;\n}\n\n._1v9BChRUT1kvaWDs-U9BIV {\n    width: 100%;\n}\n\n._2QErD7HGCO8FoiZo5-FJJI {\n    color: crimson;\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    ._2a0kHeF1SffWMLJktZcNWB {\n        padding-top: 2rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://./app/views/student/styles.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,iBAAiB;;IAEjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["\n.indexWrapper {\n    display: grid;\n    padding: 0 2rem 2rem 2rem;\n}\n\n.index {\n    width: 100%;\n    max-width: 135rem;\n\n    justify-self: center;\n}\n\n.studentsList {\n    display: grid;\n    justify-content: start;\n}\n\n.table {\n    width: 100%;\n}\n\n.errorMessage {\n    color: crimson;\n    text-align: center;\n}\n\n@media only screen and (min-width: 1025px) {\n    .index {\n        padding-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indexWrapper": "mpSdJ16HwLRYx1IBm0QHw",
	"index": "_2a0kHeF1SffWMLJktZcNWB",
	"studentsList": "_3QGRB88AGXBOOLQ-VkGQ5y",
	"table": "_1v9BChRUT1kvaWDs-U9BIV",
	"errorMessage": "_2QErD7HGCO8FoiZo5-FJJI"
};
export default ___CSS_LOADER_EXPORT___;
