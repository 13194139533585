import React from "react";

import PopupCommon from "juice-base/components/popup-common/index.js";
import DailyJuiceStats from "juice-base/components/daily-juice-stats/index.js";

import styles from "./styles.module.css";


const PopupDailyJuiceStats = (props) => {
    if (!props.stats) {
        return null;
    }

    return (
        <PopupCommon
            onClose={props.onClose}
            showClose={false}
            closeOnLayoutClick
        >
            <div className={styles.popup}>
                <div className={styles.popupWindow}>
                    <DailyJuiceStats
                        isWindows={props.isWindows}
                        stats={props.stats}
                        sponsors={props.sponsors}
                        onSponsorClick={props.onSponsorClick}
                        onScrollTo={props.onScrollTo}
                    />
                </div>
            </div>
        </PopupCommon>
    );
};

PopupDailyJuiceStats.defaultProps = {
    stats: null,
    sponsors: {},
    isWindows: false,
    onSponsorClick: () => {},
    onScrollTo: () => {},
    onClose: () => {},
};

export default PopupDailyJuiceStats;
