// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1G6jgooeNr6ZA1eo2qAuFp {\n    min-height: 6.4rem;\n\n    display: grid;\n    grid-template-columns: 5rem auto;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.1rem solid #cccccc;\n    border-radius: 0.5rem;\n\n    background-color: #fff;\n    color: #000;\n}\n\n._1QCKBmQgmNX9E3kOzTHu8N {\n    background-color: #ffffff;\n}\n\n.oqkhcdHdjkP_FDjV4EDC7 {\n    display: block;\n    padding: 0.3rem 0 0.3rem 0;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.atgPvY9FEzlnWNEKOZsuR {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n._3E9E5Cbq8WRRyFV9wCrvcm {\n    padding: 0;\n    margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/forms/radio/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,aAAa;IACb,gCAAgC;IAChC,mBAAmB;;IAEnB,kBAAkB;IAClB,oBAAoB;;IAEpB,4BAA4B;IAC5B,qBAAqB;;IAErB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,0BAA0B;;IAE1B,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,SAAS;AACb","sourcesContent":[".field {\n    min-height: 6.4rem;\n\n    display: grid;\n    grid-template-columns: 5rem auto;\n    align-items: center;\n\n    margin: 0 0 1rem 0;\n    padding: 1rem 1.5rem;\n\n    border: 0.1rem solid #cccccc;\n    border-radius: 0.5rem;\n\n    background-color: #fff;\n    color: #000;\n}\n\n.fieldChecked {\n    background-color: #ffffff;\n}\n\n.fieldLabel {\n    display: block;\n    padding: 0.3rem 0 0.3rem 0;\n\n    cursor: pointer;\n    outline: none;\n}\n\n.fieldWrapper {\n    width: 4rem;\n    height: 4rem;\n\n    display: grid;\n    justify-content: center;\n    align-items: center;\n}\n\n.fieldInput {\n    padding: 0;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "_1G6jgooeNr6ZA1eo2qAuFp",
	"fieldChecked": "_1QCKBmQgmNX9E3kOzTHu8N",
	"fieldLabel": "oqkhcdHdjkP_FDjV4EDC7",
	"fieldWrapper": "atgPvY9FEzlnWNEKOZsuR",
	"fieldInput": "_3E9E5Cbq8WRRyFV9wCrvcm"
};
export default ___CSS_LOADER_EXPORT___;
