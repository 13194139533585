import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";


const Collapse = (props) => {
    const [isExtended, setExtended] = useState(false);

    useEffect(() => {
        setExtended(props.isExtended);
    }, [props.isExtended]);

    const onSectionClick = () => {
        setExtended(!isExtended);

        props.onSectionClick({
            isExtended: !isExtended,
        });
    };

    let icon = null;

    if (props.icon) {
        icon = (
            <img
                src={props.icon.src}
                alt={props.icon.title}
                title={props.icon.title}
            />
        );
    }

    let buttonContent = props.extendButton;

    const contentStyles = {
        display: "none",
    };

    if (isExtended) {
        contentStyles.display = "block";
        buttonContent = props.collapseButton;
    }

    const renderTitle = () => {
        if (!props.withDot) {
            return (props.title);
        }

        return (
            <div className={styles.collapseTitleWithDot}>
                <div className={styles.dot} />
                {props.title}
            </div>
        );
    };

    return (
        <div>
            <div
                className={[styles.collapse, props.collapseClassName].join(" ")}
                onKeyPress={() => {
                    if (!props.extendWithButton) {
                        onSectionClick();
                    }
                }}
                onClick={() => {
                    if (!props.extendWithButton) {
                        onSectionClick();
                    }
                }}
                role="button"
                tabIndex="-1"
            >
                <div className={styles.collapseTitle}>
                    {icon}
                    {renderTitle()}
                </div>
                <div>
                    <div
                        className={[styles.collapseButton, props.collapseButtonClassName].join(" ")}
                        onKeyPress={() => {
                            if (props.extendWithButton) {
                                onSectionClick();
                            }
                        }}
                        onClick={() => {
                            if (props.extendWithButton) {
                                onSectionClick();
                            }
                        }}
                        role="button"
                        tabIndex="-1"
                    >
                        {buttonContent}
                    </div>
                </div>
            </div>
            <div
                className={[styles.expandedContent, props.extendedContentClassName].join(" ")}
                style={contentStyles}
            >
                {props.children}
            </div>
        </div>
    );
};

Collapse.defaultProps = {
    isExtended: false,

    title: "",

    extendButton: "+",
    collapseButton: "-",

    collapseClassName: "",
    extendedContentClassName: "",
    collapseButtonClassName: "",

    extendWithButton: false,

    icon: null,
    withDot: false,
    children: null,

    onWordClick: () => {},
    onSectionClick: () => {},
};


export default Collapse;
